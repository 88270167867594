import { ThemeToggleDropdown } from './ThemeToggleDropdown';
import { ClientError } from '@/components/Error/ClientError';
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from '@/components/ui/sidebar';
import { ErrorBoundaryFallback } from '@/features/Core';
import { AppSidebar } from '@/features/Core/components/AppSidebar';
import { useAppStore } from '@/stores';
import { ErrorBoundary } from '@sentry/react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

const AdministrationLayout = () => {
  const isGlobalAdmin = useAppStore((state) => state.isGlobalAdmin);

  const { pathname } = useLocation();

  if (!isGlobalAdmin) {
    return (
      <div className="lg:ml-16 flex flex-col lg:flex-row grow">
        <div className="pt-8 lg:pl-6">
          <h2 className="text-2xl font-semibold mx-2 mb-2 lg:mx-0 lg:mb-7">
            Adminstration
          </h2>
        </div>
        <ClientError code={403} />
      </div>
    );
  }

  return (
    <SidebarProvider>
      <ScrollRestoration />
      <AppSidebar />
      <SidebarInset>
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="w-full flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <ThemeToggleDropdown className="ml-auto" />
          </div>
        </header>
        <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
          <div className="min-h-[100vh] flex-1 rounded-xl md:min-h-min">
            <ErrorBoundary
              fallback={ErrorBoundaryFallback}
              key={pathname}
            >
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};

export { AdministrationLayout };
