import { IncidentSettingsEnabled } from '../components/IncidentSettingsEnabled';
import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const IncidentTypesGql = graphql(`
  query IncidentTypesGql($organizationId: String!) {
    incidentTypes {
      totalCount
      nodes {
        id
        name
        description
        category
        allowlist
        useAllowlist
        iconSvg
      }
    }
    organization(id: $organizationId) {
      id
      enabledIncidentTypes {
        totalCount
        nodes {
          id
          incidentTypeId
          defaultAssignedGroupId
        }
      }
      groups {
        nodes {
          id
          name
        }
      }
    }
  }
`);

const IncidentTypes = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, fetching }] = useQuery({
    query: IncidentTypesGql,
    variables: {
      organizationId,
    },
  });

  const enabledTotal =
    data?.organization?.enabledIncidentTypes.nodes.length ?? 0;

  return (
    <div className="space-y-10">
      <section aria-labelledby="personal-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="settings-general"
            >
              Incident Types
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Manage which incident types are enabled in the organization and
              define default assignment groups.
            </p>
            <div className="mt-4">
              <Badge variant="secondary">
                {enabledTotal} Incidents Enabled
              </Badge>
            </div>
          </div>
          <div className="md:col-span-2">
            {fetching || !data?.organization ? (
              <>
                <Skeleton className="w-full h-10 mb-4" />
                <div className="divide-y">
                  <div className="flex gap-4 py-2 border-t">
                    <Skeleton className="w-12 h-12" />
                    <Skeleton className="grow w-3/5 h-32" />
                    <Skeleton className="w-1/5 h-6" />
                  </div>
                  <div className="flex gap-4 py-2">
                    <Skeleton className="w-12 h-12" />
                    <Skeleton className="grow w-3/5 h-32" />
                    <Skeleton className="w-1/5 h-6" />
                  </div>
                  <div className="flex gap-4 py-2">
                    <Skeleton className="w-12 h-12" />
                    <Skeleton className="grow w-3/5 h-32" />
                    <Skeleton className="w-1/5 h-6" />
                  </div>
                  <div className="flex gap-4 py-2">
                    <Skeleton className="w-12 h-12" />
                    <Skeleton className="grow w-3/5 h-32" />
                    <Skeleton className="w-1/5 h-6" />
                  </div>
                  <div className="flex gap-4 py-2">
                    <Skeleton className="w-12 h-12" />
                    <Skeleton className="grow w-3/5 h-32" />
                    <Skeleton className="w-1/5 h-6" />
                  </div>
                </div>
              </>
            ) : (
              <IncidentSettingsEnabled
                enabledIncidentTypes={
                  data.organization.enabledIncidentTypes.nodes ?? []
                }
                groups={data.organization.groups.nodes ?? []}
                incidentTypes={data.incidentTypes?.nodes ?? []}
                organizationId={organizationId}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export { IncidentTypes };
