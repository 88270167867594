import { MemberActivity } from './MemberActivity';
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import { useQuery } from 'urql';

const MemberOverviewGql = graphql(`
  query MemberOverviewGql($memberId: String!) {
    member(id: $memberId) {
      id
      guardmesTotal: guardmes {
        totalCount
      }
      incidentsTotal: incidentsByContactMemberId {
        totalCount
      }
      checkInsTotal: checkIns {
        totalCount
      }
    }
  }
`);

type Props = {
  readonly className?: string;
  readonly memberId: string;
  readonly setTab: (value: string) => void;
};

const MemberOverview = ({ className, memberId, setTab }: Props) => {
  const [{ data, fetching }] = useQuery({
    query: MemberOverviewGql,
    variables: {
      memberId,
    },
  });

  const statistics = [
    {
      id: 1,
      tab: 'checkin',
      title: 'Check In Submissions',
      value: data?.member?.checkInsTotal.totalCount ?? 0,
    },
    {
      id: 2,
      tab: 'guardme',
      title: 'GuardMe Sessions',
      value: data?.member?.guardmesTotal.totalCount ?? 0,
    },
    {
      id: 3,
      tab: 'incident',
      title: 'Incidents',
      value: data?.member?.incidentsTotal.totalCount ?? 0,
    },
  ];

  return (
    <section
      className={cn('py-2 space-y-8', className)}
      id="member-overview"
    >
      <div className="grid grid-cols-1 gap-4 sm:max-w-3xl sm:grid-cols-3">
        {statistics.map((item) => (
          <button
            key={item.id}
            onClick={() => {
              setTab(item.tab);
            }}
            type="button"
          >
            <Card className="relative p-4 hover:bg-gray-50 hover:dark:bg-gray-900">
              <p className="text-left text-sm text-muted-foreground">
                {item.title}
              </p>
              {fetching ? (
                <Skeleton className="mt-3 h-9 w-9" />
              ) : (
                <p className="mt-3 flex items-end">
                  <span className="text-3xl font-semibold">
                    {item.value.toLocaleString()}
                  </span>
                </p>
              )}
            </Card>
          </button>
        ))}
      </div>

      <MemberActivity memberId={memberId} />
    </section>
  );
};

export { MemberOverview };
