import { BreadcrumbLink } from './ui/breadcrumb';
import { useAppStore } from '@/stores';
import { Link } from 'react-router-dom';

const DashboardBreadcrumbLink = () => {
  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership) {
    return (
      <BreadcrumbLink asChild>
        <Link to="/home">Home</Link>
      </BreadcrumbLink>
    );
  }

  return (
    <BreadcrumbLink asChild>
      <Link to="/dashboard">{activeMembership.name}</Link>
    </BreadcrumbLink>
  );
};

export { DashboardBreadcrumbLink };
