import { MutationError } from '../../Core';
import { GroupsSelect } from './GroupsSelect';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { graphql } from '@/gql';
import {
  type Group,
  MemberRole,
  type MembershipCode,
  type MembershipRequest,
  type Organization,
} from '@/gql/graphql';
import { parseMetadata } from '@/lib/metadata';
import { fmtPhoneNumber } from '@/utils';
import { Check, Loader, X } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { array, type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const MembershipRequestDenyQuery = graphql(`
  mutation MembershipRequestDeny($requestId: String!) {
    denyMembershipRequest(input: { id: $requestId }) {
      membershipRequest {
        id
      }
    }
  }
`);

const MembershipRequestApproveQuery = graphql(`
  mutation MembershipRequestApprove(
    $requestId: String!
    $role: MemberRole!
    $groupIds: [String!]!
    $publicMetadata: JSON!
    $privateMetadata: JSON!
    $displayName: String!
  ) {
    approveMembershipRequest(
      input: {
        id: $requestId
        role: $role
        groupIds: $groupIds
        publicMetadata: $publicMetadata
        privateMetadata: $privateMetadata
        displayName: $displayName
      }
    ) {
      member {
        id
      }
    }
  }
`);

type MembershipRequestProps = {
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly memberMetadataFields?: Pick<Organization, 'memberMetadataFields'>;
  readonly memberRequest: Pick<
    MembershipRequest,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'fullName'
    | 'phoneNumber'
    | 'emailAddress'
    | 'publicMetadata'
    | 'organizationId'
  > & {
    membershipCode?: Pick<
      MembershipCode,
      'id' | 'code' | 'groupIds' | 'organizationRole'
    > | null;
  };
};

const MemberRequestForm = ({
  groups = [],
  memberMetadataFields,
  memberRequest,
}: MembershipRequestProps) => {
  const navigate = useNavigate();

  const [{ error: denyError, fetching: denyFetching }, denyRequest] =
    useMutation(MembershipRequestDenyQuery);

  const [{ error: approveError, fetching: approveFetching }, approveRequest] =
    useMutation(MembershipRequestApproveQuery);

  const error = denyError || approveError;

  const dynamicSchema = useMemo(() => {
    return parseMetadata(memberMetadataFields, memberRequest.publicMetadata);
  }, [memberMetadataFields, memberRequest]);

  const schema = object({
    displayName: string(),
    groupIds: array(string()),
    metadata: dynamicSchema.schema,
    organizationRole: string(),
    requestId: string(),
  });

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      displayName: '',
      groupIds: (memberRequest.membershipCode?.groupIds as string[]) ?? [],
      metadata: dynamicSchema.defaultValues,
      organizationRole:
        memberRequest.membershipCode?.organizationRole ?? MemberRole.Member,
      requestId: memberRequest.id,
    },
  });

  useEffect(() => {
    form.reset({
      displayName: '',
      groupIds: (memberRequest.membershipCode?.groupIds as string[]) ?? [],
      metadata: dynamicSchema.defaultValues,
      organizationRole:
        memberRequest.membershipCode?.organizationRole ?? MemberRole.Member,
      requestId: memberRequest.id,
    });
  }, [dynamicSchema, form, memberRequest]);

  const handleDenyRequest = async () => {
    await denyRequest({
      requestId: memberRequest.id,
    });

    navigate('/members?view=requests');
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    const { data: memberData } = await approveRequest({
      displayName: values.displayName,
      groupIds: values.groupIds,
      privateMetadata: values.metadata,
      publicMetadata: values.metadata,
      requestId: memberRequest.id,
      role: values.organizationRole as MemberRole,
    });

    if (memberData?.approveMembershipRequest?.member?.id) {
      navigate(`/members/${memberData.approveMembershipRequest.member.id}`);
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {memberRequest.phoneNumber && (
          <div className="space-y-2">
            <FormLabel>Phone Number</FormLabel>
            <Input
              readOnly
              value={fmtPhoneNumber(memberRequest.phoneNumber)}
            />
          </div>
        )}

        {memberRequest.emailAddress && (
          <div className="space-y-2">
            <FormLabel>Email Address</FormLabel>
            <Input
              readOnly
              value={memberRequest.emailAddress}
            />
          </div>
        )}

        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Display Name</FormLabel>
              <FormControl>
                <Input
                  placeholder=""
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="lg:grid lg:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="organizationRole"
            render={({ field: { onChange, value } }) => (
              <FormItem>
                <FormLabel>Role</FormLabel>
                <Select
                  defaultValue={value}
                  onValueChange={onChange}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a role" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value={MemberRole.Member}>Member</SelectItem>
                    <SelectItem value={MemberRole.Supervisor}>
                      Supervisor
                    </SelectItem>
                    <SelectItem value={MemberRole.Administrator}>
                      Admin
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="groupIds"
            render={({ field: { name, onChange, value } }) => (
              <FormItem>
                <FormLabel>Groups</FormLabel>
                <FormControl>
                  <GroupsSelect
                    groups={groups}
                    name={name}
                    onChange={onChange}
                    value={value}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        {dynamicSchema.formFields.map((item) => (
          <FormField
            control={form.control}
            key={item.id}
            name={`metadata.${item.name}`}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <FormItem>
                {typeof value === 'string' ? (
                  <>
                    <FormLabel>{item.name}</FormLabel>
                    <FormControl>
                      <Input
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        value={value}
                      />
                    </FormControl>
                    <FormMessage />
                  </>
                ) : (
                  <>
                    <FormLabel>{item.name}</FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={value}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                      />
                    </FormControl>
                    <FormMessage />
                  </>
                )}
              </FormItem>
            )}
          />
        ))}

        <MutationError error={error} />

        <div className="flex items-center justify-end gap-2 border-t pt-4">
          <Button
            disabled={approveFetching || denyFetching}
            onClick={handleDenyRequest}
            variant="ghost"
          >
            <X className="h-6 w-6 mr-2" />
            Deny
          </Button>
          <Button
            disabled={approveFetching || denyFetching}
            type="submit"
          >
            {approveFetching ? (
              <Loader className="h-6 w-6 animate-spin mr-2" />
            ) : (
              <Check className="h-6 w-6 mr-2" />
            )}
            Approve
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { MemberRequestForm };
