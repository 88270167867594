import { Button, type ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { type Column } from '@tanstack/react-table';
import { ChevronDown, ChevronsUpDown, ChevronUp } from 'lucide-react';

type DataTableColumnHeaderProps<TData, TValue> = {
  readonly column: Column<TData, TValue>;
  readonly title: string;
} & ButtonProps;

export const DataTableColumnHeader = <TData, TValue>({
  className,
  column,
  title,
  ...props
}: DataTableColumnHeaderProps<TData, TValue>) => {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  const direction = column.getIsSorted();

  return (
    <Button
      className="-m-2 pl-2"
      onClick={() => column.toggleSorting(undefined)}
      variant="ghost"
      {...props}
    >
      ID
      {!direction && <ChevronsUpDown />}
      {direction === 'asc' && <ChevronUp />}
      {direction === 'desc' && <ChevronDown />}
    </Button>
  );
};
