import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import { cn } from '@/lib/utils';
import { type DetailedHTMLProps, type ImgHTMLAttributes } from 'react';

const ZoomableImage = ({
  alt,
  className,
  src,
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  if (!src) return null;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <img
          alt={alt || ''}
          className={cn('w-full, h-auto cursor-zoom-in', className)}
          height={100}
          sizes="100vw"
          src={src}
          width={500}
        />
      </DialogTrigger>
      <DialogContent className="max-w-7xl border-0 bg-muted/50 p-0">
        <div className="relative h-[calc(100vh-220px)] w-full overflow-clip rounded-md bg-transparent shadow-md">
          <img
            alt={alt || ''}
            className="h-full w-full object-contain"
            src={src}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { ZoomableImage };
