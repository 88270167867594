import { Input } from './ui/input';
import { type InputHTMLAttributes, useEffect, useState } from 'react';

type DebouncedInputProps = {
  readonly debounce?: number;
  readonly onChange: (value: string) => void;
  readonly value: string | number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const DebouncedInput = ({
  debounce = 200,
  onChange,
  value: initialValue,
  ...props
}: DebouncedInputProps) => {
  const [value, setValue] = useState<string>(initialValue.toString());

  useEffect(() => {
    setValue(initialValue.toString());
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      {...props}
      onChange={(event) => {
        if (event.target.value === '') {
          setValue('');
        } else {
          setValue(event.target.value);
        }
      }}
      value={value ?? ''}
    />
  );
};

export { DebouncedInput };
