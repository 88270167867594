import { TimePickerInput } from './time-picker-input';
import { TimePickerPeriodSelect } from './time-picker-period-select';
import { type Period } from './time-picker-utils';
import { Label } from '@/components/ui/label';
import * as React from 'react';

type TimePickerDemoProps = {
  readonly date: Date | undefined;
  readonly includeSeconds?: boolean;
  readonly setDate: (date: Date | undefined) => void;
};

const TimePickerTwelveHour = ({
  date,
  includeSeconds,
  setDate,
}: TimePickerDemoProps) => {
  const [period, setPeriod] = React.useState<Period>('PM');

  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);
  const periodRef = React.useRef<HTMLButtonElement>(null);

  return (
    <div className="flex items-end gap-2">
      <div className="grid gap-1 text-center">
        <Label
          className="text-xs"
          htmlFor="hours"
        >
          Hours
        </Label>
        <TimePickerInput
          date={date}
          onRightFocus={() => minuteRef.current?.focus()}
          period={period}
          picker="12hours"
          ref={hourRef}
          setDate={setDate}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label
          className="text-xs"
          htmlFor="minutes"
        >
          Minutes
        </Label>
        <TimePickerInput
          date={date}
          id="minutes12"
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => secondRef.current?.focus()}
          picker="minutes"
          ref={minuteRef}
          setDate={setDate}
        />
      </div>
      {includeSeconds && (
        <div className="grid gap-1 text-center">
          <Label
            className="text-xs"
            htmlFor="seconds"
          >
            Seconds
          </Label>
          <TimePickerInput
            date={date}
            id="seconds12"
            onLeftFocus={() => minuteRef.current?.focus()}
            onRightFocus={() => periodRef.current?.focus()}
            picker="seconds"
            ref={secondRef}
            setDate={setDate}
          />
        </div>
      )}
      <div className="grid gap-1 text-center">
        <Label
          className="text-xs"
          htmlFor="period"
        >
          Period
        </Label>
        <TimePickerPeriodSelect
          date={date}
          onLeftFocus={() => secondRef.current?.focus()}
          period={period}
          ref={periodRef}
          setDate={setDate}
          setPeriod={setPeriod}
        />
      </div>
    </div>
  );
};

export { TimePickerTwelveHour };
