import { DisableMemberDialog } from './DisableMemberDialog';
import { EditRoleDropdown } from './EditRoleDropdown';
import { DebouncedInput } from '@/components/debounced-input';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { type Member, MemberRole } from '@/gql/graphql';
import { pluralize } from '@/lib/pluralize';
import { useAppStore } from '@/stores';
import { fmtPhoneNumber } from '@/utils';
import {
  addMinutes,
  formatDistance,
  interval,
  isWithinInterval,
} from 'date-fns';
import { Circle, CircleDashed, MoreVertical } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  readonly activeMembers?: Array<
    Pick<
      Member,
      | 'id'
      | 'avatarUrl'
      | 'displayName'
      | 'emailAddress'
      | 'fullName'
      | 'lastActiveAt'
      | 'organizationRole'
      | 'phoneNumber'
    >
  >;
  readonly fetching?: boolean;
};

const SectionActiveMembers = ({ activeMembers, fetching }: Props) => {
  const currentMemberId = useAppStore(
    (state) => state.activeMembership?.memberId,
  );
  const [search, setSearch] = useState('');

  const filteredUsers = activeMembers
    ?.map((item) => ({
      ...item,
      search:
        item.fullName.toLowerCase() +
        item.displayName?.toLowerCase() +
        item.emailAddress?.toLowerCase() +
        item.phoneNumber?.toLowerCase(),
    }))
    .filter((item) =>
      search ? item.search.includes(search.toLowerCase()) : true,
    );

  return (
    <section aria-labelledby="active-members">
      <div className="py-2 flex md:items-center">
        <DebouncedInput
          className="w-64"
          onChange={(value) => setSearch(value)}
          placeholder="Search members..."
          type="search"
          value={search}
        />

        <div className="md:ml-auto font-semibold text-sm text-muted-foreground">
          {filteredUsers?.length !== activeMembers?.length &&
            `${filteredUsers?.length.toString()} of `}
          {activeMembers?.length.toString()}{' '}
          {pluralize('member', activeMembers?.length)}
        </div>
      </div>

      <ul className="border-t border-b divide-y">
        {fetching &&
          !activeMembers &&
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
            <li
              className="flex items-center justify-between py-2"
              key={item}
            >
              <div className="flex items-center gap-x-4">
                <Skeleton className="size-12 rounded-full" />
                <Skeleton className="w-64 h-10" />
              </div>
              <div className="flex items-center gap-2">
                <Skeleton className="size-8 rounded-full" />
                <Skeleton className="w-40 h-10" />
                <Skeleton className="size-10" />
              </div>
            </li>
          ))}
        {filteredUsers?.map((item) => (
          <li
            className="flex items-center justify-between py-2"
            key={item.id}
          >
            <div className="flex items-center gap-x-4 truncate">
              <Avatar className="size-12">
                <AvatarImage src={item.avatarUrl ?? undefined} />
                <AvatarFallback>{item.fullName.slice(0, 2)}</AvatarFallback>
              </Avatar>
              <div className="space-y-1">
                <p className="truncate text-sm font-medium">
                  {item.displayName ? (
                    <>
                      {item.displayName}
                      <span className="ml-2 font-normal text-muted-foreground">
                        ({item.fullName})
                      </span>
                    </>
                  ) : (
                    item.fullName
                  )}
                </p>
                <p className="truncate text-xs text-muted-foreground">
                  {item.emailAddress ?? '---'} /{' '}
                  {fmtPhoneNumber(item.phoneNumber)}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Tooltip>
                <TooltipTrigger className="size-8">
                  {isWithinInterval(
                    item.lastActiveAt,
                    interval(addMinutes(new Date(), -30), new Date()),
                  ) ? (
                    <Circle className="mx-auto size-4 text-green-300 fill-green-500" />
                  ) : (
                    <CircleDashed className="mx-auto size-4 text-muted-foreground fill-muted" />
                  )}
                </TooltipTrigger>
                <TooltipContent side="left">
                  Last active:{' '}
                  {item.lastActiveAt
                    ? formatDistance(item.lastActiveAt, new Date())
                    : 'never'}
                </TooltipContent>
              </Tooltip>

              <EditRoleDropdown
                disabled={
                  currentMemberId === item.id ||
                  item.organizationRole === MemberRole.Owner
                }
                memberId={item.id}
                role={item.organizationRole}
              />

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    className="size-10"
                    size="icon"
                    variant="ghost"
                  >
                    <MoreVertical />
                    <span className="sr-only">Actions</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem asChild>
                    <Link to={`/members/${item.id}`}>View details</Link>
                  </DropdownMenuItem>
                  <DisableMemberDialog
                    memberId={item.id}
                    name={item.displayName || item.fullName}
                  >
                    <DropdownMenuItem
                      className="text-destructive-foreground"
                      disabled={
                        item.organizationRole === MemberRole.Owner ||
                        currentMemberId === item.id
                      }
                      onSelect={(event) => event.preventDefault()}
                    >
                      Disable
                    </DropdownMenuItem>
                  </DisableMemberDialog>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export { SectionActiveMembers };
