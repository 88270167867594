import { NavAdministration } from './NavAdministration';
import { NavOrganization } from './NavOrganization';
import { SidebarContent } from '@/components/ui/sidebar';
import { type Membership } from '@/stores/types';

type Props = {
  readonly activeOrganization: Membership | null;
};

const AppSidebarContent = ({ activeOrganization }: Props) => {
  if (location.pathname.startsWith('/administration')) {
    return (
      <SidebarContent>
        <NavAdministration />
      </SidebarContent>
    );
  }

  return (
    <SidebarContent>
      {activeOrganization && (
        <NavOrganization activeOrganization={activeOrganization} />
      )}
    </SidebarContent>
  );
};

export { AppSidebarContent };
