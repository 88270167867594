import { MutationError } from '../../Core';
import { AttachmentUpload } from '@/components/AttachmentUpload';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { graphql } from '@/gql';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader, Lock } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { boolean, type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const CreateIncidentNoteFormGql = graphql(`
  mutation CreateIncidentNoteFormGql(
    $message: String!
    $incidentId: String!
    $isPrivate: Boolean!
    $attachmentIds: [String]
  ) {
    createIncidentNote(
      input: {
        incidentId: $incidentId
        message: $message
        isPrivate: $isPrivate
        attachmentIds: $attachmentIds
      }
    ) {
      incident {
        id
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`);

const IncidentNoteControlCloseGql = graphql(`
  mutation IncidentNoteControlCloseGql($incidentId: String!) {
    updateIncidentStatus(input: { incidentId: $incidentId, status: CLOSED }) {
      incident {
        id
        status
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`);

const schema = object({
  close: boolean(),
  isPrivate: boolean(),
  message: string(),
});

type CreateIncidentNoteFormProps = {
  readonly incidentId: string;
};

const CreateIncidentNoteForm = ({
  incidentId,
}: CreateIncidentNoteFormProps) => {
  const [{ error, fetching }, createIncidentNote] = useMutation(
    CreateIncidentNoteFormGql,
  );

  const [{ error: closeError, fetching: closeFetching }, closeIncident] =
    useMutation(IncidentNoteControlCloseGql);

  const [attachmentIds, setAttachmentIds] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      close: false,
      isPrivate: false,
      message: '',
    },
    resolver: superstructResolver(schema),
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    if (values.message || attachmentIds.length > 0) {
      const response = await createIncidentNote({
        attachmentIds,
        incidentId,
        isPrivate: values.isPrivate,
        message: values.message,
      });

      if (!response.error) {
        if (values.close) {
          const closeResponse = await closeIncident({
            incidentId,
          });

          if (!closeResponse.error) {
            form.reset();
            setFiles([]);
            setAttachmentIds([]);
          }
        } else {
          form.reset();
          setFiles([]);
          setAttachmentIds([]);
        }
      }
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex justify-between items-center -mb-2">
          <FormLabel>Add A Comment</FormLabel>
          <FormField
            control={form.control}
            name="isPrivate"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="leading-none">
                  <FormLabel>Private?</FormLabel>
                </div>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  {...field}
                  className="h-32"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormItem>
          <AttachmentUpload
            allowMultiple
            files={files}
            setAttachmentIds={setAttachmentIds}
            setFiles={setFiles}
          />
        </FormItem>

        <MutationError error={error} />
        <MutationError error={closeError} />

        <div className="flex items-center justify-end gap-2">
          <Button
            disabled={fetching || closeFetching}
            onClick={() => form.setValue('close', true)}
            type="submit"
            variant="outline"
          >
            {closeFetching ? (
              <Loader className="h-6 w-6 animate-spin mr-2" />
            ) : (
              <Lock className="h-4 w-4 mr-2" />
            )}
            Update & Close
          </Button>
          <Button
            disabled={fetching || closeFetching}
            type="submit"
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Update
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { CreateIncidentNoteForm };
