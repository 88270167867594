import { IncidentDataBuilder } from './IncidentDataBuilder';
import { IncidentDetailEditForm } from './IncidentDetailEditForm';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { DataList } from '@/components/ui/data-list';
import { type IncidentType } from '@/gql/graphql';
import { type DataSchema, type UISchema } from '@/lib/incidentType';
import { Pencil } from 'lucide-react';
import { useState } from 'react';

type IncidentDetailProps = {
  readonly additionalData: Record<string, unknown>;
  readonly canEditIncident: boolean;
  readonly dataSchema: DataSchema;
  readonly description: string;
  readonly incidentId: string;
  readonly incidentType?: Pick<IncidentType, 'name' | 'iconSvg'> | null;
  readonly subject: string;
  readonly uiSchema: UISchema;
};

const IncidentDetail = ({
  additionalData,
  canEditIncident,
  dataSchema,
  description,
  incidentId,
  incidentType,
  subject,
  uiSchema,
}: IncidentDetailProps) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card className="p-0">
      <CardContent className="p-4">
        {isEditing ? (
          <IncidentDetailEditForm
            additionalData={additionalData}
            dataSchema={dataSchema}
            description={description}
            incidentId={incidentId}
            incidentTypeIcon={incidentType?.iconSvg ?? ''}
            incidentTypeName={incidentType?.name ?? 'Unknown'}
            setIsEditing={setIsEditing}
            subject={subject}
            uiSchema={uiSchema}
          />
        ) : (
          <div className="relative">
            <div className="flex items-center mb-4">
              <div className="flex items-center gap-1 font-semibold">
                <img
                  alt={incidentType?.name}
                  className="h-4 w-4"
                  src={`data:image/svg+xml;base64,${incidentType?.iconSvg}`}
                />
                {incidentType?.name} Incident Data
              </div>

              {canEditIncident && (
                <div className="ml-auto">
                  <Button
                    onClick={() => setIsEditing(true)}
                    size="sm"
                    variant="outline"
                  >
                    <Pencil className="h-4 w-4" />
                    Edit
                  </Button>
                </div>
              )}
            </div>

            <DataList
              className="gap-4"
              orientation="horizontal"
            >
              <IncidentDataBuilder
                data={{
                  additionalData,
                  description,
                  subject,
                }}
                dataSchema={dataSchema}
                uiSchema={uiSchema}
              />
            </DataList>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export { IncidentDetail };
