import { EmptyState } from '@/components/empty-state';
import { Button, type ButtonProps } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { RadioGroup } from '@/components/ui/radio-group';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { type Member, MemberRole } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { type DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import { RadioGroupItem } from '@radix-ui/react-radio-group';
import { Loader } from 'lucide-react';
import { type ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const EditRoleDialogGql = graphql(`
  mutation EditRoleDialogGql($memberId: String!, $roleId: MemberRole!) {
    updateMemberRole(input: { memberId: $memberId, roleId: $roleId }) {
      member {
        id
        organizationRole
      }
    }
  }
`);

const schema = object({
  role: string(),
});

type EditRoleDialogProps = {
  readonly children: ReactElement<ButtonProps | DropdownMenuItemProps>;
  readonly member?: Pick<Member, 'id' | 'organizationRole'> | null;
};

const EditRoleDialog = ({ children, member }: EditRoleDialogProps) => {
  const [open, setOpen] = useState(false);
  const [{ error, fetching }, updateRole] = useMutation(EditRoleDialogGql);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      role: member?.organizationRole,
    },
    resolver: superstructResolver(schema),
  });

  useEffect(() => {
    if (member) {
      form.reset({ role: member.organizationRole });
    }
  }, [form, member]);

  const onCancel = () => {
    setOpen(false);
    form.reset();
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    if (member) {
      const response = await updateRole({
        memberId: member.id,
        roleId: values.role as MemberRole,
      });

      if (!response.error) {
        setOpen(false);
        form.reset(values);
      }
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Member Role</DialogTitle>
          <DialogDescription>
            Manage team members of your organization and set their access level.
          </DialogDescription>
        </DialogHeader>
        {member?.organizationRole === MemberRole.Owner ? (
          <EmptyState
            description="Role cannot be altered."
            title="Owner"
          />
        ) : (
          <Form {...form}>
            <form
              className="space-y-8"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem className="space-y-1">
                    <FormLabel>Select member role...</FormLabel>
                    <FormControl>
                      <RadioGroup
                        className="flex flex-col gap-2"
                        defaultValue={field.value}
                        // eslint-disable-next-line react/jsx-handler-names
                        onValueChange={field.onChange}
                      >
                        <FormItem>
                          <FormLabel className="flex flex-col">
                            <FormControl>
                              <RadioGroupItem
                                className="sr-only"
                                value={MemberRole.Administrator}
                              />
                            </FormControl>
                            <div
                              className={cn(
                                'flex flex-col gap-y-1 rounded-md border-2 border-muted bg-popover p-4 hover:bg-muted',
                                field.value === MemberRole.Administrator &&
                                  'border-primary',
                              )}
                            >
                              <p>Admin</p>
                              <p className="text-sm text-muted-foreground">
                                Admins have access to the entire organization.
                                They can edit and manage members, as well as all
                                organization settings, such as security and
                                billing settings.
                              </p>
                            </div>
                          </FormLabel>
                        </FormItem>

                        <FormItem>
                          <FormLabel className="flex flex-col">
                            <FormControl>
                              <RadioGroupItem value={MemberRole.Supervisor} />
                            </FormControl>
                            <div
                              className={cn(
                                'flex flex-col gap-y-1 rounded-md border-2 border-muted bg-popover p-4 hover:bg-muted',
                                field.value === MemberRole.Supervisor &&
                                  'border-primary',
                              )}
                            >
                              <p>Supervisor</p>
                              <p className="text-sm text-muted-foreground">
                                A supervisor is a member who is able to
                                supervise other members, including viewing their
                                GuardMe sessions, incidents, and check in's.
                              </p>
                            </div>
                          </FormLabel>
                        </FormItem>

                        <FormItem>
                          <FormLabel className="flex flex-col">
                            <FormControl>
                              <RadioGroupItem value={MemberRole.Member} />
                            </FormControl>
                            <div
                              className={cn(
                                'flex flex-col gap-y-1 rounded-md border-2 border-muted bg-popover p-4 hover:bg-muted',
                                field.value === MemberRole.Member &&
                                  'border-primary',
                              )}
                            >
                              <p>Member</p>
                              <p className="text-sm text-muted-foreground">
                                A member is an user who is a part of your
                                organization. They are able to start GuardMe
                                sessions, report incidents, and submit check
                                in's.
                              </p>
                            </div>
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <MutationError error={error} />

              <DialogFooter>
                <Button
                  disabled={fetching}
                  onClick={onCancel}
                  type="reset"
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  disabled={fetching}
                  type="submit"
                >
                  {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                  Save
                </Button>
              </DialogFooter>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export { EditRoleDialog };
