import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DataList,
  DataListItem,
  DataListLabel,
  DataListValue,
} from '@/components/ui/data-list';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { graphql } from '@/gql';
import { type Group } from '@/gql/graphql';
import { parseMemberMetadata } from '@/lib/metadata';
import { normalizeMemberRole } from '@/utils';
import { Eye } from 'lucide-react';
import { useMutation, useQuery } from 'urql';

const SectionInvitationsSheetGql = graphql(`
  query SectionInvitationsSheetGql($invitationId: String!) {
    invitation(id: $invitationId) {
      id
      key
      organizationRole
      groupIds
      metadata
      organization {
        id
        name
        memberMetadataFields
        groups {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`);

const DeleteInvitationGql = graphql(`
  mutation DeleteInvitationGql($id: String!) {
    deleteInvitation(input: { id: $id }) {
      organization {
        id
        invitations {
          nodes {
            id
            key
            organizationRole
            organizationName
            groupIds
          }
        }
      }
    }
  }
`);

type Props = {
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly invitationId: string;
  readonly onOpenChange: () => void;
  readonly open: boolean;
};

const SectionInvitationsSheet = ({
  groups = [],
  invitationId,
  onOpenChange,
  open,
}: Props) => {
  const [{ data, fetching }] = useQuery({
    query: SectionInvitationsSheetGql,
    variables: {
      invitationId,
    },
  });

  const [, deleteInvitation] = useMutation(DeleteInvitationGql);

  const assignedGroups = groups.filter((item) =>
    data?.invitation?.groupIds.includes(item.id),
  );

  const metadata = parseMemberMetadata(
    data?.invitation?.organization?.memberMetadataFields,
    data?.invitation?.metadata,
  );

  return (
    <Sheet
      onOpenChange={onOpenChange}
      open={open}
    >
      <SheetContent>
        <SheetHeader className="border-b">
          {fetching ? (
            <>
              <Skeleton className="size-16 rounded-full" />
              <Skeleton className="h-7 w-full" />
            </>
          ) : (
            <>
              <Avatar className="size-16">
                <AvatarFallback className="text-2xl">
                  {data?.invitation?.key.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              <SheetTitle>{data?.invitation?.key}</SheetTitle>
              <SheetDescription className="flex flex-wrap items-start gap-2" />
            </>
          )}
        </SheetHeader>

        <div className="py-4 space-y-4 grow">
          <DataList orientation="horizontal">
            <DataListItem className="py-1 first:pt-0 last:pb-0">
              <DataListLabel>Role</DataListLabel>
              <DataListValue className="ml-auto">
                {fetching ? (
                  <Skeleton className="h-7 w-32" />
                ) : (
                  normalizeMemberRole(data?.invitation?.organizationRole)
                )}
              </DataListValue>
            </DataListItem>
            <DataListItem className="py-2 first:pt-0 last:pb-0">
              <DataListLabel>Groups</DataListLabel>
              <DataListValue className="ml-auto">
                {fetching ? (
                  <Skeleton className="h-7 w-32" />
                ) : (
                  <div className="flex flex-col gap-1 sm:text-right">
                    {assignedGroups.map((item) => (
                      <div key={item.id}>
                        <Badge variant="info">{item.name}</Badge>
                      </div>
                    ))}
                  </div>
                )}
              </DataListValue>
            </DataListItem>
          </DataList>

          <h3 className="pt-4 font-semibold text-muted-foreground">Metadata</h3>
          <DataList orientation="horizontal">
            {metadata.map((item) => (
              <DataListItem
                className="py-2 first:pt-0 last:pb-0"
                key={item.id}
              >
                <DataListLabel className="flex items-center gap-2">
                  {item.name}
                  {item.isPrivate && (
                    <Tooltip>
                      <TooltipTrigger>
                        <Eye className="size-4 text-info-foreground" />
                      </TooltipTrigger>
                      <TooltipContent side="right">
                        <p>Private field</p>
                      </TooltipContent>
                    </Tooltip>
                  )}
                </DataListLabel>
                <DataListValue className="ml-auto">
                  {fetching ? <Skeleton className="h-7 w-32" /> : item.value}
                </DataListValue>
              </DataListItem>
            ))}
          </DataList>
        </div>

        <SheetFooter className="border-t pt-4">
          <SheetClose asChild>
            <Button
              onClick={async () => {
                await deleteInvitation({ id: invitationId });
                onOpenChange();
              }}
              variant="destructive"
            >
              Delete invitation
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export { SectionInvitationsSheet };
