import { OrganizationSwitcher } from './OrganizationSwitcher';
import Logo from '@/assets/images/logo/sisi.png';
import { Separator } from '@/components/ui/separator';
import {
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarTrigger,
  useSidebar,
} from '@/components/ui/sidebar';
import { type Membership } from '@/stores/types';
import { ArrowLeft, Home } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  readonly activeOrganization: Membership | null;
  readonly organizations: Membership[];
  readonly setActiveOrganization: (id: string) => void;
};

const AppSidebarHeader = ({
  activeOrganization,
  organizations,
  setActiveOrganization,
}: Props) => {
  const { pathname } = useLocation();
  const { open } = useSidebar();

  if (pathname.startsWith('/administration')) {
    return (
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <Link to="/home">
                <ArrowLeft />
                <span>Exit Administration </span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
    );
  }

  return (
    <SidebarHeader className="py-3">
      <div className="hidden md:flex items-center">
        <img
          alt="See It Send It"
          className={open ? 'h-6' : 'hidden'}
          src={Logo}
        />
        <SidebarTrigger className="ml-auto size-8" />
      </div>
      <Separator className="my-1 hidden md:block" />
      <OrganizationSwitcher
        activeOrganization={activeOrganization}
        organizations={organizations}
        setActiveOrganization={setActiveOrganization}
      />
      <SidebarMenu>
        <SidebarMenuItem>
          <SidebarMenuButton
            asChild
            isActive={location.pathname === '/home'}
            tooltip="Home"
          >
            <Link to="/home">
              <Home />
              <span>Home</span>
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarHeader>
  );
};

export { AppSidebarHeader };
