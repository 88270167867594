import { EditRoleDropdown } from './EditRoleDropdown';
import { EnableMemberDialog } from './EnableMemberDialog';
import { DebouncedInput } from '@/components/debounced-input';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import { type Member } from '@/gql/graphql';
import { pluralize } from '@/lib/pluralize';
import { fmtPhoneNumber } from '@/utils/formatters';
import { MoreVertical } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  readonly disabledMembers?: Array<
    Pick<
      Member,
      | 'id'
      | 'avatarUrl'
      | 'displayName'
      | 'emailAddress'
      | 'fullName'
      | 'lastActiveAt'
      | 'organizationRole'
      | 'phoneNumber'
    >
  >;
  readonly fetching?: boolean;
};

const SectionDisabledMembers = ({ disabledMembers, fetching }: Props) => {
  const [search, setSearch] = useState('');

  const filteredUsers = disabledMembers
    ?.map((item) => ({
      ...item,
      search:
        item.fullName.toLowerCase() +
        item.displayName?.toLowerCase() +
        item.emailAddress?.toLowerCase() +
        item.phoneNumber?.toLowerCase(),
    }))
    .filter((item) =>
      search ? item.search.includes(search.toLowerCase()) : true,
    );

  return (
    <section aria-labelledby="disabled-members">
      <div className="py-2 flex md:items-center">
        <DebouncedInput
          className="w-64"
          onChange={(value) => setSearch(value)}
          placeholder="Search members..."
          type="search"
          value={search}
        />

        <div className="md:ml-auto font-semibold text-sm text-muted-foreground">
          {filteredUsers?.length !== disabledMembers?.length &&
            `${filteredUsers?.length.toString()} of `}
          {disabledMembers?.length.toString()}{' '}
          {pluralize('member', disabledMembers?.length)}
        </div>
      </div>

      <ul className="border-t border-b divide-y">
        {fetching &&
          !filteredUsers &&
          [0, 1, 2, 3, 4, 5].map((item) => (
            <li
              className="flex items-center justify-between py-2"
              key={item}
            >
              <div className="flex items-center gap-x-4">
                <Skeleton className="size-12 rounded-full" />
                <Skeleton className="w-64 h-10" />
              </div>
              <div className="flex items-center gap-2">
                <Skeleton className="w-40 h-10" />
                <Skeleton className="size-10" />
              </div>
            </li>
          ))}
        {filteredUsers?.map((item) => (
          <li
            className="flex items-center justify-between py-2"
            key={item.id}
          >
            <div className="flex items-center gap-x-4 truncate">
              <Avatar className="size-12">
                <AvatarImage src={item.avatarUrl ?? undefined} />
                <AvatarFallback>{item.fullName.slice(0, 2)}</AvatarFallback>
              </Avatar>
              <div className="space-y-1">
                <p className="truncate text-sm font-medium">
                  {item.displayName ? (
                    <>
                      {item.displayName}
                      <span className="ml-2 font-normal text-muted-foreground">
                        ({item.fullName})
                      </span>
                    </>
                  ) : (
                    item.fullName
                  )}
                </p>
                <p className="truncate text-xs text-muted-foreground">
                  {item.emailAddress ?? '---'} /{' '}
                  {fmtPhoneNumber(item.phoneNumber)}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <EditRoleDropdown
                disabled
                memberId={item.id}
                role={item.organizationRole}
              />

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    className="size-10"
                    size="icon"
                    variant="ghost"
                  >
                    <MoreVertical />
                    <span className="sr-only">Actions</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem asChild>
                    <Link to={`/members/${item.id}`}>View details</Link>
                  </DropdownMenuItem>
                  <EnableMemberDialog memberId={item.id}>
                    <DropdownMenuItem
                      onSelect={(event) => event.preventDefault()}
                    >
                      Enable
                    </DropdownMenuItem>
                  </EnableMemberDialog>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export { SectionDisabledMembers };
