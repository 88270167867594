import { GuardMeActions } from '../components/GuardMeActions';
import { GuardMeAttachments } from '../components/GuardMeAttachments';
import { GuardMeAuditLog } from '../components/GuardMeAuditLog';
import { GuardMeDetails } from '../components/GuardMeDetails';
import { GuardMeLocation } from '../components/GuardMeLocation';
import { GuardMeMemberDetails } from '../components/GuardMeMemberDetails';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Header, HeaderTitle } from '@/components/header';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const GuardMeQuery = graphql(`
  query GuardMeQuery($id: String!) {
    guardme(id: $id) {
      id
      shortId
      notes
      status
      completedAt
      createdAt
      endsAt
      updatedAt
      memberId
      totalAttachments
      member {
        id
        fullName
        displayName
        timezone
        avatarUrl
        phoneNumber
        emailAddress
        emergencyContacts {
          nodes {
            id
            phoneNumber
            description
          }
        }
      }
      organization {
        id
        memberMetadataFields
        settingIsRapidsosPanicButtonFlowEnabled
        settingIsRapidsosOperatorFlowEnabled
        settingRapidsosSocPhoneNumber
      }
      batteryLevel
      locationTimelineAsEncodedPolyline
      lastLocation {
        id
        createdAt
        coordinates {
          x
          y
        }
        geocode {
          id
          address
          plusCode
        }
      }
      places {
        nodes {
          id
          name
        }
      }
    }
  }
`);

const GuardMe = () => {
  const [currentMemberId, isGuardMeSupervisor] = useAppStore((state) => [
    state.activeMembership?.memberId,
    state.activeMembership?.isGuardMeSupervisor,
  ]);

  const { guardMeId } = useParams() as { guardMeId: string };

  const [{ data, fetching }] = useQuery({
    query: GuardMeQuery,
    variables: {
      id: guardMeId,
    },
  });

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/guardme">GuardMe Sessions</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>GuardMe Details</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>GuardMe Session {data?.guardme?.shortId}</HeaderTitle>
      </Header>

      <div className="grid flex-1 items-start gap-4 md:gap-8">
        <div className="grid flex-1 auto-rows-max gap-4">
          <div className="flex items-center gap-4">
            <div className="hidden items-center gap-2 md:ml-auto md:flex">
              {isGuardMeSupervisor &&
                currentMemberId &&
                data?.guardme?.memberId &&
                currentMemberId !== data.guardme.memberId && (
                  <GuardMeActions guardmeId={guardMeId} />
                )}
            </div>
          </div>
          <div className="grid gap-4 lg:grid-cols-5">
            <div className="col-span-1 lg:col-span-3 space-y-4">
              <GuardMeDetails
                fetching={fetching}
                {...data?.guardme}
              />

              <GuardMeMemberDetails
                fetching={fetching}
                memberMetadataFields={
                  data?.guardme?.organization?.memberMetadataFields
                }
                {...data?.guardme?.member}
              />

              <GuardMeAttachments
                fetching={fetching}
                guardmeId={guardMeId}
              />
            </div>

            <div className="grid auto-rows-max items-start gap-4 lg:col-span-2">
              <GuardMeLocation
                className="col-span-full"
                fetching={fetching}
                locationTimelime={
                  data?.guardme?.locationTimelineAsEncodedPolyline
                }
                member={data?.guardme?.member}
                places={data?.guardme?.places}
                {...data?.guardme?.lastLocation}
              />

              <GuardMeAuditLog
                fetching={fetching}
                guardmeId={guardMeId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { GuardMe };
