import { JoinOrganization } from '../../Account';
import { IncidentsAssignedToMe } from '../components/IncidentsAssignedToMe';
import { MyIncidents } from '../components/MyIncidents';
import { faq } from '../components/NewUserFrequentlyAskedQuestions';
import { Recent } from '../components/Recent';
import { Header, HeaderSubtitle, HeaderTitle } from '@/components/header';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAppStore } from '@/stores';
import { getHours } from 'date-fns/getHours';
import { Link } from 'react-router-dom';

const Home = () => {
  const [activeMembership, currentUser] = useAppStore((state) => [
    state.activeMembership,
    state.currentUser,
  ]);

  if (!activeMembership) {
    return (
      <>
        <Header
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbPage>See It Send It</BreadcrumbPage>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Home</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          }
        >
          <HeaderTitle className="text-4xl lg:text-5xl">
            Welcome to See It Send It
          </HeaderTitle>
          <HeaderSubtitle className="mt-2 max-w-prose text-xl">
            Empower your organization to protect the safety of your people and
            business. Get started with your own organization or join one already
            setup for you.
          </HeaderSubtitle>
        </Header>

        <div className="grid items-start gap-4 md:gap-8">
          <Tabs
            className="w-full"
            defaultValue="create"
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger
                className="cursor-pointer"
                value="create"
              >
                Create an Organization
              </TabsTrigger>
              <TabsTrigger
                className="cursor-pointer"
                value="join"
              >
                Join an Organization
              </TabsTrigger>
            </TabsList>

            <TabsContent value="create">
              <Card>
                <CardHeader className="flex flex-row items-start justify-between space-y-0">
                  <div>
                    <CardTitle className="text-2xl">
                      Create an Organization
                    </CardTitle>
                    <CardDescription className="text-lg">
                      Unite teams and enhance safety with See It Send It.
                    </CardDescription>
                  </div>
                  <div className="ml-auto">
                    <Link to="/organization/new">
                      <Button size="lg">Get Started</Button>
                    </Link>
                  </div>
                </CardHeader>
                <CardContent className="space-y-4">
                  {faq.map((item) => (
                    <div key={item.id}>
                      <p className="font-semibold">{item.question}</p>
                      {item.answer.map((answer, index) => (
                        <p
                          className="leading-6 mb-2"
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${answer}-${index}`}
                        >
                          {answer}
                        </p>
                      ))}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="join">
              <Card>
                <CardHeader>
                  <CardTitle className="text-2xl">
                    Join an Organization
                  </CardTitle>
                  <CardDescription className="text-lg">
                    Use a Membership Code to request access to an organization.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <JoinOrganization />
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </>
    );
  }

  const hours = getHours(new Date());
  const greeting =
    hours > 12 ? (hours > 17 ? 'evening' : 'afternoon') : 'morning';

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>Home</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Overview</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>
          Good {greeting}, {currentUser.fullName}
        </HeaderTitle>
      </Header>

      <div className="grid grid-cols-2 gap-4 md:gap-6">
        <Recent />
        <MyIncidents />
      </div>

      <div className="mt-4">
        <IncidentsAssignedToMe />
      </div>
    </>
  );
};

export { Home };
