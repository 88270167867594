import { ErrorBoundaryFallback } from '../../Core';
import { ClientError } from '@/components';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Header, HeaderTitle } from '@/components/header';
import { TabsListAlt, TabsTriggerAlt } from '@/components/tabs-alt';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Tabs } from '@/components/ui/tabs';
import { useAppStore } from '@/stores';
import { ErrorBoundary } from '@sentry/react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const navItems = [
  {
    title: 'General',
    to: '/settings/general',
    value: 'general',
  },
  {
    title: 'Incident Types',
    to: '/settings/incident-types',
    value: 'incident-types',
  },
  {
    title: 'Notifications',
    to: '/settings/notifications',
    value: 'notifications',
  },
  {
    title: 'Places',
    to: '/settings/places',
    value: 'places',
  },
  {
    title: 'Integrations',
    to: '/settings/integrations',
    value: 'integrations',
  },
];

const OrganizationSettingsLayout = () => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  const pathname = useLocation().pathname;
  const currentPage = navItems.find((x) => x.to === pathname) ?? navItems[0];

  if (!isAdmin) {
    return <ClientError code={401} />;
  }

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Settings</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{currentPage.title}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>Settings</HeaderTitle>
      </Header>

      <Tabs
        onValueChange={() => {}}
        value={currentPage.value}
      >
        <TabsListAlt>
          {navItems.map((item) => (
            <TabsTriggerAlt
              asChild
              key={item.value}
              value={item.value}
            >
              <Link to={item.to}>
                <span>{item.title}</span>
              </Link>
            </TabsTriggerAlt>
          ))}
        </TabsListAlt>
      </Tabs>

      <div className="pt-6">
        <ErrorBoundary
          fallback={ErrorBoundaryFallback}
          key={pathname}
        >
          <Outlet />
        </ErrorBoundary>
      </div>
    </>
  );
};

export { OrganizationSettingsLayout };
