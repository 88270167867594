import { Empty } from '@/components';
import { GroupSelect } from '@/components/GroupSelect';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { MutationError } from '@/features/Core';
import { NewGroupDialog } from '@/features/Members/components/NewGroupDialog';
import { graphql } from '@/gql';
import { type Group, type OrganizationSetting } from '@/gql/graphql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { boolean, type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const UpdateOrganizationIncidentSettingsGql = graphql(`
  mutation UpdateOrganizationIncidentSettingsGql(
    $input: UpdateOrganizationIncidentSettingsInput!
  ) {
    updateOrganizationIncidentSettings(input: $input) {
      organizationSetting {
        id
        isOwnerNotifiedOnIncident
        isAdministratorNotifiedOnIncident
        isSupervisorNotifiedOnIncident
        incidentSupervisorGroupId
        incidentSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const schema = object({
  notifyAdministrator: boolean(),
  notifyOwner: boolean(),
  notifySupervisor: boolean(),
  supervisorGroupId: string(),
});

type Props = {
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly organizationId: string;
  readonly settings?: Pick<
    OrganizationSetting,
    | 'incidentSupervisorGroupId'
    | 'isAdministratorNotifiedOnIncident'
    | 'isOwnerNotifiedOnIncident'
    | 'isSupervisorNotifiedOnIncident'
  >;
};

const IncidentNotificationsForm = ({
  groups = [],
  organizationId,
  settings = {
    incidentSupervisorGroupId: '',
    isAdministratorNotifiedOnIncident: false,
    isOwnerNotifiedOnIncident: false,
    isSupervisorNotifiedOnIncident: false,
  },
}: Props) => {
  const [{ error, fetching }, updateSettings] = useMutation(
    UpdateOrganizationIncidentSettingsGql,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      notifyAdministrator: settings.isAdministratorNotifiedOnIncident,
      notifyOwner: settings.isOwnerNotifiedOnIncident,
      notifySupervisor: settings.isSupervisorNotifiedOnIncident,
      supervisorGroupId: settings.incidentSupervisorGroupId ?? '',
    },
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateSettings({
      input: {
        organizationId,
        shouldNotifyAdministrator: values.notifyAdministrator,
        shouldNotifyOwner: values.notifyOwner,
        shouldNotifySupervisor: values.notifySupervisor,
        supervisorGroupId: values.supervisorGroupId,
      },
    });

    if (!response.error) {
      form.reset(values);
      toast.success('Updated Incident Settings', {
        id: 'incident-settings',
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
          <div className="col-span-full">
            <FormItem>
              <FormLabel>Notification Recipients</FormLabel>

              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="notifyOwner"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          // eslint-disable-next-line react/jsx-handler-names
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>
                          Send notification to organization owner
                        </FormLabel>
                      </div>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="notifyAdministrator"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          // eslint-disable-next-line react/jsx-handler-names
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>
                          Send notification to organization administrator(s)
                        </FormLabel>
                      </div>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="notifySupervisor"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          // eslint-disable-next-line react/jsx-handler-names
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>
                          Send notification to organization supervisor(s)
                        </FormLabel>
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </FormItem>
          </div>

          <div className="col-span-full">
            <FormItem>
              <FormField
                control={form.control}
                name="supervisorGroupId"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Grant Incident Supervisor to Group</FormLabel>
                    <FormDescription>
                      Grants the supervisor role to the following group. Create
                      a{' '}
                      <NewGroupDialog
                        className="py-0 h-5"
                        organizationId={organizationId}
                        type="link"
                      />
                      .
                    </FormDescription>

                    {groups.length === 0 ? (
                      <Empty className="w-full border border-dashed flex-col space-y-1">
                        <div>
                          <NewGroupDialog organizationId={organizationId} />
                        </div>
                      </Empty>
                    ) : (
                      <GroupSelect
                        {...field}
                        allowEmpty
                        groups={groups}
                      />
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </FormItem>
          </div>

          <MutationError error={error} />

          <div className="col-span-full mt-6 flex justify-end items-center">
            {form.formState.isDirty && (
              <Button
                disabled={fetching}
                onClick={() => form.reset()}
                type="reset"
                variant="ghost"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Reset
              </Button>
            )}
            <Button
              disabled={fetching || !form.formState.isDirty}
              type="submit"
            >
              Save Settings
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { IncidentNotificationsForm };
