import { BivyStickActions } from './bivy-stick/BivyStickActions';
import { EmptyState } from '@/components/empty-state';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { type BivyStick, type Member } from '@/gql/graphql';

type Props = {
  readonly bivySticks?: Array<
    Pick<
      BivyStick,
      'id' | 'isRegistered' | 'createdAt' | 'updatedAt' | 'imei'
    > & {
      member?: Pick<
        Member,
        'id' | 'fullName' | 'displayName' | 'avatarUrl'
      > | null;
    }
  >;
  readonly fetching?: boolean;
};

const BivySticks = ({ bivySticks = [], fetching = false }: Props) => {
  if (fetching) {
    return <Skeleton className="w-full h-64" />;
  }

  if (bivySticks.length === 0) {
    return (
      <EmptyState title="No Bivy Sticks registered to your organization at this time." />
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>IMEI</TableHead>
          <TableHead>Member</TableHead>
          <TableHead>Registered</TableHead>
          <TableHead>
            <span className="sr-only">Actions</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {bivySticks.map((item) => (
          <TableRow key={item.id}>
            <TableCell className="font-bold">{item.imei}</TableCell>
            <TableCell>
              {item.member?.displayName ?? item.member?.fullName}
            </TableCell>
            <TableCell>{item.isRegistered && 'Enabled'}</TableCell>
            <TableCell className="text-right">
              <BivyStickActions
                id={item.id}
                imei={item.imei}
                memberId={item.member?.id}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export { BivySticks };
