import { useMemberGuardmeSearchParameters } from '../hooks/useMemberGuardmeSearchParameters';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { Datetime } from '@/components/Datetime';
import { Badge } from '@/components/ui/badge';
import { GuardMeStatus } from '@/features/GuardMe/components/GuardMeStatus';
import { type DocumentType, graphql } from '@/gql';
import { GuardmesOrderBy, GuardmeStatus } from '@/gql/graphql';
import { sorToOrderByParser } from '@/lib/data-table';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberGuardMeListGql = graphql(`
  query MemberGuardMeListGql(
    $memberId: String!
    $first: Int!
    $offset: Int!
    $search: String
    $createdAt: GuardMesCreatedAtConditionInput
    $status: GuardMesStatusConditionInput
    $orderBy: [GuardmesOrderBy!] = [ID_DESC]
  ) {
    guardmes(
      first: $first
      offset: $offset
      condition: {
        createdAt: $createdAt
        search: $search
        memberId: $memberId
        status: $status
      }
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        shortId
        status
        createdAt
        endsAt
      }
    }
  }
`);

type Column = NonNullable<
  DocumentType<typeof MemberGuardMeListGql>['guardmes']
>['nodes'][0];

const columnHelper = createColumnHelper<Column>();

const COLUMNS = [
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <Link to={`/guardme/${getValue()}`}>
        <Badge
          className="hover:bg-primary hover:text-primary-foreground"
          variant="secondary"
        >
          {row.original.shortId}
        </Badge>
      </Link>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="ID"
      />
    ),
  }),
  columnHelper.accessor('status', {
    cell: ({ getValue }) => <GuardMeStatus status={getValue()} />,
    header: () => 'Status',
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Created',
  }),
  columnHelper.accessor('endsAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Ended',
  }),
];

const filterFields: Array<DataTableFacetedFilterField<Column>> = [
  {
    label: 'Status',
    options: [
      {
        label: 'In Progress',
        value: GuardmeStatus.InProgress,
      },
      {
        label: 'In Panic',
        value: GuardmeStatus.InPanic,
      },
      {
        label: 'Concluded',
        value: GuardmeStatus.Concluded,
      },
      {
        label: 'Expired',
        value: GuardmeStatus.Expired,
      },
    ],
    type: 'checkbox',
    value: 'status',
  },
  {
    label: 'Date',
    type: 'timerange',
    value: 'createdAt',
  },
];

const sorToOrderBy = sorToOrderByParser({
  id_asc: GuardmesOrderBy.IdAsc,
  id_desc: GuardmesOrderBy.IdDesc,
});

type GuardMeListProps = {
  readonly memberId: string;
};

const MemberGuardMeList = ({ memberId }: GuardMeListProps) => {
  const [queryParameters, setQueryParameters] =
    useMemberGuardmeSearchParameters();

  const [{ data, fetching }, refresh] = useQuery({
    query: MemberGuardMeListGql,
    variables: {
      createdAt: {
        gte: queryParameters.createdAt?.at(0)?.toISOString() || null,
        lte: queryParameters.createdAt?.at(1)?.toISOString() || null,
      },
      first: queryParameters.pageSize,
      memberId,
      offset: queryParameters.pageIndex * queryParameters.pageSize,
      orderBy: sorToOrderBy(queryParameters.sort),
      search: queryParameters.search || null,
      status: queryParameters.status
        ? {
            concluded: queryParameters.status.includes(GuardmeStatus.Concluded),
            expired: queryParameters.status.includes(GuardmeStatus.Expired),
            inPanic: queryParameters.status.includes(GuardmeStatus.InPanic),
            inProgress: queryParameters.status.includes(
              GuardmeStatus.InProgress,
            ),
          }
        : null,
    },
  });

  return (
    <DataTable
      columns={COLUMNS as Array<ColumnDef<Column>>}
      data={data?.guardmes?.nodes}
      fetching={fetching}
      filterFields={filterFields}
      queryParameters={queryParameters}
      refresh={refresh}
      rowCount={data?.guardmes?.totalCount}
      searchPlaceholder="Search by ID..."
      setQueryParameters={setQueryParameters}
    />
  );
};

export { MemberGuardMeList };
