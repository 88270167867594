import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from './constants';
import {
  createParser,
  parseAsArrayOf,
  parseAsIndex,
  parseAsInteger,
  parseAsString,
} from 'nuqs';

export const ARRAY_DELIMITER = ',';
export const SLIDER_DELIMITER = '-';
export const SPACE_DELIMITER = '_';
export const RANGE_DELIMITER = '-';
export const SORT_DELIMITER = '.';

export const parseAsSort = createParser({
  parse(queryValue) {
    const [id, desc] = queryValue.split('.');
    if (!id && !desc) return null;
    return { desc: desc === 'desc', id };
  },
  serialize(value) {
    return `${value.id}.${value.desc ? 'desc' : 'asc'}`;
  },
});

const baseDataTableQueryStates = {
  parsers: {
    hiddenColumns: parseAsArrayOf(parseAsString, ARRAY_DELIMITER).withDefault(
      [],
    ),
    pageIndex: parseAsIndex.withDefault(DEFAULT_PAGE_INDEX),
    pageSize: parseAsInteger.withDefault(DEFAULT_PAGE_SIZE),
    search: parseAsString.withDefault(''),
    sort: parseAsSort,
  },
  urlKeys: {
    hiddenColumns: 'hidden',
    pageIndex: 'page',
    pageSize: 'perPage',
    search: 'q',
    sort: 'sort',
  },
};

const sorToOrderByParser = <T>(map: { [name: string]: T }) => {
  return (sort: { desc: boolean; id: string } | null) => {
    if (!sort) {
      return undefined;
    }

    return map[sort.id + (sort.desc ? '_desc' : '_asc')];
  };
};

export { baseDataTableQueryStates, sorToOrderByParser };
