import { baseDataTableQueryStates, RANGE_DELIMITER } from '@/lib/data-table';
import { parseAsArrayOf, parseAsTimestamp, useQueryStates } from 'nuqs';

const checkinsDataTableQueryStates = {
  parsers: {
    ...baseDataTableQueryStates.parsers,
    createdAt: parseAsArrayOf(parseAsTimestamp, RANGE_DELIMITER),
  },
  urlKeys: {
    ...baseDataTableQueryStates.urlKeys,
    createdAt: 'created',
  },
};

const useCheckInsSearchParameters = () =>
  useQueryStates(checkinsDataTableQueryStates.parsers, {
    urlKeys: checkinsDataTableQueryStates.urlKeys,
  });

export { checkinsDataTableQueryStates, useCheckInsSearchParameters };
