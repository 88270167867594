import { GuardMeStatus } from '../components/GuardMeStatus';
import { useGuardmesSearchParameters } from '../hooks/useGuardmesSearchParameters';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { Datetime } from '@/components/Datetime';
import { Header, HeaderTitle } from '@/components/header';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { type DocumentType, graphql } from '@/gql';
import { GuardmesOrderBy, GuardmeStatus } from '@/gql/graphql';
import { sorToOrderByParser } from '@/lib/data-table';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const GuardMesGql = graphql(`
  query GuardMesGql(
    $createdAt: GuardMesCreatedAtConditionInput
    $organizationId: String!
    $first: Int = 0
    $offset: Int = 0
    $search: String = null
    $status: GuardMesStatusConditionInput
    $orderBy: [GuardmesOrderBy!] = ID_DESC
  ) {
    guardmes(
      first: $first
      offset: $offset
      condition: {
        createdAt: $createdAt
        organizationId: $organizationId
        search: $search
        status: $status
      }
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        shortId
        status
        createdAt
        completedAt
        endsAt
        memberId
        member {
          id
          fullName
          displayName
          avatarUrl
        }
      }
    }
  }
`);

type Column = NonNullable<
  DocumentType<typeof GuardMesGql>['guardmes']
>['nodes'][0];

const columnHelper = createColumnHelper<Column>();

const COLUMNS = [
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <Link
        className="capitalize hover:text-primary"
        to={`/guardme/${getValue()}`}
      >
        {row.original.shortId}
      </Link>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="ID"
      />
    ),
  }),
  columnHelper.accessor('member', {
    cell: ({ getValue }) => (
      <div className="flex items-center gap-2">
        <Avatar className="h-6 w-6">
          <AvatarImage src={getValue()?.avatarUrl ?? undefined} />
          <AvatarFallback>{getValue()?.fullName.slice(0, 2)}</AvatarFallback>
        </Avatar>
        <div className="text-sm">
          {getValue()?.displayName ?? getValue()?.fullName}
        </div>
      </div>
    ),
    header: () => 'Member',
  }),
  columnHelper.accessor('status', {
    cell: ({ getValue }) => <GuardMeStatus status={getValue()} />,
    header: () => 'Status',
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Created',
  }),
  columnHelper.accessor('completedAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Completed',
  }),
];

const filterFields: Array<DataTableFacetedFilterField<Column>> = [
  {
    label: 'Status',
    options: [
      {
        label: 'In Progress',
        value: GuardmeStatus.InProgress,
      },
      {
        label: 'In Panic',
        value: GuardmeStatus.InPanic,
      },
      {
        label: 'Concluded',
        value: GuardmeStatus.Concluded,
      },
      {
        label: 'Expired',
        value: GuardmeStatus.Expired,
      },
    ],
    type: 'checkbox',
    value: 'status',
  },
  {
    label: 'Date',
    type: 'timerange',
    value: 'createdAt',
  },
];

const sorToOrderBy = sorToOrderByParser({
  id_asc: GuardmesOrderBy.IdAsc,
  id_desc: GuardmesOrderBy.IdDesc,
});

const GuardMes = () => {
  const [organizationId, memberId] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const [queryParameters, setQueryParameters] = useGuardmesSearchParameters();

  const [{ data, fetching }, refresh] = useQuery({
    query: GuardMesGql,
    variables: {
      createdAt: {
        gte: queryParameters.createdAt?.at(0)?.toISOString() || null,
        lte: queryParameters.createdAt?.at(1)?.toISOString() || null,
      },
      first: queryParameters.pageSize,
      offset: queryParameters.pageIndex * queryParameters.pageSize,
      orderBy: sorToOrderBy(queryParameters.sort),
      organizationId,
      search: queryParameters.search || null,
      status: queryParameters.status
        ? {
            concluded: queryParameters.status.includes(GuardmeStatus.Concluded),
            expired: queryParameters.status.includes(GuardmeStatus.Expired),
            inPanic: queryParameters.status.includes(GuardmeStatus.InPanic),
            inProgress: queryParameters.status.includes(
              GuardmeStatus.InProgress,
            ),
          }
        : null,
    },
  });

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>GuardMe Sessions</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>GuardMe Sessions</HeaderTitle>
      </Header>

      <DataTable
        columns={COLUMNS as Array<ColumnDef<Column>>}
        data={data?.guardmes?.nodes}
        fetching={fetching}
        filterFields={filterFields}
        queryParameters={queryParameters}
        refresh={refresh}
        rowCount={data?.guardmes?.totalCount}
        searchPlaceholder="Search by ID..."
        setQueryParameters={setQueryParameters}
      />
    </>
  );
};

export { GuardMes };
