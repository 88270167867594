import { MemberSelect } from '@/components/MemberSelect';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { type Infer, object, string } from 'superstruct';
import { useMutation, useQuery } from 'urql';

const AssignBivyStickDataGql = graphql(`
  query AssignBivyStickDataGql($id: String!) {
    organization(id: $id) {
      id
      members {
        nodes {
          id
          fullName
          displayName
          avatarUrl
          isEnabled
        }
      }
    }
  }
`);

const AssignBivyStickGql = graphql(`
  mutation AssignBivyStickGql($input: AssignBivyStickToMemberInput!) {
    assignBivyStickToMember(input: $input) {
      bivyStick {
        id
        isActive
        organizationId
        memberId
        member {
          id
          displayName
          fullName
          avatarUrl
          isEnabled
        }
      }
    }
  }
`);

const schema = object({
  memberId: string(),
});

type AssignBivyStickFormDialogProps = {
  readonly id: string;
  readonly imei: string;
  readonly memberId?: string;
  readonly open: boolean;
  readonly organizationId: string;
  readonly setOpen: (value: boolean) => void;
};

const AssignBivyStickFormDialog = ({
  id,
  imei,
  memberId = '',
  open,
  organizationId,
  setOpen,
}: AssignBivyStickFormDialogProps) => {
  const [{ data, fetching: loading }] = useQuery({
    query: AssignBivyStickDataGql,
    variables: {
      id: organizationId,
    },
  });

  const [{ error, fetching }, assignBivyStick] =
    useMutation(AssignBivyStickGql);

  const form = useForm({
    defaultValues: {
      memberId,
    },
    resolver: superstructResolver(schema),
  });

  if (loading) {
    return null;
  }

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await assignBivyStick({
      input: {
        id,
        memberId: values.memberId,
      },
    });

    if (!response.error) {
      form.reset();
      setOpen(false);
    }
  };

  const onCancel = () => {
    form.reset();
    setOpen(false);
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Assign Bivy Stick to Member</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormItem>
              <FormLabel>IMEI</FormLabel>
              <FormControl>
                <Input
                  disabled
                  onChange={() => {}}
                  value={imei}
                />
              </FormControl>
              <FormMessage />
            </FormItem>

            <FormField
              control={form.control}
              name="memberId"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Member</FormLabel>
                  <FormControl>
                    <MemberSelect
                      {...field}
                      members={data?.organization?.members.nodes}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Assign
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { AssignBivyStickFormDialog };
