import { graphql } from '@/gql';
import { useMutation } from 'urql';

const DisableMemberGql = graphql(`
  mutation DisableMemberGql($memberId: String!) {
    disableMember(input: { id: $memberId }) {
      member {
        id
        isEnabled
      }
    }
  }
`);

const useDisableMember = () => {
  return useMutation(DisableMemberGql);
};

export { useDisableMember };
