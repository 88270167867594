import { GuardmeStatus } from '@/gql/graphql';
import {
  ARRAY_DELIMITER,
  baseDataTableQueryStates,
  RANGE_DELIMITER,
} from '@/lib/data-table';
import {
  createParser,
  parseAsArrayOf,
  parseAsTimestamp,
  useQueryStates,
} from 'nuqs';

const GuardmeStatusToQueryValue = {
  [GuardmeStatus.Concluded]: GuardmeStatus.Concluded.toLowerCase(),
  [GuardmeStatus.Expired]: GuardmeStatus.Expired.toLowerCase(),
  [GuardmeStatus.InPanic]: GuardmeStatus.InPanic.toLowerCase(),
  [GuardmeStatus.InProgress]: GuardmeStatus.InProgress.toLowerCase(),
} as const;

const GuardmeStatusFromQueryValue = {
  [GuardmeStatus.Concluded.toLowerCase()]: GuardmeStatus.Concluded,
  [GuardmeStatus.Expired.toLowerCase()]: GuardmeStatus.Expired,
  [GuardmeStatus.InPanic.toLowerCase()]: GuardmeStatus.InPanic,
  [GuardmeStatus.InProgress.toLowerCase()]: GuardmeStatus.InProgress,
} as const;

const parseAsGuardmeStatus = createParser({
  parse(queryValue) {
    return GuardmeStatusFromQueryValue[queryValue];
  },
  serialize(value) {
    return GuardmeStatusToQueryValue[value];
  },
});

const guardmesDataTableQueryStates = {
  parsers: {
    ...baseDataTableQueryStates.parsers,
    createdAt: parseAsArrayOf(parseAsTimestamp, RANGE_DELIMITER),
    status: parseAsArrayOf(parseAsGuardmeStatus, ARRAY_DELIMITER),
  },
  urlKeys: {
    ...baseDataTableQueryStates.urlKeys,
    createdAt: 'created',
    status: 'status',
  },
};

const useGuardmesSearchParameters = () =>
  useQueryStates(guardmesDataTableQueryStates.parsers, {
    urlKeys: guardmesDataTableQueryStates.urlKeys,
  });

export { guardmesDataTableQueryStates, useGuardmesSearchParameters };
