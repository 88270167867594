import { EmptyState } from '@/components/empty-state';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { Toggle } from '@/components/ui/toggle';
import { type Place } from '@/gql/graphql';
import { Eye, EyeOff } from 'lucide-react';
import { useMemo } from 'react';

type Props = {
  readonly emptyTitle?: string;
  readonly fetching?: boolean;
  readonly places?: Array<Pick<Place, 'id' | 'name'>> | null;
  readonly selected: string[];
  readonly setSelected: (value: string[]) => void;
};

const PlaceToggleGroup = ({
  emptyTitle = 'No places defined.',
  fetching,
  places = [],
  selected,
  setSelected,
}: Props) => {
  const sortedPlaces = useMemo(
    () =>
      places
        ? places.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
          )
        : [],
    [places],
  );

  if (fetching) {
    return <Skeleton className="h-12 w-full" />;
  }

  if (!places) {
    return (
      <EmptyState
        className="py-6"
        title={emptyTitle}
      />
    );
  }

  if (places.length === 0) {
    return (
      <EmptyState
        className="py-6"
        title={emptyTitle}
      />
    );
  }

  return (
    <ScrollArea className="h-40 border p-2">
      <ul className="flex flex-wrap gap-2">
        {sortedPlaces.map((item) => (
          <li key={item.id}>
            <Toggle
              aria-label="Toggle visible"
              className="h-6 flex items-center gap-2 text-muted-foreground data-[state=on]:text-foreground"
              onPressedChange={() => {
                if (selected.includes(item.id)) {
                  setSelected(selected.filter((x) => x !== item.id));
                } else {
                  setSelected([...selected, item.id]);
                }
              }}
              pressed={selected.includes(item.id)}
              size="sm"
              variant="outline"
            >
              {selected.includes(item.id) ? (
                <Eye className="size-4" />
              ) : (
                <EyeOff className="size-4" />
              )}
              {item.name}
            </Toggle>
          </li>
        ))}
      </ul>
    </ScrollArea>
  );
};

export { PlaceToggleGroup };
