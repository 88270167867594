import { Datetime } from '@/components/Datetime';
import { Badge } from '@/components/ui/badge';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import {
  addDays,
  eachDayOfInterval,
  endOfDay,
  formatDate,
  startOfDay,
} from 'date-fns';
import { ArrowUpRight, Flag, Pin, ShieldCheck } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberActivityGql = graphql(`
  query MemberActivityGql($memberId: String!, $timestamp: Datetime!) {
    member(id: $memberId) {
      id
      guardmes(condition: { createdAt: { gte: $timestamp } }) {
        nodes {
          id
          shortId
          createdAt
        }
      }
      incidents: incidentsByContactMemberId(
        condition: { createdAt: { gte: $timestamp } }
      ) {
        nodes {
          id
          shortId
          createdAt
          subject
          incidentType {
            id
            name
          }
        }
      }
      checkIns(condition: { createdAt: { gte: $timestamp } }) {
        nodes {
          id
          shortId
          createdAt
        }
      }
    }
  }
`);

const options = [
  { label: 'Today', value: 1 },
  { label: 'Last 3 days', value: 3 },
  { label: 'Last 5 days', value: 5 },
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 14 days', value: 14 },
];

type Props = {
  readonly className?: string;
  readonly memberId: string;
};

const MemberActivity = ({ className, memberId }: Props) => {
  const [timespan, setTimespan] = useState(5);

  const startDate = new Date();
  const endDate = timespan === 1 ? startDate : addDays(startDate, 0 - timespan);
  const startOfFirstDay = startOfDay(endDate);
  const datesBetween = eachDayOfInterval({ end: endDate, start: startDate });

  const [{ data, error, fetching }] = useQuery({
    query: MemberActivityGql,
    variables: {
      memberId,
      timestamp: startOfFirstDay,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  const activity = useMemo(() => {
    const nodes = [];
    if (data?.member) {
      for (const node of data.member.checkIns.nodes ?? []) {
        nodes.push({
          createdAt: new Date(node.createdAt),
          id: node.id,
          shortId: node.shortId,
          type: 'checkin',
        });
      }

      for (const node of data.member.guardmes.nodes ?? []) {
        nodes.push({
          createdAt: new Date(node.createdAt),
          id: node.id,
          shortId: node.shortId,
          type: 'guardme',
        });
      }

      for (const node of data.member.incidents.nodes ?? []) {
        nodes.push({
          createdAt: new Date(node.createdAt),
          id: node.id,
          incidentType: node.incidentType?.name || '',
          shortId: node.shortId,
          subject: node.subject,
          type: 'incident',
        });
      }
    }

    return nodes.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
  }, [data?.member]);

  return (
    <div className={cn('', className)}>
      <div className="flex items-center mb-6 justify-between">
        <h2 className="font-extrabold text-xl">Recent Activity</h2>

        <Select
          onValueChange={(value) => {
            setTimespan(Number.parseInt(value, 10));
          }}
          value={timespan.toString()}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select an activity timespan" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Activity Timespan</SelectLabel>
              {options.map((item) => (
                <SelectItem
                  key={item.value}
                  value={item.value.toString()}
                >
                  {item.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      {datesBetween.map((date) => {
        const startActivity = startOfDay(date);
        const endActivity = endOfDay(date);

        const activityFilter = (x: { createdAt: Date }) =>
          startActivity <= x.createdAt && x.createdAt < endActivity;

        return (
          <div
            className="pb-6"
            key={date.valueOf()}
          >
            <h3 className="text-lg font-semibold">
              {formatDate(date, 'EEEE, MMMM dd, yyy')}
            </h3>
            <ul className="py-2 divide-y">
              {fetching ? (
                <li className="py-2">
                  <Skeleton className="w-64 h-6" />
                </li>
              ) : (
                activity.filter(activityFilter).length === 0 && (
                  <li className="py-2 italic">No Activity.</li>
                )
              )}
              {activity.filter(activityFilter).map((item) => {
                switch (item.type) {
                  case 'checkin':
                    return (
                      <li key={item.id}>
                        <div className="text-sm py-3 grid grid-cols-3 gap-2">
                          <div className="flex items-center gap-2 font-semibold">
                            <Pin className="size-4" /> Check In Created
                          </div>
                          <div className="flex items-center gap-2 justify-center">
                            <Datetime
                              datetime={item.createdAt}
                              format="hh:mm aa"
                            />
                          </div>
                          <div className="flex items-center gap-2 justify-end">
                            <Link to={`/check-in/${item.id}`}>
                              <Badge>
                                {item.shortId}{' '}
                                <ArrowUpRight className="ml-2 size-4" />
                              </Badge>
                            </Link>
                          </div>
                        </div>
                      </li>
                    );
                  case 'guardme':
                    return (
                      <li key={item.id}>
                        <div className="text-sm py-3 grid grid-cols-3 gap-2">
                          <div className="flex items-center gap-2 font-semibold">
                            <ShieldCheck className="size-4" /> GuardMe Started
                          </div>
                          <div className="flex items-center gap-2 justify-center">
                            <Datetime
                              datetime={item.createdAt}
                              format="hh:mm aa"
                            />
                          </div>
                          <div className="flex items-center gap-2 justify-end">
                            <Link to={`/guardme/${item.id}`}>
                              <Badge>
                                {item.shortId}{' '}
                                <ArrowUpRight className="ml-2 size-4" />
                              </Badge>
                            </Link>
                          </div>
                        </div>
                      </li>
                    );
                  case 'incident':
                    return (
                      <li key={item.id}>
                        <div className="text-sm py-3 grid grid-cols-3 gap-2">
                          <div className="flex items-center gap-2 font-semibold">
                            <Flag className="size-4" /> Incident Submitted
                          </div>
                          <div className="flex items-center gap-2 justify-center">
                            <Datetime
                              datetime={item.createdAt}
                              format="hh:mm aa"
                            />
                          </div>
                          <div className="flex items-center gap-2 justify-end">
                            <Link to={`/incidents/${item.id}`}>
                              <Badge>
                                {item.shortId}{' '}
                                <ArrowUpRight className="ml-2 size-4" />
                              </Badge>
                            </Link>
                          </div>
                          <div className="col-span-3 italic font-semibold">
                            <Badge
                              className="ml-6 mr-3 text-xs"
                              variant="secondary"
                            >
                              {item.incidentType}
                            </Badge>{' '}
                            {item.subject}
                          </div>
                        </div>
                      </li>
                    );
                  default:
                    return null;
                }
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export { MemberActivity };
