import { GroupDelete } from './GroupDelete';
import { GroupUpdate } from './GroupUpdate';
import { Empty } from '@/components/Empty';
import { List, ListItem } from '@/components/List';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { graphql } from '@/gql';
import { type Member } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { Plus, Trash } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

const SectionGroupsSheetGql = graphql(`
  query SectionGroupsSheetGql($groupId: String!) {
    group(id: $groupId) {
      id
      name
      description
      members {
        nodes {
          id
          avatarUrl
          fullName
          displayName
          isEnabled
        }
      }
      organization {
        id
        settings {
          id
          checkInSupervisorGroupId
          guardmeSupervisorGroupId
          incidentSupervisorGroupId
        }
      }
    }
  }
`);

const AddMemberToGroupGql = graphql(`
  mutation AddMemberToGroupGql($groupId: String!, $memberId: String!) {
    addMemberToGroup(input: { groupId: $groupId, memberId: $memberId }) {
      query {
        group(id: $groupId) {
          id
          members {
            nodes {
              id
              avatarUrl
              fullName
              displayName
              isEnabled
            }
          }
        }
      }
    }
  }
`);

const RemoveMemberFromGroupGql = graphql(`
  mutation RemoveMemberFromGroupGql($groupId: String!, $memberId: String!) {
    removeMemberFromGroup(input: { groupId: $groupId, memberId: $memberId }) {
      query {
        group(id: $groupId) {
          id
          members {
            nodes {
              id
              avatarUrl
              fullName
              displayName
              isEnabled
            }
          }
        }
      }
    }
  }
`);

type Props = {
  readonly activeMembers?: Array<
    Pick<Member, 'id' | 'avatarUrl' | 'fullName' | 'displayName'>
  >;
  readonly groupId?: string;
  readonly onOpenChange: () => void;
  readonly open: boolean;
};

const SectionGroupsSheet = ({
  activeMembers = [],
  groupId = '',
  onOpenChange,
  open,
}: Props) => {
  const isAdmin = useAppStore((store) => store.activeMembership?.isAdmin);

  const [{ data, fetching }] = useQuery({
    query: SectionGroupsSheetGql,
    variables: {
      groupId,
    },
  });

  const [, addMemberToGroup] = useMutation(AddMemberToGroupGql);
  const [, removeMemberFromGroup] = useMutation(RemoveMemberFromGroupGql);

  const groupMembers = data?.group?.members.nodes ?? [];
  const groupMemberIds = groupMembers.map((item) => item.id);
  const availableMembers = activeMembers.filter(
    (item) => !groupMemberIds.includes(item.id),
  );

  return (
    <Sheet
      onOpenChange={onOpenChange}
      open={open}
    >
      <SheetContent>
        <SheetHeader>
          {fetching ? (
            <>
              <Skeleton className="size-16 rounded-full" />
              <Skeleton className="h-7 w-full" />
              <Skeleton className="h-5 w-full" />
              <Skeleton className="h-5 w-full" />
            </>
          ) : (
            <>
              <Avatar className="size-16">
                <AvatarFallback className="text-2xl">
                  {data?.group?.name.slice(0, 1)}
                </AvatarFallback>
              </Avatar>
              <SheetTitle>{data?.group?.name}</SheetTitle>
              <SheetDescription>{data?.group?.description} </SheetDescription>
              <SheetDescription className="flex flex-wrap items-start gap-2">
                {groupId ===
                  data?.group?.organization?.settings
                    ?.checkInSupervisorGroupId && (
                  <Badge variant="info">Check In Supervisor</Badge>
                )}
                {groupId ===
                  data?.group?.organization?.settings
                    ?.guardmeSupervisorGroupId && (
                  <Badge variant="info">GuardMe Supervisor</Badge>
                )}
                {groupId ===
                  data?.group?.organization?.settings
                    ?.incidentSupervisorGroupId && (
                  <Badge variant="info">Incident Supervisor</Badge>
                )}
              </SheetDescription>
            </>
          )}
        </SheetHeader>
        <div className="py-4 space-y-4 grow">
          <Tabs
            className="w-full"
            defaultValue="members"
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="members">Members</TabsTrigger>
              <TabsTrigger
                disabled={!isAdmin}
                value="modify"
              >
                Modify
              </TabsTrigger>
            </TabsList>

            <TabsContent value="members">
              <Empty visible={groupMembers.length === 0} />
              <List className="py-2 max-h-96">
                {!fetching &&
                  groupMembers
                    .sort((a, b) =>
                      `${a.displayName}${a.fullName}` <
                      `${b.displayName}${b.fullName}`
                        ? -1
                        : 1,
                    )
                    .map((item) => (
                      <ListItem
                        className="flex items-center py-2"
                        key={`member_${item.id}`}
                      >
                        <Avatar className="size-8">
                          <AvatarImage src={item.avatarUrl ?? undefined} />
                          <AvatarFallback>
                            {item.fullName.slice(0, 2)}
                          </AvatarFallback>
                        </Avatar>

                        <div className="ml-2 text-sm">
                          <div>
                            <Link
                              className="hover:underline"
                              to={`/members/${item.id}`}
                            >
                              {item.displayName ??
                                item.fullName ??
                                'Deleted User'}
                            </Link>
                          </div>
                          {item.displayName && (
                            <div className="text-xs text-muted-foreground">
                              {item.fullName}
                            </div>
                          )}
                        </div>

                        <div className="ml-auto">
                          <Button
                            className="rounded-full"
                            disabled={fetching}
                            onClick={async () => {
                              await removeMemberFromGroup({
                                groupId,
                                memberId: item.id,
                              });
                            }}
                            size="icon"
                            variant="destructive"
                          >
                            <Trash />
                            <span className="sr-only">Remove</span>
                          </Button>
                        </div>
                      </ListItem>
                    ))}
              </List>

              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    aria-expanded={open}
                    className="w-full"
                    role="combobox"
                    variant="outline"
                  >
                    <Plus className="opacity-50" />
                    Add member...
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="PopoverContent p-0"
                  usePortal={false}
                >
                  <Command>
                    <CommandInput
                      className="h-9"
                      placeholder="Add member..."
                    />
                    <CommandList>
                      <CommandEmpty>No members found.</CommandEmpty>
                      <CommandGroup>
                        {availableMembers.map((member) => (
                          <CommandItem
                            key={member.id}
                            onSelect={async (currentValue) => {
                              // do the thing
                              await addMemberToGroup({
                                groupId,
                                memberId: currentValue,
                              });
                            }}
                            value={member.id}
                          >
                            <div className="flex items-center">
                              <Avatar className="size-8">
                                <AvatarImage
                                  src={member.avatarUrl ?? undefined}
                                />
                                <AvatarFallback>
                                  {member.fullName.slice(0, 2)}
                                </AvatarFallback>
                              </Avatar>

                              <div className="ml-2 text-sm">
                                <div>
                                  <Link
                                    className="hover:underline"
                                    to={`/members/${member.id}`}
                                  >
                                    {member.displayName ??
                                      member.fullName ??
                                      'Deleted User'}
                                  </Link>
                                </div>
                                {member.displayName && (
                                  <div className="text-xs text-muted-foreground">
                                    {member.fullName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </TabsContent>
            <TabsContent value="modify">
              {fetching ? (
                <div className="space-y-8 py-3">
                  <Skeleton className="h-32 w-full" />
                  <Separator orientation="horizontal" />
                  <Skeleton className="h-32 w-full" />
                </div>
              ) : (
                <div className="space-y-8 py-3">
                  <GroupUpdate group={data?.group} />
                  <Separator orientation="horizontal" />
                  <GroupDelete groupId={groupId} />
                </div>
              )}
            </TabsContent>
          </Tabs>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export { SectionGroupsSheet };
