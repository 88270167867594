import { MultiSelect } from '@/components/multi-select';
import { PhoneNumberInput } from '@/components/PhoneNumberInput';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { parseMetadata } from '@/lib/metadata';
import { useAppStore } from '@/stores';
import { type MetadataFieldDefinitions } from '@/utils/metadata';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader, Plus, Send } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { array, type Infer, object, refine, string } from 'superstruct';
import { useMutation } from 'urql';

const NewInivtationDialogGql = graphql(`
  mutation NewInivtationDialogGql(
    $groupIds: [String]!
    $key: String!
    $metadata: JSON!
    $organizationId: String!
    $organizationRole: MemberRole!
  ) {
    createInvitation(
      input: {
        groupIds: $groupIds
        key: $key
        metadata: $metadata
        organizationId: $organizationId
        organizationRole: $organizationRole
      }
    ) {
      invitation {
        id
        key
        metadata
        groupIds
        organizationId
        organizationRole
      }
    }
  }
`);

const emailOrPhone = () =>
  refine(string(), 'EmailOrPhone', (value) => {
    if (value.includes('@')) {
      // validate email address
      const mailFormat =
        // eslint-disable-next-line no-control-regex, regexp/no-dupe-characters-character-class
        /(?:[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\u0001-\u0008\v\f\u000E-\u001F\u0021\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\v\f\u000E-\u007F])*")@(?:(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[a-z\d-]*[a-z\d]:(?:[\u0001-\u0008\v\f\u000E-\u001F\u0021-\u005A\u0053-\u007F]|\\[\u0001-\u0009\v\f\u000E-\u007F])+)\])/u;
      if (mailFormat.test(value)) {
        return true;
      }

      return 'Invalid email address format';
    }

    const phoneFormat =
      // eslint-disable-next-line unicorn/no-unsafe-regex
      /^(?:\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/u;

    if (phoneFormat.test(value)) {
      return true;
    }

    return 'Invalid phone number format';
  });

type NewInivtationDialogProps = {
  readonly groups?: Array<{
    id: string;
    name: string;
  }>;
  readonly memberMetadataFields?: MetadataFieldDefinitions;
  readonly organizationId: string;
  readonly type?: 'button' | 'link';
};

const NewInivtationDialog = ({
  groups = [],
  memberMetadataFields,
  organizationId,
  type = 'button',
}: NewInivtationDialogProps) => {
  const [open, setOpen] = useState(false);

  const isAdmin = useAppStore(
    (state) => state.activeMembership?.isAdmin ?? false,
  );

  // parsing metadata should be considered costly
  const dynamicSchema = useMemo(
    () => parseMetadata(memberMetadataFields),
    [memberMetadataFields],
  );

  const schema = object({
    groupIds: array(string()),
    key: emailOrPhone(),
    metadata: dynamicSchema.schema,
    organizationRole: string(),
  });

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      groupIds: [],
      key: '',
      metadata: dynamicSchema.defaultValues,
      organizationRole: MemberRole.Member,
    },
    resolver: superstructResolver(schema),
  });

  const [{ error, fetching }, createInvitation] = useMutation(
    NewInivtationDialogGql,
  );

  // full list of options is unlikely to change
  // between re-renders
  const groupOptions = useMemo(
    () =>
      groups.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [groups],
  );

  if (!isAdmin) {
    return null;
  }

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await createInvitation({
      groupIds: values.groupIds,
      key: values.key,
      metadata: values.metadata,
      organizationId,
      organizationRole: values.organizationRole as MemberRole,
    });

    if (!response.error) {
      setOpen(false);
    }
  };

  const onCancel = () => {
    form.reset();
    setOpen(false);
  };

  return (
    <Dialog
      onOpenChange={(value) => {
        if (!value) {
          form.reset();
        }

        setOpen(value);
      }}
      open={open}
    >
      <DialogTrigger asChild>
        <Button
          className="whitespace-nowrap h-8"
          size="sm"
          variant={type === 'button' ? 'default' : 'link'}
        >
          {type === 'button' && <Plus />}
          Invite a new member
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite people to your organization</DialogTitle>
          <DialogDescription>
            Invite new members to join by sending them an invitation. Assign a
            role to define their access level. Optionally set any groups they
            should be assigned to.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Tabs
              defaultValue="phone"
              onValueChange={() => {
                form.setValue('key', '');
              }}
            >
              <TabsList className="grid grid-cols-2">
                <TabsTrigger value="phone">Phone Number</TabsTrigger>
                <TabsTrigger value="email">Email Address</TabsTrigger>
              </TabsList>

              <TabsContent value="email">
                <FormField
                  control={form.control}
                  name="key"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Enter email address"
                          type="email"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TabsContent>
              <TabsContent value="phone">
                <FormField
                  control={form.control}
                  name="key"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <PhoneNumberInput
                          {...field}
                          placeholder="Enter phone number"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TabsContent>
            </Tabs>

            <FormField
              control={form.control}
              name="organizationRole"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel>Role</FormLabel>
                  <Select
                    onValueChange={onChange}
                    value={value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a role" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={MemberRole.Member}>Member</SelectItem>
                      <SelectItem value={MemberRole.Supervisor}>
                        Supervisor
                      </SelectItem>
                      <SelectItem value={MemberRole.Administrator}>
                        Admin
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="groupIds"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel>Groups</FormLabel>
                  <MultiSelect
                    label="groups"
                    onValueChange={onChange}
                    options={groupOptions}
                    value={value}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            {dynamicSchema.formFields.map((item) => (
              <FormField
                control={form.control}
                key={item.id}
                name={`metadata.${item.name}`}
                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                  <FormItem>
                    {item.type === 'STRING' || item.type === 'NUMBER' ? (
                      <>
                        <FormLabel>
                          {item.name}{' '}
                          <span className="text-muted-foreground">
                            (Custom Field)
                          </span>
                        </FormLabel>
                        <FormControl>
                          <Input
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={ref}
                            value={value as string}
                          />
                        </FormControl>
                        <FormMessage />
                      </>
                    ) : (
                      <div className="flex items-center gap-2">
                        <FormControl>
                          <Checkbox
                            checked={value as boolean}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={ref}
                          />
                        </FormControl>
                        <FormLabel>{item.name}</FormLabel>
                        <FormMessage />
                      </div>
                    )}
                  </FormItem>
                )}
              />
            ))}

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                Invite
                {fetching ? <Loader className="animate-spin" /> : <Send />}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { NewInivtationDialog };
