import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button, type ButtonProps } from '@/components/ui/button';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { type Member } from '@/gql/graphql';
import { toast } from '@/lib/toast';
import { type DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import { Loader } from 'lucide-react';
import { type ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

const DeleteMemberDialogGql = graphql(`
  mutation DeleteMemberDialogGql($memberId: String!) {
    deleteMember(input: { id: $memberId }) {
      member {
        id
      }
    }
  }
`);

type DeleteMemberDialogProps = {
  readonly children: ReactElement<ButtonProps | DropdownMenuItemProps>;
  readonly member?: Pick<Member, 'id' | 'displayName' | 'fullName'> | null;
};

const DeleteMemberDialog = ({ children, member }: DeleteMemberDialogProps) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [{ error, fetching }, deleteMember] = useMutation(
    DeleteMemberDialogGql,
  );

  const handleOnClick = async () => {
    if (member) {
      await deleteMember({ memberId: member.id });
      setOpen(false);
      toast.success(`Deleted ${member.displayName ?? member.fullName}`);
      navigate('/members');
    }
  };

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Delete Member {member?.displayName ?? member?.fullName}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Deleting a member will remove them and all data related to them from
            the organization. This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <MutationError error={error} />

        <AlertDialogFooter>
          <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={handleOnClick}
            variant="destructive"
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Delete Member
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DeleteMemberDialog };
