import { Separator } from './ui/separator';
import { cn } from '@/lib/utils';

type AnnotatedLayoutProps = React.HtmlHTMLAttributes<HTMLDivElement>;
const AnnotatedLayout = ({ className, ...props }: AnnotatedLayoutProps) => (
  <div
    className={cn('space-y-8', className)}
    {...props}
  />
);

type AnnotatedSectionProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  readonly 'aria-labelledby': string;
};

const AnnotatedSection = ({
  children,
  className,
  ...props
}: AnnotatedSectionProps) => (
  <section
    className={cn('', className)}
    {...props}
  >
    <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
      {children}
    </div>
  </section>
);

type AnnotatedHeaderProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  readonly description?: string;
  readonly title: string;
  readonly titleId: string;
};

const AnnotatedHeader = ({
  children,
  className,
  description,
  title,
  titleId,
}: AnnotatedHeaderProps) => (
  <div className={cn('space-y-2', className)}>
    <h2
      className="font-semibold"
      id={titleId}
    >
      {title}
    </h2>
    {description && (
      <p className="text-sm leading-6 text-foreground/80">{description}</p>
    )}
    {children}
  </div>
);

type AnnotatedContentProps = React.HtmlHTMLAttributes<HTMLDivElement>;

const AnnotatedContent = ({ className, ...props }: AnnotatedContentProps) => (
  <div
    className={cn('md:col-span-2', className)}
    {...props}
  />
);

const AnnotatedSeparator = () => <Separator orientation="horizontal" />;

export {
  AnnotatedContent,
  AnnotatedHeader,
  AnnotatedLayout,
  AnnotatedSection,
  AnnotatedSeparator,
};
