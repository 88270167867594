import { DeleteMemberDialog } from '../components/DeleteMemberDialog';
import { DisableMemberDialog } from '../components/DisableMemberDialog';
import { EditDisplayNameDialog } from '../components/EditDisplayNameDialog';
import { EditMetadataDialog } from '../components/EditMetadataDialog';
import { EditRoleDialog } from '../components/EditRoleDialog';
import { EnableMemberDialog } from '../components/EnableMemberDialog';
import { MemberDetail } from '../components/MemberDetail';
import { MemberCheckInList } from '../components/memberDetails/MemberCheckInList';
import { MemberGuardMeList } from '../components/memberDetails/MemberGuardMeList';
import { MemberIncidentList } from '../components/memberDetails/MemberIncidentList';
import { MemberOverview } from '../components/MemberOverview';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Header } from '@/components/header';
import { TabsListAlt, TabsTriggerAlt } from '@/components/tabs-alt';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Tabs, TabsContent } from '@/components/ui/tabs';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { parseMemberMetadata } from '@/lib/metadata';
import { useAppStore } from '@/stores';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberGql = graphql(`
  query MemberGql($memberId: String!) {
    member(id: $memberId) {
      id
      fullName
      displayName
      avatarUrl
      isEnabled
      organizationRole
      createdAt
      phoneNumber
      emailAddress
      metadata
      timezone
      organization {
        id
        memberMetadataFields
        groups {
          nodes {
            id
            name
          }
        }
      }
      emergencyContacts {
        nodes {
          id
          description
          phoneNumber
          priority
        }
      }
      groups {
        nodes {
          id
          name
        }
      }
    }
  }
`);

// eslint-disable-next-line complexity
const Member = () => {
  const [currentMemberId, isAdmin] = useAppStore((store) => [
    store.activeMembership?.memberId,
    store.activeMembership?.isAdmin,
  ]);
  const { memberId } = useParams() as { memberId: string };
  const [tab, setTab] = useState('overview');
  const [, setSearchParameters] = useSearchParams();

  const [{ data, error, fetching }] = useQuery({
    query: MemberGql,
    variables: {
      memberId,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  const metadata = parseMemberMetadata(
    data?.member?.organization?.memberMetadataFields,
    data?.member?.metadata,
  );

  return (
    <>
      <Header
        actions={
          isAdmin ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  className="w-36"
                  size="sm"
                  variant="secondary"
                >
                  Manage member <ChevronDown />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                className="w-36"
              >
                <DropdownMenuGroup>
                  <EditDisplayNameDialog member={data?.member}>
                    <DropdownMenuItem
                      disabled={!data?.member}
                      onSelect={(event) => event.preventDefault()}
                    >
                      Edit name
                    </DropdownMenuItem>
                  </EditDisplayNameDialog>
                  <EditMetadataDialog
                    member={data?.member}
                    metadataFields={
                      data?.member?.organization?.memberMetadataFields
                    }
                  >
                    <DropdownMenuItem
                      disabled={!data?.member}
                      onSelect={(event) => event.preventDefault()}
                    >
                      Edit metadata
                    </DropdownMenuItem>
                  </EditMetadataDialog>
                  <EditRoleDialog member={data?.member}>
                    <DropdownMenuItem
                      disabled={
                        !data?.member || currentMemberId === data.member.id
                      }
                      onSelect={(event) => event.preventDefault()}
                    >
                      Change role
                    </DropdownMenuItem>
                  </EditRoleDialog>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  {data?.member?.isEnabled ? (
                    <DisableMemberDialog
                      memberId={memberId}
                      name={data.member.displayName || data.member.fullName}
                    >
                      <DropdownMenuItem
                        disabled={
                          !data?.member ||
                          data.member.organizationRole === MemberRole.Owner ||
                          currentMemberId === data.member.id
                        }
                        onSelect={(event) => event.preventDefault()}
                      >
                        Disable
                      </DropdownMenuItem>
                    </DisableMemberDialog>
                  ) : (
                    <>
                      <EnableMemberDialog memberId={memberId}>
                        <DropdownMenuItem
                          disabled={!data?.member}
                          onSelect={(event) => event.preventDefault()}
                        >
                          Enable
                        </DropdownMenuItem>
                      </EnableMemberDialog>
                      <DeleteMemberDialog member={data?.member}>
                        <DropdownMenuItem
                          disabled={data?.member?.isEnabled}
                          onSelect={(event) => event.preventDefault()}
                        >
                          Delete
                        </DropdownMenuItem>
                      </DeleteMemberDialog>
                    </>
                  )}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : undefined
        }
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/members">Members</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Member Details</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      />

      <div className="grid md:grid-cols-3 gap-8">
        <div className="col-span-1">
          <Avatar className="size-24 mb-2">
            <AvatarImage
              alt="member"
              src={data?.member?.avatarUrl ?? undefined}
            />
            <AvatarFallback>
              {(data?.member?.displayName ?? data?.member?.fullName)?.slice(
                0,
                2,
              )}
            </AvatarFallback>
          </Avatar>

          <h1 className="text-2xl font-bold ">
            {data?.member?.displayName ?? data?.member?.fullName}
          </h1>
          {Boolean(data?.member?.displayName) &&
            data?.member?.displayName !== data?.member?.fullName && (
              <p className="text-lg text-muted-foreground">
                {data?.member?.fullName}
              </p>
            )}

          <div className="mt-4">
            <MemberDetail
              allGroups={data?.member?.organization?.groups.nodes ?? []}
              fetching={fetching}
              member={data?.member ? { ...data.member, metadata } : undefined}
            />
          </div>
        </div>
        <div className="md:col-span-2">
          <Tabs
            onValueChange={(value) => {
              setSearchParameters({});
              setTab(value);
            }}
            value={tab}
          >
            <div className="flex items-center">
              <TabsListAlt>
                <TabsTriggerAlt value="overview">Overview</TabsTriggerAlt>
                <TabsTriggerAlt value="checkin">Check In</TabsTriggerAlt>
                <TabsTriggerAlt value="guardme">GuardMe</TabsTriggerAlt>
                <TabsTriggerAlt value="incident">Incidents</TabsTriggerAlt>
              </TabsListAlt>
            </div>

            <TabsContent value="overview">
              <MemberOverview
                memberId={memberId}
                setTab={setTab}
              />
            </TabsContent>
            <TabsContent value="checkin">
              <MemberCheckInList memberId={memberId} />
            </TabsContent>
            <TabsContent value="guardme">
              <MemberGuardMeList memberId={memberId} />
            </TabsContent>
            <TabsContent value="incident">
              <MemberIncidentList memberId={memberId} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export { Member };
