import { baseDataTableQueryStates, RANGE_DELIMITER } from '@/lib/data-table';
import { parseAsArrayOf, parseAsTimestamp, useQueryStates } from 'nuqs';

const parsers = {
  ...baseDataTableQueryStates.parsers,
  createdAt: parseAsArrayOf(parseAsTimestamp, RANGE_DELIMITER),
};

const urlKeys = {
  ...baseDataTableQueryStates.urlKeys,
  createdAt: 'created',
};

const useCheckInReportsSearchParameters = () =>
  useQueryStates(parsers, { urlKeys });

export { useCheckInReportsSearchParameters };
