import { EmptyState } from '@/components/empty-state';
import { MultiSelect } from '@/components/multi-select';
import { TimePickerTwelveHour } from '@/components/time-picker-twelve-hour';
import { Button, type ButtonProps } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { TabsContent } from '@radix-ui/react-tabs';
import { format } from 'date-fns';
import { CalendarIcon, Loader, X } from 'lucide-react';
import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  array,
  date,
  type Infer,
  nonempty,
  nullable,
  object,
  string,
  union,
} from 'superstruct';
import { useMutation, useQuery } from 'urql';

const NewCheckInReportSheetGql = graphql(`
  query NewCheckInReportSheetGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      members {
        nodes {
          id
          fullName
          displayName
          isEnabled
          avatarUrl
        }
      }
      places {
        nodes {
          id
          name
        }
      }
      groups {
        nodes {
          id
          name
        }
      }
    }
  }
`);

const CreateCheckInReportGql = graphql(`
  mutation CreateCheckInReportQuery($input: CreateCheckInReportInput!) {
    createCheckInReport(input: $input) {
      checkInReport {
        id
        createdAt
        requestorMember {
          id
          displayName
          fullName
          avatarUrl
        }
        cutoffAt
        placeId
        place {
          id
          name
          description
        }
      }
    }
  }
`);

const schema = union([
  object({
    cutoff: nullable(date()),
    groupIds: array(string()),
    memberIds: nonempty(array(string())),
    placeId: string(),
  }),
  object({
    cutoff: nullable(date()),
    groupIds: nonempty(array(string())),
    memberIds: array(string()),
    placeId: string(),
  }),
]);

type Props = {
  readonly children: ReactElement<ButtonProps>;
  readonly defaultOpen?: boolean;
  readonly organizationId: string;
};

const NewCheckInReportSheet = ({
  children: trigger,
  defaultOpen = false,
  organizationId,
}: Props) => {
  const [open, setOpen] = useState(defaultOpen);
  const [, setSearchParameters] = useSearchParams();

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const [{ data }] = useQuery({
    query: NewCheckInReportSheetGql,
    variables: {
      organizationId,
    },
  });

  const navigate = useNavigate();

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      cutoff: null,
      groupIds: [],
      memberIds: [],
      placeId: '',
    },
    resolver: superstructResolver(schema),
  });

  const [{ error, fetching: mutating }, createCheckIn] = useMutation(
    CreateCheckInReportGql,
  );

  const onSubmit = async (values: Infer<typeof schema>) => {
    // alert(JSON.stringify(values, null, 2));

    const response = await createCheckIn({
      input: {
        cutoffAt: values.cutoff ? values.cutoff.toISOString() : null,
        groupIds: values.groupIds,
        memberIds: values.memberIds,
        organizationId,
        placeId: values.placeId ? values.placeId : null,
      },
    });

    if (response.data?.createCheckInReport?.checkInReport?.id) {
      form.reset();
      navigate(
        `/check-in-reports/${response.data.createCheckInReport.checkInReport.id}`,
      );
    }
  };

  const groupOptions = useMemo(
    () =>
      data?.organization?.groups.nodes
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
        ) || [],
    [data?.organization?.groups.nodes],
  );

  const memberOptions = useMemo(
    () =>
      data?.organization?.members.nodes
        .filter((x) => x.isEnabled)
        .map((item) => ({
          label: item.displayName
            ? `${item.displayName} (${item.fullName})`
            : item.fullName,
          value: item.id,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
        ) || [],
    [data?.organization?.members.nodes],
  );

  const placeOptions = useMemo(
    () =>
      data?.organization?.places.nodes
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
        ) || [],
    [data?.organization?.places.nodes],
  );

  return (
    <Sheet
      onOpenChange={(value) => {
        form.reset();
        setSearchParameters((previous) => {
          previous.delete('new');
          return { ...previous };
        });
        setOpen(value);
      }}
      open={open}
    >
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>New Check In Report</SheetTitle>
          <SheetDescription>
            Request members Check In to your organization.
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Tabs
              className="mt-4"
              defaultValue="recipients"
            >
              <TabsList className="grid grid-cols-2">
                <TabsTrigger value="recipients">Recipients</TabsTrigger>
                <TabsTrigger value="options">Options</TabsTrigger>
              </TabsList>
              <TabsContent value="recipients">
                <div className="py-4 space-y-4">
                  {groupOptions.length === 0 ? (
                    <EmptyState
                      className="py-4 gap-1"
                      title="No groups defined."
                    >
                      <Link to="/members?view=groups">
                        <Button variant="link">Manage groups</Button>
                      </Link>
                    </EmptyState>
                  ) : (
                    <FormField
                      control={form.control}
                      name="groupIds"
                      render={({ field: { onChange, value } }) => (
                        <FormItem>
                          <FormLabel>Groups</FormLabel>
                          <MultiSelect
                            label="groups"
                            onValueChange={onChange}
                            options={groupOptions}
                            value={value}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  <FormField
                    control={form.control}
                    name="memberIds"
                    render={({ field: { onChange, value } }) => (
                      <FormItem>
                        <FormLabel>Members</FormLabel>
                        <MultiSelect
                          label="members"
                          onValueChange={onChange}
                          options={memberOptions}
                          value={value}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </TabsContent>
              <TabsContent value="options">
                <div className="py-4 space-y-6">
                  <FormField
                    control={form.control}
                    name="cutoff"
                    render={({ field: { onChange, value } }) => (
                      <FormItem>
                        <FormLabel>Specify a Cutoff time</FormLabel>
                        <FormDescription>
                          Members will required to complete this Check In
                          request if the specified cutoff has not been met,
                          afterward they will no longer be able to submit a
                          Check In to satisfy this report.
                        </FormDescription>
                        <Popover>
                          <FormControl>
                            <div className="flex items-center gap-1">
                              <PopoverTrigger asChild>
                                <Button
                                  className={cn(
                                    'w-full justify-start text-left font-normal',
                                    !value && 'text-muted-foreground',
                                  )}
                                  variant="outline"
                                >
                                  <CalendarIcon className="mr-2 h-4 w-4" />
                                  {value ? (
                                    format(value, 'PPP HH:mm:ss')
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <Button
                                className="h-10"
                                disabled={!value}
                                onClick={() => {
                                  form.resetField('cutoff');
                                }}
                                size="icon"
                                variant="ghost"
                              >
                                <X />
                                <span className="sr-only">Clear selection</span>
                              </Button>
                            </div>
                          </FormControl>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              initialFocus
                              mode="single"
                              onSelect={onChange}
                              selected={value ?? undefined}
                            />
                            <div className="p-3 border-t border-border">
                              <TimePickerTwelveHour
                                date={value ?? undefined}
                                setDate={onChange}
                              />
                            </div>
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="placeId"
                    render={({ field: { onChange, value } }) => (
                      <FormItem>
                        <FormLabel>Restrict to Place</FormLabel>
                        <FormDescription>
                          Members will only be allowed to complete this Check In
                          request if they are within the bounds of the specified
                          place.
                        </FormDescription>
                        {placeOptions.length === 0 ? (
                          <EmptyState
                            className="py-4 gap-1"
                            title="No places defined."
                          >
                            <Link to="/settings/places">
                              <Button variant="link">Manage places</Button>
                            </Link>
                          </EmptyState>
                        ) : (
                          <div className="flex items-center gap-1">
                            <Select
                              onValueChange={onChange}
                              value={value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a place" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent className="max-h-64">
                                {placeOptions.map((item) => (
                                  <SelectItem
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <Button
                              className="h-10"
                              disabled={!value}
                              onClick={() => {
                                form.resetField('placeId');
                              }}
                              size="icon"
                              variant="ghost"
                            >
                              <X />
                              <span className="sr-only">Clear selection</span>
                            </Button>
                          </div>
                        )}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </TabsContent>
            </Tabs>

            {error && (
              <div className="py-2">
                <MutationError error={error} />
              </div>
            )}

            <SheetFooter className="mt-8">
              <Button
                disabled={form.formState.isDirty || mutating}
                onClick={() => {
                  setOpen(false);
                }}
                type="reset"
                variant="ghost"
              >
                Cancel
              </Button>
              <Button
                disabled={!form.formState.isDirty || mutating}
                type="submit"
              >
                {mutating && <Loader className="animate-spin" />}
                Create Report
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};

export { NewCheckInReportSheet };
