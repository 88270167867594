import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { useMutation } from 'urql';

const EditRoleDropdownGql = graphql(`
  mutation EditRoleDropdownGql($memberId: String!, $roleId: MemberRole!) {
    updateMemberRole(input: { memberId: $memberId, roleId: $roleId }) {
      member {
        id
        organizationRole
      }
    }
  }
`);

type Props = {
  readonly disabled?: boolean;
  readonly memberId: string;
  readonly role: string;
};

const EditRoleDropdown = ({ disabled, memberId, role }: Props) => {
  const [, executeMutation] = useMutation(EditRoleDropdownGql);

  const handleValueChange = async (value: string) => {
    const response = await executeMutation({
      memberId,
      roleId: value as MemberRole,
    });

    if (response.error) {
      // toast with success message
    } else {
      // toast with error message
    }
  };

  return (
    <Select
      disabled={disabled}
      onValueChange={handleValueChange}
      value={role}
    >
      <SelectTrigger className="w-40">
        <SelectValue placeholder="Select a role" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem
            disabled
            value={MemberRole.Owner}
          >
            Owner
          </SelectItem>
          <SelectItem
            disabled={role === MemberRole.Owner}
            value={MemberRole.Administrator}
          >
            Admin
          </SelectItem>
          <SelectItem
            disabled={role === MemberRole.Owner}
            value={MemberRole.Supervisor}
          >
            Supervisor
          </SelectItem>
          <SelectItem
            disabled={role === MemberRole.Owner}
            value={MemberRole.Member}
          >
            Member
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export { EditRoleDropdown };
