import { MutationError } from '../../Core';
import { PlaceCreateMap } from './PlaceCreateMap';
import { MapProvider } from '@/components/Map';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Textarea } from '@/components/ui/textarea';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import {
  geometryFromShape,
  OrganizationNotFoundError,
  PermissionDeniedError,
} from '@/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { type ReactElement, useRef, useState } from 'react';
import { type ButtonProps } from 'react-day-picker';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const createPlaceQuery = graphql(`
  mutation createPlaceQuery(
    $organizationId: String!
    $name: String!
    $description: String!
    $geojson: JSON!
    $radius: Int!
  ) {
    createPlace(
      input: {
        organizationId: $organizationId
        name: $name
        description: $description
        geojson: $geojson
        radius: $radius
      }
    ) {
      place {
        id
        name
        description
        radius
        spatialData {
          geojson
        }
      }
    }
  }
`);

const schema = object({
  description: string(),
  geojson: string(),
  name: nonempty(string()),
});

type Props = {
  readonly children: ReactElement<ButtonProps>;
};

const PlaceCreateSheet = ({ children }: Props) => {
  const [open, setOpen] = useState(false);
  const organizationId = useAppStore((state) => state.activeMembership?.id);
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  if (!isAdmin) {
    throw new PermissionDeniedError();
  }

  const navigate = useNavigate();

  const shapeRef = useRef<google.maps.Circle | google.maps.Polygon | null>(
    null,
  );

  const [{ error, fetching }, createPlace] = useMutation(createPlaceQuery);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      description: '',
      geojson: '',
      name: '',
    },
    resolver: superstructResolver(schema),
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    if (!shapeRef.current) {
      form.setError('geojson', {
        message: 'Missing spatial data',
        type: 'custom',
      });
      return;
    }

    const shapeType = shapeRef.current instanceof google.maps.Circle;

    const geometry = geometryFromShape(
      shapeType ? 'circle' : 'polygon',
      shapeRef.current,
    );

    const response = await createPlace({
      description: values.description,
      geojson: {
        coordinates: geometry.coordinates,
        type: geometry.type,
      },
      name: values.name,
      organizationId,
      radius: geometry.radius,
    });

    if (!response.error) {
      navigate('/settings/places');
      setOpen(false);
    }
  };

  return (
    <Sheet
      onOpenChange={(value) => {
        if (!value) {
          form.reset();
        }

        setOpen(value);
      }}
      open={open}
    >
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="md:max-w-[650px]">
        <SheetHeader>
          <SheetTitle>Add Place</SheetTitle>
          <SheetDescription>
            Places allow your organization to geofence and name a particular
            area. These can be configured to identify and tag key locations for
            a Check In, Incident, or GuardMe.
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-3">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-right">Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-right">Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormItem>
                <FormLabel>Geofence</FormLabel>
                <MapProvider>
                  <PlaceCreateMap
                    className="h-[80vh] w-[80vw] md:h-[300px] md:w-[600px]"
                    onCircleComplete={(circle) => {
                      shapeRef.current?.setMap(null);
                      shapeRef.current = circle;
                    }}
                    onPolygonComplete={(polygon) => {
                      shapeRef.current?.setMap(null);
                      shapeRef.current = polygon;
                    }}
                  />
                </MapProvider>
              </FormItem>
            </div>

            <MutationError error={error} />

            <SheetFooter className="pt-6">
              <Button
                disabled={fetching}
                onClick={() => setOpen(false)}
                type="reset"
                variant="ghost"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Save Place
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};

export { PlaceCreateSheet };
