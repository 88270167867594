import {
  AnnotatedContent,
  AnnotatedHeader,
  AnnotatedLayout,
  AnnotatedSection,
  AnnotatedSeparator,
} from '@/components/annotated';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { Textarea } from '@/components/ui/textarea';
import { config } from '@/config';
import { graphql } from '@/gql';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

const AccountDebugGql = graphql(`
  query AccountDebugGql {
    currentUser {
      id
      mobileDevices {
        nodes {
          id
          deviceToken
          deviceModel
          deviceType
          deviceOsVersion
          operationalStatus
        }
      }
    }
  }
`);

const AccountDebug = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const [fetchingToken, setFetchingToken] = useState(false);

  const [{ data, fetching }] = useQuery({
    query: AccountDebugGql,
  });

  const handleRefresh = async () => {
    setFetchingToken(true);
    const authToken = await getAccessTokenSilently();
    setFetchingToken(false);
    setToken(authToken);
  };

  useEffect(() => {
    if (['development', 'local'].includes(config.STAGE)) {
      handleRefresh();
    }
  });

  if (!['development', 'local'].includes(config.STAGE)) {
    return null;
  }

  const tokenString = JSON.stringify(
    { authorization: `Bearer ${token}` },
    null,
    2,
  );

  return (
    <AnnotatedLayout>
      <AnnotatedSection aria-labelledby="account-token">
        <AnnotatedHeader
          description="View your access token."
          title="Account Token"
          titleId="account-token"
        >
          <Button
            className="mt-4"
            disabled={fetchingToken}
            onClick={handleRefresh}
            variant="outline"
          >
            {fetchingToken && <Loader className="animate-spin" />}
            Refresh Token
          </Button>
        </AnnotatedHeader>

        <AnnotatedContent>
          <Textarea
            className="min-h-72"
            onChange={() => {}}
            value={tokenString}
          />
        </AnnotatedContent>
      </AnnotatedSection>

      <AnnotatedSeparator />

      <AnnotatedSection aria-labelledby="mobile-devices">
        <AnnotatedHeader
          title="Mobile devices"
          titleId="mobile-devices"
        />

        <AnnotatedContent>
          {fetching ? (
            <Skeleton className="w-full h-32" />
          ) : (
            <ul className="space-y-2 divide-y">
              {data?.currentUser?.mobileDevices.nodes.map((item) => (
                <li
                  className="flex items-center"
                  key={item.id}
                >
                  <p className="ml-0">{item.deviceModel}</p>
                  <p className="ml-2">
                    {item.deviceType} {item.deviceOsVersion}
                  </p>

                  <p className="ml-auto">{item.operationalStatus}</p>
                </li>
              ))}
            </ul>
          )}
        </AnnotatedContent>
      </AnnotatedSection>
    </AnnotatedLayout>
  );
};

export { AccountDebug };
