import { NewInivtationDialog } from '../components/NewInivtationDialog';
import { SectionActiveMembers } from '../components/SectionActiveMembers';
import { SectionDisabledMembers } from '../components/SectionDisabledMembers';
import { SectionGroups } from '../components/SectionGroups';
import { SectionInvitations } from '../components/SectionInvitations';
import { SectionMembershipRequests } from '../components/SectionMembershipRequests';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Header, HeaderTitle } from '@/components/header';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

const MembersGql = graphql(`
  query MembersGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      memberMetadataFields
      groups {
        nodes {
          id
          name
          description
          members {
            nodes {
              id
            }
          }
        }
      }
      invitations {
        nodes {
          id
          key
          organizationRole
          groupIds
        }
      }
      membershipRequests {
        nodes {
          id
          createdAt
          fullName
          avatarUrl
          phoneNumber
          emailAddress
          membershipCode {
            id
            organizationRole
          }
        }
      }
      members {
        nodes {
          id
          createdAt
          fullName
          displayName
          avatarUrl
          organizationRole
          lastActiveAt
          isEnabled
          emailAddress
          phoneNumber
          updatedAt
        }
      }
    }
  }
`);

const TABS = [
  { label: 'Active', value: 'active-members' },
  { label: 'Disabled', value: 'disabled-members' },
  { label: 'Pending invitations', value: 'pending-invitations' },
  { label: 'Membership requests', value: 'membership-requests' },
  { label: 'Groups', value: 'groups' },
];

const Members = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [searchParameters, setSearchParameters] = useSearchParams();
  const view = searchParameters.get('view') ?? 'active-members';
  const tab = TABS.find((x) => x.value === view) || TABS[0];

  const [{ data, fetching }] = useQuery({
    query: MembersGql,
    variables: {
      organizationId,
    },
  });

  const activeMembers = useMemo(() => {
    return data?.organization?.members.nodes
      .filter((x) => x.isEnabled)
      .sort((a, b) =>
        (a.displayName || a.fullName).toUpperCase() >
        (b.displayName || b.fullName).toUpperCase()
          ? 1
          : -1,
      );
  }, [data?.organization?.members]);

  const disabledMembers = useMemo(() => {
    return data?.organization?.members.nodes
      .filter((x) => !x.isEnabled)
      .sort((a, b) =>
        (a.displayName || a.fullName).toUpperCase() >
        (b.displayName || b.fullName).toUpperCase()
          ? 1
          : -1,
      );
  }, [data?.organization?.members]);

  const invitations = useMemo(() => {
    return data?.organization?.invitations.nodes.sort((a, b) =>
      a.key.toUpperCase() > b.key.toUpperCase() ? 1 : -1,
    );
  }, [data?.organization?.invitations]);

  const membershipRequests = useMemo(() => {
    return data?.organization?.membershipRequests.nodes.sort((a, b) =>
      (a.fullName || '').toUpperCase() > (b.fullName || '').toUpperCase()
        ? 1
        : -1,
    );
  }, [data?.organization?.membershipRequests]);

  const groups = useMemo(() => {
    return data?.organization?.groups.nodes.sort((a, b) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
    );
  }, [data?.organization?.groups]);

  return (
    <>
      <Header
        actions={
          <NewInivtationDialog
            groups={groups}
            memberMetadataFields={data?.organization?.memberMetadataFields}
            organizationId={organizationId}
          />
        }
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Members</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{tab.label}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>Members</HeaderTitle>
      </Header>

      <Tabs
        onValueChange={(value) => {
          setSearchParameters(
            value === 'active-members' ? {} : { view: value },
          );
        }}
        value={tab.value}
      >
        <TabsList>
          {TABS.map((item) => (
            <TabsTrigger
              key={item.value}
              value={item.value}
            >
              {item.label}
            </TabsTrigger>
          ))}
        </TabsList>

        <TabsContent value="active-members">
          <SectionActiveMembers
            activeMembers={activeMembers}
            fetching={fetching}
          />
        </TabsContent>

        <TabsContent value="pending-invitations">
          <SectionInvitations
            fetching={fetching}
            groups={groups}
            invitations={invitations}
          />
        </TabsContent>

        <TabsContent value="membership-requests">
          <SectionMembershipRequests
            fetching={fetching}
            groups={groups}
            memberMetadataFields={data?.organization?.memberMetadataFields}
            membershipRequests={membershipRequests}
          />
        </TabsContent>

        <TabsContent value="disabled-members">
          <SectionDisabledMembers
            disabledMembers={disabledMembers}
            fetching={fetching}
          />
        </TabsContent>

        <TabsContent value="groups">
          <SectionGroups
            activeMembers={activeMembers}
            allMembers={data?.organization?.members.nodes}
            fetching={fetching}
            groups={groups}
            organizationId={organizationId}
          />
        </TabsContent>
      </Tabs>
    </>
  );
};

export { Members };
