import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { SidebarMenuButton, useSidebar } from '@/components/ui/sidebar';
import { cn } from '@/lib/utils';
import { useTheme } from '@/providers/ThemeProvider';
import {
  BadgeCheck,
  Bell,
  Building2,
  Computer,
  FileText,
  LogOut,
  Moon,
  MoreVertical,
  Sun,
} from 'lucide-react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';

type NavUserProps = {
  readonly user: {
    avatarUrl: string;
    emailAddress: string;
    fullName: string;
    id: string;
    phoneNumber: string;
    timezone: string;
  };
};

const NavUser = ({ user }: NavUserProps) => {
  const { setTheme, theme } = useTheme();
  const { open } = useSidebar();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton
          className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground rounded p-1"
          size="lg"
        >
          <Avatar className={cn(open ? 'size-6' : 'size-8', 'rounded-full')}>
            <AvatarImage
              alt={user.fullName}
              src={user.avatarUrl}
            />
            <AvatarFallback>{user.fullName.slice(0, 2)}</AvatarFallback>
          </Avatar>

          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate font-semibold">{user.fullName}</span>
          </div>
          <MoreVertical className="ml-auto" />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className={cn(
          open ? 'w-[--radix-dropdown-menu-trigger-width]' : 'min-w-56',
          'rounded',
        )}
      >
        <DropdownMenuLabel className="space-y-0.5 font-normal">
          {user.emailAddress && (
            <div className="truncate text-xs text-muted-foreground">
              {user.emailAddress}
            </div>
          )}
          {user.phoneNumber && (
            <div className="truncate text-xs text-muted-foreground">
              {formatPhoneNumber(user.phoneNumber)}
            </div>
          )}
        </DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Theme</DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuCheckboxItem
                aria-label="Switch to Light Mode"
                checked={theme === 'light'}
                className="gap-2 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0"
                onCheckedChange={() => {
                  setTheme('light');
                }}
              >
                <Sun aria-hidden="true" />
                Light
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem
                aria-label="Switch to Dark Mode"
                checked={theme === 'dark'}
                className="gap-2 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0"
                onCheckedChange={() => {
                  setTheme('dark');
                }}
              >
                <Moon aria-hidden="true" />
                Dark
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem
                aria-label="Switch to System Mode"
                checked={theme === 'system'}
                className="gap-2 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0"
                onCheckedChange={() => {
                  setTheme('system');
                }}
              >
                <Computer aria-hidden="true" />
                System
              </DropdownMenuCheckboxItem>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <Link to="/account/profile">
              <BadgeCheck />
              <span>Account</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/account/memberships">
              <Building2 />
              <span>Memberships</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/account/notifications">
              <Bell />
              <span>Notifications</span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <a
              href="https://seeitsendit.com/privacy-policy"
              rel="noreferrer noopener"
              target="_blank"
            >
              <FileText />
              <span> Privacy Policy</span>
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <a
              href="https://seeitsendit.com/terms-of-use"
              rel="noreferrer noopener"
              target="_blank"
            >
              <FileText />
              <span>Terms of Service</span>
            </a>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to="/auth/signout">
            <LogOut />
            Sign out
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { NavUser, type NavUserProps };
