import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { config } from '@/config';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useMutation } from 'urql';

const CloseAccountFormGql = graphql(`
  mutation currentUserCloseAccount($userId: String!) {
    currentUserCloseAccount(input: { clientMutationId: $userId }) {
      user {
        id
      }
    }
  }
`);

const CloseAccountDialog = () => {
  const [open, setOpen] = useState(false);
  const userId = useAppStore((state) => state.currentUser.id);
  const { logout } = useAuth0();

  const [{ error, fetching }, closeAccount] = useMutation(CloseAccountFormGql);

  const onClick = async () => {
    const response = await closeAccount({
      userId,
    });

    if (!response.error) {
      logout({
        logoutParams: {
          returnTo: config.WEB_URL,
        },
      });
    }
  };

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      <AlertDialogTrigger asChild>
        <Button
          className="text-destructive-foreground hover:bg-destructive hover:text-destructive-foreground"
          variant="outline"
        >
          Close Account
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your
            account and remove your data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <MutationError error={error} />
        <AlertDialogFooter>
          <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={onClick}
            variant="destructive"
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { CloseAccountDialog };
