import { Badge } from '@/components/ui/badge';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from '@/components/ui/sidebar';
import { graphql } from '@/gql';
import { GuardmeStatus } from '@/gql/graphql';
import { type Membership } from '@/stores/types';
import {
  ChevronRight,
  Flag,
  type LucideIcon,
  Mail,
  Map,
  MapPinPlus,
  PieChart,
  Pin,
  Plus,
  Settings,
  Settings2,
  Shapes,
  ShieldCheck,
  Users,
} from 'lucide-react';
import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

type Nav = Array<{
  action?: {
    icon: LucideIcon;
    title: string;
    url: string;
  };
  icon: LucideIcon;
  items?: Array<{
    icon?: LucideIcon;
    matchExact?: boolean;
    name: string;
    search?: string;
    url: string;
    value?: number;
  }>;
  name: string;
  url?: string;
}>;

const NavOrganizationGql = graphql(`
  query NavOrganizationGql($organizationId: String!) {
    activeGuardmes(organizationId: $organizationId) {
      nodes {
        id
        status
      }
    }
  }
`);

const NavOrganization = ({
  activeOrganization,
}: {
  readonly activeOrganization: Membership;
}) => {
  const { pathname, search } = useLocation();
  const { open } = useSidebar();

  const [{ data }] = useQuery({
    query: NavOrganizationGql,
    variables: { organizationId: activeOrganization.id },
  });

  const navItems = useMemo(() => {
    const inProgressCount =
      data?.activeGuardmes?.nodes.filter(
        (x) => x.status === GuardmeStatus.InProgress,
      ).length || 0;
    const inPanicCount =
      data?.activeGuardmes?.nodes.filter(
        (x) => x.status === GuardmeStatus.InPanic,
      ).length || 0;
    const expiredCount =
      data?.activeGuardmes?.nodes.filter(
        (x) => x.status === GuardmeStatus.Expired,
      ).length || 0;

    const nav: Nav = [
      {
        icon: PieChart,
        name: 'Dashboard',
        url: '/dashboard',
      },
      {
        icon: ShieldCheck,
        items: activeOrganization.isGuardMeSupervisor
          ? [
              {
                matchExact: true,
                name: 'In Progress',
                search: '?status=in_progress',
                url: '/guardme',
                value: inProgressCount,
              },
              {
                matchExact: true,
                name: 'Expired',
                search: '?status=expired',
                url: '/guardme',
                value: expiredCount,
              },
              {
                matchExact: true,
                name: 'In Panic',
                search: '?status=in_panic',
                url: '/guardme',
                value: inPanicCount,
              },
            ]
          : undefined,
        name: 'GuardMe',
        url: '/guardme',
      },
      {
        action: {
          icon: Plus,
          title: 'Add incident',
          url: '/incidents/new',
        },
        icon: Flag,
        name: 'Incidents',
        url: '/incidents',
      },
      {
        icon: Pin,
        name: 'Check In',
        url: '/check-in',
      },
    ];

    if (activeOrganization.isCheckInSupervisor) {
      nav.push({
        action: {
          icon: Plus,
          title: 'Add check in report',
          url: '/check-in-reports?new',
        },
        icon: Map,
        name: 'Check In Reports',
        url: '/check-in-reports',
      });
    }

    nav.push({
      icon: Users,
      name: 'Members',
      url: '/members',
    });

    if (activeOrganization.isAdmin) {
      nav.push({
        icon: Settings,
        items: [
          {
            icon: Settings2,
            name: 'General',
            url: '/settings/general',
          },
          {
            icon: Flag,
            name: 'Incident Types',
            url: '/settings/incident-types',
          },
          {
            icon: Mail,
            name: 'Notifications',
            url: '/settings/notifications',
          },
          {
            icon: MapPinPlus,
            name: 'Places',
            url: '/settings/places',
          },
          {
            icon: Shapes,
            name: 'Integrations',
            url: '/settings/integrations',
          },
        ],
        name: 'Settings',
      });
    }

    return nav;
  }, [activeOrganization, data]);

  return (
    <SidebarGroup>
      <SidebarGroupLabel>{activeOrganization.name}</SidebarGroupLabel>
      <SidebarMenu>
        {navItems.map((item) => (
          <Fragment key={item.name}>
            {item.url ? (
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={pathname === item.url}
                  tooltip={item.name}
                >
                  <Link to={item.url}>
                    <item.icon />
                    <span>{item.name}</span>
                  </Link>
                </SidebarMenuButton>
                {item.action ? (
                  <SidebarMenuAction asChild>
                    <Link to={item.action.url}>
                      <item.action.icon />
                      <span className="sr-only">{item.action.title}</span>
                    </Link>
                  </SidebarMenuAction>
                ) : null}
                {item.items?.length ? (
                  <SidebarMenuSub>
                    {item.items.map((subitem) => (
                      <SidebarMenuSubItem key={subitem.name}>
                        <SidebarMenuSubButton
                          asChild
                          isActive={
                            subitem.matchExact
                              ? pathname + search ===
                                subitem.url + subitem.search
                              : pathname === subitem.url
                          }
                        >
                          <Link
                            to={
                              subitem.search
                                ? subitem.url + subitem.search
                                : subitem.url
                            }
                          >
                            <span>{subitem.name}</span>
                            {subitem.value ? (
                              <Badge
                                className="ml-auto"
                                variant="outline"
                              >
                                {subitem.value?.toString()}
                              </Badge>
                            ) : null}
                          </Link>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                ) : null}
              </SidebarMenuItem>
            ) : open ? (
              <Collapsible
                className="group/collapsible"
                defaultOpen={pathname.startsWith('/settings')}
              >
                <SidebarMenuItem>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuButton>
                      <item.icon />
                      <span>{item.name}</span>
                      <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map((subitem) => (
                        <SidebarMenuSubItem key={subitem.name}>
                          <SidebarMenuSubButton
                            asChild
                            isActive={
                              subitem.matchExact
                                ? pathname + search ===
                                  subitem.url + subitem.search
                                : pathname === subitem.url
                            }
                          >
                            <Link
                              to={
                                subitem.search
                                  ? subitem.url + subitem.search
                                  : subitem.url
                              }
                            >
                              {subitem.icon && <subitem.icon />}
                              <span>{subitem.name}</span>
                              {subitem.value ? (
                                <Badge
                                  className="ml-auto"
                                  variant="outline"
                                >
                                  {subitem.value?.toString()}
                                </Badge>
                              ) : null}
                            </Link>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </SidebarMenuItem>
              </Collapsible>
            ) : (
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={pathname === item.url}
                  tooltip={item.name}
                >
                  {item.items && (
                    <Link to={item.items[0].url}>
                      <item.icon />
                      <span>{item.name}</span>
                    </Link>
                  )}
                </SidebarMenuButton>
              </SidebarMenuItem>
            )}
          </Fragment>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
};

export { NavOrganization };
