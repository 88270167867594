import {
  ControlPosition,
  Map,
  MapBoundsHandler,
  MapControl,
  useDrawingManager,
} from '@/components/Map';
import { PlaceAutocomplete } from '@/components/Map/PlaceAutocomplete';
import { PermissionDeniedError } from '@/lib/error';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { useEffect, useState } from 'react';

type Props = {
  readonly className?: string;
  readonly onCircleComplete: (circle: google.maps.Circle) => void;
  readonly onPolygonComplete: (polygon: google.maps.Polygon) => void;
};

const PlaceCreateMap = ({
  className,
  onCircleComplete,
  onPolygonComplete,
}: Props) => {
  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership?.isAdmin) {
    throw new PermissionDeniedError();
  }

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const drawingManager = useDrawingManager();

  useEffect(() => {
    if (drawingManager) {
      drawingManager.setOptions({
        drawingControlOptions: {
          drawingModes: [
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
          ],
          position: ControlPosition.TOP_RIGHT,
        },
      });
      // clear old listeners
      google.maps.event.clearListeners(drawingManager, 'circlecomplete');
      google.maps.event.clearListeners(drawingManager, 'polygoncomplete');

      // add new listeners
      drawingManager.addListener('circlecomplete', onCircleComplete);
      drawingManager.addListener('polygoncomplete', onPolygonComplete);
    }

    return () => {
      if (drawingManager) {
        google.maps.event.clearListeners(drawingManager, 'circlecomplete');
        google.maps.event.clearListeners(drawingManager, 'polygoncomplete');
      }
    };
  }, [drawingManager, onCircleComplete, onPolygonComplete]);

  const handleOnPlaceSelect = (
    newValue: google.maps.places.PlaceResult | null,
  ) => {
    setSelectedPlace(newValue);
  };

  return (
    <>
      <Map
        className={cn('h-96', className)}
        defaultCenter={{
          lat: activeMembership.defaultMapCenter.latitude,
          lng: activeMembership.defaultMapCenter.longitude,
        }}
        defaultZoom={activeMembership.defaultMapZoom}
        gestureHandling="greedy"
      />

      <MapControl position={ControlPosition.TOP_LEFT}>
        <div className="autocomplete-control p-2">
          <PlaceAutocomplete onPlaceSelect={handleOnPlaceSelect} />
        </div>
      </MapControl>

      <MapBoundsHandler bounds={selectedPlace?.geometry?.viewport} />
    </>
  );
};

export { PlaceCreateMap };
