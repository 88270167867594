import { IncidentPriority } from '../components/IncidentPriority';
import { IncidentStatus } from '../components/IncidentStatus';
import { useIncidentsSearchParameters } from '../hooks/useIncidentsSearchParameters';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { Datetime } from '@/components/Datetime';
import { Header, HeaderTitle } from '@/components/header';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent } from '@/components/ui/tooltip';
import { type DocumentType, graphql } from '@/gql';
import {
  IncidentPriorityType,
  IncidentsOrderBy,
  IncidentStatusType,
} from '@/gql/graphql';
import { sorToOrderByParser } from '@/lib/data-table';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Plus } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentsGql = graphql(`
  query IncidentsGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $search: String
    $createdAt: IncidentsCreatedAtConditionInput
    $contactMemberId: String
    $status: IncidentsStatusConditionInput
    $priority: IncidentsPriorityConditionInput
    $assignedToMeOrMyGroups: Boolean
    $types: [String!]
    $orderBy: [IncidentsOrderBy!] = ID_DESC
  ) {
    incidents(
      first: $first
      offset: $offset
      condition: {
        contactMemberId: $contactMemberId
        organizationId: $organizationId
        createdAt: $createdAt
        search: $search
        status: $status
        priority: $priority
        assignedToMeOrMyGroups: $assignedToMeOrMyGroups
        types: $types
      }
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        shortId
        subject
        description
        status
        priority
        createdAt
        updatedAt
        contactMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByGroup {
          id
          name
        }
        incidentType {
          id
          name
          iconSvg
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`);

type Column = NonNullable<
  DocumentType<typeof IncidentsGql>['incidents']
>['nodes'][0];

const columnHelper = createColumnHelper<Column>();

const COLUMNS = [
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <Link
        className="capitalize hover:text-primary"
        to={`/incidents/${getValue()}`}
      >
        {row.original.shortId}
      </Link>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="ID"
      />
    ),
  }),
  columnHelper.accessor('incidentType', {
    cell: ({ getValue }) => (
      <Badge variant="secondary">
        <img
          alt={getValue()?.name}
          className="h-4 w-4 mr-1"
          src={`data:image/svg+xml;base64,${getValue()?.iconSvg}`}
        />
        {getValue()?.name}
      </Badge>
    ),
    header: () => 'Type',
  }),
  columnHelper.accessor('subject', {
    cell: ({ getValue }) => (
      <div className="max-w-[45ch]">
        {getValue().length > 45 ? (
          <HoverCard>
            <HoverCardTrigger className="max-w-[45ch]">
              <span className="line-clamp-1">{getValue()}</span>
            </HoverCardTrigger>
            <HoverCardContent className="w-80">{getValue()}</HoverCardContent>
          </HoverCard>
        ) : (
          getValue()
        )}
      </div>
    ),
    header: () => 'Subject',
  }),
  columnHelper.accessor('contactMember', {
    cell: ({ getValue }) => {
      const contact = getValue();
      return (
        <div className="flex justify-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Avatar className="size-6">
                  <AvatarImage src={contact?.avatarUrl ?? undefined} />
                  <AvatarFallback>
                    {contact?.fullName.slice(0, 2)}
                  </AvatarFallback>
                </Avatar>
              </TooltipTrigger>
              <TooltipContent side="left">
                <p>{contact?.displayName || contact?.fullName}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    },
    header: () => 'Contact',
  }),
  columnHelper.accessor('priority', {
    cell: ({ getValue }) => <IncidentPriority priority={getValue()} />,
    header: () => 'Priority',
  }),
  columnHelper.accessor('status', {
    cell: ({ getValue }) => <IncidentStatus status={getValue()} />,
    header: () => 'Status',
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Created',
  }),
];

const filterFields: Array<DataTableFacetedFilterField<Column>> = [
  {
    label: 'Status',
    options: [
      { label: 'Open', value: IncidentStatusType.Open },
      { label: 'In Progress', value: IncidentStatusType.InProgress },
      { label: 'Closed', value: IncidentStatusType.Closed },
    ],
    type: 'checkbox',
    value: 'status',
  },
  {
    label: 'Priority',
    options: [
      { label: 'Urgent', value: IncidentPriorityType.Urgent },
      { label: 'High', value: IncidentPriorityType.High },
      { label: 'Medium', value: IncidentPriorityType.Medium },
      { label: 'Low', value: IncidentPriorityType.Low },
    ],
    type: 'checkbox',
    value: 'priority',
  },
  {
    label: 'Date',
    type: 'timerange',
    value: 'createdAt',
  },
];

const sorToOrderBy = sorToOrderByParser({
  id_asc: IncidentsOrderBy.IdAsc,
  id_desc: IncidentsOrderBy.IdDesc,
});

const Incidents = () => {
  const [organizationId, memberId] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const [queryParameters, setQueryParameters] = useIncidentsSearchParameters();

  const [{ data, fetching }, refresh] = useQuery({
    query: IncidentsGql,
    variables: {
      assignedToMeOrMyGroups: queryParameters.tab === 'group',
      contactMemberId: queryParameters.tab === 'contact' ? memberId : undefined,
      createdAt: {
        gte: queryParameters.createdAt?.at(0)?.toISOString() || null,
        lte: queryParameters.createdAt?.at(1)?.toISOString() || null,
      },
      first: queryParameters.pageSize,
      offset: queryParameters.pageIndex * queryParameters.pageSize,
      orderBy: sorToOrderBy(queryParameters.sort),
      organizationId,
      priority: queryParameters.priority
        ? {
            high: queryParameters.priority.includes(IncidentPriorityType.High),
            low: queryParameters.priority.includes(IncidentPriorityType.Low),
            medium: queryParameters.priority.includes(
              IncidentPriorityType.Medium,
            ),
            urgent: queryParameters.priority.includes(
              IncidentPriorityType.Urgent,
            ),
          }
        : null,
      search: queryParameters.search || null,
      status: queryParameters.status
        ? {
            closed: queryParameters.status.includes(IncidentStatusType.Closed),
            inProgress: queryParameters.status.includes(
              IncidentStatusType.InProgress,
            ),
            open: queryParameters.status.includes(IncidentStatusType.Open),
          }
        : null,
    },
  });

  return (
    <>
      <Header
        actions={
          <Link to="/incidents/new">
            <Button size="sm">
              <Plus /> Submit New Incident
            </Button>
          </Link>
        }
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Incidents</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>Incidents</HeaderTitle>
      </Header>

      <Tabs
        defaultValue="all"
        onValueChange={(value) => {
          setQueryParameters({ tab: value as 'all' | 'contact' | 'group' });
        }}
        value={queryParameters.tab}
      >
        <TabsList className="mb-4">
          <TabsTrigger value="all">All</TabsTrigger>
          <TabsTrigger value="contact">My Incidents</TabsTrigger>
          <TabsTrigger value="group">My Group Incidents</TabsTrigger>
        </TabsList>
      </Tabs>

      <DataTable
        columns={COLUMNS as Array<ColumnDef<Column>>}
        data={data?.incidents?.nodes}
        fetching={fetching}
        filterFields={filterFields}
        queryParameters={queryParameters}
        refresh={refresh}
        rowCount={data?.incidents?.totalCount}
        searchPlaceholder="Search by Id, Subject, Description, or IncidentType..."
        setQueryParameters={setQueryParameters}
      />
    </>
  );
};

export { Incidents };
