import { CheckInNotificationsForm } from '../components/CheckInNotificationsForm';
import { GuardMeNotificationsForm } from '../components/GuardMeNotificationsForm';
import { IncidentNotificationsForm } from '../components/IncidentNotificationsForm';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const NotificationsSettingsGql = graphql(`
  query NotificationsSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      memberMetadataFields
      enabledIncidentTypes {
        totalCount
      }
      groups {
        nodes {
          id
          name
        }
      }
      settings {
        id
        isOwnerNotifiedOnCheckIn
        isAdministratorNotifiedOnCheckIn
        isSupervisorNotifiedOnCheckIn
        checkInSupervisorGroupId
        checkInSupervisorGroup {
          id
          name
        }
        isOwnerNotifiedOnGuardme
        isAdministratorNotifiedOnGuardme
        isSupervisorNotifiedOnGuardme
        guardmeIsStartedNotificationEnabled
        guardmeIsConcludedNotificationEnabled
        guardmeIsExpiredNotificationEnabled
        guardmeIsPanicNotificationEnabled
        guardmeIsMetadataIncludedInNotification
        guardmeSupervisorGroupId
        guardmeSupervisorGroup {
          id
          name
        }
        isOwnerNotifiedOnIncident
        isAdministratorNotifiedOnIncident
        isSupervisorNotifiedOnIncident
        incidentSupervisorGroupId
        incidentSupervisorGroup {
          id
          name
        }
      }
    }
  }
`);

const NotificationsSettings = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, fetching }] = useQuery({
    query: NotificationsSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <div className="space-y-10">
      <section aria-labelledby="personal-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="settings-general"
            >
              Check In Notifications
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Configure who view and create Check In reports.
            </p>
          </div>
          <div className="md:col-span-2">
            {fetching || !data?.organization ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/3 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                </div>
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/3 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-full h-10" />
                </div>
                <div className="col-span-full mt-6 flex justify-end items-center">
                  <Skeleton className="w-32 h-10" />
                </div>
              </div>
            ) : (
              <CheckInNotificationsForm
                groups={data.organization.groups.nodes}
                organizationId={organizationId}
                settings={data.organization.settings ?? undefined}
              />
            )}
          </div>
        </div>
      </section>

      <Separator orientation="horizontal" />

      <section aria-labelledby="personal-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="settings-general"
            >
              GuardMe Notifications
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Configure who will monitor GuardMe sessions, which events they
              should receive notifications for, whether to include custom
              metadata fields in event notifications, and configuring your
              RapidSOS integration.
            </p>
          </div>
          <div className="md:col-span-2">
            {fetching || !data?.organization ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/3 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                </div>
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/3 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                </div>
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/3 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                </div>
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/3 h-5" />
                  <Skeleton className="w-1/2 h-5" />
                  <Skeleton className="w-full h-10" />
                </div>
                <div className="col-span-full mt-6 flex justify-end items-center">
                  <Skeleton className="w-32 h-10" />
                </div>
              </div>
            ) : (
              <GuardMeNotificationsForm
                groups={data.organization.groups.nodes}
                organizationId={organizationId}
                settings={data.organization.settings ?? undefined}
              />
            )}
          </div>
        </div>
      </section>

      <Separator orientation="horizontal" />

      <section aria-labelledby="personal-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="settings-general"
            >
              Incident Notifications
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Configure who will manage Incidents, and choose which incidents
              will be enabled/disabled for your members to report.
            </p>
          </div>
          <div className="md:col-span-2">
            {fetching || !data?.organization ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="col-span-full sm:col-span-3 space-y-2">
                  <Skeleton className="w-full h-5" />
                  <Skeleton className="w-full h-10" />
                </div>
                <div className="col-span-full sm:col-span-3 space-y-2">
                  <Skeleton className="w-full h-5" />
                  <Skeleton className="w-full h-10" />
                </div>

                <div className="col-span-full space-y-2">
                  <Skeleton className="w-full h-5" />
                  <div className="flex gap-4">
                    <Skeleton className="w-full h-64" />
                    <Skeleton className="w-full h-64" />
                  </div>
                </div>

                <div className="col-span-full mt-6 flex justify-end items-center">
                  <Skeleton className="w-32 h-10" />
                </div>
              </div>
            ) : (
              <>
                {data?.organization?.enabledIncidentTypes.totalCount === 0 && (
                  <Alert
                    className="mb-2"
                    variant="info"
                  >
                    <Info className="size-4" />
                    <AlertTitle>No Incidents Enabled</AlertTitle>
                    <AlertDescription>
                      There are no incident types enabled for this organization.
                      To allow members to create incidents please enable at
                      least one incident type.
                      <Link
                        className="text-right block"
                        to="/settings/incident-types"
                      >
                        <Button variant="outline">Manage Incident Types</Button>
                      </Link>
                    </AlertDescription>
                  </Alert>
                )}
                <IncidentNotificationsForm
                  groups={data.organization.groups.nodes}
                  organizationId={organizationId}
                  settings={data.organization.settings ?? undefined}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export { NotificationsSettings };
