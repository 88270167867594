import { AcceptInvitation } from './AcceptInvitation';
import { CancelMembershipReqeust } from './membershipRequest/CancelMembershipReqeust';
import { Datetime } from '@/components/Datetime';
import { EmptyState } from '@/components/empty-state';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';
import { type MemberRole } from '@/gql/graphql';
import { formatMemberRole } from '@/lib/formatGql';
import { Building2 } from 'lucide-react';

type MembershipListProps = {
  readonly fetching?: boolean;
  readonly invitations?: Array<{
    id: string;
    key: string;
    organization?:
      | {
          __typename?: 'Organization' | undefined;
          id: string;
          logoUrl: string;
        }
      | null
      | undefined;
    organizationName?: string | null | undefined;
    organizationRole: MemberRole;
  }> | null;
  readonly membershipRequests?: Array<{
    createdAt: string;
    id: string;
    organizationId: string;
    organizationName?: string | null | undefined;
  }> | null;
  readonly memberships?: Array<{
    createdAt: string;
    id: string;
    organization?:
      | {
          id: string;
          logoUrl: string;
          name: string;
        }
      | null
      | undefined;
    organizationRole: MemberRole;
  }> | null;
};

const MembershipList = ({
  fetching,
  invitations,
  membershipRequests,
  memberships,
}: MembershipListProps) => {
  const isEmpty =
    !fetching &&
    invitations?.length === 0 &&
    membershipRequests?.length === 0 &&
    memberships?.length === 0;

  if (fetching) {
    return (
      <ul className="divide-y text-sm">
        <li className="py-1 flex items-center">
          <Skeleton className="w-full h-12" />
        </li>
        <li className="py-1 flex items-center">
          <Skeleton className="w-full h-12" />
        </li>
        <li className="py-1 flex items-center">
          <Skeleton className="w-full h-12" />
        </li>
        <li className="py-1 flex items-center">
          <Skeleton className="w-full h-12" />
        </li>
        <li className="py-1 flex items-center">
          <Skeleton className="w-full h-12" />
        </li>
      </ul>
    );
  }

  if (isEmpty) {
    return (
      <EmptyState
        description="Get started by joinging an existing organization or start your own!"
        icon={Building2}
        title="No Memberships Found"
      />
    );
  }

  return (
    <ul className="divide-y text-sm">
      {invitations?.map((item) => (
        <li
          className="py-2 flex items-center"
          key={item.id}
        >
          <div className="flex gap-2 items-center justify-start">
            <Avatar className="size-6">
              <AvatarImage
                alt={item.organizationName ?? 'Unknown Organization'}
                src={item.organization?.logoUrl}
              />
              <AvatarFallback>
                {item.organizationName?.slice(0, 1) ?? 'O'}
              </AvatarFallback>
            </Avatar>
            <div className="ml-2 space-y-1">
              <div>{item.organizationName ?? 'Unknown Organization'}</div>
              <div>{formatMemberRole(item.organizationRole)}</div>
            </div>
          </div>
          <div className="ml-auto">
            <AcceptInvitation
              invitationId={item.id}
              organizationName={item.organizationName}
            />
          </div>
        </li>
      ))}

      {membershipRequests?.map((item) => (
        <li
          className="py-2 flex items-center"
          key={item.id}
        >
          <div className="flex gap-2 items-center justify-start">
            <Avatar className="size-6">
              <AvatarFallback>
                {item.organizationName?.slice(0, 1) ?? 'O'}
              </AvatarFallback>
            </Avatar>
            <div className="ml-2 space-y-1">
              <div className="font-semibold">
                {item.organizationName ?? 'Unknown Organization'}
              </div>
              <div className="text-muted-foreground">
                Created on{' '}
                <Datetime
                  datetime={item.createdAt}
                  format="MMM dd, yyyy"
                />
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <CancelMembershipReqeust
              membershipRequestId={item.id}
              organizationName={item.organizationName}
            />
          </div>
        </li>
      ))}

      {memberships?.map((item) => (
        <li
          className="py-2 flex items-center"
          key={item.id}
        >
          <div className="flex gap-2 items-center justify-start">
            <Avatar className="size-6">
              <AvatarImage
                alt={item.organization?.name ?? 'Unknown Organization'}
                src={item.organization?.logoUrl}
              />
              <AvatarFallback>
                {item.organization?.name.slice(0, 1) ?? 'O'}
              </AvatarFallback>
            </Avatar>
            <div className="ml-2">
              <div className="font-semibold">
                {item.organization?.name ?? 'Unknown Organization'}
              </div>
              <div className="text-muted-foreground">
                Joined on{' '}
                <Datetime
                  datetime={item.createdAt}
                  format="MMM dd, yyyy"
                />
              </div>
            </div>
          </div>
          <div className="ml-auto text-right text-lg">
            <Badge variant="secondary">
              {formatMemberRole(item.organizationRole)}
            </Badge>
          </div>
        </li>
      ))}
    </ul>
  );
};

export { MembershipList };
