import { Datetime } from '@/components/Datetime';
import { Empty } from '@/components/Empty';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { ZoomableImage } from '@/components/ZoomableImage';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { LivestreamStatus } from '@/gql/graphql';
import { Video } from 'lucide-react';
import { useMemo } from 'react';
import ReactHlsPlayer from 'react-hls-video-player';
import { useQuery } from 'urql';

const GuardmeLivestreamGql = graphql(`
  query GuardmeLivestreamGql($id: String!) {
    livestream(id: $id) {
      id
      status
      streamingUrl
      url
      createdAt
    }
  }
`);

const LivestreamAttachment = ({
  hideEmpty,
  id,
}: {
  readonly hideEmpty?: boolean;
  readonly id: string;
}) => {
  const [{ data }] = useQuery({
    query: GuardmeLivestreamGql,
    variables: {
      id,
    },
  });

  if (!data?.livestream) {
    return hideEmpty ? null : 'No Livestream data found';
  }

  if (
    hideEmpty &&
    data.livestream.status === LivestreamStatus.Ended &&
    !data.livestream.url &&
    !data.livestream.streamingUrl
  ) {
    return null;
  }

  return (
    <div className="px-6 py-3">
      <p>
        Livestream added at <Datetime datetime={data.livestream.createdAt} />
      </p>

      <div className="mx-auto">
        <div className="h-full w-full">
          {data.livestream.status === LivestreamStatus.Ended ? (
            data.livestream.url ? (
              <video
                className="h-full w-full max-h-48 rounded-lg"
                controls
              >
                <track kind="captions" />
                <source
                  src={data.livestream.url}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : data.livestream.streamingUrl ? (
              <ReactHlsPlayer
                autoPlay={false}
                className="h-full w-full max-h-48 rounded-lg"
                controls
                height="auto"
                hlsConfig={{
                  maxLiveSyncPlaybackRate: 1.2,
                  startPosition: -1,
                }}
                src={data.livestream.streamingUrl}
                width="100%"
              />
            ) : (
              <div className="flex items-center rounded-xl border px-3 w-full bg-muted/30">
                <Video className="h-10 w-10" />
                <span className="ml-4 italic">
                  No video provided from livestream
                </span>
              </div>
            )
          ) : data.livestream.streamingUrl ? (
            <ReactHlsPlayer
              autoPlay={false}
              className="h-full w-full max-h-48 rounded-lg"
              controls
              height="auto"
              hlsConfig={{
                maxLiveSyncPlaybackRate: 1.2,
                startPosition: -1,
              }}
              src={data.livestream.streamingUrl}
              width="100%"
            />
          ) : (
            <div className="flex items-center rounded-xl border px-3 w-full bg-muted/30">
              <Video className="h-10 w-10" />
              <span className="animate-pulse ml-4 italic">
                Waiting for stream to start...
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const GuardmeAttachmenGql = graphql(`
  query GuardmeAttachmenGql($id: String!) {
    guardmeAttachment(id: $id) {
      id
      comment
      createdAt
      attachments {
        nodes {
          id
          url
          mimetype
        }
      }
    }
  }
`);

const GuardMeAttachment = ({ id }: { readonly id: string }) => {
  const [{ data }] = useQuery({
    query: GuardmeAttachmenGql,
    variables: {
      id,
    },
  });

  if (!data?.guardmeAttachment) {
    return null;
  }

  return (
    <div className="px-6 py-3 last:pb-6 first:pt-6">
      <p className="mb-1 text-sm">
        {data.guardmeAttachment.attachments.nodes.length > 1
          ? 'Attachments '
          : 'Attachment '}
        added at <Datetime datetime={data.guardmeAttachment.createdAt} />
      </p>
      {data.guardmeAttachment.comment && (
        <p className="mb-2">{data.guardmeAttachment.comment}</p>
      )}
      <div className="grid grid-cols-4 gap-2 mx-auto">
        {data.guardmeAttachment.attachments.nodes
          .filter((item) => item.url)
          .map((attachment) => {
            return (
              <div key={attachment.id}>
                {attachment.mimetype?.includes('video') && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video>
                    <source
                      src={attachment.url || undefined}
                      type={attachment.mimetype}
                    />
                  </video>
                )}
                {attachment.mimetype?.includes('image') && (
                  <ZoomableImage src={attachment.url || undefined} />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const GuardMeAttachmentsGql = graphql(`
  query GuardMeAttachmentsGql($id: String!) {
    guardme(id: $id) {
      id
      guardmeAttachments {
        nodes {
          id
          createdAt
          attachments {
            totalCount
          }
        }
      }
      livestreams {
        nodes {
          id
          createdAt
        }
      }
    }
  }
`);

type SortedAttachments = Array<{
  createdAt: Date;
  id: string;
  type: 'attachment' | 'livestream';
}>;

type GuardMeAttachmentsProps = {
  readonly className?: string;
  readonly fetching: boolean;
  readonly guardmeId: string;
};

const GuardMeAttachments = ({
  className,
  fetching,
  guardmeId,
}: GuardMeAttachmentsProps) => {
  const [{ data, error, fetching: dataFetching }] = useQuery({
    query: GuardMeAttachmentsGql,
    variables: {
      id: guardmeId,
      replay: false,
    },
  });

  const items: SortedAttachments = useMemo(() => {
    if (error) {
      return [];
    }

    const result: SortedAttachments = [];

    // push item only if (attachment has nodes)
    if (data?.guardme?.guardmeAttachments.nodes) {
      for (const node of data.guardme.guardmeAttachments.nodes) {
        if (node.attachments.totalCount > 0) {
          result.push({
            createdAt: new Date(node.createdAt),
            id: node.id,
            type: 'attachment',
          });
        }
      }
    }

    if (data?.guardme?.livestreams.nodes) {
      for (const node of data.guardme.livestreams.nodes) {
        result.push({
          createdAt: new Date(node.createdAt),
          id: node.id,
          type: 'livestream',
        });
      }
    }

    return result.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
  }, [data?.guardme?.guardmeAttachments, data?.guardme?.livestreams, error]);

  if (error) {
    return (
      <Card className={className}>
        <CardHeader>
          <CardTitle>Attachments</CardTitle>
        </CardHeader>
        <CardContent>
          <MutationError error={error} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>Attachments</CardTitle>
      </CardHeader>
      <CardContent className="p-0 divide-y border-t">
        {fetching || dataFetching ? (
          <Skeleton className="h-32 w-full" />
        ) : (
          <>
            {items.length === 0 && (
              <Empty className="text-muted-foreground">No Attachments</Empty>
            )}
            {items.map((item) => {
              if (item.type === 'attachment') {
                return (
                  <GuardMeAttachment
                    id={item.id}
                    key={item.id}
                  />
                );
              }

              if (item.type === 'livestream') {
                return (
                  <LivestreamAttachment
                    id={item.id}
                    key={item.id}
                  />
                );
              }

              return null;
            })}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export { GuardMeAttachments };
