import { EmptyState } from './empty-state';
import { Button } from './ui/button';
import { Skeleton } from './ui/skeleton';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { DataTablePagination } from '@/components/data-table-pagination';
import { DataTableToolbar } from '@/components/data-table-toolbar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type PaginationState,
  type SortingState,
  type Updater,
  useReactTable,
  type VisibilityState,
} from '@tanstack/react-table';
import { Database, X } from 'lucide-react';
import { type SetValues } from 'nuqs';
import * as React from 'react';

type DataTableProps<TData, TValue> = {
  readonly columns: Array<ColumnDef<TData, TValue>>;
  readonly data?: TData[];
  readonly fetching?: boolean;
  readonly filterFields?: Array<DataTableFacetedFilterField<TData>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly queryParameters: Record<string, any>;
  readonly refresh: () => void;
  readonly rowCount?: number;
  readonly searchPlaceholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly setQueryParameters: SetValues<Record<string, any>>;
};

const DataTable = <TData, TValue>({
  columns,
  data = [],
  fetching,
  filterFields = [],
  queryParameters,
  refresh,
  rowCount,
  searchPlaceholder,
  setQueryParameters,
}: DataTableProps<TData, TValue>) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const pagination: PaginationState = {
    pageIndex: queryParameters.pageIndex,
    pageSize: queryParameters.pageSize,
  };

  // PaginationState managed with query parameteres
  const setPagination = (newValue: Updater<PaginationState>) => {
    const newState =
      typeof newValue === 'function' ? newValue(pagination) : newValue;

    setQueryParameters({
      pageIndex: newState.pageIndex || null,
      pageSize: newState.pageSize || null,
    });
  };

  // ColumnFiltersState managed with query parameteres
  const columnFilters = Object.entries(queryParameters)
    .map(([key, value]) => ({
      id: key,
      value,
    }))
    .filter(({ id }) => filterFields.map((x) => x.value as string).includes(id))
    .filter(({ value }) => value ?? undefined);

  const setColumnFilters = (newValue: Updater<ColumnFiltersState>) => {
    const newState =
      typeof newValue === 'function' ? newValue(columnFilters) : newValue;

    const columnFiltersWithNullable = filterFields.map((field) => {
      const filterValue = newState.find((filter) => filter.id === field.value);
      if (!filterValue) {
        return { id: field.value, value: null };
      } else if (
        Array.isArray(filterValue.value) &&
        filterValue.value.length === 0
      ) {
        return { id: field.value, value: null };
      }

      return { id: field.value, value: filterValue.value };
    });

    // eslint-disable-next-line unicorn/no-array-reduce
    const search = columnFiltersWithNullable.reduce(
      (previous, current) => {
        previous[current.id as string] = current.value;
        return previous;
      },
      {} as Record<string, unknown>,
    );

    setQueryParameters(search);
  };

  // eslint-disable-next-line unicorn/no-array-reduce
  const columnVisibility = (queryParameters.hiddenColumns as string[]).reduce(
    (previous, current) => {
      previous[current as string] = false;
      return previous;
    },
    {} as VisibilityState,
  );

  const setColumnVisibility = (newValue: Updater<VisibilityState>) => {
    const newState =
      typeof newValue === 'function' ? newValue(columnVisibility) : newValue;

    setQueryParameters({
      hiddenColumns: Object.entries(newState)
        .filter(([, value]) => !value)
        .map(([key]) => key),
    });
  };

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      columnVisibility,
      pagination,
      sorting,
    },
  });

  React.useEffect(() => {
    setQueryParameters({ sort: sorting?.[0] || null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  return (
    <div className="w-full space-y-2">
      <DataTableToolbar
        fetching={fetching}
        filterFields={filterFields}
        placeholder={searchPlaceholder}
        refresh={refresh}
        setQueryParameters={setQueryParameters}
        table={table}
      />

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {fetching ? (
              Array.from(
                { length: pagination.pageSize },
                (_, index) => 0 + index,
              ).map((row) => (
                <TableRow key={row}>
                  <TableCell colSpan={10}>
                    <Skeleton className="h-5 w-full" />
                  </TableCell>
                </TableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  data-state={row.getIsSelected() && 'selected'}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <EmptyState
                    className="border-0"
                    icon={Database}
                    title="No results."
                  >
                    {columnFilters.length > 0 && (
                      <Button
                        onClick={() => {
                          setColumnFilters([]);
                        }}
                        variant="outline"
                      >
                        <X /> Clear Filters
                      </Button>
                    )}
                  </EmptyState>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
};

export { DataTable, type DataTableProps };
