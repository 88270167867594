import { useMemberIncidentsSearchParameters } from '../hooks/useMemberIncidentsSearchParameters';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { Datetime } from '@/components/Datetime';
import { Badge } from '@/components/ui/badge';
import { IncidentPriority } from '@/features/Incidents/components/IncidentPriority';
import { IncidentStatus } from '@/features/Incidents/components/IncidentStatus';
import { type DocumentType, graphql } from '@/gql';
import {
  IncidentPriorityType,
  IncidentsOrderBy,
  IncidentStatusType,
} from '@/gql/graphql';
import { sorToOrderByParser } from '@/lib/data-table';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberIncidentListGql = graphql(`
  query MemberIncidentListGql(
    $memberId: String!
    $first: Int!
    $offset: Int!
    $search: String
    $createdAt: IncidentsCreatedAtConditionInput
    $orderBy: [IncidentsOrderBy!] = [ID_DESC]
    $status: IncidentsStatusConditionInput
    $priority: IncidentsPriorityConditionInput
  ) {
    incidents(
      first: $first
      offset: $offset
      condition: {
        createdAt: $createdAt
        search: $search
        contactMemberId: $memberId
        status: $status
        priority: $priority
      }
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        shortId
        subject
        description
        status
        priority
        createdAt
        incidentType {
          id
          name
          iconSvg
        }
      }
    }
  }
`);

type Column = NonNullable<
  DocumentType<typeof MemberIncidentListGql>['incidents']
>['nodes'][0];

const columnHelper = createColumnHelper<Column>();

const COLUMNS = [
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <Link to={`/incidents/${getValue()}`}>
        <Badge
          className="hover:bg-primary hover:text-primary-foreground"
          variant="secondary"
        >
          {row.original.shortId}
        </Badge>
      </Link>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="ID"
      />
    ),
  }),
  columnHelper.accessor('priority', {
    cell: ({ getValue }) => <IncidentPriority priority={getValue()} />,
    header: () => 'Priority',
  }),
  columnHelper.accessor('subject', {
    cell: ({ getValue }) => getValue(),
    header: () => 'Subject',
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => (
      <Datetime
        datetime={getValue()}
        format="MMM dd, yyyy"
      />
    ),
    header: () => 'Created',
  }),
  columnHelper.accessor('status', {
    cell: ({ getValue }) => <IncidentStatus status={getValue()} />,
    header: () => 'Status',
  }),
];

const filterFields: Array<DataTableFacetedFilterField<Column>> = [
  {
    label: 'Status',
    options: [
      { label: 'Open', value: IncidentStatusType.Open },
      { label: 'In Progress', value: IncidentStatusType.InProgress },
      { label: 'Closed', value: IncidentStatusType.Closed },
    ],
    type: 'checkbox',
    value: 'status',
  },
  {
    label: 'Priority',
    options: [
      { label: 'Urgent', value: IncidentPriorityType.Urgent },
      { label: 'High', value: IncidentPriorityType.High },
      { label: 'Medium', value: IncidentPriorityType.Medium },
      { label: 'Low', value: IncidentPriorityType.Low },
    ],
    type: 'checkbox',
    value: 'priority',
  },
  {
    label: 'Date',
    type: 'timerange',
    value: 'createdAt',
  },
];

const sorToOrderBy = sorToOrderByParser({
  id_asc: IncidentsOrderBy.IdAsc,
  id_desc: IncidentsOrderBy.IdDesc,
});

type MemberIncidentListProps = {
  readonly memberId: string;
};

const MemberIncidentList = ({ memberId }: MemberIncidentListProps) => {
  const [queryParameters, setQueryParameters] =
    useMemberIncidentsSearchParameters();

  const [{ data, fetching }, refresh] = useQuery({
    query: MemberIncidentListGql,
    variables: {
      createdAt: {
        gte: queryParameters.createdAt?.at(0)?.toISOString() || null,
        lte: queryParameters.createdAt?.at(1)?.toISOString() || null,
      },
      first: queryParameters.pageSize,
      memberId,
      offset: queryParameters.pageIndex * queryParameters.pageSize,
      orderBy: sorToOrderBy(queryParameters.sort),
      priority: queryParameters.priority
        ? {
            high: queryParameters.priority.includes(IncidentPriorityType.High),
            low: queryParameters.priority.includes(IncidentPriorityType.Low),
            medium: queryParameters.priority.includes(
              IncidentPriorityType.Medium,
            ),
            urgent: queryParameters.priority.includes(
              IncidentPriorityType.Urgent,
            ),
          }
        : null,
      search: queryParameters.search || null,
      status: queryParameters.status
        ? {
            closed: queryParameters.status.includes(IncidentStatusType.Closed),
            inProgress: queryParameters.status.includes(
              IncidentStatusType.InProgress,
            ),
            open: queryParameters.status.includes(IncidentStatusType.Open),
          }
        : null,
    },
  });

  return (
    <DataTable
      columns={COLUMNS as Array<ColumnDef<Column>>}
      data={data?.incidents?.nodes}
      fetching={fetching}
      filterFields={filterFields}
      queryParameters={queryParameters}
      refresh={refresh}
      rowCount={data?.incidents?.totalCount}
      searchPlaceholder="Search by ID..."
      setQueryParameters={setQueryParameters}
    />
  );
};

export { MemberIncidentList };
