import { SectionMembershipRequestsSheet } from './SectionMembershipRequestsSheet';
import { DebouncedInput } from '@/components/debounced-input';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  type Group,
  type MembershipRequest,
  type Organization,
} from '@/gql/graphql';
import { pluralize } from '@/lib/pluralize';
import { MoreVertical } from 'lucide-react';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

type Props = {
  readonly fetching?: boolean;
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly memberMetadataFields?: Pick<Organization, 'memberMetadataFields'>;
  readonly membershipRequests?: Array<
    Pick<
      MembershipRequest,
      | 'id'
      | 'avatarUrl'
      | 'fullName'
      | 'phoneNumber'
      | 'emailAddress'
      | 'createdAt'
    > & {
      membershipCode?:
        | {
            organizationRole: string;
          }
        | null
        | undefined;
    }
  >;
};

const SectionMembershipRequests = ({
  fetching,
  groups = [],
  memberMetadataFields,
  membershipRequests,
}: Props) => {
  const [search, setSearch] = useState('');
  const [searchParameters, setSearchParameters] = useSearchParams();
  const id = searchParameters.get('id');

  const selected = membershipRequests?.find((x) => x.id === id);

  const filteredRequests = membershipRequests
    ?.map((item) => ({
      ...item,
      search:
        String(item.fullName?.toLowerCase()) +
        String(item.emailAddress?.toLowerCase()) +
        String(item.phoneNumber?.toLowerCase()),
    }))
    .filter((item) =>
      search ? item.search.includes(search.toLowerCase()) : true,
    );
  return (
    <section aria-labelledby="membership-requests">
      <div className="py-2 flex md:items-center">
        <DebouncedInput
          className="w-64"
          onChange={(value) => setSearch(value)}
          placeholder="Search requests..."
          type="search"
          value={search}
        />

        <div className="md:ml-auto font-semibold text-sm text-muted-foreground">
          {filteredRequests?.length !== membershipRequests?.length &&
            `${filteredRequests?.length.toString()} of `}
          {membershipRequests?.length.toString()}{' '}
          {pluralize('request', membershipRequests?.length)}
        </div>
      </div>

      <ul className="border-t border-b divide-y">
        {fetching &&
          !membershipRequests &&
          [0, 1, 2, 3, 4, 5].map((item) => (
            <li
              className="flex items-center justify-between gap-x-6 py-2.5"
              key={item}
            >
              <div className="flex items-center gap-x-4 truncate">
                <Skeleton className="size-8 shrink-0 rounded-full" />
                <Skeleton className="w-64 h-8" />
              </div>
              <div className="flex items-center gap-2">
                <Skeleton className="w-32 h-8" />
                <Skeleton className="w-32 h-8" />
                <Skeleton className="w-8 h-8" />
              </div>
            </li>
          ))}
        {membershipRequests?.map((item) => (
          <li
            className="flex items-center justify-between gap-x-6 py-2.5"
            key={item.id}
          >
            <div className="flex items-center gap-x-4 truncate">
              <Avatar className="size-8 shrink-0">
                <AvatarImage src={item.avatarUrl ?? undefined} />
                <AvatarFallback>{item.fullName?.slice(0, 2)}</AvatarFallback>
              </Avatar>
              <div className="truncate">
                <p className="truncate text-sm font-medium text-gray-900 dark:text-gray-50">
                  {item.fullName}
                </p>

                <p className="truncate text-xs text-gray-500">
                  {item.emailAddress}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Link to={`/members?view=membership-requests&id=${item.id}`}>
                <Button
                  size="icon"
                  variant="ghost"
                >
                  <MoreVertical />
                  <span className="sr-only">View Details</span>
                </Button>
              </Link>
            </div>
          </li>
        ))}
      </ul>

      {selected && (
        <SectionMembershipRequestsSheet
          groups={groups}
          memberMetadataFields={memberMetadataFields}
          membershipRequestId={selected.id}
          onOpenChange={() => {
            setSearchParameters({ view: 'pending-invitations' });
          }}
          open={Boolean(selected)}
        />
      )}
    </section>
  );
};

export { SectionMembershipRequests };
