import { useMemberCheckInsSearchParameters } from '../hooks/useMemberCheckInsSearchParameters';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { Datetime } from '@/components/Datetime';
import { Badge } from '@/components/ui/badge';
import { type DocumentType, graphql } from '@/gql';
import { CheckInsOrderBy } from '@/gql/graphql';
import { sorToOrderByParser } from '@/lib/data-table';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MemberCheckInListGql = graphql(`
  query MemberCheckInListGql(
    $memberId: String!
    $first: Int!
    $offset: Int!
    $shortId: String
    $createdAt: CheckInsCreatedAtConditionInput
    $orderBy: [CheckInsOrderBy!] = [ID_DESC]
  ) {
    checkIns(
      first: $first
      offset: $offset
      condition: {
        createdAt: $createdAt
        shortId: $shortId
        memberId: $memberId
      }
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        shortId
        createdAt
        places {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`);

type Column = NonNullable<
  DocumentType<typeof MemberCheckInListGql>['checkIns']
>['nodes'][0];

const columnHelper = createColumnHelper<Column>();

const COLUMNS = [
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <Link to={`/check-in/${getValue()}`}>
        <Badge
          className="hover:bg-primary hover:text-primary-foreground"
          variant="secondary"
        >
          {row.original.shortId}
        </Badge>
      </Link>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="ID"
      />
    ),
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Created',
  }),
  columnHelper.accessor('places', {
    cell: ({ getValue }) => (
      <ul className="flex flex-wrap gap-2">
        {getValue().nodes.map((item) => (
          <li key={item.id}>
            <Badge variant="secondary">{item.name}</Badge>
          </li>
        ))}
      </ul>
    ),
    header: () => 'Places',
  }),
];

const filterFields: Array<DataTableFacetedFilterField<Column>> = [
  {
    label: 'Date',
    type: 'timerange',
    value: 'createdAt',
  },
];

const sorToOrderBy = sorToOrderByParser({
  id_asc: CheckInsOrderBy.IdAsc,
  id_desc: CheckInsOrderBy.IdDesc,
});

type CheckInListProps = {
  readonly memberId: string;
};

const MemberCheckInList = ({ memberId }: CheckInListProps) => {
  const [queryParameters, setQueryParameters] =
    useMemberCheckInsSearchParameters();

  const [{ data, fetching }, refresh] = useQuery({
    query: MemberCheckInListGql,
    variables: {
      createdAt: {
        gte: queryParameters.createdAt?.at(0)?.toISOString() || null,
        lte: queryParameters.createdAt?.at(1)?.toISOString() || null,
      },
      first: queryParameters.pageSize,
      memberId,
      offset: queryParameters.pageIndex * queryParameters.pageSize,
      orderBy: sorToOrderBy(queryParameters.sort),
      shortId: queryParameters.search || null,
    },
  });

  return (
    <DataTable
      columns={COLUMNS as Array<ColumnDef<Column>>}
      data={data?.checkIns?.nodes}
      fetching={fetching}
      filterFields={filterFields}
      queryParameters={queryParameters}
      refresh={refresh}
      rowCount={data?.checkIns?.totalCount}
      searchPlaceholder="Search by ID..."
      setQueryParameters={setQueryParameters}
    />
  );
};

export { MemberCheckInList };
