import { config } from '../config';
import { logger } from '../utils';
import { currentUserUpdatesResolver } from '@/features/Account';
import { checkinUpdatesResolver } from '@/features/CheckIn/components/CheckInUpdates';
import { guardmeUpdatesResolver } from '@/features/GuardMe/components/GuardMeUpdates';
import { incidentsUpdatesResolver } from '@/features/Incidents/components/IncidentsUpdates';
import { incidentUpdatesResolver } from '@/features/Incidents/components/IncidentUpdates';
import { organizationUpdatesResolver } from '@/features/Organization/subscriptions/OrganizationUpdates';
import { useAuth0 } from '@auth0/auth0-react';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import { createClient as createWSClient } from 'graphql-ws';
import { useNavigate } from 'react-router-dom';
import {
  createClient,
  fetchExchange,
  gql,
  Provider,
  subscriptionExchange,
} from 'urql';

type GraphQLProviderProps = {
  readonly children: React.ReactNode;
};

const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();

  const wsClient = createWSClient({
    connectionParams: async () => {
      try {
        const authToken = await getAccessTokenSilently();
        return {
          authorization: `Bearer ${authToken}`,
        };
      } catch (error) {
        logger.error({ error });
        return {};
      }
    },
    url: config.WEBSOCKET_URL,
  });

  const client = createClient({
    exchanges: [
      devtoolsExchange,
      cacheExchange({
        keys: {
          CreateInvitationPayload: (parent) => {
            return parent.id?.toString() ?? null;
          },
          GeographyPoint: () => {
            return null;
          },
          GeographyPolygon: () => {
            return null;
          },
          GeometryPoint: () => {
            return null;
          },
          GeometryPolygon: () => {
            return null;
          },
          GuardmeLocation: () => {
            return null;
          },
          IncidentTypeCountType: () => {
            return null;
          },
          OrganizationMetric: (parent) => {
            return parent.organizationId?.toString() ?? null;
          },
          TimeSeries: () => {
            return null;
          },
          uploadAttachmentPayload: () => {
            return null;
          },
          uploadAvatarPayload: () => {
            return null;
          },
          uploadOrganizationLogoPayload: () => {
            return null;
          },
        },
        updates: {
          Mutation: {
            createInvitation: (result, _args, cache, info) => {
              const OrganizationInvitationsGql = {
                query: gql`
                  query OrganizationInvitationsGql($organizationId: String!) {
                    organization(id: $organizationId) {
                      id
                      invitations {
                        nodes {
                          id
                        }
                      }
                    }
                  }
                `,
                variables: {
                  organizationId: info.variables.organizationId,
                },
              };

              cache.updateQuery(OrganizationInvitationsGql, (data) => {
                data.organization.invitations.nodes.push(
                  result.createInvitation,
                );
                return data;
              });
            },
            deleteUserNotifications: (_result, args, cache) => {
              const { notificationIds } = args.input as {
                notificationIds: string[];
              };

              for (const id of notificationIds as string[]) {
                cache.invalidate({
                  __typename: 'UserNotification',
                  id,
                });
              }
            },
          },
          Subscription: {
            checkInUpdates: checkinUpdatesResolver(navigate),
            currentUserUpdates: currentUserUpdatesResolver,
            guardmeUpdates: guardmeUpdatesResolver(navigate),
            incidentsUpdates: incidentsUpdatesResolver(navigate),
            incidentUpdates: incidentUpdatesResolver,
            organizationUpdates: organizationUpdatesResolver,
          },
        },
      }),
      authExchange(async (utils) => {
        const initalResponse = await getAccessTokenSilently({
          detailedResponse: true,
        }).catch((error) => {
          logger.error({ error }, 'get access token silenty failed');
        });

        if (initalResponse?.access_token === undefined) {
          logger.error('Could not get token from auth0');
        }

        let token = initalResponse?.access_token ?? '';
        let expiresAt = (initalResponse?.expires_in || 0) * 1_000 + Date.now();

        return {
          addAuthToOperation(operation) {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
            });
          },
          didAuthError(error) {
            logger.error('didAuthError', error);
            if (error.response) {
              return error.response?.status === 401;
            }

            if (error.graphQLErrors) {
              return error.graphQLErrors.some(
                (item) => item.extensions?.code === 'A0001',
              );
            }

            return false;
          },
          async refreshAuth() {
            try {
              const response = await getAccessTokenSilently({
                detailedResponse: true,
              });

              token = response.access_token;
              expiresAt = response.expires_in * 1_000 + Date.now();
            } catch (error) {
              logger.log({ error });
              logout({
                logoutParams: {
                  returnTo: config.WEB_URL,
                },
              });
            }
          },
          willAuthError() {
            if (!token) {
              return true;
            }

            return expiresAt - 60 * 1_000 < Date.now();
          },
        };
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (request) => {
          const input = { ...request, query: request.query || '' };
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink);
              return { unsubscribe };
            },
          };
        },
      }),
    ],
    url: config.GRAPHQL_URL,
  });

  return <Provider value={client}>{children}</Provider>;
};

export { GraphQLProvider };
