// https://github.com/openstatusHQ/time-picker

import {
  getArrowByType,
  getDateByType,
  type Period,
  setDateByType,
  type TimePickerType,
} from './time-picker-utils';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import * as React from 'react';

export type TimePickerInputProps = {
  readonly date: Date | undefined;
  readonly onLeftFocus?: () => void;
  readonly onRightFocus?: () => void;
  readonly period?: Period;
  readonly picker: TimePickerType;
  readonly setDate: (date: Date | undefined) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TimePickerInput = React.forwardRef<
  HTMLInputElement,
  TimePickerInputProps
>(
  (
    {
      className,
      date = new Date(new Date().setHours(0, 0, 0, 0)),
      id,
      name,
      onChange,
      onKeyDown,
      onLeftFocus,
      onRightFocus,
      period,
      picker,
      setDate,
      type = 'tel',
      value,
      ...props
    },
    ref,
  ) => {
    const [flag, setFlag] = React.useState<boolean>(false);
    const [previousIntKey, setPreviousIntKey] = React.useState<string>('0');

    /**
     * allow the user to enter the second digit within 2 seconds
     * otherwise start again with entering first digit
     */
    React.useEffect(() => {
      if (flag) {
        const timer = setTimeout(() => {
          setFlag(false);
        }, 2_000);

        return () => clearTimeout(timer);
      }

      return () => {};
    }, [flag]);

    const calculatedValue = React.useMemo(() => {
      return getDateByType(date, picker);
    }, [date, picker]);

    const calculateNewValue = (key: string) => {
      /*
       * If picker is '12hours' and the first digit is 0, then the second digit is automatically set to 1.
       * The second entered digit will break the condition and the value will be set to 10-12.
       */
      if (
        picker === '12hours' &&
        flag &&
        calculatedValue.slice(1, 2) === '1' &&
        previousIntKey === '0'
      )
        return '0' + key;

      return flag ? calculatedValue.slice(1, 2) + key : '0' + key;
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Tab') return;
      event.preventDefault();
      if (event.key === 'ArrowRight') onRightFocus?.();
      if (event.key === 'ArrowLeft') onLeftFocus?.();
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        const step = event.key === 'ArrowUp' ? 1 : -1;
        const newValue = getArrowByType(calculatedValue, step, picker);
        if (flag) setFlag(false);
        const temporaryDate = new Date(date);
        setDate(setDateByType(temporaryDate, newValue, picker, period));
      }

      if (event.key >= '0' && event.key <= '9') {
        if (picker === '12hours') setPreviousIntKey(event.key);

        const newValue = calculateNewValue(event.key);
        if (flag) onRightFocus?.();
        setFlag((previous) => !previous);
        const temporaryDate = new Date(date);
        setDate(setDateByType(temporaryDate, newValue, picker, period));
      }
    };

    return (
      <Input
        className={cn(
          'w-[48px] text-center font-mono text-base tabular-nums caret-transparent focus:bg-accent focus:text-accent-foreground [&::-webkit-inner-spin-button]:appearance-none',
          className,
        )}
        id={id || picker}
        inputMode="decimal"
        name={name || picker}
        onChange={(event) => {
          event.preventDefault();
          onChange?.(event);
        }}
        onKeyDown={(event) => {
          onKeyDown?.(event);
          handleKeyDown(event);
        }}
        ref={ref}
        type={type}
        value={value || calculatedValue}
        {...props}
      />
    );
  },
);

TimePickerInput.displayName = 'TimePickerInput';

export { TimePickerInput };
