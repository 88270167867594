import { IncidentStatusType } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { Activity, Lock, LockOpen } from 'lucide-react';

const commonClasses =
  'inline-flex items-center gap-2 text-xs px-2 py-1 rounded whitespace-nowrap';

type IncidentStatusProps = {
  readonly className?: string;
  readonly iconOnly?: boolean;
  readonly noColor?: boolean;
  readonly status: IncidentStatusType;
};

const IncidentStatus = ({
  className,
  iconOnly = false,
  noColor = false,
  status,
}: IncidentStatusProps) => {
  if (status === IncidentStatusType.Closed) {
    return (
      <span
        className={cn(
          !noColor && 'bg-success text-success-foreground',
          commonClasses,
          className,
        )}
      >
        <Lock className="h-4 w-4" /> {!iconOnly && 'Closed'}
      </span>
    );
  }

  if (status === IncidentStatusType.InProgress) {
    return (
      <span
        className={cn(
          !noColor && 'bg-info text-info-foreground',
          commonClasses,
          className,
        )}
      >
        <Activity className="h-4 w-4" />
        {!iconOnly && 'In Progress'}
      </span>
    );
  }

  if (status === IncidentStatusType.Open) {
    return (
      <span
        className={cn(
          !noColor && 'bg-muted text-muted-foreground',
          commonClasses,
          className,
        )}
      >
        <LockOpen className="h-4 w-4" />
        {!iconOnly && 'Open'}
      </span>
    );
  }

  return null;
};

export { IncidentStatus };
