import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { graphql } from '@/gql';
import { type Member } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';
import { useMutation, useQuery } from 'urql';

const EditIncidentOwnerDropdownGql = graphql(`
  mutation EditIncidentOwnerDropdownGql($input: UpdateIncidentOwnerInput!) {
    updateIncidentOwner(input: $input) {
      incident {
        id
        ownedByMemberId
        ownedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
      }
    }
  }
`);

const AllMemberDropdownGql = graphql(`
  query AllMemberDropdownGql($organizationId: String!) {
    members(condition: { organizationId: $organizationId }) {
      nodes {
        id
        isEnabled
        fullName
        displayName
        avatarUrl
      }
    }
  }
`);

type EditIncidentOwnerDropdownProps = {
  readonly className?: string;
  readonly incidentId: string;
  readonly member?: Pick<
    Member,
    'avatarUrl' | 'displayName' | 'fullName' | 'id'
  > | null;
  readonly onComplete?: () => void;
  readonly readonly: boolean;
};

const EditIncidentOwnerDropdown = ({
  className,
  incidentId,
  member,
  onComplete,
  readonly,
}: EditIncidentOwnerDropdownProps) => {
  const [open, setOpen] = useState(false);

  const organizationId = useAppStore((state) => state.activeMembership?.id);

  const [, updateOwner] = useMutation(EditIncidentOwnerDropdownGql);

  const [{ data }] = useQuery({
    pause: !organizationId,
    query: AllMemberDropdownGql,
    variables: {
      organizationId: organizationId ?? '',
    },
  });

  const handleSelect = async (value: string) => {
    const memberId = member?.id === value ? '' : value;

    await updateOwner({
      input: {
        incidentId,
        memberId,
      },
    });

    onComplete?.();

    setOpen(false);
  };

  if (readonly) {
    return (
      <div
        className={cn(
          'inline-flex items-center justify-center rounded-md text-sm font-medium h-9 rounded-md px-3 border border-input bg-background',
          className,
        )}
      >
        {member ? (
          <span className="flex items-center">
            <Avatar className="h-6 w-6 mr-2 shrink-0">
              <AvatarImage
                alt={member.displayName ?? member.fullName ?? 'Deleted Member'}
                src={member.avatarUrl ?? undefined}
              />
              <AvatarFallback>
                {(
                  member.displayName ??
                  member.fullName ??
                  'Deleted Member'
                ).slice(0, 2)}
              </AvatarFallback>
            </Avatar>
            {member.displayName ?? member.fullName}
          </span>
        ) : (
          <>No Agent</>
        )}
      </div>
    );
  }

  return (
    <Popover
      onOpenChange={setOpen}
      open={open}
    >
      <PopoverTrigger asChild>
        <Button
          className={cn('justify-between group', className)}
          size="sm"
          variant="outline"
        >
          {member ? (
            <span className="flex items-center">
              <Avatar className="h-6 w-6 mr-2 shrink-0">
                <AvatarImage
                  alt={
                    member.displayName ?? member.fullName ?? 'Deleted Member'
                  }
                  src={member.avatarUrl ?? undefined}
                />
                <AvatarFallback>
                  {(
                    member.displayName ??
                    member.fullName ??
                    'Deleted Member'
                  ).slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              {member.displayName ?? member.fullName}
            </span>
          ) : (
            <>Assign an agent</>
          )}
          <ChevronsUpDown className="ml-4 h-4 w-4 shrink-0 opacity-25 group-hover:opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="p-0"
        side="bottom"
      >
        <Command>
          <CommandInput placeholder="Filter members..." />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {data?.members?.nodes
                .filter((item) => item.isEnabled)
                .map((item) => (
                  <CommandItem
                    className={
                      item.id === member?.id ? 'bg-accent/90' : undefined
                    }
                    key={item.id}
                    onSelect={async () => await handleSelect(item.id)}
                    value={`${item.id}-${item.displayName}-${item.fullName}`}
                  >
                    <Avatar className="h-5 w-5 mr-2 shrink-0">
                      <AvatarImage
                        alt={
                          item.displayName ?? item.fullName ?? 'Deleted Member'
                        }
                        src={item.avatarUrl ?? undefined}
                      />
                      <AvatarFallback>
                        {(
                          item.displayName ??
                          item.fullName ??
                          'Deleted Member'
                        ).slice(0, 2)}
                      </AvatarFallback>
                    </Avatar>
                    <span>{item.displayName ?? item.fullName}</span>
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { EditIncidentOwnerDropdown };
