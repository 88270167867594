import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const DataListOrientationContext = React.createContext<
  'horizontal' | 'vertical'
>('horizontal');

const dataListVariants = cva('overflow-hidden font-normal text-left', {
  defaultVariants: {
    orientation: 'horizontal',
    size: 'default',
  },
  variants: {
    orientation: {
      horizontal: 'flex flex-col',
      vertical: 'flex flex-col',
    },
    size: {
      default: 'text-base',
      lg: 'text-lg',
      sm: 'text-sm',
    },
  },
});

export type DataListProps = {
  readonly asChild?: boolean;
} & React.HTMLAttributes<HTMLDListElement> &
  VariantProps<typeof dataListVariants>;

const DataList = React.forwardRef<HTMLDListElement, DataListProps>(
  (
    { asChild = false, className, orientation = 'horizontal', size, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'dl';

    return (
      <DataListOrientationContext.Provider value={orientation || 'horizontal'}>
        <Comp
          className={cn(dataListVariants({ orientation, size }), className)}
          ref={ref}
          {...props}
        />
      </DataListOrientationContext.Provider>
    );
  },
);
DataList.displayName = 'DataList';

export type DataListItemProps = {
  readonly className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DataListItem = React.forwardRef<HTMLDivElement, DataListItemProps>(
  ({ className, ...props }, ref) => {
    const orientation = React.useContext(DataListOrientationContext);

    return (
      <div
        className={cn(
          'flex',
          orientation === 'horizontal' ? 'items-center' : 'flex-col', // Aplica a classe correta com base na orientação
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
DataListItem.displayName = 'DataListItem';

export type DataListLabelProps = {
  readonly className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DataListLabel = React.forwardRef<HTMLDivElement, DataListLabelProps>(
  ({ className, ...props }, ref) => (
    <dt
      className={cn('text-gray-600 dark:text-gray-400', className)}
      ref={ref}
      {...props}
    />
  ),
);
DataListLabel.displayName = 'DataListLabel';

export type DataListValueProps = {
  readonly className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DataListValue = React.forwardRef<HTMLDivElement, DataListValueProps>(
  ({ className, ...props }, ref) => (
    <dd
      className={cn('text-black dark:text-white', className)}
      ref={ref}
      {...props}
    />
  ),
);
DataListValue.displayName = 'DataListValue';

export { DataList, DataListItem, DataListLabel, DataListValue };
