import { checkinsDataTableQueryStates } from '@/features/CheckIn/hooks/useCheckInsSearchParameters';
import { useQueryStates } from 'nuqs';

// set default page size to 10
const parsers = {
  ...checkinsDataTableQueryStates.parsers,
  pageSize: checkinsDataTableQueryStates.parsers.pageSize.withDefault(10),
};

const useMemberCheckInsSearchParameters = () =>
  useQueryStates(parsers, {
    urlKeys: checkinsDataTableQueryStates.urlKeys,
  });

export { useMemberCheckInsSearchParameters };
