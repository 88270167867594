import {
  DataTableFacetedFilter,
  type DataTableFacetedFilterField,
} from './data-table-faceted-filter';
import { DataTableTimerangeFilter } from './data-table-timerange-filter';
import { DebouncedInput } from './debounced-input';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { type Table } from '@tanstack/react-table';
import { RefreshCw, Settings2 } from 'lucide-react';
import { type SetValues } from 'nuqs';
import { useEffect, useState } from 'react';

type DataTableToolbarProps<TData> = {
  readonly fetching?: boolean;
  readonly filterFields?: Array<DataTableFacetedFilterField<TData>>;
  readonly placeholder?: string;
  readonly refresh: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly setQueryParameters?: SetValues<Record<string, any>>;
  readonly table: Table<TData>;
};

const DataTableToolbar = <T,>({
  fetching,
  filterFields,
  placeholder = 'Search...',
  refresh,
  setQueryParameters,
  table,
}: DataTableToolbarProps<T>) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (setQueryParameters) {
      setQueryParameters({ search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="flex items-end gap-2">
      <DebouncedInput
        className="h-8 w-full"
        onChange={setSearch}
        placeholder={placeholder}
        type="search"
        value={search}
      />
      {filterFields
        ?.filter((x) => x.type !== 'timerange')
        .map((item) => (
          <DataTableFacetedFilter
            column={table.getColumn(item.value as string)}
            key={item.value as string}
            options={item.options ?? []}
            title={item.label}
          />
        ))}
      {filterFields
        ?.filter((x) => x.type === 'timerange')
        .map((item) => (
          <DataTableTimerangeFilter
            className="h-8"
            columnId={item.value as string}
            key={item.value as string}
            table={table}
          />
        ))}
      <div className="ml-auto flex gap-2">
        <Button
          className="h-8"
          disabled={fetching}
          onClick={() => {
            refresh();
          }}
          size="icon"
          variant="outline"
        >
          <RefreshCw className={fetching ? 'animate-spin' : ''} />
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="ml-auto h-8"
              size="icon"
              variant="outline"
            >
              <Settings2 />
              <span className="sr-only">View Columns</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    checked={column.getIsVisible()}
                    className="capitalize"
                    key={column.id}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(Boolean(value))
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export { DataTableToolbar };
