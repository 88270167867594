import { Navigate, type RouteObject } from 'react-router-dom';

export const administrationRoutes: RouteObject[] = [
  {
    lazy: async () => ({
      Component: (await import('./routes/Overview')).Overview,
    }),
    path: '/administration/overview',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/Plans')).Plans,
    }),
    path: '/administration/plans/:id',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/Plans')).Plans,
    }),
    path: '/administration/plans',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/PlanNew')).PlanNew,
    }),
    path: '/administration/plans/new',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/Organizations')).Organizations,
    }),
    path: '/administration/organizations/:id',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/Organizations')).Organizations,
    }),
    path: '/administration/organizations',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/OrganizationsNew')).OrganizationsNew,
    }),
    path: '/administration/organizations/new',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/Users')).Users,
    }),
    path: '/administration/users/:id',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/Users')).Users,
    }),
    path: '/administration/users',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/IncidentTypes')).IncidentTypes,
    }),
    path: '/administration/incident-types/:id',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/IncidentTypes')).IncidentTypes,
    }),
    path: '/administration/incident-types',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/BivySticks')).BivySticks,
    }),
    path: '/administration/bivy-sticks',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/Colors')).Colors,
    }),
    path: '/administration/colors',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/ClientToolsGuardMe'))
        .ClientToolsGuardMe,
    }),
    path: '/administration/client-tools/guardme',
  },
  {
    lazy: async () => ({
      Component: (await import('./routes/ClientToolsCheckIn'))
        .ClientToolsCheckIn,
    }),
    path: '/administration/client-tools/check-in',
  },
  {
    element: <Navigate to="/administration/overview" />,
    path: '/administration/client-tools',
  },
  {
    element: <Navigate to="/administration/overview" />,
    path: '/administration',
  },
];
