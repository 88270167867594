import { ActiveGuardMeList } from '../components/ActiveGuardMeList';
import { ActiveIncidents } from '../components/ActiveIncidents';
import { CheckInCount } from '../components/CheckInCount';
import { GuardmeCount } from '../components/GuardmeCount';
import { IncidentCount } from '../components/IncidentCount';
import { MemberCount } from '../components/MemberCount';
import { RecentMap } from '../components/RecentMap';
import { Header, HeaderTitle } from '@/components/header';
import { MapProvider } from '@/components/Map';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { OrganizationNotFoundError } from '@/lib/error';
import { useAppStore } from '@/stores';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership) {
    throw new OrganizationNotFoundError();
  }

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Overview</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle className="flex items-center gap-2">
          <Avatar className="size-10">
            <AvatarImage
              alt={activeMembership.name}
              src={activeMembership.logoUrl}
            />
            <AvatarFallback>{activeMembership.name.slice(0, 1)}</AvatarFallback>
          </Avatar>
          {activeMembership.name}
        </HeaderTitle>
      </Header>

      <div className="grid grid-cols-1 items-start gap-4 md:gap-6 lg:grid-cols-2 xl:grid-cols-5">
        <div className="col-span-full grid grid-cols-1 items-start gap-4 lg:grid-cols-3">
          <div className="col-span-full grid grid-cols-1 items-start gap-4 lg:grid-cols-3">
            <CheckInCount organizationId={activeMembership.id} />

            <GuardmeCount organizationId={activeMembership.id} />

            <IncidentCount organizationId={activeMembership.id} />
          </div>

          <div className="col-span-2 grid grid-cols-1 items-start gap-4 lg:grid-cols-2">
            <div className="col-span-full">
              <MemberCount organizationId={activeMembership.id} />
            </div>

            <div className="col-span-full">
              <MapProvider>
                <RecentMap
                  defaultMapCenter={activeMembership.defaultMapCenter}
                  defaultMapZoom={activeMembership.defaultMapZoom}
                  organizationId={activeMembership.id}
                />
              </MapProvider>
            </div>

            <div className="col-span-full">
              <ActiveGuardMeList organizationId={activeMembership.id} />
            </div>
          </div>

          <div className="col-span-1 grid grid-cols-1 items-start gap-4 ">
            <ActiveIncidents organizationId={activeMembership.id} />
          </div>
        </div>
      </div>
    </>
  );
};

export { Dashboard };
