import { Badge } from '@/components/ui/badge';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { PopoverAnchor } from '@radix-ui/react-popover';
import { Command as CommandPrimitive } from 'cmdk';
import { X } from 'lucide-react';
import * as React from 'react';

type Option = Record<'value' | 'label', string>;

type MultiSelectProps = {
  readonly className?: string;
  readonly defaultValue?: string[];
  readonly label?: string;
  readonly onValueChange?: (value: string[]) => void;
  readonly options?: Option[];
  readonly value?: string[];
};

const MultiSelect = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  MultiSelectProps
>(
  (
    {
      className,
      defaultValue = [],
      label = 'Option',
      onValueChange,
      options,
      value,
    },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [selected, setSelected] = React.useState<Option[]>(
      value
        ? options?.filter((x) => value.includes(x.value)) || []
        : options?.filter((x) => defaultValue.includes(x.value)) || [],
    );

    const handleUnselect = React.useCallback(
      (option: Option) => {
        if (onValueChange && value) {
          onValueChange(value.filter((x) => x !== option.value));
        } else {
          setSelected((previous) =>
            previous.filter((x) => x.value !== option.value),
          );
        }
      },
      [value, onValueChange],
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (input) {
          if (
            (event.key === 'Delete' || event.key === 'Backspace') &&
            input.value === ''
          ) {
            if (onValueChange && value) {
              const newSelected = [...value];
              newSelected.pop();
              onValueChange(newSelected);
            } else {
              setSelected((previous) => {
                const newSelected = [...previous];
                newSelected.pop();
                return newSelected;
              });
            }
          }

          // This is not a default behaviour of the <input /> field
          if (event.key === 'Escape') {
            input.blur();
          }

          // keeps focus on input after selecting an option from CommandList
          if (event.key === 'Enter') {
            event.preventDefault();
          }

          // up and down keys should only affect CommandList
          if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
          }

          // Pass all keydown events from the input to the `CommandInput`
          // to provide navigation using up/down arrow keys etc.
          const { bubbles, code, key } = event;
          input.dispatchEvent(
            new KeyboardEvent('keydown', { bubbles, code, key }),
          );
        }
      },
      [onValueChange, value],
    );

    const onInputValueChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      const eventValue = event.target.value;
      setInputValue(eventValue);
    };

    const selectedOptions = value
      ? options?.filter((x) => value.includes(x.value)) || []
      : selected;
    const selectables =
      options?.filter((x) => !selectedOptions.includes(x)) || [];

    return (
      <Popover
        modal
        onOpenChange={setOpen}
        open={open}
      >
        <PopoverAnchor asChild>
          <div className="group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
            <div className="flex flex-wrap gap-1">
              {selectedOptions.map((item) => {
                return (
                  <Badge
                    key={item.value}
                    variant="secondary"
                  >
                    {item.label}
                    <button
                      className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      onClick={() => handleUnselect(item)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleUnselect(item);
                        }
                      }}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      type="button"
                    >
                      <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                    </button>
                  </Badge>
                );
              })}
              <PopoverAnchor asChild>
                <input
                  className={cn(
                    'flex-1 bg-transparent outline-none placeholder:text-muted-foreground',
                    selectedOptions.length > 0 && 'ml-2',
                  )}
                  onBlur={() => setOpen(false)}
                  onChange={onInputValueChange}
                  onFocus={() => setOpen(true)}
                  onKeyDown={handleKeyDown}
                  placeholder={`Select ${label}...`}
                  value={inputValue}
                />
              </PopoverAnchor>
            </div>
          </div>
        </PopoverAnchor>
        <PopoverContent
          className="p-0 PopoverContent"
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          <Command
            className={cn('overflow-visible bg-transparent', className)}
            ref={ref}
          >
            {/* Avoid having the "Search" Icon */}
            <CommandPrimitive.Input
              className="hidden"
              ref={inputRef}
              value={inputValue}
            />
            <CommandList>
              {selectables.length > 0 ? (
                <div className="w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
                  <CommandGroup className="h-full overflow-auto">
                    {selectables.map((selection, index) => {
                      return (
                        <CommandItem
                          className="cursor-pointer"
                          key={selection.value}
                          onMouseDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                          onSelect={() => {
                            setInputValue('');

                            if (onValueChange && value) {
                              onValueChange([...value, selection.value]);
                            } else {
                              setSelected((previous) => [
                                ...previous,
                                selection,
                              ]);
                            }
                          }}
                        >
                          <span className="hidden">{index}</span>
                          {selection.label}
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </div>
              ) : null}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

MultiSelect.displayName = 'MultiSelect';

export { MultiSelect };
