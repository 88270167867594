import { type DocumentType, graphql } from '@/gql';
import { GraphqlIncidentUpdateEvent } from '@/gql/graphql';
import { type Cache } from '@urql/exchange-graphcache';
import { useSubscription } from 'urql';

const IncidentUpdatesGql = graphql(`
  subscription IncidentUpdatesGql($incidentId: String!) {
    incidentUpdates(input: { incidentId: $incidentId }) {
      incident {
        id
        shortId
        organizationId
        updatedAt
        createdAt
        priority
        status
        subject
        description
        additionalData
        incidentCoordinates {
          x
          y
        }
        incidentCoordinatesGeocode {
          id
          address
          plusCode
        }
        submittedByMemberCoordinates {
          x
          y
        }
        submittedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
        contactMemberId
        contactMember {
          id
          fullName
          displayName
          avatarUrl
          emailAddress
          phoneNumber
        }
        ownedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByGroup {
          id
          name
        }
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            previousValue
            updatedValue
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                mimetype
                url
              }
            }
          }
        }
        incidentType {
          id
          name
          dataSchema
          uiSchema
        }
      }
      event
      relatedIds
    }
  }
`);

const incidentUpdatesResolver = (
  result: DocumentType<typeof IncidentUpdatesGql>,
  _args: never,
  cache: Cache,
) => {
  if (!result?.incidentUpdates?.incident) {
    // no incidents on object, do nothing
    return;
  }

  if (!result.incidentUpdates.incident.organizationId) {
    // unknown organization
    return;
  }

  const event = result.incidentUpdates.event ?? 'updated';

  if (event === GraphqlIncidentUpdateEvent.LivestreamActive) {
    const livestreamId = result.incidentUpdates.relatedIds
      ? result.incidentUpdates.relatedIds[2]
      : null;

    if (livestreamId) {
      // invalidate livestream query results to cause refetch with new data
      cache.invalidate('Query', 'livestream', { id: livestreamId });
    }
  }
};

type IncidentUpdatesProps = { readonly incidentId: string };

const IncidentUpdates = ({ incidentId }: IncidentUpdatesProps) => {
  useSubscription({
    query: IncidentUpdatesGql,
    variables: {
      incidentId,
    },
  });

  return null;
};

export { IncidentUpdates, incidentUpdatesResolver };
