import {
  AdvancedMarker,
  Circle,
  Map,
  MapProvider,
  Polygon,
} from '@/components/Map';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { Toggle } from '@/components/ui/toggle';
import { graphql } from '@/gql';
import { Eye, EyeOffIcon } from 'lucide-react';
import { Fragment, useState } from 'react';
import { useQuery } from 'urql';

const IncidentLocationPlaces = graphql(`
  query IncidentLocationPlaces($organizationId: String!) {
    organization(id: $organizationId) {
      id
      places {
        nodes {
          id
          name
          radius
          spatialData {
            geojson
          }
        }
      }
    }
  }
`);

type IncidentLocationProps = {
  readonly coordinates: {
    x: number;
    y: number;
  };
  readonly fetching: boolean;
  readonly organizationId: string;
  readonly relatedPlaces: Array<{ id: string }>;
};

const IncidentLocation = ({
  coordinates,
  fetching,
  organizationId,
  relatedPlaces,
}: IncidentLocationProps) => {
  const [places, setPlaces] = useState<string[]>([]);

  const [{ data }] = useQuery({
    query: IncidentLocationPlaces,
    variables: {
      organizationId,
    },
  });

  const togglePlaceVisibility = (placeId: string) => {
    setPlaces((previous) => {
      if (previous.includes(placeId)) {
        return previous.filter((item) => item !== placeId);
      }

      return [...previous, placeId];
    });
  };

  const relatedPlaceIds = relatedPlaces.map((a) => a.id);

  return (
    <div className="w-full h-full">
      {fetching && <Skeleton className="h-96 w-full" />}

      <MapProvider>
        {coordinates && (
          <Map
            className="h-96"
            defaultCenter={{
              lat: coordinates.y,
              lng: coordinates.x,
            }}
            defaultZoom={14}
          >
            <AdvancedMarker
              position={{
                lat: coordinates.y,
                lng: coordinates.x,
              }}
            />
            {data?.organization?.places.nodes
              .filter((a) => places.includes(a.id))
              .map((item) => (
                <Fragment key={item.id}>
                  {item.spatialData.geojson.type === 'Polygon' && (
                    <Polygon
                      draggable={false}
                      editable={false}
                      paths={(
                        item.spatialData.geojson.coordinates as number[][][]
                      ).map((outer) => {
                        return outer.map((inner) => {
                          const [lng, lat] = inner;
                          return { lat, lng };
                        });
                      })}
                    />
                  )}
                  {item.spatialData.geojson.type === 'Point' && (
                    <Circle
                      center={{
                        lat: item.spatialData.geojson.coordinates[1],
                        lng: item.spatialData.geojson.coordinates[0],
                      }}
                      draggable={false}
                      editable={false}
                      radius={item.radius}
                    />
                  )}
                </Fragment>
              ))}
          </Map>
        )}
      </MapProvider>

      <Separator className="my-4" />

      <div className="font-semibold mt-4 mb-1">Organization Places</div>
      <ul className="grid grid-cols-1 gap-1 md:grid-cols-3 md:gap-1">
        {data?.organization?.places.nodes
          .sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
          )
          .map((item) => (
            <li
              className="flex items-center"
              key={item.id}
            >
              <Toggle
                aria-label="Toggle visible"
                className="flex items-center gap-2"
                onPressedChange={() => togglePlaceVisibility(item.id)}
                pressed={places.includes(item.id)}
                size="sm"
              >
                {places.includes(item.id) ? (
                  <Eye className="h-4 w-4" />
                ) : (
                  <EyeOffIcon className="h-4 w-4" />
                )}
                {item.name}
                {relatedPlaceIds.includes(item.id) && <Badge>Related</Badge>}
              </Toggle>
            </li>
          ))}
      </ul>
    </div>
  );
};

export { IncidentLocation };
