import { NavUser, type NavUserProps } from './NavUser';
import {
  SidebarFooter,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import { BadgeHelp, GlobeLock } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

type Props = NavUserProps & {
  readonly isAdmin: boolean;
};

const AppSidebarFooter = ({ isAdmin, user }: Props) => {
  const { pathname } = useLocation();

  return (
    <SidebarFooter>
      {!pathname.startsWith('/administration') && (
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <a
                href="https://seeitsendit.com/contact"
                rel="noreferrer noopener"
                target="_blank"
              >
                <BadgeHelp />
                <span>Contact Us</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {isAdmin && (
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <Link to="/administration">
                  <GlobeLock />
                  <span>Administration</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}
        </SidebarMenu>
      )}
      <SidebarMenu>
        <SidebarMenuItem>
          <NavUser user={user} />
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarFooter>
  );
};

export { AppSidebarFooter };
