import { PlaceDetail } from './PlaceDetail';
import { EmptyState } from '@/components/empty-state';
import { MapProvider } from '@/components/Map';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { type Place } from '@/gql/graphql';
import { Pin, Search } from 'lucide-react';
import { useState } from 'react';

type Props = {
  readonly fetching?: boolean;
  readonly places?: Array<
    Pick<
      Place,
      | 'id'
      | 'name'
      | 'description'
      | 'center'
      | 'encodedPolyline'
      | 'radius'
      | 'spatialData'
    >
  > | null;
};

const PlaceList = ({ fetching, places = [] }: Props) => {
  const [search, setSearch] = useState('');

  const filteredPlaces =
    places?.filter((x) =>
      `${x.name.toLowerCase()}${x.description.toLowerCase()}`.includes(
        search.toLowerCase(),
      ),
    ) ?? [];

  if (fetching) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div className="col-span-full">
          <Skeleton className="w-full h-10" />
        </div>
        <Skeleton className="w-full h-32" />
        <Skeleton className="w-full h-32" />
      </div>
    );
  }

  if (places?.length === 0) {
    return (
      <EmptyState
        description="Get started by adding a custom place which will be linked automatically to existing and new Incidents, Check Ins and GuardMes."
        icon={Pin}
        title="No Places Created"
      />
    );
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="relative">
          <Search className="absolute left-2.5 top-2.5 size-4 text-muted-foreground" />
          <Input
            className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] max-w-1/2"
            onChange={(event) => {
              setSearch(event.currentTarget.value);
            }}
            placeholder="Search Places..."
            type="search"
            value={search}
          />
        </div>
        <div className="flex gap-1 items-center">
          <div>
            {filteredPlaces.length.toString()} {search ? 'Found' : 'Places'}
          </div>
        </div>
      </div>

      <div className="py-2 grid grid-cols-3 gap-4">
        {filteredPlaces.length > 0 ? (
          <MapProvider>
            {filteredPlaces.map((item) => (
              <div key={`place_${item.id}`}>
                <PlaceDetail
                  center={item.center}
                  description={item.description}
                  encodedPolyline={item.encodedPolyline}
                  id={item.id}
                  name={item.name}
                  radius={item.radius}
                  spatialData={item.spatialData}
                />
              </div>
            ))}
          </MapProvider>
        ) : (
          <div className="col-span-full p-4 md:p-8 italic text-center">
            No Search Results Found
          </div>
        )}
      </div>
    </>
  );
};

export { PlaceList };
