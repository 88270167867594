import { type FoundOrganization } from './Types';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { createLogoUrl, memberMetadataFieldsToArray } from '@/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const StartMembershipRequestQuery = graphql(`
  mutation StartMembershipRequest($code: String!) {
    membershipRequestDiscover(input: { code: $code }) {
      membershipRequestDiscoverResult {
        code
        organizationId
        organizationLogoUrl
        organizationName
        publicMetadataFields
      }
    }
  }
`);

const schema = object({
  code: nonempty(string()),
});

type StartMembershipRequestProps = {
  readonly setOrganization: (found: FoundOrganization) => void;
};

const StartMembershipRequest = ({
  setOrganization,
}: StartMembershipRequestProps) => {
  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      code: '',
    },
    resolver: superstructResolver(schema),
  });

  const [{ error, fetching }, discoverMembershipRequest] = useMutation(
    StartMembershipRequestQuery,
  );

  const onSubmit = async (values: Infer<typeof schema>) => {
    const result = await discoverMembershipRequest({
      code: values.code,
    });

    if (
      result.data?.membershipRequestDiscover?.membershipRequestDiscoverResult &&
      result.data.membershipRequestDiscover.membershipRequestDiscoverResult
        .code &&
      result.data.membershipRequestDiscover.membershipRequestDiscoverResult
        .organizationName
    ) {
      const fields = memberMetadataFieldsToArray(
        result.data.membershipRequestDiscover.membershipRequestDiscoverResult
          ?.publicMetadataFields,
      );

      setOrganization({
        code: result.data.membershipRequestDiscover
          .membershipRequestDiscoverResult.code,
        logoUrl:
          result.data.membershipRequestDiscover.membershipRequestDiscoverResult
            .organizationLogoUrl ??
          createLogoUrl(
            result.data.membershipRequestDiscover
              .membershipRequestDiscoverResult.organizationName,
          ),
        name: result.data.membershipRequestDiscover
          .membershipRequestDiscoverResult.organizationName,
        publicFields: fields.publicFields,
      });
    }
  };

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Organization Membership Code</FormLabel>
              <div className="flex items-center">
                <FormControl>
                  <Input
                    {...field}
                    className="max-w-sm rounded-r-none h-9"
                    placeholder="CODE"
                  />
                </FormControl>
                <Button
                  className="rounded-l-none h-9"
                  disabled={fetching}
                  type="submit"
                >
                  <Loader className={fetching ? 'animate-spin' : 'hidden'} />
                  Submit
                </Button>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <MutationError error={error} />

        <div />
      </form>
    </Form>
  );
};

export { StartMembershipRequest };
