import { graphql } from '@/gql';
import { LivestreamStatus } from '@/gql/graphql';
import { Video } from 'lucide-react';
import ReactHlsPlayer from 'react-hls-video-player';
import { useQuery } from 'urql';

const IncidentLivestreamGql = graphql(`
  query IncidentLivestreamGql($id: String!) {
    livestream(id: $id) {
      id
      status
      streamingUrl
      url
    }
  }
`);

type Props = {
  readonly hideEmpty?: boolean;
  readonly id?: string | null;
};

const IncidentLivestream = ({ hideEmpty, id }: Props) => {
  const [{ data }] = useQuery({
    pause: !id,
    query: IncidentLivestreamGql,
    variables: {
      id: id ?? '',
    },
  });

  if (!id) {
    return null;
  }

  if (!data?.livestream) {
    return hideEmpty ? null : 'No Livestream data found';
  }

  if (
    hideEmpty &&
    data.livestream.status === LivestreamStatus.Ended &&
    !data.livestream.url &&
    !data.livestream.streamingUrl
  ) {
    return null;
  }

  return (
    <div className="space-y-1">
      {data.livestream.status === LivestreamStatus.Ended ? (
        data.livestream.url ? (
          <video
            className="h-full w-full max-h-48 rounded-lg"
            controls
          >
            <track kind="captions" />
            <source
              src={data.livestream.url}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        ) : data.livestream.streamingUrl ? (
          <ReactHlsPlayer
            autoPlay={false}
            className="h-full w-full max-h-48 rounded-lg"
            controls
            height="auto"
            hlsConfig={{
              maxLiveSyncPlaybackRate: 1.2,
              startPosition: -1,
            }}
            src={data.livestream.streamingUrl}
            width="100%"
          />
        ) : (
          <div className="flex items-center rounded-xl border px-3 w-full bg-muted/30">
            <Video className="h-10 w-10" />
            <span className="ml-4 italic">
              No video provided from livestream
            </span>
          </div>
        )
      ) : data.livestream.streamingUrl ? (
        <ReactHlsPlayer
          autoPlay={false}
          className="h-full w-full max-h-48 rounded-lg"
          controls
          height="auto"
          hlsConfig={{
            maxLiveSyncPlaybackRate: 1.2,
            startPosition: -1,
          }}
          src={data.livestream.streamingUrl}
          width="100%"
        />
      ) : (
        <div className="flex items-center rounded-xl border px-3 w-full bg-muted/30">
          <Video className="h-10 w-10" />
          <span className="animate-pulse ml-4 italic">
            Waiting for stream to start...
          </span>
        </div>
      )}
    </div>
  );
};

export { IncidentLivestream };
