import { EditGroupsDialog } from './EditGroupsDialog';
import { Datetime } from '@/components/Datetime';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DataList,
  DataListItem,
  DataListLabel,
  DataListValue,
} from '@/components/ui/data-list';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  type EmergencyContact,
  type Group,
  type Member,
  MemberRole,
} from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { fmtPhoneNumber } from '@/utils';
import { Eye, Info } from 'lucide-react';
import { Link } from 'react-router-dom';

type Props = {
  readonly allGroups: Array<Pick<Group, 'id' | 'name'>>;
  readonly fetching?: boolean;
  readonly member?:
    | (Pick<
        Member,
        | 'id'
        | 'avatarUrl'
        | 'fullName'
        | 'displayName'
        | 'emailAddress'
        | 'timezone'
        | 'phoneNumber'
        | 'isEnabled'
        | 'organizationRole'
        | 'createdAt'
      > & {
        emergencyContacts?: {
          nodes: Array<
            Pick<EmergencyContact, 'id' | 'phoneNumber' | 'description'>
          >;
        } | null;
        groups?: {
          nodes: Array<Pick<Group, 'id' | 'name'>>;
        } | null;
        metadata: Array<{
          id: string;
          isPrivate: boolean;
          name: string;
          value: string;
        }>;
      })
    | null;
};

// eslint-disable-next-line complexity
const MemberDetail = ({ allGroups, fetching, member }: Props) => {
  const isAdmin = useAppStore((store) => store.activeMembership?.isAdmin);

  if (!member) {
    return null;
  }

  const roleDetails: Record<MemberRole, string> = {
    [MemberRole.Visualc3]: 'A SeeItSendIt system administrator.',
    [MemberRole.ExternalMonitoringAgent]: 'An external application.',
    [MemberRole.Owner]:
      'Owners have access to the entire organization. They can edit and manage members, as well as all organization settings, such as security and billing settings.',
    [MemberRole.Administrator]:
      'Admins have access to the entire organization. They can edit and manage members, as well as all organization settings, such as security and billing settings.',
    [MemberRole.Supervisor]:
      "A supervisor is a member who is able to supervise other members, including viewing their GuardMe sessions, incidents, and check in's.",
    [MemberRole.Member]:
      "A member is an user who is a part of your organization. They are able to start GuardMe  sessions, report incidents, and submit check in's.",
  };

  return (
    <div className="space-y-4">
      <section id="member-profile">
        <h2 className="text-base font-semibold">Member details</h2>

        <DataList
          className="py-2 space-y-3 text-sm"
          orientation="vertical"
        >
          <div className="grid grid-cols-2">
            <DataListItem>
              <DataListLabel>Joined</DataListLabel>
              <DataListValue>
                {fetching ? (
                  <Skeleton className="w-32 h-5" />
                ) : (
                  <Datetime
                    datetime={member.createdAt}
                    format="MMMM dd, yyyy"
                  />
                )}
              </DataListValue>
            </DataListItem>
            <DataListItem>
              <DataListLabel>Status</DataListLabel>
              <DataListValue>
                {fetching ? (
                  <Skeleton className="w-32 h-5" />
                ) : member.isEnabled ? (
                  <Badge variant="default">Enabled</Badge>
                ) : (
                  <Badge variant="secondary">Disabled</Badge>
                )}
              </DataListValue>
            </DataListItem>
          </div>
          <div className="grid grid-cols-2">
            <DataListItem>
              <DataListLabel>Role</DataListLabel>
              <DataListValue className="space-y-1">
                {fetching ? (
                  <Skeleton className="w-32 h-5" />
                ) : (
                  <div className="flex items-center gap-1">
                    {member?.organizationRole === MemberRole.Owner && 'Owner'}
                    {member?.organizationRole === MemberRole.Administrator &&
                      'Admin'}
                    {member?.organizationRole === MemberRole.Supervisor &&
                      'Supervisor'}
                    {member?.organizationRole === MemberRole.Member && 'Member'}
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          className="rounded-full p-0 m-0 h-5 w-5"
                          size="icon"
                          variant="link"
                        >
                          <Info />
                          <span className="sr-only">Role details</span>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent
                        align="start"
                        className="w-80"
                        side="bottom"
                      >
                        <p className="text-sm">
                          {roleDetails[member.organizationRole]}
                        </p>
                      </PopoverContent>
                    </Popover>
                  </div>
                )}
              </DataListValue>
            </DataListItem>
            <DataListItem>
              <DataListLabel>Timezone</DataListLabel>
              <DataListValue>
                {fetching ? <Skeleton className="w-32 h-5" /> : member.timezone}
              </DataListValue>
            </DataListItem>
          </div>
          <DataListItem>
            <DataListLabel>Email Address</DataListLabel>
            <DataListValue>
              {fetching ? (
                <Skeleton className="w-32 h-5" />
              ) : (
                member.emailAddress ?? '---'
              )}
            </DataListValue>
          </DataListItem>
          <DataListItem>
            <DataListLabel>Phone Number</DataListLabel>
            <DataListValue>
              {fetching ? (
                <Skeleton className="w-32 h-5" />
              ) : (
                fmtPhoneNumber(member.phoneNumber)
              )}
            </DataListValue>
          </DataListItem>
        </DataList>
      </section>

      <section id="member-metadata">
        <h2 className="text-base font-semibold">Custom metadata</h2>

        <DataList
          className="py-2 space-y-2 text-sm"
          orientation="vertical"
        >
          {fetching ? (
            <div className="grid grid-cols-2">
              <div className="space-y-1">
                <Skeleton className="w-32 h-4" />
                <Skeleton className="w-32 h-4" />
              </div>
              <div className="space-y-1">
                <Skeleton className="w-32 h-4" />
                <Skeleton className="w-32 h-4" />
              </div>
            </div>
          ) : Object.keys(member.metadata).length === 0 ? (
            <span className="italic">No custom metadata defined.</span>
          ) : (
            <div className="grid grid-cols-2">
              {member.metadata.map((item) => (
                <DataListItem key={item.id}>
                  <DataListLabel className="flex items-center gap-1">
                    {item.name}
                    {item.isPrivate && (
                      <Tooltip>
                        <TooltipTrigger>
                          <Eye className="size-4 text-info-foreground" />
                        </TooltipTrigger>
                        <TooltipContent side="right">
                          <p>Private field</p>
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </DataListLabel>
                  <DataListValue>{item.value || '---'}</DataListValue>
                </DataListItem>
              ))}
            </div>
          )}
        </DataList>
      </section>

      <section id="member-groups">
        <div className="flex items-baseline mb-1">
          <h2 className="text-base font-semibold">Assigned Groups</h2>
          {isAdmin && (
            <div className="ml-1">
              <EditGroupsDialog
                allGroups={allGroups}
                currentGroupIds={
                  member.groups?.nodes.map((item) => item.id) ?? []
                }
                memberId={member.id}
              >
                <Button
                  className="px-1"
                  size="sm"
                  variant="link"
                >
                  manage
                </Button>
              </EditGroupsDialog>
            </div>
          )}
        </div>

        {fetching ? (
          <div className="flex flex-wrap items-center gap-2">
            <Skeleton className="w-32 h-9" /> <Skeleton className="w-32 h-9" />
          </div>
        ) : (member.groups?.nodes.length ?? 0) === 0 ? (
          <span className="italic">No assigned groups.</span>
        ) : (
          <ol className="flex flex-wrap items-center gap-2">
            {member.groups?.nodes
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((item) => (
                <li key={item.name}>
                  <Link to={`/members/groups/${item.id}`}>
                    <Button
                      className="rounded-full pl-1 pr-4"
                      variant="outline"
                    >
                      <Avatar className="size-6 shrink-0">
                        <AvatarFallback>
                          {item.name.slice(0, 1).toUpperCase()}
                        </AvatarFallback>
                      </Avatar>

                      {item.name}
                    </Button>
                  </Link>
                </li>
              ))}
          </ol>
        )}
      </section>

      <section id="member-emergency-contacts">
        <h2 className="text-base font-semibold">Emergency Contacts</h2>

        <DataList
          className="py-2 space-y-2 text-sm"
          orientation="horizontal"
        >
          {fetching ? (
            <div className="grid grid-cols-2">
              <Skeleton className="w-32 h-5" />
              <Skeleton className="w-32 h-5" />
            </div>
          ) : (member.emergencyContacts?.nodes.length ?? 0) === 0 ? (
            <span className="italic">No emergency contacts.</span>
          ) : (
            member.emergencyContacts?.nodes.map((item) => (
              <DataListItem
                className="grid grid-cols-2"
                key={item.id}
              >
                <DataListLabel>{item.description}</DataListLabel>
                <DataListValue>
                  {fmtPhoneNumber(item.phoneNumber)}
                </DataListValue>
              </DataListItem>
            ))
          )}
        </DataList>
      </section>
    </div>
  );
};

export { MemberDetail };
