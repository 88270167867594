import { incidentsDataTableQueryStates } from '@/features/Incidents/hooks/useIncidentsSearchParameters';
import { useQueryStates } from 'nuqs';

// set default page size to 10
const parsers = {
  ...incidentsDataTableQueryStates.parsers,
  pageSize: incidentsDataTableQueryStates.parsers.pageSize.withDefault(10),
};

const useMemberIncidentsSearchParameters = () =>
  useQueryStates(parsers, {
    urlKeys: incidentsDataTableQueryStates.urlKeys,
  });

export { useMemberIncidentsSearchParameters };
