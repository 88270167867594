import { CurrentOrganization } from './CurrentOrganization';
import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar';
import { ErrorBoundaryFallback } from '@/features/Core';
import { AppSidebar } from '@/features/Core/components/AppSidebar';
import { useLocalStorage } from '@/hooks';
import { ErrorBoundary } from '@sentry/react';
import { Outlet, useLocation } from 'react-router-dom';

const ApplicationLayout = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useLocalStorage('copper:sidebar:state', 'open');

  return (
    <CurrentOrganization>
      <SidebarProvider
        onOpenChange={() => setOpen(open === 'open' ? 'closed' : 'open')}
        open={open === 'open'}
      >
        <AppSidebar />
        <SidebarInset>
          <div className="px-4 pb-8 md:px-8 flex-1 min-h-[100vh] md:min-h-min">
            <ErrorBoundary
              fallback={ErrorBoundaryFallback}
              key={pathname}
            >
              <Outlet />
            </ErrorBoundary>
          </div>
        </SidebarInset>
      </SidebarProvider>
    </CurrentOrganization>
  );
};

export { ApplicationLayout };
