import { CheckInDetailsMap } from '../components/CheckInDetailsMap';
import { PlaceToggleGroup } from '../components/PlaceToggleGroup';
import { ClientError } from '@/components';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Datetime } from '@/components/Datetime';
import { Header } from '@/components/header';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import {
  DataList,
  DataListItem,
  DataListLabel,
  DataListValue,
} from '@/components/ui/data-list';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { ChevronRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const CheckInGql = graphql(`
  query CheckInGql($id: String!) {
    checkIn(id: $id) {
      id
      shortId
      createdAt
      coordinates {
        x
        y
      }
      places {
        nodes {
          id
          name
          spatialData {
            geojson
          }
          radius
          center {
            longitude
            latitude
          }
        }
      }
      geocode {
        id
        address
        plusCode
      }
      memberId
      member {
        id
        fullName
        displayName
        avatarUrl
        lastActiveAt
      }
      organization {
        id
        places {
          nodes {
            id
            name
            spatialData {
              geojson
            }
            radius
            center {
              longitude
              latitude
            }
          }
        }
      }
    }
  }
`);

const CheckIn = () => {
  const { id } = useParams() as { id: string };

  const [places, setPlaces] = useState<string[]>([]);

  const [{ data, fetching }] = useQuery({
    query: CheckInGql,
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data?.checkIn?.places.nodes) {
      setPlaces(data?.checkIn?.places.nodes.map((item) => item.id));
    }
  }, [data]);

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/check-in">Check In</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {data?.checkIn?.shortId} Details
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      />

      <div className="grid grid-cols-3 gap-x-8">
        <div className="flex flex-col gap-4">
          <div>
            {fetching ? (
              <Skeleton className="size-24 rounded-full" />
            ) : (
              <Avatar className="size-24 mb-2">
                <AvatarImage
                  alt="member"
                  src={data?.checkIn?.member?.avatarUrl ?? undefined}
                />
                <AvatarFallback>
                  {(
                    data?.checkIn?.member?.displayName ??
                    data?.checkIn?.member?.fullName
                  )?.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
            )}

            <div className="flex items-baseline gap-2">
              {fetching ? (
                <Skeleton className="h-10 w-64" />
              ) : (
                <>
                  <h1 className="h-10 text-2xl font-bold">
                    {data?.checkIn?.member?.displayName ??
                      data?.checkIn?.member?.fullName}
                  </h1>

                  <Link to={`/members/${data?.checkIn?.member?.id}`}>
                    <Button
                      className="px-0"
                      size="sm"
                      variant="link"
                    >
                      info
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </div>

          <section aria-labelledby="check-in-details">
            <Collapsible
              className="group/collapsible"
              defaultOpen
            >
              <div className="flex items-center">
                <CollapsibleTrigger asChild>
                  <Button
                    className="-ml-2 h-6 w-6"
                    size="icon"
                    variant="ghost"
                  >
                    <ChevronRight className="transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    <span className="sr-only">Toggle</span>
                  </Button>
                </CollapsibleTrigger>
                <h2
                  className="text-base font-semibold"
                  id="check-in-details"
                >
                  Check In details
                </h2>
              </div>
              <CollapsibleContent className="py-2 space-y-2 transition transition-duration-300 ease-in-out">
                <DataList
                  className="space-y-2 text-sm"
                  orientation="horizontal"
                >
                  <DataListItem>
                    <DataListLabel>ID</DataListLabel>
                    <DataListValue className="ml-auto">
                      {fetching ? (
                        <Skeleton className="h-6 w-32" />
                      ) : (
                        data?.checkIn?.shortId
                      )}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListLabel>Reported</DataListLabel>
                    <DataListValue className="ml-auto">
                      {fetching ? (
                        <Skeleton className="h-6 w-32" />
                      ) : (
                        <Datetime datetime={data?.checkIn?.createdAt} />
                      )}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListLabel>Latitude</DataListLabel>
                    <DataListValue className="ml-auto">
                      {fetching ? (
                        <Skeleton className="h-6 w-32" />
                      ) : (
                        data?.checkIn?.coordinates.y || '---'
                      )}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListLabel>Longitude</DataListLabel>
                    <DataListValue className="ml-auto">
                      {fetching ? (
                        <Skeleton className="h-6 w-32" />
                      ) : (
                        data?.checkIn?.coordinates.x || '---'
                      )}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListLabel>Google Plus Code</DataListLabel>
                    <DataListValue className="ml-auto">
                      {fetching ? (
                        <Skeleton className="h-6 w-32" />
                      ) : (
                        data?.checkIn?.geocode?.plusCode || '---'
                      )}
                    </DataListValue>
                  </DataListItem>
                </DataList>

                <DataList
                  className="space-y-2 text-sm"
                  orientation="vertical"
                >
                  <DataListItem className="space-y-1">
                    <DataListLabel>Approximate address</DataListLabel>
                    <DataListValue>
                      {fetching ? (
                        <Skeleton className="h-6 w-32" />
                      ) : (
                        data?.checkIn?.geocode?.address || 'No Address Found'
                      )}
                    </DataListValue>
                  </DataListItem>
                </DataList>
              </CollapsibleContent>
            </Collapsible>
          </section>

          <section aria-labelledby="related-places">
            <Collapsible
              className="group/collapsible"
              defaultOpen
            >
              <div className="flex items-center">
                <CollapsibleTrigger asChild>
                  <Button
                    className="-ml-2 h-6 w-6"
                    size="icon"
                    variant="ghost"
                  >
                    <ChevronRight className="transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    <span className="sr-only">Toggle</span>
                  </Button>
                </CollapsibleTrigger>
                <h2
                  className="text-base font-semibold"
                  id="related-places"
                >
                  Related Places
                </h2>
              </div>
              <CollapsibleContent className="space-y-2 transition transition-duration-300 ease-in-out">
                <PlaceToggleGroup
                  emptyTitle="No related places."
                  places={data?.checkIn?.places.nodes}
                  selected={places}
                  setSelected={setPlaces}
                />
              </CollapsibleContent>
            </Collapsible>
          </section>

          <section aria-labelledby="all-places">
            <Collapsible className="group/collapsible">
              <div className="flex items-center">
                <CollapsibleTrigger asChild>
                  <Button
                    className="-ml-2 h-6 w-6"
                    size="icon"
                    variant="ghost"
                  >
                    <ChevronRight className="transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    <span className="sr-only">Toggle</span>
                  </Button>
                </CollapsibleTrigger>
                <h2
                  className="text-base font-semibold"
                  id="all-places"
                >
                  All Places
                </h2>
              </div>
              <CollapsibleContent className="space-y-2 transition transition-duration-300 ease-in-out">
                <PlaceToggleGroup
                  places={data?.checkIn?.organization?.places.nodes.filter(
                    (x) =>
                      !data.checkIn?.places.nodes
                        .map((place) => place.id)
                        .includes(x.id),
                  )}
                  selected={places}
                  setSelected={setPlaces}
                />
              </CollapsibleContent>
            </Collapsible>
          </section>
        </div>
        {/* px-4 md:px-6 // 16px 32px */}
        <div className="col-span-2 h-[calc(100vh-144px)]">
          {fetching ? (
            <Skeleton className="h-full w-full" />
          ) : (
            <>
              {data?.checkIn ? (
                <CheckInDetailsMap
                  className="h-[calc(100vh-144px)]"
                  coordinates={data.checkIn.coordinates}
                  member={data.checkIn.member}
                  places={data.checkIn.organization?.places.nodes.filter(
                    (item) => places.includes(item.id),
                  )}
                />
              ) : (
                <ClientError
                  code={404}
                  message="Check In does not exist"
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { CheckIn };
