import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { type Member } from '@/gql/graphql';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { type DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import { Loader } from 'lucide-react';
import { type ReactElement, useEffect, useState } from 'react';
import { type ButtonProps } from 'react-day-picker';
import { useForm } from 'react-hook-form';
import { type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const EditDisplayNameDialogGql = graphql(`
  mutation EditDisplayNameDialogGql($memberId: String!, $displayName: String!) {
    updateMemberDisplayName(
      input: { memberId: $memberId, displayName: $displayName }
    ) {
      member {
        id
        displayName
        fullName
      }
    }
  }
`);

const schema = object({
  displayName: string(),
});

type EditDisplayNameDialogProps = {
  readonly children: ReactElement<ButtonProps | DropdownMenuItemProps>;
  readonly member?: Pick<Member, 'id' | 'displayName' | 'fullName'> | null;
};

const EditDisplayNameDialog = ({
  children,
  member,
}: EditDisplayNameDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, updateDisplayName] = useMutation(
    EditDisplayNameDialogGql,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      displayName: member?.displayName ?? '',
    },
    resolver: superstructResolver(schema),
  });

  useEffect(() => {
    if (member) {
      form.reset({ displayName: member.displayName ?? '' });
    }
  }, [form, member]);

  const onCancel = () => {
    setOpen(false);
    form.reset();
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    if (member) {
      const response = await updateDisplayName({
        displayName: values.displayName,
        memberId: member?.id,
      });

      if (!response.error) {
        setOpen(false);
        form.reset(values);
      }
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Member Display Name</DialogTitle>
          <DialogDescription>
            This name will be used in lieu of the members full name.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="displayName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Display Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={member?.fullName}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { EditDisplayNameDialog };
