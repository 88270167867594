import { PhoneNumberInput } from '@/components/PhoneNumberInput';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { AlertTriangle, Loader, PhoneForwarded, Siren } from 'lucide-react';
import { type ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type Infer, object, refine, string } from 'superstruct';
import { useMutation } from 'urql';

const GuardMePanicAsAdminDocumentQuery = graphql(`
  mutation GuardMePanicAsAdmin(
    $guardmeId: String!
    $useOperatorFlow: Boolean!
    $operatorPhoneNumber: String!
  ) {
    updateGuardmePanicAsAdmin(
      input: {
        guardmeId: $guardmeId
        useOperatorFlow: $useOperatorFlow
        operatorPhoneNumber: $operatorPhoneNumber
      }
    ) {
      guardme {
        id
        endsAt
        isExpired
        isSafe
        updatedAt
      }
    }
  }
`);

const phoneNumber = () =>
  refine(string(), 'PhoneNumber', (value) => {
    if (/^\+?[1-9]\d{1,10}$/u.test(value)) {
      return true;
    }

    return 'Expected valid phone number';
  });

const schema = object({
  operatorPhoneNumber: phoneNumber(),
  useOperatorFlow: string(),
});

type GuardMePanicDialogProps = {
  readonly children?: ReactNode;
  readonly guardmeId: string;
  readonly organization?: {
    settingIsRapidsosOperatorFlowEnabled: boolean;
    settingIsRapidsosPanicButtonFlowEnabled: boolean;
    settingRapidsosSocPhoneNumber: string;
  } | null;
};

const GuardMePanicDialog = ({
  children,
  guardmeId,
  organization,
}: GuardMePanicDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, panic] = useMutation(
    GuardMePanicAsAdminDocumentQuery,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      operatorPhoneNumber: organization?.settingRapidsosSocPhoneNumber ?? '',
      useOperatorFlow: 'default',
    },
    resolver: superstructResolver(schema),
  });

  if (!organization) {
    return null;
  }

  const handleCancel = () => {
    form.reset();
    setOpen(false);
  };

  if (!organization.settingIsRapidsosOperatorFlowEnabled) {
    return (
      <AlertDialog
        onOpenChange={setOpen}
        open={open}
      >
        {children && (
          <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
        )}
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Initiate Panic?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. You should only initaite panic in
              this GuardMe session with consent from the member being monitored.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <MutationError error={error} />

          <AlertDialogFooter>
            <AlertDialogCancel
              disabled={fetching}
              onClick={handleCancel}
            >
              Cancel
            </AlertDialogCancel>
            <Button
              disabled={fetching}
              onClick={async () => {
                const response = await panic({
                  guardmeId,
                  operatorPhoneNumber: '',
                  useOperatorFlow: false,
                });

                if (!response.error) {
                  setOpen(false);
                }
              }}
              variant="destructive"
            >
              {fetching ? (
                <Loader className="h-6 w-6 animate-spin mr-2" />
              ) : (
                <AlertTriangle className="h-6 w-6 mr-2" />
              )}
              Panic
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  const onSubmit = async (data: Infer<typeof schema>) => {
    const response = await panic({
      guardmeId,
      operatorPhoneNumber: data.operatorPhoneNumber,
      useOperatorFlow: data.useOperatorFlow === 'operator',
    });

    if (!response.error) {
      setOpen(false);
    }
  };

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      {children && <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Select Panic Workflow</AlertDialogTitle>
          <AlertDialogDescription>
            Please select the workflow you wish to initiate for this GuardMe
            Session. The Default Organization designated Operator contact number
            is prefilled, but can be overridden.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Form {...form}>
          <form
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="useOperatorFlow"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel>Use workflow...</FormLabel>
                  <FormMessage />
                  <RadioGroup
                    className="grid grid-cols-2 gap-4"
                    defaultValue={field.value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onValueChange={field.onChange}
                  >
                    <FormItem>
                      <FormLabel>
                        <FormControl>
                          <RadioGroupItem
                            className="sr-only"
                            value="default"
                          />
                        </FormControl>
                        <div
                          className={cn(
                            'flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent',
                            field.value === 'default' && 'border-primary',
                          )}
                        >
                          <Siren className="mb-3 h-6 w-6" />
                          Default Flow
                        </div>
                      </FormLabel>
                    </FormItem>

                    <FormItem>
                      <FormLabel>
                        <FormControl>
                          <RadioGroupItem
                            className="sr-only"
                            value="operator"
                          />
                        </FormControl>
                        <div
                          className={cn(
                            'flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent',
                            field.value === 'operator' && 'border-primary',
                          )}
                        >
                          <PhoneForwarded className="mb-3 h-6 w-6" />
                          Operator Flow
                        </div>
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="operatorPhoneNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Operator Contact Number</FormLabel>
                  <FormControl>
                    <PhoneNumberInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <MutationError error={error} />

            <AlertDialogFooter>
              <AlertDialogCancel
                disabled={fetching}
                onClick={handleCancel}
                type="reset"
              >
                Cancel
              </AlertDialogCancel>
              <Button
                disabled={fetching}
                type="submit"
                variant="destructive"
              >
                {fetching ? (
                  <Loader className="h-6 w-6 animate-spin mr-2" />
                ) : (
                  <AlertTriangle className="h-6 w-6 mr-2" />
                )}
                Panic
              </Button>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { GuardMePanicDialog, type GuardMePanicDialogProps };
