import { MapSettingsDialogForm } from '../components/MapSettingsDialogForm';
import { PlaceCreateSheet } from '../components/PlaceCreateSheet';
import { PlaceList } from '../components/PlaceList';
import {
  AnnotatedContent,
  AnnotatedHeader,
  AnnotatedLayout,
  AnnotatedSection,
  AnnotatedSeparator,
} from '@/components/annotated';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { generateStaticGoogleMapUrl } from '@/lib/staticGoogleMaps';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Plus } from 'lucide-react';
import { useMemo } from 'react';
import { useQuery } from 'urql';

const PlacesGql = graphql(`
  query PlacesGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      defaultMapCenter {
        longitude
        latitude
      }
      defaultMapZoom
      places {
        nodes {
          id
          name
          description
          updatedAt
          createdAt
          radius
          center {
            longitude
            latitude
            srid
          }
          spatialData {
            geojson
            srid
          }
          encodedPolyline
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`);

const Places = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, fetching }] = useQuery({
    query: PlacesGql,
    requestPolicy: 'cache-and-network',
    variables: {
      organizationId,
    },
  });

  const defaultMapUrl = useMemo(() => {
    if (data?.organization?.defaultMapCenter) {
      return generateStaticGoogleMapUrl({
        encodedPolyline: null,
        height: 256,
        latitude: data.organization.defaultMapCenter.latitude,
        longitude: data.organization.defaultMapCenter.longitude,
        width: 512,
        zoom: data.organization.defaultMapZoom,
      });
    }

    return null;
  }, [data?.organization]);

  return (
    <AnnotatedLayout>
      <AnnotatedSection aria-labelledby="default-map">
        <AnnotatedHeader
          description=" Configure the default map used by your organization."
          title="Default Map"
          titleId="default-map"
        >
          <div>
            <MapSettingsDialogForm
              defaultMapCenter={{
                lat: data?.organization?.defaultMapCenter.latitude || 0,
                lng: data?.organization?.defaultMapCenter.longitude || 0,
              }}
              defaultMapZoom={data?.organization?.defaultMapZoom || 7}
              organizationId={organizationId}
            >
              <Button disabled={fetching}>
                <Plus />
                Edit Map
              </Button>
            </MapSettingsDialogForm>
          </div>
        </AnnotatedHeader>
        <AnnotatedContent>
          {fetching || !data?.organization ? (
            <Skeleton className="h-64 w-128" />
          ) : (
            <img
              alt="Default Map"
              className="h-64 w-128"
              src={defaultMapUrl || undefined}
            />
          )}
        </AnnotatedContent>
      </AnnotatedSection>

      <AnnotatedSeparator />

      <AnnotatedSection aria-labelledby="custom-places">
        <AnnotatedHeader
          description="Places allow your organization to geofence and name a particular area. These can be configured to identify and tag key locations for a Check In, Incident, or GuardMe."
          title="Custom Places"
          titleId="custom-places"
        >
          <div>
            <PlaceCreateSheet>
              <Button>
                <Plus /> Add Place
              </Button>
            </PlaceCreateSheet>
          </div>
        </AnnotatedHeader>
        <AnnotatedContent>
          <PlaceList
            fetching={fetching}
            places={data?.organization?.places.nodes}
          />
        </AnnotatedContent>
      </AnnotatedSection>
    </AnnotatedLayout>
  );
};

export { Places };
