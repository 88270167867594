import { useDisableMember } from './hooks/useDisableMember';
import { useEnableMember } from './hooks/useEnableMember';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button, type ButtonProps } from '@/components/ui/button';
import { MutationError } from '@/features/Core';
import { toast } from '@/lib/toast';
import { type DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import { Loader } from 'lucide-react';
import { type ReactElement, useState } from 'react';

type EnableMemberDialogProps = {
  readonly children: ReactElement<ButtonProps | DropdownMenuItemProps>;
  readonly memberId: string;
};

const EnableMemberDialog = ({
  children,
  memberId,
}: EnableMemberDialogProps) => {
  const [open, setOpen] = useState(false);

  const [{ error, fetching }, enableMember] = useEnableMember();
  const [, disableMember] = useDisableMember();

  const handleEnableMember = async () => {
    const response = await enableMember({ memberId });

    if (!response.error) {
      setOpen(false);
      toast('Member has been enabled', {
        action: {
          label: 'Undo',
          onClick: () => {
            disableMember({ memberId });
          },
        },
      });
    }
  };

  return (
    <AlertDialog
      onOpenChange={setOpen}
      open={open}
    >
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Enable Member?</AlertDialogTitle>
          <AlertDialogDescription>
            This will grant access to this organization to the member in
            question and may impact your payment.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <MutationError error={error} />

        <AlertDialogFooter>
          <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
          <Button
            disabled={fetching}
            onClick={handleEnableMember}
          >
            {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
            Enable Member
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { EnableMemberDialog };
