import { Button } from './ui/button';
import { useSidebar } from './ui/sidebar';
import { cn } from '@/lib/utils';
import { Menu } from 'lucide-react';
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type ReactElement,
} from 'react';

type HeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  readonly actions?: ReactElement<ComponentPropsWithoutRef<'div'>>;
  readonly breadcrumbs?: ReactElement<
    ComponentPropsWithoutRef<'nav'> & {
      separator?: React.ReactNode;
    }
  >;
};

const Header = ({ actions, breadcrumbs, className, ...props }: HeaderProps) => {
  const { toggleSidebar } = useSidebar();

  return (
    <header
      className={cn('pt-3 pb-4', className)}
      {...props}
    >
      <div className="flex items-center h-8 mb-3">
        <div className="md:hidden">
          <Button
            className="[&_svg]:size-5 mr-2"
            data-sidebar="trigger"
            onClick={() => {
              toggleSidebar();
            }}
            size="icon"
            variant="ghost"
          >
            <Menu />
            <span className="sr-only">Toggle Sidebar</span>
          </Button>
        </div>
        {(Boolean(breadcrumbs) || Boolean(actions)) && (
          <>
            {breadcrumbs}
            <div className="ml-auto">{actions}</div>
          </>
        )}
      </div>
      {props.children}
    </header>
  );
};

type HeaderTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

const HeaderTitle = forwardRef<HTMLHeadingElement, HeaderTitleProps>(
  ({ children, className, ...props }, ref) => (
    <h1
      className={cn(
        'text-3xl lg:text-4xl font-extrabold tracking-tight',
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
    </h1>
  ),
);
HeaderTitle.displayName = 'HeaderTitle';

type HeaderSubtitleProps = React.HTMLAttributes<HTMLParagraphElement>;

const HeaderSubtitle = forwardRef<HTMLParagraphElement, HeaderSubtitleProps>(
  ({ children, className, ...props }, ref) => (
    <p
      className={cn('text-base text-muted-foreground', className)}
      ref={ref}
      {...props}
    >
      {children}
    </p>
  ),
);
HeaderSubtitle.displayName = 'HeaderSubtitle';

export { Header, HeaderSubtitle, HeaderTitle };
