import { NewIncidentFormInputBuilder } from './NewIncidentFormInputBuilder';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import {
  type DataSchema,
  incidentTypeDefaultValues,
  incidentTypeSchema,
  type UISchema,
} from '@/lib/incidentType';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader, X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { type Infer } from 'superstruct';
import { useMutation } from 'urql';

const UpdateIncidentDetailsGql = graphql(`
  mutation UpdateIncidentDetailsGql($input: UpdateIncidentDetailsInput!) {
    updateIncidentDetails(input: $input) {
      incident {
        id
        additionalData
        subject
        description
        updatedAt
        incidentUpdates {
          nodes {
            id
            type
            message
            createdAt
            isSystemMessage
            performedByMember {
              id
              fullName
              displayName
              avatarUrl
            }
            attachments {
              nodes {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`);

type IncidentDetailEditFormProps = {
  readonly additionalData: Object;
  readonly dataSchema: DataSchema;
  readonly description: string;
  readonly incidentId: string;
  readonly incidentTypeIcon: string;
  readonly incidentTypeName: string;
  readonly setIsEditing: (value: boolean) => void;
  readonly subject: string;
  readonly uiSchema: UISchema;
};

const IncidentDetailEditForm = ({
  additionalData,
  dataSchema,
  description,
  incidentId,
  incidentTypeIcon,
  incidentTypeName,
  setIsEditing,
  subject,
  uiSchema,
}: IncidentDetailEditFormProps) => {
  const [{ error, fetching }, updateIncident] = useMutation(
    UpdateIncidentDetailsGql,
  );

  const schema = incidentTypeSchema(dataSchema);
  const defaultValues = incidentTypeDefaultValues(dataSchema);
  defaultValues['description'] = description;
  defaultValues['subject'] = subject;
  if (schema.schema.additionalData) {
    defaultValues['additionalData'] = additionalData;
  }

  const form = useForm<Infer<typeof schema>>({
    defaultValues,
    resolver: superstructResolver(schema),
  });

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateIncident({
      input: {
        additionalData: values.additionalData ? values.additionalData : {},
        description: values.description,
        incidentId,
        subject: values.subject,
      },
    });

    if (!response.error) {
      setIsEditing(false);
    }
  };

  const handleReset = () => {
    setIsEditing(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex items-center mb-4">
          <div className="flex items-center gap-1 font-semibold">
            <img
              alt={incidentTypeName}
              className="h-4 w-4"
              src={`data:image/svg+xml;base64,${incidentTypeIcon}`}
            />
            {incidentTypeName} Incident Data
          </div>

          <div className="ml-auto flex items-center gap-2">
            <Button
              disabled={fetching}
              onClick={() => handleReset()}
              size="sm"
              type="reset"
              variant="outline"
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Cancel</span>
            </Button>
            <Button
              disabled={fetching}
              size="sm"
              type="submit"
            >
              {fetching && <Loader className="h-4 w-4 animate-spin mr-1" />}
              Save
            </Button>
          </div>
        </div>

        <NewIncidentFormInputBuilder
          control={form.control}
          dataSchema={dataSchema}
          schema={uiSchema}
        />

        <MutationError error={error} />
      </form>
    </Form>
  );
};

export { IncidentDetailEditForm };
