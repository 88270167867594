import { Empty } from '@/components';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { MemberRole } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { array, type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const EditGroupsDialogGql = graphql(`
  mutation EditGroupsDialogGql($memberId: String!, $groupIds: [String]!) {
    updateMemberGroups(input: { memberId: $memberId, groupIds: $groupIds }) {
      member {
        id
        groups {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`);

const schema = object({
  groupIds: array(string()),
});

type EditGroupsDialogProps = {
  readonly allGroups: Array<{ id: string; name: string }>;
  readonly children: React.ReactElement<React.HTMLProps<HTMLButtonElement>>;
  readonly currentGroupIds: string[];
  readonly memberId: string;
};

const EditGroupsDialog = ({
  allGroups,
  children,
  currentGroupIds,
  memberId,
}: EditGroupsDialogProps) => {
  const organizationRole = useAppStore((state) => state.activeMembership?.role);

  const allowedRoles = [MemberRole.Owner, MemberRole.Administrator];

  const [open, setOpen] = useState(false);

  const [{ error, fetching }, updateGroups] = useMutation(EditGroupsDialogGql);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      groupIds: currentGroupIds,
    },
    resolver: superstructResolver(schema),
  });

  if (!organizationRole || !allowedRoles.includes(organizationRole)) {
    return null;
  }

  const onCancel = () => {
    setOpen(false);
    form.reset();
  };

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateGroups({
      groupIds: values.groupIds,
      memberId,
    });

    if (!response.error) {
      setOpen(false);
    }
  };

  return (
    <Dialog
      onOpenChange={setOpen}
      open={open}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Manage Assigned Groups</DialogTitle>
          <DialogDescription>Modify group membership.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            className="space-y-8"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            {allGroups.length === 0 ? (
              <Empty>
                <Link
                  className="underline"
                  to="/members/groups"
                >
                  Create a group
                </Link>
              </Empty>
            ) : (
              <FormItem>
                <FormField
                  control={form.control}
                  name="groupIds"
                  render={() => (
                    <FormItem>
                      <FormLabel className="mb-2">Select Groups</FormLabel>
                      {allGroups.map((item) => (
                        <FormField
                          control={form.control}
                          key={item.id}
                          name="groupIds"
                          render={({ field }) => {
                            return (
                              <FormItem
                                className="flex flex-row items-start space-x-3 space-y-0"
                                key={item.id}
                              >
                                <FormControl>
                                  <Checkbox
                                    checked={field.value?.includes(item.id)}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([
                                            ...field.value,
                                            item.id,
                                          ])
                                        : field.onChange(
                                            field.value?.filter(
                                              (value) => value !== item.id,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="font-normal">
                                  {item.name}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </FormItem>
                  )}
                />
              </FormItem>
            )}

            <MutationError error={error} />

            <DialogFooter>
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                disabled={fetching}
                type="submit"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export { EditGroupsDialog };
