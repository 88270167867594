import { graphql } from '@/gql';
import { useMutation } from 'urql';

const EnableMemberGql = graphql(`
  mutation EnableMemberGql($memberId: String!) {
    enableMember(input: { id: $memberId }) {
      member {
        id
        isEnabled
      }
    }
  }
`);

const useEnableMember = () => {
  return useMutation(EnableMemberGql);
};

export { useEnableMember };
