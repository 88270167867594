import { cn } from '@/lib/utils';
import { type LucideIcon } from 'lucide-react';
import { forwardRef } from 'react';

const EmptyState = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    readonly description?: string;
    readonly icon?: LucideIcon;
    readonly title: string;
  }
>(({ children, className, description, icon, title, ...props }, ref) => {
  const Icon = icon;
  return (
    <div
      className={cn(
        'flex h-full flex-col items-center justify-center gap-6 rounded-lg border px-8 py-12 sm:px-10 md:px-12',
        className,
      )}
      ref={ref}
      {...props}
    >
      {Icon && (
        <div className="flex size-12 items-center justify-center rounded-md border">
          <Icon className="size-6 shrink-0 text-muted-foreground" />
        </div>
      )}
      <div className="mx-auto flex max-w-sm flex-col gap-2 text-balance text-center">
        <p className="text-base font-semibold">{title}</p>
        {description && (
          <p className="text-sm text-muted-foreground">{description}</p>
        )}
      </div>
      {children}
    </div>
  );
});
EmptyState.displayName = 'EmptyState';

export { EmptyState };
