import { MutationError } from '../../Core';
import { EmptyState } from '@/components/empty-state';
import { PhoneNumberInput } from '@/components/PhoneNumberInput';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';
import { graphql } from '@/gql';
import { type Organization, type Plan } from '@/gql/graphql';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Zap } from 'lucide-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { boolean, type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const UpdateOrganizationRapidsosSettingsGql = graphql(`
  mutation UpdateOrganizationRapidsosSettingsGql(
    $organizationId: String!
    $isPanicButtonFlowEnabled: Boolean!
    $isRapidsosOperatorFlowEnabled: Boolean!
    $socPhoneNumber: String!
  ) {
    updateOrganizationRapidsosSettings(
      input: {
        organizationId: $organizationId
        isPanicButtonFlowEnabled: $isPanicButtonFlowEnabled
        isOperatorFlowEnabled: $isRapidsosOperatorFlowEnabled
        socPhoneNumber: $socPhoneNumber
      }
    ) {
      organization {
        settingIsRapidsosOperatorFlowEnabled
        settingIsRapidsosPanicButtonFlowEnabled
        settingRapidsosSocPhoneNumber
      }
    }
  }
`);

const schema = object({
  settingIsRapidsosOperatorFlowEnabled: boolean(),
  settingIsRapidsosPanicButtonFlowEnabled: boolean(),
  settingRapidsosSocPhoneNumber: string(),
});

type RapidSosIntegrationFormProps = {
  readonly fetching?: boolean;
  readonly plan?: Pick<Plan, 'id' | 'name' | 'featureRapidsos'> | null;
  readonly settings?: Pick<
    Organization,
    | 'id'
    | 'settingIsRapidsosOperatorFlowEnabled'
    | 'settingIsRapidsosPanicButtonFlowEnabled'
    | 'settingRapidsosSocPhoneNumber'
  > | null;
};

const RapidSosIntegrationForm = ({
  fetching = false,
  plan,
  settings,
}: RapidSosIntegrationFormProps) => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  const [{ error }, updateSettings] = useMutation(
    UpdateOrganizationRapidsosSettingsGql,
  );

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      settingIsRapidsosOperatorFlowEnabled:
        settings?.settingIsRapidsosOperatorFlowEnabled ?? false,
      settingIsRapidsosPanicButtonFlowEnabled:
        settings?.settingIsRapidsosPanicButtonFlowEnabled ?? false,
      settingRapidsosSocPhoneNumber:
        settings?.settingRapidsosSocPhoneNumber ?? '',
    },
    resolver: superstructResolver(schema),
  });

  const watchOperactorFlow = form.watch('settingIsRapidsosOperatorFlowEnabled');

  useEffect(() => {
    if (settings) {
      form.reset({
        settingIsRapidsosOperatorFlowEnabled:
          settings.settingIsRapidsosOperatorFlowEnabled,
        settingIsRapidsosPanicButtonFlowEnabled:
          settings.settingIsRapidsosPanicButtonFlowEnabled,
        settingRapidsosSocPhoneNumber: settings.settingRapidsosSocPhoneNumber,
      });
    }
  }, [form, settings]);

  const onSubmit = async (values: Infer<typeof schema>) => {
    const response = await updateSettings({
      isPanicButtonFlowEnabled: values.settingIsRapidsosPanicButtonFlowEnabled,
      isRapidsosOperatorFlowEnabled:
        values.settingIsRapidsosOperatorFlowEnabled,
      organizationId: settings?.id ?? '',
      socPhoneNumber: values.settingRapidsosSocPhoneNumber,
    });

    if (!response.error) {
      form.reset(values);
      toast.success('Updated RapidSOS integration settings!');
    }
  };

  const onReset = () => {
    if (settings) {
      form.reset({
        settingIsRapidsosOperatorFlowEnabled:
          settings.settingIsRapidsosOperatorFlowEnabled,
        settingIsRapidsosPanicButtonFlowEnabled:
          settings.settingIsRapidsosPanicButtonFlowEnabled,
        settingRapidsosSocPhoneNumber: settings.settingRapidsosSocPhoneNumber,
      });
    }
  };

  if (plan?.featureRapidsos) {
    return (
      <EmptyState
        description="This feature is not available at the current plan level."
        icon={Zap}
        title="Premium Feature"
      />
    );
  }

  if (fetching) {
    return <Skeleton className="w-full h-64" />;
  }

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="settingIsRapidsosPanicButtonFlowEnabled"
          render={({ field: { onChange, value } }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
              <div className="space-y-0.5">
                <FormLabel className="text-base">
                  Enable 911 Integration
                </FormLabel>
                <FormDescription>
                  When enabled, the 911 integration will route all SOS requests
                  directly to the nearest 911 call center.
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={value}
                  onCheckedChange={onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="settingIsRapidsosOperatorFlowEnabled"
          render={({ field: { onChange, value } }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
              <div className="space-y-0.5">
                <FormLabel className="text-base">Use Operator Flow</FormLabel>
                <FormDescription>
                  When enabled, safety monitoring agents will be able to
                  initiate SOS and be connected to the 911 call center nearest
                  to the member in GuardMe. A default SOC phone number must be
                  set to connect with 911.
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={value}
                  onCheckedChange={onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="settingRapidsosSocPhoneNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>SOC Phone Number</FormLabel>
              <FormControl>
                <PhoneNumberInput
                  {...field}
                  disabled={!watchOperactorFlow}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <MutationError error={error} />

        <div className="flex items-center gap-2">
          <Button
            disabled={!isAdmin || !form.formState.isDirty}
            type="submit"
          >
            Update
          </Button>
          <Button
            disabled={!isAdmin || !form.formState.isDirty}
            onClick={onReset}
            type="reset"
            variant="ghost"
          >
            Reset
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { RapidSosIntegrationForm };
