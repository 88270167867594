import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { Check, Copy, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useMutation, useQuery } from 'urql';

const IncidentLivestreamDroneGql = graphql(`
  query IncidentLivestreamDroneGql($livestreamId: String!) {
    livestream(id: $livestreamId) {
      id
      status
      droneEndpoint
    }
  }
`);

const StartLivestreamDroneGql = graphql(`
  mutation StartLivestreamDroneGql($input: StartIncidentLivestreamInput!) {
    startIncidentLivestream(input: $input) {
      livestream {
        id
        droneEndpoint
        createdAt
        status
      }
    }
  }
`);

const EndLivestreamDroneGql = graphql(`
  mutation EndLivestreamDroneGql($input: EndIncidentLivestreamInput!) {
    endIncidentLivestream(input: $input) {
      livestream {
        id
        endedAt
        createdAt
        status
      }
    }
  }
`);

const LivestreamInfo = ({
  livestreamId,
  onOpenChange,
  open,
  setLivestreamId,
}: {
  readonly livestreamId: string;
  readonly onOpenChange: (open: boolean) => void;
  readonly open: boolean;
  readonly setLivestreamId: (id: string) => void;
}) => {
  const [copied, setCopied] = useState(false);
  const [{ fetching }, endLivestream] = useMutation(EndLivestreamDroneGql);

  const [{ data }] = useQuery({
    query: IncidentLivestreamDroneGql,
    variables: {
      livestreamId,
    },
  });

  const handleEnd = async () => {
    await endLivestream({
      input: {
        livestreamId,
      },
    });
    onOpenChange(false);
    setLivestreamId('');
  };

  return (
    <Dialog
      onOpenChange={(value) => {
        setCopied(false);
        onOpenChange(value);
      }}
      open={open}
    >
      <DialogTrigger asChild>
        <Button
          size="sm"
          variant="default"
        >
          Drone <span className="hidden md:block">Livestream Info</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Drone Livestream</DialogTitle>
          <DialogDescription className="space-y-2">
            Connection Information
          </DialogDescription>
        </DialogHeader>

        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="rtmp">Streaming Url</Label>
          <div className="flex items-center gap-2">
            <Input
              id="rtmp"
              placeholder="Streaming Url"
              readOnly
              type="text"
              value={data?.livestream?.droneEndpoint ?? ''}
            />
            <Button
              className={
                copied
                  ? 'bg-success text-success-foreground hover:bg-success/90 border border-success-foreground/10'
                  : ''
              }
              disabled={!data?.livestream?.droneEndpoint}
              onClick={() => {
                if (data?.livestream?.droneEndpoint)
                  navigator.clipboard.writeText(
                    data?.livestream?.droneEndpoint,
                  );
                setCopied(true);
              }}
              variant={copied ? 'default' : 'outline'}
            >
              {copied ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
              <span className="sr-only">Copy connection string</span>
            </Button>
          </div>
        </div>

        <DialogFooter>
          <Button
            disabled={fetching}
            onClick={handleEnd}
            variant="destructive"
          >
            {fetching && <Loader2 className="animate-spin" />}
            End Livestream
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

type Props = {
  readonly contactMemberId?: string | undefined | null;
  readonly incidentId: string;
  readonly livestreams: Array<{ id: string; memberId: string }>;
  readonly ownedByMemberId?: string | undefined | null;
};

const IncidentLivestreamDrone = ({
  contactMemberId,
  incidentId,
  livestreams,
  ownedByMemberId,
}: Props) => {
  const memberId = useAppStore((state) => state.activeMembership?.memberId);
  const [livestreamId, setLivestreamId] = useState('');
  const [open, setOpen] = useState(false);

  const [{ fetching }, startLivestream] = useMutation(StartLivestreamDroneGql);

  if (!incidentId) {
    return null;
  }

  if (contactMemberId !== memberId && ownedByMemberId !== memberId) {
    return null;
  }

  if (memberId) {
    const livestreamIds = livestreams
      .filter((x) => x.memberId === memberId)
      .map((x) => x.id);

    if (livestreamIds.length === 1 && livestreamIds[0] !== livestreamId) {
      setLivestreamId(livestreamIds[0]);
    }
  }

  const handleStart = async () => {
    try {
      const response = await startLivestream({
        input: {
          incidentId,
        },
      });

      if (response.data?.startIncidentLivestream?.livestream?.id) {
        setLivestreamId(response.data?.startIncidentLivestream?.livestream?.id);
        setOpen(true);
      }
    } catch {
      // noop
    }
  };

  return (
    <div>
      {livestreamId ? (
        <LivestreamInfo
          livestreamId={livestreamId}
          onOpenChange={setOpen}
          open={open}
          setLivestreamId={setLivestreamId}
        />
      ) : (
        <Button
          disabled={fetching}
          onClick={handleStart}
          size="sm"
          variant="outline"
        >
          {fetching && <Loader2 className="animate-spin" />}
          <span className="hidden md:block">Start</span> Drone{' '}
          <span className="hidden md:block">Livestream</span>
        </Button>
      )}
    </div>
  );
};

export { IncidentLivestreamDrone };
