import { AssignDefaultGroupToIncidentTypeModal } from './AssignDefaultGroupToIncidentTypeModal';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { graphql } from '@/gql';
import {
  type EnabledIncidentType,
  type Group,
  type IncidentType,
} from '@/gql/graphql';
import { pluralize } from '@/lib/pluralize';
import { toast } from '@/lib/toast';
import { Info, SearchIcon } from 'lucide-react';
import { useState } from 'react';
import { useMutation } from 'urql';

const EnableIncidentTypeQuery = graphql(`
  mutation EnableIncidentTypeQuery(
    $organizationId: String!
    $incidentTypeId: String!
  ) {
    enableIncidentForOrganization(
      input: {
        organizationId: $organizationId
        incidentTypeId: $incidentTypeId
        defaultAssignedGroupId: ""
      }
    ) {
      query {
        organization(id: $organizationId) {
          id
          enabledIncidentTypes {
            totalCount
            nodes {
              id
              organizationId
              incidentTypeId
              defaultAssignedGroup {
                id
                name
                members {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`);

const DisableIncidentTypeQuery = graphql(`
  mutation DisableIncidentTypeQuery(
    $organizationId: String!
    $incidentTypeId: String!
  ) {
    disableIncidentForOrganization(
      input: {
        organizationId: $organizationId
        incidentTypeId: $incidentTypeId
      }
    ) {
      query {
        organization(id: $organizationId) {
          id
          enabledIncidentTypes {
            totalCount
            nodes {
              id
              organizationId
              incidentTypeId
              defaultAssignedGroup {
                id
                name
                members {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`);

type IncidentDetails = Array<{
  assignedGroup?: {
    id?: string;
    name?: string;
  } | null;
  category: string;
  description: string;
  enabledIncidentTypeId?: string;
  icon: string;
  id: string;
  isEnabled: boolean;
  name: string;
  search: string;
}>;

type IncidentSettingsEnabledProps = {
  readonly enabledIncidentTypes?: Array<
    Pick<
      EnabledIncidentType,
      'id' | 'defaultAssignedGroupId' | 'incidentTypeId'
    >
  >;
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly incidentTypes?: Array<
    Pick<
      IncidentType,
      | 'id'
      | 'name'
      | 'useAllowlist'
      | 'allowlist'
      | 'category'
      | 'description'
      | 'iconSvg'
    >
  >;
  readonly organizationId: string;
};

const IncidentSettingsEnabled = ({
  enabledIncidentTypes = [],
  groups = [],
  incidentTypes = [],
  organizationId,
}: IncidentSettingsEnabledProps) => {
  const [, enableIncidentType] = useMutation(EnableIncidentTypeQuery);
  const [, disableIncidentType] = useMutation(DisableIncidentTypeQuery);

  const [search, setSearch] = useState('');

  const incidentNodes: IncidentDetails = [];

  // replace data?.incidentTypes with this to prevent globale admins from
  // seeing all incident type until backend is corrected.
  const globalAdminFix =
    incidentTypes.filter(
      (item) => !item.useAllowlist || item.allowlist.includes(organizationId),
    ) || [];

  if (globalAdminFix && enabledIncidentTypes) {
    for (const node of globalAdminFix.sort((a, b) =>
      a.name < b.name ? -1 : 1,
    )) {
      const enabledIncidentType = enabledIncidentTypes.find(
        (x) => x.incidentTypeId === node.id,
      );

      const assignedGroup = groups.find(
        (x) => x.id === enabledIncidentType?.defaultAssignedGroupId,
      );

      const incidentType: IncidentDetails[0] = {
        assignedGroup: null,
        category: node.category,
        description: node.description,
        enabledIncidentTypeId: enabledIncidentType?.id,
        icon: node.iconSvg,
        id: node.id,
        isEnabled: Boolean(enabledIncidentType?.id),
        name: node.name,
        search:
          `${node.name}${node.description}${node.category}${assignedGroup?.name}`.toLowerCase(),
      };

      if (assignedGroup) {
        incidentType.assignedGroup = {
          id: assignedGroup.id,
          name: assignedGroup.name,
        };
      }

      incidentNodes.push(incidentType);
    }
  }

  const handleToggle = (id: string, name: string) => {
    return async (value: boolean) => {
      if (value) {
        const response = await enableIncidentType({
          incidentTypeId: id,
          organizationId,
        });
        if (response.error) {
          toast.error(`Enable ${name} Incident type failed!`, {
            description: response.error.message,
          });
        } else {
          toast.success(`Enabled ${name} Incident type`);
        }
      } else {
        const response = await disableIncidentType({
          incidentTypeId: id,
          organizationId,
        });
        if (response.error) {
          toast.error(`Disable ${name} Incident type failed!`, {
            description: response.error.message,
          });
        } else {
          toast.success(`Disabled ${name} Incident type`);
        }
      }
    };
  };

  const filteredIncidents = incidentNodes.filter((x) =>
    x.search.includes(search.toLowerCase()),
  );

  return (
    <>
      <div className="flex justify-between items-center pb-2">
        <div className="relative">
          <SearchIcon className="absolute left-2.5 top-2.5 size-4 text-muted-foreground" />
          <Input
            className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] max-w-1/2"
            onChange={(event) => {
              setSearch(event.currentTarget.value);
            }}
            placeholder="Search Incident Types..."
            type="search"
            value={search}
          />
        </div>
        <div className="flex gap-1 items-center">
          {filteredIncidents?.length !== incidentNodes?.length &&
            `${filteredIncidents?.length.toString()} of `}
          {incidentNodes?.length.toString()}{' '}
          {pluralize('Incident', incidentNodes?.length)}
        </div>
      </div>

      {incidentNodes.filter((x) => x.isEnabled).length === 0 && (
        <Alert
          className="my-2"
          variant="info"
        >
          <Info className="size-4" />
          <AlertTitle>No Incidents Enabled</AlertTitle>
          <AlertDescription>
            There are no incident types enabled for this organization. To allow
            members to create incidents please enable at least one incident
            type.
          </AlertDescription>
        </Alert>
      )}

      <ul className="divide-y">
        {filteredIncidents.map((item) => (
          <li
            className="py-2 first:border-t"
            key={item.id}
          >
            <div className="flex items-start">
              <img
                alt={item.name}
                className="h-12 w-12 bg-info rounded-xl p-1.5"
                src={`data:image/svg+xml;base64,${item.icon}`}
              />
              <div className="ml-4">
                <Label
                  className="font-semibold text-base"
                  htmlFor={item.id}
                >
                  {item.name}
                </Label>
                <div className="mt-2 space-y-1 text-sm">
                  <p>
                    <span className="text-muted-foreground">Category:</span>{' '}
                    <span className="capitalize">{item.category}</span>
                  </p>
                  <p>{item.description}</p>
                  <div className="flex items-center gap-2">
                    <span className="text-muted-foreground">
                      Assigned to group:
                    </span>
                    <div>
                      <AssignDefaultGroupToIncidentTypeModal
                        assignedGroupId={item.assignedGroup?.id}
                        enabledIncidentTypeId={item.enabledIncidentTypeId}
                        groups={groups}
                        incidentTypeId={item.id}
                        organizationId={organizationId}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-auto">
                <Switch
                  checked={item.enabledIncidentTypeId !== undefined}
                  id={item.id}
                  onCheckedChange={handleToggle(item.id, item.name)}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export { IncidentSettingsEnabled };
