import { AvatarEditor, type AvatarEditorType } from '@/components/AvatarEditor';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { AvatarType, UpdateAvatarType, type User } from '@/gql/graphql';
import { supportedTimezones } from '@/lib/timezone';
import { toast } from '@/lib/toast';
import { generateAvatarUrl } from '@/utils';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { boolean, type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const CurrentUserUploadAvatarGql = graphql(`
  mutation CurrentUserUploadAvatarGql($input: uploadAvatarInput!) {
    uploadAvatar(input: $input) {
      id
      fields
      key
      url
    }
  }
`);

const CurrentUserUpdateDetailsGql = graphql(`
  mutation CurrentUserUpdateDetailsGql($input: CurrentUserUpdateDetailsInput!) {
    currentUserUpdateDetails(input: $input) {
      user {
        id
        fullName
        timezone
        avatarType
        avatarUrl
      }
    }
  }
`);

const schema = object({
  avatarType: string(),
  fullName: nonempty(string()),
  timezone: nonempty(string()),
  updatedCustomAvatar: boolean(),
});

type AccountDetailsFormProps = {
  readonly user: Pick<
    User,
    'avatarType' | 'avatarUrl' | 'emailAddress' | 'fullName' | 'timezone'
  >;
};

const AccountDetailsForm = ({ user }: AccountDetailsFormProps) => {
  const [{ error: avatarError, fetching: avatarFetching }, uploadAvatar] =
    useMutation(CurrentUserUploadAvatarGql);
  const [{ error: updateError, fetching: updateFetching }, updateDetails] =
    useMutation(CurrentUserUpdateDetailsGql);

  const error = avatarError || updateError;
  const fetching = avatarFetching || updateFetching;

  const avatarEditorRef = useRef<AvatarEditorType | null>(null);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      avatarType: user?.avatarType,
      fullName: user?.fullName,
      timezone: user?.timezone,
      updatedCustomAvatar: false,
    },
    resolver: superstructResolver(schema),
  });

  const watchFullName = form.watch('fullName');

  const onSubmit = async (values: Infer<typeof schema>) => {
    const updatedAvatarType =
      values.avatarType === user?.avatarType
        ? UpdateAvatarType.Unchanged
        : (values.avatarType as UpdateAvatarType);

    if (
      values.avatarType === UpdateAvatarType.Custom &&
      avatarEditorRef.current &&
      values.updatedCustomAvatar
    ) {
      // get blob from avatarEditor
      const dataUrl = avatarEditorRef.current
        .getImageScaledToCanvas()
        .toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      // need to upload the custom avatar before updaing the user
      const uploadResponse = await uploadAvatar({
        input: {
          mimetype: blob.type,
          useWorkerTask: false,
        },
      });

      const uploadData = uploadResponse?.data?.uploadAvatar;

      if (!uploadData) {
        throw new Error('Unknown upload data');
      }

      const formData = new FormData();
      if (!Object.keys(uploadData.fields).includes('key')) {
        formData.append('key', uploadData.key);
      }

      const fieldsObject = JSON.parse(uploadData.fields as string);
      for (const field of Object.keys(fieldsObject)) {
        formData.append(field, fieldsObject[field]);
      }

      formData.append('file', blob);

      await fetch(uploadData.url, {
        body: formData,
        method: 'POST',
      })
        .then(async (fetchResponse) => {
          if (fetchResponse.status === 204) {
            const updateResult = await updateDetails({
              input: {
                avatarType: UpdateAvatarType.Custom,
                customAvatarKey: uploadData.key,
                fullName: values.fullName,
                timezone: values.timezone,
              },
            });

            if (updateResult.error) {
              toast.error('Problem updating profile!', {
                description: updateResult.error.message,
              });
            } else {
              toast.success('Profile updated!');
              form.reset({ ...values, updatedCustomAvatar: false });
            }
          } else {
            toast.error('Failed to upload avatar!', {
              description: `Server responded with: (${fetchResponse.status}) ${fetchResponse.statusText}`,
            });
          }
        })
        .catch(() => {
          toast.error('Problem uploading avatar!');
        });
    } else {
      // No need to upload the custom avatar before updaing the user
      const response = await updateDetails({
        input: {
          avatarType: updatedAvatarType,
          customAvatarKey: '',
          fullName: values.fullName,
          timezone: values.timezone,
        },
      });

      if (response.error) {
        toast.error('Problem updating profile!', {
          description: response.error.message,
        });
      } else {
        toast.success('Profile updated!');

        form.reset({ ...values, updatedCustomAvatar: false });
      }
    }
  };

  const onCancel = () => {
    form.reset();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
          <div className="col-span-full sm:col-span-3">
            <FormField
              control={form.control}
              name="fullName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="full name"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="col-span-full sm:col-span-3">
            <FormField
              control={form.control}
              name="timezone"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel>Timezone</FormLabel>
                  <Select
                    defaultValue={value}
                    onValueChange={onChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Timezone" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {supportedTimezones.map((item) => (
                        <SelectItem
                          key={item}
                          value={item}
                        >
                          {item}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="col-span-full">
            <FormField
              control={form.control}
              name="avatarType"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel>Avatar</FormLabel>
                  <div className="flex flex-row items-start justify-between rounded-lg border p-4">
                    <FormControl>
                      <div className="w-1/2">
                        <RadioGroup
                          className="flex flex-col space-y-1"
                          defaultValue={value}
                          onValueChange={onChange}
                        >
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem
                                value={UpdateAvatarType.Initials}
                              />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Use initials
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={UpdateAvatarType.Custom} />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Upload an image
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem
                                disabled={!user?.emailAddress}
                                value={UpdateAvatarType.Gravatar}
                              />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Use Gravatar
                            </FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </div>
                    </FormControl>

                    <div className="w-1/2">
                      {value === UpdateAvatarType.Initials && (
                        <div>
                          <Avatar className="h-16 w-16">
                            <AvatarImage
                              src={generateAvatarUrl(watchFullName, 2)}
                            />
                          </Avatar>
                        </div>
                      )}
                      {value === UpdateAvatarType.Custom && (
                        <AvatarEditor
                          blobRef={avatarEditorRef}
                          circle
                          defaultValue={
                            user?.avatarType === AvatarType.Custom
                              ? user?.avatarUrl
                              : undefined
                          }
                          onValueChange={() => {
                            form.setValue('updatedCustomAvatar', true, {
                              shouldDirty: true,
                            });
                          }}
                        />
                      )}
                      {value === UpdateAvatarType.Gravatar && (
                        <div className="text-sm w-full p-4 bg-muted/40">
                          <p>
                            Gravatars are managed through{' '}
                            <a
                              className="text-info-foreground hover:underline"
                              href="https://gravatar.com"
                            >
                              Gravatar.com
                            </a>
                          </p>{' '}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="col-span-full">
            <MutationError error={error} />
          </div>

          <div className="col-span-full mt-6 flex justify-end items-center">
            {form.formState.isDirty && (
              <Button
                disabled={fetching}
                onClick={onCancel}
                type="reset"
                variant="ghost"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Reset
              </Button>
            )}
            <Button
              disabled={fetching || !form.formState.isDirty}
              type="submit"
            >
              Save Settings
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { AccountDetailsForm };
