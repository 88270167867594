import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { type Column } from '@tanstack/react-table';
import { Check, type LucideIcon, PlusCircle } from 'lucide-react';

export type DatePreset = {
  from: Date;
  label: string;
  shortcut: string;
  to: Date;
};

// TODO: we could type the value(!) especially when using enums
export type Option = {
  icon?: LucideIcon;
  label: string;
  value: string | boolean | number;
};

export type Input = {
  options?: Option[];
  type: 'input';
};

export type Checkbox = {
  component?: (props: Option) => JSX.Element | null;
  options?: Option[];
  type: 'checkbox';
};

export type Slider = {
  max: number;
  min: number;
  // if options is undefined, we will provide all the steps between min and max
  options?: Option[];
  type: 'slider';
};

export type Timerange = {
  options?: Option[];
  // required for TS
  presets?: DatePreset[];
  type: 'timerange';
};

export type Base<TData> = {
  label: string;
  value: keyof TData;
};

export type DataTableCheckboxFilterField<TData> = Base<TData> & Checkbox;
export type DataTableSliderFilterField<TData> = Base<TData> & Slider;
export type DataTableInputFilterField<TData> = Base<TData> & Input;
export type DataTableTimerangeFilterField<TData> = Base<TData> & Timerange;

export type DataTableFacetedFilterField<TData> =
  | DataTableCheckboxFilterField<TData>
  | DataTableSliderFilterField<TData>
  | DataTableInputFilterField<TData>
  | DataTableTimerangeFilterField<TData>;

type DataTableFacetedFilterProps<TData, TValue> = {
  readonly column?: Column<TData, TValue>;
  readonly options: Option[];
  readonly title?: string;
};

const DataTableFacetedFilter = <TData, TValue>({
  column,
  options,
  title,
}: DataTableFacetedFilterProps<TData, TValue>) => {
  const selectedValues = new Set(column?.getFilterValue() as string[]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            'h-8 text-primary',
            selectedValues?.size === 0 && 'text-foreground border-dashed',
          )}
          size="sm"
          variant="outline"
        >
          <PlusCircle
            className={selectedValues?.size === 0 ? '' : 'rotate-45'}
          />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator
                className="mx-2 h-4"
                orientation="vertical"
              />
              <Badge
                className="rounded-sm px-1 font-normal lg:hidden"
                variant="secondary"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    className="rounded-sm px-1 font-normal"
                    variant="secondary"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) =>
                      selectedValues.has(option.value.toString()),
                    )
                    .map((option) => (
                      <Badge
                        className="rounded-sm px-1 font-normal"
                        key={option.value.toString()}
                        variant="secondary"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-40 p-0"
      >
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value.toString());
                return (
                  <CommandItem
                    key={option.value.toString()}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value.toString());
                      } else {
                        selectedValues.add(option.value.toString());
                      }

                      const filterValues = Array.from(selectedValues);
                      column?.setFilterValue(
                        filterValues.length ? filterValues : undefined,
                      );
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <Check />
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    className="justify-center text-center"
                    onSelect={() => column?.setFilterValue(undefined)}
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { DataTableFacetedFilter };
