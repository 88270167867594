import { Member } from './routes/Member';
import { Members } from './routes/Members';
import { type RouteObject } from 'react-router-dom';

export * from './components/GroupSelect';
export * from './components/GroupsSelect';

export const membersRoutes: RouteObject[] = [
  {
    element: <Member />,
    path: '/members/:memberId',
  },
  {
    element: <Members />,
    path: '/members',
  },
];
