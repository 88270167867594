import { MemberRequestForm } from './MemberRequestForm';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { type Group, type Organization } from '@/gql/graphql';
import { useQuery } from 'urql';

const SectionMembershipRequestsSheetGql = graphql(`
  query SectionMembershipRequestsSheetGql($id: String!) {
    membershipRequest(id: $id) {
      id
      createdAt
      updatedAt
      fullName
      phoneNumber
      emailAddress
      publicMetadata
      organizationId
      membershipCode {
        id
        code
        groupIds
        organizationRole
      }
    }
  }
`);

type Props = {
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly memberMetadataFields?: Pick<Organization, 'memberMetadataFields'>;
  readonly membershipRequestId: string;
  readonly onOpenChange: () => void;
  readonly open: boolean;
};

const SectionMembershipRequestsSheet = ({
  groups = [],
  memberMetadataFields,
  membershipRequestId,
  onOpenChange,
  open,
}: Props) => {
  const [{ data, fetching }] = useQuery({
    query: SectionMembershipRequestsSheetGql,
    variables: {
      id: membershipRequestId,
    },
  });

  return (
    <Sheet
      onOpenChange={onOpenChange}
      open={open}
    >
      <SheetContent>
        <SheetHeader className="border-b">
          {fetching ? (
            <>
              <Skeleton className="size-16 rounded-full" />
              <Skeleton className="h-7 w-full" />
            </>
          ) : (
            <>
              <Avatar className="size-16">
                <AvatarFallback className="text-2xl">
                  {data?.membershipRequest?.fullName?.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              <SheetTitle>{data?.membershipRequest?.fullName}</SheetTitle>
              <SheetDescription className="flex flex-wrap items-start gap-2" />
            </>
          )}
        </SheetHeader>

        <div className="py-4 space-y-4 grow">
          {fetching ? (
            <Skeleton className="h-96 w-full" />
          ) : (
            data?.membershipRequest && (
              <MemberRequestForm
                groups={groups}
                memberMetadataFields={memberMetadataFields}
                memberRequest={data.membershipRequest}
              />
            )
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export { SectionMembershipRequestsSheet };
