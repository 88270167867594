import { config } from '@/config';

const latRad = (lat: number) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const getZoom = (bounds: google.maps.LatLngBounds) => {
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  const latDif = Math.abs(latRad(northEast.lat()) - latRad(southWest.lat()));
  const lngDif = Math.abs(northEast.lng() - southWest.lng());

  const latFrac = latDif / Math.PI;
  const lngFrac = lngDif / 360;

  const lngZoom = Math.log(1 / latFrac) / Math.log(2);
  const latZoom = Math.log(1 / lngFrac) / Math.log(2);

  return Math.min(lngZoom, latZoom);
};

const generateStaticGoogleMapUrl = (args: {
  bounds?: google.maps.LatLngBounds | null;
  encodedPolyline: string | null;
  height?: number | undefined;
  latitude: number;
  longitude: number;
  radius?: number | undefined;
  width?: number | undefined;
  zoom?: number;
}) => {
  const defaulZoom = args.zoom ?? 7;
  const zoom = args.bounds ? getZoom(args.bounds) : defaulZoom;
  const height = args.height || 200;
  const width = args.width || 200;

  let url =
    `https://maps.googleapis.com/maps/api/staticmap` +
    `?center=${args.latitude.toString()},${args.longitude.toString()}` +
    `&zoom=${zoom.toString()}` +
    `&size=${width.toString()}x${height.toString()}` +
    '&maptype=roadmap';

  if (args.encodedPolyline) {
    url +=
      '&path=fillcolor:0xB0002050%7Ccolor:0xFFFFFF00%7Cenc:' +
      encodeURIComponent(args.encodedPolyline);
  }

  return url + `&key=${config.GOOGLE_MAPS_KEY}`;
};

export { generateStaticGoogleMapUrl };
