import { IncidentTypeSelector } from '../components/IncidentTypeSelector';
import { NewIncidentForm } from '../components/NewIncidentForm';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Header, HeaderSubtitle, HeaderTitle } from '@/components/header';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { type IncidentType } from '@/gql/graphql';
import { useGeolocationService } from '@/hooks/useGeolocationService';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils/errors';
import { RotateCcw } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const EnabledIncidentTypesQuery = graphql(`
  query EnabledIncidentTypesQuery($organizationId: String = "") {
    enabledIncidentTypes(condition: { organizationId: $organizationId }) {
      totalCount
      nodes {
        id
        defaultAssignedGroup {
          id
          name
        }
        incidentType {
          id
          category
          name
          description
          iconSvg
          uiSchema
          dataSchema
        }
      }
    }
    organization(id: $organizationId) {
      id
      members {
        nodes {
          id
          fullName
          displayName
          isEnabled
        }
      }
    }
  }
`);

const isValidIncidentType = (
  item:
    | Pick<
        IncidentType,
        | 'category'
        | 'dataSchema'
        | 'description'
        | 'iconSvg'
        | 'id'
        | 'name'
        | 'uiSchema'
      >
    | null
    | undefined,
): item is Pick<
  IncidentType,
  | 'category'
  | 'dataSchema'
  | 'description'
  | 'iconSvg'
  | 'id'
  | 'name'
  | 'uiSchema'
> => item !== undefined && item !== null;

const IncidentNew = () => {
  const [organizationId, memberId, isIncidentSupervisor] = useAppStore(
    (state) => [
      state.activeMembership?.id,
      state.activeMembership?.memberId,
      state.activeMembership?.isIncidentSupervisor,
    ],
  );

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const currentLocation = useGeolocationService();

  const [selectedIncidentTypeId, setSelectedIncidentTypeId] = useState<
    string | null
  >(null);

  const [{ data }] = useQuery({
    pause: !organizationId,
    query: EnabledIncidentTypesQuery,
    variables: {
      organizationId,
    },
  });

  const selectedIncidentType = data?.enabledIncidentTypes?.nodes.find(
    (item) => item.incidentType?.id === selectedIncidentTypeId,
  )?.incidentType;

  return (
    <>
      <Header
        actions={
          selectedIncidentType ? (
            <Button
              onClick={() => setSelectedIncidentTypeId(null)}
              size="sm"
            >
              <RotateCcw />
              Change Incident Type
            </Button>
          ) : undefined
        }
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/incidents">Incidents</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>New</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle className="flex items-center gap-2">
          {selectedIncidentType ? (
            <>
              <img
                alt={selectedIncidentType.name}
                className="size-10"
                src={`data:image/svg+xml;base64,${selectedIncidentType.iconSvg}`}
              />
              {selectedIncidentType.name}
            </>
          ) : (
            'Select an Incident Type'
          )}
        </HeaderTitle>

        <HeaderSubtitle className="max-w-prose">
          {selectedIncidentType?.description}
        </HeaderSubtitle>
      </Header>

      <div className="grid flex-1 items-start gap-4">
        {data?.enabledIncidentTypes && (
          <>
            {selectedIncidentType ? (
              <div className="w-full">
                <NewIncidentForm
                  currentLocation={currentLocation}
                  currentMemberId={memberId}
                  incidentType={selectedIncidentType}
                  isIncidentSupervisor={isIncidentSupervisor ?? false}
                  members={data.organization?.members.nodes ?? []}
                  organizationId={organizationId}
                />
              </div>
            ) : (
              <div className="w-full">
                <IncidentTypeSelector
                  incidentTypes={data.enabledIncidentTypes?.nodes
                    .map((item) => item.incidentType)
                    .filter(isValidIncidentType)}
                  setSelectedIncidentTypeId={(id) =>
                    setSelectedIncidentTypeId(id)
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export { IncidentNew };
