import { guardmesDataTableQueryStates } from '@/features/GuardMe/hooks/useGuardmesSearchParameters';
import { useQueryStates } from 'nuqs';

// set default page size to 10
const parsers = {
  ...guardmesDataTableQueryStates.parsers,
  pageSize: guardmesDataTableQueryStates.parsers.pageSize.withDefault(10),
};

const useMemberGuardmeSearchParameters = () =>
  useQueryStates(parsers, {
    urlKeys: guardmesDataTableQueryStates.urlKeys,
  });

export { useMemberGuardmeSearchParameters };
