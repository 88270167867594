import { MutationError } from '../../Core';
import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

const GroupDeleteGql = graphql(`
  mutation GroupDeleteGql($groupId: String!) {
    deleteGroup(input: { id: $groupId }) {
      group {
        id
        organizationId
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`);

type Props = {
  readonly groupId: string;
};

const GroupDelete = ({ groupId }: Props) => {
  const navigate = useNavigate();

  const [{ error, fetching }, deleteGroup] = useMutation(GroupDeleteGql);

  const onSubmit = async () => {
    const { data } = await deleteGroup({
      groupId,
    });

    if (data?.deleteGroup?.group?.name) {
      toast.success(
        `${data.deleteGroup.group.name} group deleted from organization.`,
      );

      navigate('/members/?view=groups');
    }
  };

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <h3 className="font-semibold leading-none tracking-tight">
          Delete Group
        </h3>
        <p className="text-sm text-muted-foreground">
          This will permanently remove all members from this group. Deleting
          this group is permanent and cannot be undone!
        </p>
      </div>

      <MutationError error={error} />

      <div className="flex justify-end">
        <Button
          disabled={fetching}
          onClick={onSubmit}
          variant="destructive"
        >
          {fetching && <Loader className="size-6 animate-spin" />}
          Delete Group
        </Button>
      </div>
    </div>
  );
};

export { GroupDelete };
