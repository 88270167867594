import { EditRoleDropdown } from './EditRoleDropdown';
import { SectionInvitationsSheet } from './SectionInvitationsSheet';
import { DebouncedInput } from '@/components/debounced-input';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { type Group, type Invitation } from '@/gql/graphql';
import { pluralize } from '@/lib/pluralize';
import { MoreVertical } from 'lucide-react';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

type Props = {
  readonly fetching?: boolean;
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly invitations?: Array<
    Pick<Invitation, 'id' | 'key' | 'groupIds' | 'organizationRole'>
  >;
};

const SectionInvitations = ({ fetching, groups, invitations }: Props) => {
  const [search, setSearch] = useState('');
  const [searchParameters, setSearchParameters] = useSearchParams();
  const id = searchParameters.get('id');

  const selected = invitations?.find((x) => x.id === id);

  const filteredInvitations = invitations
    ?.map((item) => ({
      ...item,
      search: item.key.toLowerCase(),
    }))
    .filter((item) =>
      search ? item.search.includes(search.toLowerCase()) : true,
    );

  return (
    <section aria-labelledby="pending-invitations">
      <div className="py-2 flex md:items-center">
        <DebouncedInput
          className="w-64"
          onChange={(value) => setSearch(value)}
          placeholder="Search invitations..."
          type="search"
          value={search}
        />

        <div className="md:ml-auto font-semibold text-sm text-muted-foreground">
          {filteredInvitations?.length !== invitations?.length &&
            `${filteredInvitations?.length.toString()} of `}
          {invitations?.length.toString()}{' '}
          {pluralize('invitation', invitations?.length)}
        </div>
      </div>

      <ul className="border-t border-b divide-y">
        {fetching &&
          !filteredInvitations &&
          [0, 1, 2, 3, 4, 5].map((item) => (
            <li
              className="flex items-center justify-between py-2"
              key={item}
            >
              <div className="flex items-center gap-x-4">
                <Skeleton className="size-12 rounded-full" />
                <Skeleton className="w-64 h-10" />
              </div>
              <div className="flex items-center gap-2">
                <Skeleton className="w-40 h-10" />
                <Skeleton className="size-10" />
              </div>
            </li>
          ))}
        {filteredInvitations?.map((item) => (
          <li
            className="flex items-center justify-between py-2"
            key={item.id}
          >
            <div className="flex items-center gap-x-4 truncate">
              <Avatar className="size-12">
                <AvatarFallback>{item.key.slice(0, 2)}</AvatarFallback>
              </Avatar>
              <div className="space-y-1">
                <p className="truncate text-sm font-medium">{item.key}</p>
                <p className="truncate text-xs flex gap-1">
                  {item.groupIds.map((groupId) => {
                    // replace id with name if available
                    const result = groups?.find(
                      (group) => group.id === groupId,
                    );
                    return (
                      <Link
                        key={groupId}
                        to={`/members/groups/${groupId}`}
                      >
                        <Badge variant="info">{result?.name || groupId}</Badge>
                      </Link>
                    );
                  })}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <EditRoleDropdown
                disabled
                memberId=""
                role={item.organizationRole}
              />

              <Link to={`/members?view=pending-invitations&id=${item.id}`}>
                <Button
                  size="icon"
                  variant="ghost"
                >
                  <MoreVertical />
                  <span className="sr-only">View Details</span>
                </Button>
              </Link>
            </div>
          </li>
        ))}
      </ul>

      {selected && (
        <SectionInvitationsSheet
          groups={groups}
          invitationId={selected.id}
          onOpenChange={() => {
            setSearchParameters({ view: 'pending-invitations' });
          }}
          open={Boolean(selected)}
        />
      )}
    </section>
  );
};

export { SectionInvitations };
