import { PlaceEditSheet } from './PlaceEditSheet';
import { useIsMapProviderLoaded } from '@/components/Map';
import { Skeleton } from '@/components/ui/skeleton';
import { type Place } from '@/gql/graphql';
import { generateStaticGoogleMapUrl } from '@/lib/staticGoogleMaps';
import { useAppStore } from '@/stores';

type PlaceDetailProps = Pick<
  Place,
  | 'center'
  | 'description'
  | 'id'
  | 'name'
  | 'radius'
  | 'spatialData'
  | 'encodedPolyline'
>;

const PlaceDetail = ({
  center,
  description,
  encodedPolyline,
  id,
  name,
  radius,
  spatialData,
}: PlaceDetailProps) => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);
  const isMapLoaded = useIsMapProviderLoaded();

  if (!isMapLoaded) {
    return <Skeleton className="h-64" />;
  }

  // determine bounds of place
  let bounds: google.maps.LatLngBounds | null = null;

  if (google.maps.Circle && google.maps.LatLngBounds) {
    if (spatialData.geojson.type === 'Point' && google?.maps) {
      let circle: google.maps.Circle | null = new google.maps.Circle({
        center: {
          lat: spatialData.geojson.coordinates[1],
          lng: spatialData.geojson.coordinates[0],
        },
        radius,
      });

      bounds = circle.getBounds();
      circle = null;
    }

    if (spatialData.geojson.type === 'Polygon' && google?.maps) {
      bounds = new google.maps.LatLngBounds();

      for (const outer of spatialData.geojson.coordinates as number[][][]) {
        for (const inner of outer) {
          const [lng, lat] = inner;
          bounds.extend({ lat, lng });
        }
      }
    }
  }

  const mapImageUrl = generateStaticGoogleMapUrl({
    bounds,
    encodedPolyline: encodedPolyline || null,
    height: 300,
    latitude: center.latitude,
    longitude: center.longitude,
    width: 300,
  });

  return (
    <article className="border shadow">
      <div
        className="bg-cover bg-center w-full aspect-square"
        style={{ backgroundImage: `url("${mapImageUrl}")` }}
      />

      <div className="px-3 py-2">
        <div className="flex items-center gap-2">
          <h3 className="text-sm font-semibold">{name}</h3>{' '}
          {isAdmin && (
            <PlaceEditSheet
              description={description}
              geoJson={{ ...spatialData.geojson, radius }}
              name={name}
              placeId={id}
            />
          )}
        </div>

        <p className="mt-1 line-clamp-3 text-sm text-muted-foreground">
          {description ? (
            description
          ) : (
            <span className="italic">No Description</span>
          )}
        </p>
      </div>
    </article>
  );
};

export { PlaceDetail };
