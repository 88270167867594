import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Check, ChevronDown } from 'lucide-react';
import { forwardRef } from 'react';

type GroupsSelectProps = Omit<
  React.InputHTMLAttributes<HTMLButtonElement>,
  'onChange' | 'value'
> & {
  readonly groups: Array<{
    id: string;
    name: string;
  }>;
  readonly onChange: (...event: any[]) => void;
  readonly value: string[];
};

const GroupsSelect = forwardRef<HTMLButtonElement, GroupsSelectProps>(
  (props, ref) => {
    const { className, groups, onChange, value } = props;

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            className={cn(
              'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
              className,
            )}
            ref={ref}
            variant="outline"
          >
            {value.length > 0 ? (
              <>
                <Badge
                  className="rounded-sm px-1 font-normal lg:hidden"
                  variant="secondary"
                >
                  {value.length}
                </Badge>
                <div className="hidden space-x-1 lg:flex">
                  {value.length > 1 ? (
                    <Badge
                      className="rounded-sm px-1 font-normal"
                      variant="secondary"
                    >
                      {value.length} selected
                    </Badge>
                  ) : (
                    groups
                      .filter((group) => value.includes(group.id))
                      .map((group) => (
                        <Badge
                          className="rounded-sm px-1 font-normal"
                          key={group.id}
                          variant="secondary"
                        >
                          {group.name}
                        </Badge>
                      ))
                  )}
                </div>
              </>
            ) : (
              '---'
            )}
            <ChevronDown className="h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="p-0"
        >
          <Command>
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {groups.map((group) => {
                  const isSelected = value.includes(group.id);
                  return (
                    <CommandItem
                      key={group.id}
                      onSelect={() => {
                        if (isSelected) {
                          onChange(value.filter((item) => item !== group.id));
                        } else {
                          onChange([...value, group.id]);
                        }
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isSelected
                            ? 'bg-primary text-primary-foreground'
                            : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <Check className="h-4 w-4" />
                      </div>
                      <span>{group.name}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

export { GroupsSelect };
