import { CheckInReportUpdates } from '../components/CheckInReportUpdates';
import { CheckInResponseStatus } from '../components/CheckInResponseStatus';
import { ZoomToButton } from '../components/ZoomToButton';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Datetime } from '@/components/Datetime';
import { ClientError } from '@/components/Error';
import { Header } from '@/components/header';
import { Loader } from '@/components/Loader';
import {
  AdvancedMarker,
  Circle,
  ControlPosition,
  Map,
  MapControl,
  MapProvider,
  Pin,
  Polygon,
} from '@/components/Map';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import {
  DataList,
  DataListItem,
  DataListLabel,
  DataListValue,
} from '@/components/ui/data-list';
import { Input } from '@/components/ui/input';
import { PaginationEllipsis } from '@/components/ui/pagination';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { graphql } from '@/gql';
import { type Member } from '@/gql/graphql';
import { OrganizationNotFoundError } from '@/lib/error';
import { getPaginationRange } from '@/lib/pagination';
import { useAppStore } from '@/stores';
import { PermissionDeniedError } from '@/utils';
import { ChevronLeft, ChevronRight, LinkIcon } from 'lucide-react';
import { Fragment, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const CheckInReportQuery = graphql(`
  query CheckInReportQuery($id: String!) {
    checkInReport(id: $id) {
      id
      shortId
      createdAt
      cutoffAt
      place {
        id
        name
        description
        spatialData {
          geojson
        }
        radius
      }
      requestorMember {
        id
        fullName
        displayName
        avatarUrl
      }
      checkInRequests {
        totalCount
        nodes {
          id
          checkInId
          checkIn {
            id
            shortId
            createdAt
            coordinates {
              x
              y
            }
          }
          member {
            id
            displayName
            fullName
            avatarUrl
          }
        }
      }
    }
  }
`);

const compare = (
  target: string,
  member?: { displayName?: string | null; fullName: string } | null,
) => {
  if (!target) {
    return true;
  }

  if (!member) {
    return false;
  }

  if (member.displayName?.toLowerCase().includes(target.toLowerCase())) {
    return true;
  }

  if (member.fullName.toLowerCase().includes(target.toLowerCase())) {
    return true;
  }

  return false;
};

// eslint-disable-next-line complexity
const CheckInReport = () => {
  const { id } = useParams() as { id: string };
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership) {
    throw new OrganizationNotFoundError();
  }

  if (!activeMembership.isCheckInSupervisor) {
    throw new PermissionDeniedError();
  }

  const [{ data, error, fetching }] = useQuery({
    pause: !id,
    query: CheckInReportQuery,
    variables: {
      id,
    },
  });

  const checkInRequests = (data?.checkInReport?.checkInRequests.nodes ?? [])
    .map((item) => ({
      checkIn: item.checkIn,
      checkInRequestId: item.id,
      member: item.member,
    }))
    .filter((item) => compare(search, item.member))
    .filter((item) => item.member) as Array<{
    checkIn?: {
      coordinates: {
        x: number;
        y: number;
      };
      createdAt: string;
      id: string;
      shortId: string;
    };
    checkInRequestId: string;
    member: Pick<Member, 'avatarUrl' | 'displayName' | 'fullName' | 'id'>;
  }>;

  const checkIns = (data?.checkInReport?.checkInRequests.nodes ?? [])
    .map((item) => ({
      checkIn: item.checkIn,
      checkInRequestId: item.id,
      member: item.member,
    }))
    .filter((item) => compare(search, item.member))
    .filter((item) => Boolean(item.checkIn))
    .filter((item) => item.member) as Array<{
    checkIn: {
      coordinates: {
        x: number;
        y: number;
      };
      createdAt: string;
      id: string;
      shortId: string;
    };
    checkInRequestId: string;
    member: {
      avatarUrl?: string | null | undefined;
      displayName?: string | null | undefined;
      fullName: string;
      id: string;
    };
  }>;

  const totalPages = Math.ceil(checkInRequests.length / 7);
  const pagninationRange = getPaginationRange({
    currentPage: page,
    pageSize: 7,
    totalCount: checkInRequests.length,
  });

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/check-in-reports">Check In Reports</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {data?.checkInReport?.shortId} Details
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
        className="pb-0"
      />

      <MapProvider>
        <Loader
          isLoading={fetching}
          loadingError={error}
        >
          <>
            {data?.checkInReport ? (
              <>
                <CheckInReportUpdates
                  checkInReportId={data?.checkInReport?.id}
                />

                <div className="relative h-100vh md:h-[calc(100vh-88px)]">
                  <Map
                    className="-ml-4 w-[calc(100%+32px)] md:-ml-8 md:w-[calc(100%+64px)] h-[calc(100vh)] md:h-[calc(100vh-56px)]"
                    defaultCenter={{
                      lat: activeMembership.defaultMapCenter.latitude,
                      lng: activeMembership.defaultMapCenter.longitude,
                    }}
                    defaultZoom={activeMembership.defaultMapZoom}
                  >
                    <MapControl position={ControlPosition.TOP_LEFT}>
                      <div className="ml-4 md:ml-8 mt-4 w-80 text-base font-sans">
                        <div className="bg-card text-card-foreground py-4 px-4 rounded-md shadow-sm">
                          <p className="text-xl font-bold leading-6">
                            {data.checkInReport.shortId}
                          </p>

                          <DataList
                            className="mt-2 space-y-2 text-sm"
                            orientation="horizontal"
                          >
                            <DataListItem>
                              <DataListLabel>Created</DataListLabel>
                              <DataListValue className="ml-auto">
                                <Datetime
                                  datetime={data.checkInReport.createdAt}
                                />
                              </DataListValue>
                            </DataListItem>
                          </DataList>

                          <DataList
                            className="mt-2 space-y-2 text-sm"
                            orientation="horizontal"
                          >
                            <DataListItem>
                              <DataListLabel>Requested by</DataListLabel>
                              <DataListValue className="ml-auto">
                                <span className="flex items-center">
                                  <Avatar className="size-4 ml-2 mr-1">
                                    <AvatarImage
                                      alt={
                                        data.checkInReport.requestorMember
                                          ?.displayName ??
                                        data.checkInReport.requestorMember
                                          ?.fullName ??
                                        'Deleted Member'
                                      }
                                      src={
                                        data.checkInReport.requestorMember
                                          ?.avatarUrl ?? undefined
                                      }
                                    />
                                    <AvatarFallback>
                                      {(
                                        data.checkInReport.requestorMember
                                          ?.displayName ??
                                        data.checkInReport.requestorMember
                                          ?.fullName ??
                                        'Deleted Member'
                                      ).slice(0, 2)}
                                    </AvatarFallback>
                                  </Avatar>
                                  {data.checkInReport.requestorMember
                                    ?.displayName ??
                                    data.checkInReport.requestorMember
                                      ?.fullName ??
                                    'Deleted Member'}
                                </span>
                              </DataListValue>
                            </DataListItem>
                            <DataListItem>
                              <DataListLabel>Cutoff</DataListLabel>
                              <DataListValue className="ml-auto">
                                {data.checkInReport.cutoffAt ? (
                                  <Datetime
                                    datetime={data.checkInReport.cutoffAt}
                                  />
                                ) : (
                                  'No cutoff'
                                )}
                              </DataListValue>
                            </DataListItem>
                            <DataListItem>
                              <DataListLabel>Place Restriction</DataListLabel>
                              <DataListValue className="ml-auto">
                                {data.checkInReport.place
                                  ? data.checkInReport.place.name
                                  : 'None'}
                              </DataListValue>
                            </DataListItem>
                          </DataList>

                          <div className="mt-6">
                            <div className="flex items-center py-1 border-b px-2">
                              <span className="text-xs uppercase font-bold text-muted-foreground grow">
                                Member
                              </span>
                              <span className="text-right text-muted-foreground text-xs uppercase font-bold w-14">
                                Status
                              </span>
                            </div>
                            <Input
                              className="mt-1 h-8"
                              onChange={(event) => {
                                setPage(0);
                                setSearch(event.target.value);
                              }}
                              placeholder="Filter Members..."
                              type="search"
                              value={search}
                            />
                            <div className="mt-1 space-y-1">
                              {checkInRequests
                                .slice(page * 7, page * 7 + 7)
                                .map((item) => (
                                  <div
                                    className="flex items-center py-1 px-2 rounded-lg border"
                                    key={item.checkInRequestId}
                                  >
                                    <span className="flex items-center grow text-sm">
                                      <Avatar className="h-5 w-5 mr-2">
                                        <AvatarImage
                                          alt={
                                            item.member?.displayName ??
                                            item.member?.fullName ??
                                            'Deleted Member'
                                          }
                                          src={
                                            item.member?.avatarUrl ?? undefined
                                          }
                                        />
                                        <AvatarFallback>
                                          {(
                                            item.member?.displayName ??
                                            item.member?.fullName ??
                                            'Deleted Member'
                                          ).slice(0, 2)}
                                        </AvatarFallback>
                                      </Avatar>
                                      {item.member?.displayName ??
                                        item.member?.fullName ??
                                        'Deleted Member'}

                                      <span className="ml-auto flex items-center gap-2 justify-end">
                                        {item.checkIn && (
                                          <>
                                            <Tooltip>
                                              <TooltipTrigger asChild>
                                                <Link
                                                  className="hover:text-info-foreground"
                                                  to={`/check-in/${item.checkIn.id}`}
                                                >
                                                  <LinkIcon className="h-5 w-5" />
                                                </Link>
                                              </TooltipTrigger>
                                              <TooltipContent side="bottom">
                                                View Details
                                              </TooltipContent>
                                            </Tooltip>

                                            <ZoomToButton
                                              lat={item.checkIn.coordinates.y}
                                              lng={item.checkIn.coordinates.x}
                                            />
                                          </>
                                        )}
                                      </span>
                                    </span>

                                    <span className="w-14 flex items-center justify-end">
                                      <CheckInResponseStatus
                                        createdAt={item.checkIn?.createdAt}
                                        cutoff={data.checkInReport?.cutoffAt}
                                      />
                                    </span>
                                  </div>
                                ))}

                              {pagninationRange.length > 1 && (
                                <div className="text-sm flex items-center justify-center gap-2 py-1 px-2">
                                  <Button
                                    className="h-6 w-6 p-1"
                                    disabled={page === 0}
                                    onClick={() =>
                                      setPage((current) => current - 1)
                                    }
                                    variant="outline"
                                  >
                                    <ChevronLeft className="h-4 w-4" />
                                  </Button>

                                  {pagninationRange.map((item) => (
                                    <Fragment key={item}>
                                      {item === -1 || item === -2 ? (
                                        <PaginationEllipsis className="h-6 w-6" />
                                      ) : (
                                        <Button
                                          className="h-6 w-6 p-1"
                                          key={item}
                                          onClick={() => setPage(item - 1)}
                                          variant={
                                            item === page + 1
                                              ? 'default'
                                              : 'outline'
                                          }
                                        >
                                          {item}
                                        </Button>
                                      )}
                                    </Fragment>
                                  ))}

                                  <Button
                                    className="h-6 w-6 p-1"
                                    disabled={page === totalPages - 1}
                                    onClick={() =>
                                      setPage((current) => current + 1)
                                    }
                                    variant="outline"
                                  >
                                    <ChevronRight className="h-4 w-4" />
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </MapControl>
                    {checkIns.map((item) => (
                      <AdvancedMarker
                        key={item.checkIn?.id}
                        position={{
                          lat: item.checkIn.coordinates.y,
                          lng: item.checkIn.coordinates.x,
                        }}
                      >
                        <Pin size="avatar">
                          <Avatar className="h-8 w-8">
                            <AvatarImage
                              alt={
                                item.member?.displayName ??
                                item.member?.fullName ??
                                'Deleted Member'
                              }
                              src={item.member?.avatarUrl ?? undefined}
                            />
                            <AvatarFallback>
                              {(
                                item.member?.displayName ??
                                item.member?.fullName ??
                                'Deleted Member'
                              ).slice(0, 2)}
                            </AvatarFallback>
                          </Avatar>
                        </Pin>
                      </AdvancedMarker>
                    ))}
                    {data.checkInReport.place &&
                      data.checkInReport.place.spatialData.geojson.type ===
                        'Polygon' && (
                        <Polygon
                          paths={(
                            data.checkInReport.place.spatialData.geojson
                              .coordinates as number[][][]
                          ).map((outer) => {
                            return outer.map((inner) => {
                              const [lng, lat] = inner;
                              return { lat, lng };
                            });
                          })}
                        />
                      )}
                    {data.checkInReport.place &&
                      data.checkInReport.place.spatialData.geojson.type ===
                        'Point' && (
                        <Circle
                          center={{
                            lat: data.checkInReport.place.spatialData.geojson
                              .coordinates[1],
                            lng: data.checkInReport.place.spatialData.geojson
                              .coordinates[0],
                          }}
                          radius={data.checkInReport.place.radius}
                        />
                      )}
                  </Map>
                </div>
              </>
            ) : (
              <ClientError
                code={404}
                message="Check in does not exist"
              />
            )}
          </>
        </Loader>
      </MapProvider>
    </>
  );
};

export { CheckInReport };
