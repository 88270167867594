import { AppSidebarContent } from './AppSidebarContent';
import { AppSidebarFooter } from './AppSidebarFooter';
import { AppSidebarHeader } from './AppSidebarHeader';
import { Sidebar, SidebarRail } from '@/components/ui/sidebar';
import { ApplicationRole } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import * as React from 'react';

const AppSidebar = ({ ...props }: React.ComponentProps<typeof Sidebar>) => {
  const [
    currentUser,
    activeOrganization,
    organizations,
    setActiveOrganization,
  ] = useAppStore((state) => [
    state.currentUser,
    state.activeMembership,
    state.memberships,
    state.setActiveMembership,
  ]);

  return (
    <Sidebar
      collapsible="icon"
      {...props}
    >
      <AppSidebarHeader
        activeOrganization={activeOrganization}
        organizations={organizations}
        setActiveOrganization={setActiveOrganization}
      />

      <AppSidebarContent activeOrganization={activeOrganization} />

      <AppSidebarFooter
        isAdmin={currentUser.role === ApplicationRole.GlobalAdmin}
        user={currentUser}
      />

      <SidebarRail />
    </Sidebar>
  );
};

export { AppSidebar };
