import { AvatarEditor, type AvatarEditorType } from '@/components/AvatarEditor';
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { MutationError } from '@/features/Core';
import { graphql } from '@/gql';
import { LogoType, type Organization, UpdateLogoType } from '@/gql/graphql';
import { supportedTimezones } from '@/lib/timezone';
import { toast } from '@/lib/toast';
import { generateAvatarUrl } from '@/utils/generateAvatarUrl';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { boolean, type Infer, object, string } from 'superstruct';
import { useMutation } from 'urql';

const OrganizationUploadLogoGql = graphql(`
  mutation OrganizationUploadLogoGql($input: uploadOrganizationLogoInput!) {
    uploadOrganizationLogo(input: $input) {
      id
      fields
      key
      url
    }
  }
`);

const updateOrganizationDetailsGql = graphql(`
  mutation updateOrganizationDetailsGql(
    $input: UpdateOrganizationDetailsInput!
  ) {
    updateOrganizationDetails(input: $input) {
      organization {
        id
        logoUrl
      }
    }
  }
`);

const schema = object({
  logoType: string(),
  name: string(),
  ownerMemberId: string(),
  timezone: string(),
  updatedCustomLogo: boolean(),
});

type SettingsGeneralProps = {
  readonly isAdmin?: boolean;
  readonly logoType?: Organization['logoType'];
  readonly logoUrl?: Organization['logoUrl'];
  readonly name?: Organization['name'];
  readonly organizationId: string;
  readonly ownerMemberId?: Organization['ownerMemberId'];
  readonly timezone?: Organization['timezone'];
};

const SettingsForm = ({
  isAdmin,
  logoType,
  logoUrl,
  name,
  organizationId,
  ownerMemberId,
  timezone,
}: SettingsGeneralProps) => {
  const [{ error: logoError, fetching: logoFetching }, uploadLogo] =
    useMutation(OrganizationUploadLogoGql);
  const [{ error: updateError, fetching: updateFetching }, updateDetails] =
    useMutation(updateOrganizationDetailsGql);

  const error = logoError || updateError;
  const fetching = logoFetching || updateFetching;

  const avatarEditorRef = useRef<AvatarEditorType | null>(null);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      logoType: logoType ?? 'initials',
      name: name ?? '',
      ownerMemberId: ownerMemberId ?? undefined,
      timezone: timezone ?? '',
      updatedCustomLogo: false,
    },
    resolver: superstructResolver(schema),
  });

  useEffect(() => {
    form.reset({
      logoType: logoType ?? 'initials',
      name: name ?? '',
      ownerMemberId: ownerMemberId ?? undefined,
      timezone: timezone ?? '',
      updatedCustomLogo: false,
    });
  }, [form, logoType, name, ownerMemberId, timezone]);

  const watchName = form.watch('name');

  if (!logoType || !logoUrl || !name || !ownerMemberId || !timezone) {
    return null;
  }

  const onSubmit = async (values: Infer<typeof schema>) => {
    let updatedLogoType =
      values.logoType === logoType
        ? UpdateLogoType.Unchanged
        : (values.logoType as UpdateLogoType);
    let key = '';

    if (
      values.logoType === UpdateLogoType.Custom &&
      avatarEditorRef.current &&
      values.updatedCustomLogo
    ) {
      updatedLogoType = UpdateLogoType.Custom;

      // get blob from avatarEditor
      const dataUrl = avatarEditorRef.current
        .getImageScaledToCanvas()
        .toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();

      const uploadResponse = await uploadLogo({
        input: {
          mimetype: blob.type,
          organizationId,
          useWorkerTask: false,
        },
      });

      const uploadData = uploadResponse?.data?.uploadOrganizationLogo;

      if (!uploadData) {
        throw new Error('Unknown upload data');
      }

      const formData = new FormData();
      if (!Object.keys(uploadData.fields).includes('key')) {
        formData.append('key', uploadData.key);
      }

      const fieldsObject = JSON.parse(uploadData.fields as string);
      for (const field of Object.keys(fieldsObject)) {
        formData.append(field, fieldsObject[field]);
      }

      formData.append('file', blob);

      // update key to ensure backend can locate file during update
      key = uploadData.key;

      try {
        const fetchResponse = await fetch(uploadData.url, {
          body: formData,
          method: 'POST',
        });

        if (fetchResponse.status !== 204) {
          throw new Error('Problem uploading logo!');
        }
      } catch {
        toast.error('Could not upload logo!');
      }
    }

    const response = await updateDetails({
      input: {
        customLogoKey: key,
        logoType: updatedLogoType,
        name: values.name,
        organizationId,
        ownerMemberId: values.ownerMemberId,
        timezone: values.timezone,
      },
    });

    if (response.error) {
      toast.error('Problem updating organization settings!', {
        description: response.error.message,
      });
    } else {
      toast.success('Organization settings updated!');

      form.reset({ ...values, updatedCustomLogo: false });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
          <div className="col-span-full">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormDescription>
                    The name of your organization that will be displayed to your
                    members.
                  </FormDescription>
                  <FormControl>
                    <Input
                      placeholder="organization name"
                      readOnly={!isAdmin}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="col-span-full">
            <FormField
              control={form.control}
              name="timezone"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel>Timezone</FormLabel>
                  <Select
                    defaultValue={value}
                    onValueChange={onChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Timezone" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {supportedTimezones.map((item) => (
                        <SelectItem
                          key={item}
                          value={item}
                        >
                          {item}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="col-span-full">
            <FormField
              control={form.control}
              name="logoType"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel>Logo</FormLabel>
                  <div className="flex flex-row items-start justify-between rounded-lg border p-4">
                    <FormControl>
                      <div className="w-1/2">
                        <RadioGroup
                          className="flex flex-col space-y-1"
                          defaultValue={value}
                          onValueChange={onChange}
                        >
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={UpdateLogoType.Initials} />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Use initials
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={UpdateLogoType.Custom} />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Upload an image
                            </FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </div>
                    </FormControl>

                    <div className="w-1/2 flex items-center">
                      {value === UpdateLogoType.Initials && (
                        <div className="mx-auto">
                          <Avatar className="size-16">
                            <AvatarImage
                              src={generateAvatarUrl(watchName, 1)}
                            />
                          </Avatar>
                        </div>
                      )}
                      {value === UpdateLogoType.Custom && (
                        <div className="mx-auto">
                          <AvatarEditor
                            blobRef={avatarEditorRef}
                            defaultValue={
                              logoType === LogoType.Custom ? logoUrl : undefined
                            }
                            onValueChange={() => {
                              form.setValue('updatedCustomLogo', true, {
                                shouldDirty: true,
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="col-span-full">
            <MutationError error={error} />
          </div>

          <div className="col-span-full mt-6 flex justify-end items-center">
            {form.formState.isDirty && (
              <Button
                disabled={fetching}
                onClick={() => form.reset()}
                type="reset"
                variant="ghost"
              >
                {fetching && <Loader className="h-6 w-6 animate-spin mr-2" />}
                Reset
              </Button>
            )}
            <Button
              disabled={fetching || !form.formState.isDirty}
              type="submit"
            >
              Save Settings
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { SettingsForm };
