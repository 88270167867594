import { Billing } from './routes/Billing';
import { GeneralSettings } from './routes/GeneralSettings';
import { IncidentTypes } from './routes/IncidentTypes';
import { Integrations } from './routes/Integrations';
import { NotificationsSettings } from './routes/NotificationsSettings';
import { OrganizationSettingsLayout } from './routes/OrganizationSettingsLayout';
import { Places } from './routes/Places';
import { Navigate, type RouteObject } from 'react-router-dom';

export const organizationRoutes: RouteObject[] = [
  {
    children: [
      {
        Component: GeneralSettings,
        path: '/settings/general',
      },
      {
        Component: Billing,
        path: '/settings/billing',
      },
      {
        Component: IncidentTypes,
        path: '/settings/incident-types',
      },
      {
        Component: NotificationsSettings,
        path: '/settings/notifications',
      },
      {
        Component: Places,
        path: '/settings/places',
      },
      {
        Component: Integrations,
        path: '/settings/integrations',
      },
      {
        element: <Navigate to="/settings/general" />,
        path: '/settings/*',
      },
    ],
    element: <OrganizationSettingsLayout />,
  },
];
