import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { percentChange } from '@/lib/percentChange';
import { daysFromStartOfWeek, formatDatetime } from '@/lib/time';
import { Pin, TrendingDown, TrendingUp } from 'lucide-react';
import { Bar, BarChart, CartesianGrid, Rectangle, XAxis } from 'recharts';
import { useQuery } from 'urql';

const CheckInCountGql = graphql(`
  query CheckInCountGql($organizationId: String!, $days: Int!) {
    organizationMetric(organizationId: $organizationId) {
      nodeId
      checkInsTotal
      checkInReportsTotal
      checkInsGraph(days: $days, timezone: "US/Eastern") {
        date
        count
      }
    }
  }
`);

type Props = {
  readonly organizationId: string;
};

const CheckInCount = ({ organizationId }: Props) => {
  const days = daysFromStartOfWeek();

  const [{ data }] = useQuery({
    query: CheckInCountGql,
    variables: {
      days: days + 7,
      organizationId,
    },
  });

  const lastweek =
    data?.organizationMetric?.checkInsGraph?.slice(
      0,
      data?.organizationMetric?.checkInsGraph?.length - days,
    ) ?? [];

  const thisweek =
    data?.organizationMetric?.checkInsGraph?.slice(
      data?.organizationMetric?.checkInsGraph?.length - days,
    ) ?? [];

  const countThisWeek = thisweek.reduce(
    (accumulator, currentValue) =>
      accumulator + Number.parseInt(currentValue?.count, 10),
    0,
  );

  const countLastWeek = lastweek.reduce(
    (accumulator, currentValue) =>
      accumulator + Number.parseInt(currentValue?.count, 10),
    0,
  );

  const change = percentChange(countLastWeek, countThisWeek);

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">Check In</CardTitle>
        <Pin className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        {data?.organizationMetric ? (
          <div className="text-2xl font-bold">
            +{countThisWeek.toLocaleString()}
          </div>
        ) : (
          <Skeleton className="h-8 w-1/4" />
        )}
        {data?.organizationMetric ? (
          <p className="text-xs text-muted-foreground flex items-center">
            {change ? change.toLocaleString() : '+0'}% from last week
            {change > 0 && <TrendingUp className="ml-1 h-4 w-4" />}
            {change < 0 && <TrendingDown className="ml-1 h-4 w-4" />}
          </p>
        ) : (
          <Skeleton className="h-4 w-1/2" />
        )}
        {data?.organizationMetric?.checkInsGraph ? (
          <ChartContainer
            className="mt-2 h-12 w-full border-b"
            config={{
              count: {
                color: 'hsl(var(--chart-1))',
                label: 'Count',
              },
            }}
          >
            <BarChart
              accessibilityLayer
              data={
                data.organizationMetric.checkInsGraph.map((item) => ({
                  count: item?.count ? Number.parseInt(item.count, 10) : 0,
                  date: formatDatetime({
                    datetime: item?.date,
                    format: 'MMM dd, yyyy',
                  }),
                })) ?? []
              }
              margin={{
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                axisLine={false}
                dataKey="date"
                hide
                tickLine={false}
                tickMargin={4}
              />
              <ChartTooltip content={<ChartTooltipContent />} />
              <Bar
                activeBar={<Rectangle fillOpacity={0.8} />}
                activeIndex={data.organizationMetric.checkInsGraph.length - 1}
                dataKey="count"
                fill="var(--color-count)"
                fillOpacity={0.2}
                radius={2}
              />
            </BarChart>
          </ChartContainer>
        ) : (
          <Skeleton className="mt-2 h-12 w-full" />
        )}
      </CardContent>
    </Card>
  );
};

export { CheckInCount };
