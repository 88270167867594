import { GroupMemberAvatarGroup } from './GroupMemberAvatarGroup';
import { NewGroupDialog } from './NewGroupDialog';
import { SectionGroupsSheet } from './SectionGroupsSheet';
import { DebouncedInput } from '@/components/debounced-input';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { type Group, type Member } from '@/gql/graphql';
import { pluralize } from '@/lib/pluralize';
import { MoreVertical } from 'lucide-react';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

type Props = {
  readonly activeMembers?: Array<
    Pick<Member, 'id' | 'avatarUrl' | 'fullName' | 'displayName'>
  >;
  readonly allMembers?: Array<
    Pick<Member, 'id' | 'avatarUrl' | 'fullName' | 'displayName'>
  >;
  readonly fetching?: boolean;
  readonly groups?: Array<
    Pick<Group, 'id' | 'name' | 'description'> & {
      members?: { nodes: Array<{ id: string }> } | null;
    }
  >;
  readonly organizationId: string;
};

const SectionGroups = ({
  activeMembers = [],
  allMembers = [],
  fetching,
  groups = [],
  organizationId,
}: Props) => {
  const [search, setSearch] = useState('');
  const [searchParameters, setSearchParameters] = useSearchParams();
  const id = searchParameters.get('id');

  const selected = groups?.find((item) => item.id === id);
  const filteredGroups = groups
    ?.map((item) => ({
      ...item,
      search: item.name.toLowerCase() + item.description.toLowerCase(),
    }))
    .filter((item) =>
      search ? item.search.includes(search.toLowerCase()) : true,
    );

  return (
    <section aria-labelledby="groups">
      <div className="sm:flex sm:items-start sm:justify-between">
        <p className="text-sm leading-6 text-muted-foreground max-w-lg">
          Groups can be used to logically seperate members of your organization.
          Administrators can add, manage, and delete groups.
        </p>

        <NewGroupDialog
          activeMembers={activeMembers}
          organizationId={organizationId}
        />
      </div>

      <div className="py-2 flex md:items-center">
        <DebouncedInput
          className="w-64"
          onChange={(value) => setSearch(value)}
          placeholder="Search groups..."
          type="search"
          value={search}
        />

        <div className="md:ml-auto font-semibold text-sm text-muted-foreground">
          {filteredGroups?.length !== groups?.length &&
            `${filteredGroups?.length.toString()} of `}
          {groups?.length.toString()} {pluralize('group', groups?.length)}
        </div>
      </div>

      <ul className="border-t border-b divide-y">
        {fetching &&
          !filteredGroups &&
          [0, 1, 2, 3, 4, 5].map((item) => (
            <li
              className="flex items-center justify-between gap-x-6 py-2.5"
              key={item}
            >
              <div className="flex items-center gap-x-4 truncate">
                <Skeleton className="size-8 shrink-0 rounded-full" />
                <Skeleton className="w-64 h-8" />
              </div>
              <div className="flex items-center gap-2">
                <Skeleton className="w-32 h-8" />
                <Skeleton className="w-32 h-8" />
                <Skeleton className="w-8 h-8" />
              </div>
            </li>
          ))}
        {filteredGroups?.map((item) => (
          <li
            className="flex items-center justify-between gap-x-6 py-2.5"
            key={item.id}
          >
            <div className="flex items-center gap-x-4 truncate">
              <Avatar className="size-8 shrink-0">
                <AvatarFallback>{item.name.slice(0, 1)}</AvatarFallback>
              </Avatar>
              <div className="truncate space-y-0.5">
                <p className="truncate text-sm font-medium">{item.name}</p>
                <p className="truncate text-xs flex gap-1">
                  {item.description}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-xs hidden md:block">
                <GroupMemberAvatarGroup
                  members={allMembers.filter((x) =>
                    item.members?.nodes.find((y) => y.id === x.id),
                  )}
                  to={`/members?view=groups&id=${item.id}`}
                />
              </div>
              <Link to={`/members?view=groups&id=${item.id}`}>
                <Button
                  size="icon"
                  variant="ghost"
                >
                  <MoreVertical />
                  <span className="sr-only">View Details</span>
                </Button>
              </Link>
            </div>
          </li>
        ))}
      </ul>

      <SectionGroupsSheet
        activeMembers={activeMembers}
        groupId={selected?.id}
        onOpenChange={() => {
          setSearchParameters({ view: 'groups' });
        }}
        open={Boolean(selected)}
      />
    </section>
  );
};

export { SectionGroups };
