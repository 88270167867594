import { AdministrationLayout } from './components/AdministrationLayout';
import { ApplicationLayout } from './components/ApplicationLayout';
import { AuthenticatedLayout } from './components/AuthenticatedLayout';
import { Authorize } from './components/Authorize';
import { RootLayout } from './components/RootLayout';
import { SignOut } from './components/SignOut';
import { accountRoutes } from '@/features/Account';
import { administrationRoutes } from '@/features/Administration';
import { checkInRoutes } from '@/features/CheckIn';
import { coreRoutes } from '@/features/Core';
import { dashbordRoutes } from '@/features/Dashboard';
import { guardMeRoutes } from '@/features/GuardMe';
import { homeRoutes } from '@/features/Home';
import { incidentsRoutes } from '@/features/Incidents';
import { membersRoutes } from '@/features/Members';
import { newOrganizationRoutes } from '@/features/NewOrganization';
import { organizationRoutes } from '@/features/Organization';
import { ErrorBoundary } from '@sentry/react';
import { Navigate, type RouteObject } from 'react-router-dom';

const appRoutes: RouteObject[] = [
  {
    children: [
      {
        children: [
          {
            children: [
              ...coreRoutes,
              ...dashbordRoutes,
              ...homeRoutes,
              ...checkInRoutes,
              ...guardMeRoutes,
              ...incidentsRoutes,
              ...membersRoutes,
              ...accountRoutes,
              ...organizationRoutes,
            ],
            element: <ApplicationLayout />,
          },
          {
            children: [...administrationRoutes],
            element: <AdministrationLayout />,
          },
          ...newOrganizationRoutes,
        ],
        element: <AuthenticatedLayout />,
      },
      {
        element: <Authorize />,
        path: '/authorize',
      },
      {
        element: <SignOut />,
        path: '/auth/signout',
      },
      {
        element: <Navigate to="/home" />,
        path: '*',
      },
    ],
    element: <RootLayout />,
    ErrorBoundary,
  },
];

export { appRoutes };
