import { IncidentPriorityType, IncidentStatusType } from '@/gql/graphql';
import {
  ARRAY_DELIMITER,
  baseDataTableQueryStates,
  RANGE_DELIMITER,
} from '@/lib/data-table';
import {
  createParser,
  parseAsArrayOf,
  parseAsStringEnum,
  parseAsTimestamp,
  useQueryStates,
} from 'nuqs';

const IncidentStatusToQueryValue = {
  [IncidentStatusType.Closed]: IncidentStatusType.Closed.toLowerCase(),
  [IncidentStatusType.InProgress]: IncidentStatusType.InProgress.toLowerCase(),
  [IncidentStatusType.Open]: IncidentStatusType.Open.toLowerCase(),
} as const;

const IncidentStatusFromQueryValue = {
  [IncidentStatusType.Closed.toLowerCase()]: IncidentStatusType.Closed,
  [IncidentStatusType.InProgress.toLowerCase()]: IncidentStatusType.InProgress,
  [IncidentStatusType.Open.toLowerCase()]: IncidentStatusType.Open,
} as const;

const parseAsIncidentStatus = createParser({
  parse(queryValue) {
    return IncidentStatusFromQueryValue[queryValue];
  },
  serialize(value) {
    return IncidentStatusToQueryValue[value];
  },
});

const IncidentPriorityToQueryValue = {
  [IncidentPriorityType.High]: IncidentPriorityType.High.toLowerCase(),
  [IncidentPriorityType.Low]: IncidentPriorityType.Low.toLowerCase(),
  [IncidentPriorityType.Medium]: IncidentPriorityType.Medium.toLowerCase(),
  [IncidentPriorityType.Urgent]: IncidentPriorityType.Urgent.toLowerCase(),
} as const;

const IncidentPriorityFromQueryValue = {
  [IncidentPriorityType.High.toLowerCase()]: IncidentPriorityType.High,
  [IncidentPriorityType.Low.toLowerCase()]: IncidentPriorityType.Low,
  [IncidentPriorityType.Medium.toLowerCase()]: IncidentPriorityType.Medium,
  [IncidentPriorityType.Urgent.toLowerCase()]: IncidentPriorityType.Urgent,
} as const;

const parseAsIncidentPriority = createParser({
  parse(queryValue) {
    return IncidentPriorityFromQueryValue[queryValue];
  },
  serialize(value) {
    return IncidentPriorityToQueryValue[value];
  },
});

const incidentsDataTableQueryStates = {
  parsers: {
    ...baseDataTableQueryStates.parsers,
    createdAt: parseAsArrayOf(parseAsTimestamp, RANGE_DELIMITER),
    priority: parseAsArrayOf(parseAsIncidentPriority, ARRAY_DELIMITER),
    status: parseAsArrayOf(parseAsIncidentStatus, ARRAY_DELIMITER),
  },
  urlKeys: {
    ...baseDataTableQueryStates.urlKeys,
    createdAt: 'created',
    priority: 'priority',
    status: 'status',
  },
};

const useIncidentsSearchParameters = () =>
  useQueryStates(
    {
      ...incidentsDataTableQueryStates.parsers,
      tab: parseAsStringEnum(['all', 'contact', 'group']).withDefault('all'),
    },
    {
      urlKeys: { ...incidentsDataTableQueryStates.urlKeys, tab: 't' },
    },
  );

export { incidentsDataTableQueryStates, useIncidentsSearchParameters };
