import { DeleteMembershipRequestsCodeDialog } from './DeleteMembershipRequestsCodeDialog';
import { Empty, Spinner } from '@/components';
import { Datetime } from '@/components/Datetime';
import { EmptyState } from '@/components/empty-state';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { type Group, type MembershipCode } from '@/gql/graphql';
import { normalizeMemberRole } from '@/utils';
import { CircleCheck } from 'lucide-react';
import { Link } from 'react-router-dom';

type Props = {
  readonly fetching?: boolean;
  readonly groups?: Array<Pick<Group, 'id' | 'name'>>;
  readonly membershipCodes?: Array<
    Pick<
      MembershipCode,
      | 'id'
      | 'createdAt'
      | 'code'
      | 'groupIds'
      | 'organizationRole'
      | 'requiresApproval'
    >
  >;
};

const MembershipCodesTable = ({
  fetching = false,
  groups = [],
  membershipCodes = [],
}: Props) => {
  if (fetching) {
    return <Skeleton className="w-full h-64" />;
  }

  if (membershipCodes.length === 0) {
    return (
      <EmptyState
        description="Enable members to join your organization by creating a new code."
        title="No Membership Codes"
      />
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Created</TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Groups</TableHead>
          <TableHead className="text-center">Requires Approval?</TableHead>
          <TableHead>
            <span className="sr-only">Actions</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {fetching ? (
          <TableRow>
            <TableCell
              className="h-24 text-center"
              colSpan={6}
            >
              <Spinner title="Loading..." />
            </TableCell>
          </TableRow>
        ) : membershipCodes.length > 0 ? (
          membershipCodes.map((item) => (
            <TableRow key={item.id}>
              <TableCell className="font-bold">{item.code}</TableCell>
              <TableCell>
                <Datetime
                  datetime={item.createdAt}
                  format="MMM dd, yyyy"
                />
              </TableCell>
              <TableCell>
                {normalizeMemberRole(item.organizationRole)}
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-1">
                  {item.groupIds.map((group) => {
                    const value = groups.find((x) => x.id === group);
                    if (value) {
                      return (
                        <TooltipProvider key={value.id}>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Link to={`/members?view=groups&id=${value.id}`}>
                                <Avatar className="size-6">
                                  <AvatarFallback>
                                    {value.name.slice(0, 1).toUpperCase()}
                                  </AvatarFallback>
                                </Avatar>
                              </Link>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{value.name}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      );
                    }

                    return null;
                  })}
                </div>
              </TableCell>
              <TableCell>
                {item.requiresApproval && (
                  <CircleCheck className="mx-auto h-6 w-6 text-success-foreground" />
                )}
              </TableCell>
              <TableCell className="text-right">
                <DeleteMembershipRequestsCodeDialog id={item.id} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <Empty message="Add a Membership Code to allow others to easily join your organization!" />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export { MembershipCodesTable };
