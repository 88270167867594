import { AccountDetailsForm } from '../components/AccountDetailsForm';
import { CloseAccountDialog } from '../components/CloseAccountDialog';
import { ContactDetailsForm } from '../components/ContactDetailsForm';
import {
  AnnotatedContent,
  AnnotatedHeader,
  AnnotatedLayout,
  AnnotatedSection,
  AnnotatedSeparator,
} from '@/components/annotated';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { useQuery } from 'urql';

const AccountDetailsGql = graphql(`
  query AccountDetailsGql {
    currentUser {
      id
      applicationRole
      timezone
      fullName
      avatarUrl
      avatarType
      metadata
      createdAt
      emailAddress
    }
  }
`);

const AccountDetails = () => {
  const [{ data, fetching }] = useQuery({
    query: AccountDetailsGql,
  });

  return (
    <AnnotatedLayout>
      <AnnotatedSection aria-labelledby="personal-information">
        <AnnotatedHeader
          description="Manage your personal information and avatar."
          title="Personal information"
          titleId="personal-information"
        />

        <AnnotatedContent>
          {fetching || !data?.currentUser ? (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
              <div className="col-span-full sm:col-span-3 space-y-2">
                <Skeleton className="w-32 h-5" />
                <Skeleton className="w-full h-10" />
              </div>
              <div className="col-span-full sm:col-span-3 space-y-2">
                <Skeleton className="w-32 h-5" />
                <Skeleton className="w-full h-10" />
              </div>
              <div className="col-span-full space-y-2">
                <Skeleton className="w-32 h-5" />
                <Skeleton className="w-full h-64" />
              </div>
              <div className="col-span-full mt-6 flex justify-end items-center">
                <Skeleton className="w-32 h-10" />
              </div>
            </div>
          ) : (
            <AccountDetailsForm user={data.currentUser} />
          )}
        </AnnotatedContent>
      </AnnotatedSection>

      <AnnotatedSeparator />

      <AnnotatedSection aria-labelledby="contact-details">
        <AnnotatedHeader
          description="Manage your email address and phone number."
          title="Contact details"
          titleId="contact-details"
        />

        <AnnotatedContent>
          {fetching || !data?.currentUser ? (
            <div className="grid grid-cols-1 gap-4">
              <div className="col-span-full space-y-2">
                <Skeleton className="w-32 h-5" />
                <Skeleton className="w-full h-10" />
              </div>
              <div className="col-span-full space-y-2">
                <Skeleton className="w-32 h-5" />
                <Skeleton className="w-full h-10" />
              </div>

              <div className="col-span-full mt-6 flex justify-end items-center">
                <Skeleton className="w-32 h-10" />
              </div>
            </div>
          ) : (
            <ContactDetailsForm {...data.currentUser} />
          )}
        </AnnotatedContent>
      </AnnotatedSection>

      <AnnotatedSeparator />

      <AnnotatedSection aria-labelledby="account-management">
        <AnnotatedHeader
          description="Manage general workspace. Contact system admin for more information."
          title="Danger zone"
          titleId="account-management"
        />

        <AnnotatedContent>
          <Card>
            <CardHeader className="flex flex-row justify-between items-start space-y-0">
              <div className="space-y-4">
                <CardTitle>Close Account</CardTitle>
                <CardDescription>
                  This will permanently remove all associated data for your
                  user. Closing your account is permanent and cannot be undone!
                </CardDescription>
              </div>
              <div>
                <CloseAccountDialog />
              </div>
            </CardHeader>
          </Card>
        </AnnotatedContent>
      </AnnotatedSection>
    </AnnotatedLayout>
  );
};

export { AccountDetails };
