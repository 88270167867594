import { CustomMetadata } from '../components/CustomMetadata';
import { MembershipCodesTable } from '../components/MembershipCodesTable';
import { NewCustomMetadataFieldDialog } from '../components/NewCustomMetadataFieldDialog';
import { NewMembershipCodeDialog } from '../components/NewMembershipCodeDialog';
import { SettingsForm } from '../components/SettingsForm';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { useQuery } from 'urql';

const GeneralSettingsGql = graphql(`
  query GeneralSettingsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      name
      timezone
      logoUrl
      logoType
      memberMetadataFields
      defaultMapCenter {
        longitude
        latitude
      }
      groups {
        totalCount
        nodes {
          id
          name
        }
      }
      defaultMapZoom
      ownerMemberId
      ownerMember {
        id
        avatarUrl
        fullName
        displayName
      }
      membershipCodes {
        nodes {
          id
          code
          groupIds
          organizationRole
          requiresApproval
          createdAt
        }
      }
      members {
        nodes {
          id
          fullName
          displayName
          isEnabled
        }
      }
    }
  }
`);

const GeneralSettings = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, fetching }, refetch] = useQuery({
    query: GeneralSettingsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <div className="space-y-10">
      <section aria-labelledby="personal-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="settings-general"
            >
              General
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Update your organzation details settings. Set your organization
              logo and timezone.
            </p>
          </div>
          <div className="md:col-span-2">
            {fetching || !data?.organization ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/5 h-5" />
                  <Skeleton className="w-3/5 h-5" />
                  <Skeleton className="w-full h-10" />
                </div>
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/5 h-5" />
                  <Skeleton className="w-full h-10" />
                </div>
                <div className="col-span-full space-y-2">
                  <Skeleton className="w-1/5 h-5" />
                  <div className="flex gap-4">
                    <Skeleton className="w-full h-64" />
                    <Skeleton className="w-full h-64" />
                  </div>
                </div>

                <div className="col-span-full mt-6 flex justify-end items-center">
                  <Skeleton className="w-32 h-10" />
                </div>
              </div>
            ) : (
              <SettingsForm
                {...data.organization}
                organizationId={organizationId}
              />
            )}
          </div>
        </div>
      </section>

      <Separator orientation="horizontal" />

      <section aria-labelledby="personal-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="settings-general"
            >
              Custom Metadata
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              You can add custom fields to your members to capture additional
              information.
            </p>
            <div className="mt-4">
              <NewCustomMetadataFieldDialog
                existingFields={data?.organization?.memberMetadataFields}
                onComplete={() => refetch({ requestPolicy: 'network-only' })}
                organizationId={organizationId}
              />
            </div>
          </div>
          <div className="md:col-span-2">
            <CustomMetadata
              fetching={fetching}
              memberMetadataFields={data?.organization?.memberMetadataFields}
              organizationId={organizationId}
            />
          </div>
        </div>
      </section>

      <Separator orientation="horizontal" />

      <section aria-labelledby="personal-information">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="settings-general"
            >
              Membership Codes
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Create custom codes that members can use to join your
              organization. Each code can be used to help sort new members into
              default groups or roles.
            </p>
            <div className="mt-4">
              <NewMembershipCodeDialog
                groups={data?.organization?.groups.nodes || []}
                onComplete={() => refetch({ requestPolicy: 'network-only' })}
                organizationId={organizationId}
              />
            </div>
          </div>
          <div className="md:col-span-2">
            <MembershipCodesTable
              fetching={fetching}
              groups={data?.organization?.groups.nodes}
              membershipCodes={data?.organization?.membershipCodes.nodes}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export { GeneralSettings };
