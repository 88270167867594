import { BivySticks } from '../components/BivySticks';
import { RapidSosIntegrationForm } from '../components/RapidSosIntegrationForm';
import { Separator } from '@/components/ui/separator';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Zap } from 'lucide-react';
import { useQuery } from 'urql';

const IntegrationsGql = graphql(`
  query IntegrationsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      settingIsRapidsosOperatorFlowEnabled
      settingIsRapidsosPanicButtonFlowEnabled
      settingRapidsosSocPhoneNumber
      plan {
        id
        name
        featureRapidsos
      }
      bivySticks {
        nodes {
          id
          imei
          member {
            id
            fullName
            displayName
            avatarUrl
          }
          isRegistered
          createdAt
          updatedAt
        }
      }
    }
  }
`);

const Integrations = () => {
  const organizationId = useAppStore((state) => state.activeMembership?.id);

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, fetching }] = useQuery({
    query: IntegrationsGql,
    variables: {
      organizationId,
    },
  });

  return (
    <div className="space-y-10">
      <section aria-labelledby="911-integration">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div className="space-y-1">
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="911-integration"
            >
              911 Integration
            </h2>
            <div className="bg-primary text-primary-foreground flex items-center text-xs font-semibold p-1 rounded">
              <Zap className="size-4 mr-1" /> Premium Feature
            </div>
            <p className="text-sm leading-6 text-gray-500">
              With 911 integration enabled a panic during a GuardMe session will
              allow 911 Emergency Services to be automatically connected with
              the individual in distress.
            </p>
          </div>
          <div className="md:col-span-2">
            <RapidSosIntegrationForm
              fetching={fetching}
              plan={data?.organization?.plan}
              settings={data?.organization}
            />
          </div>
        </div>
      </section>

      <Separator orientation="horizontal" />

      <section aria-labelledby="bivy-sticks">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              id="bivy-sticks"
            >
              Manage Bivy Sticks
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              A 2-way satellite communicator that allows you to text message,
              Check In, share your location, and integrate directly with
              GuardMe.
            </p>
          </div>
          <div className="md:col-span-2">
            <BivySticks
              bivySticks={data?.organization?.bivySticks.nodes}
              fetching={fetching}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export { Integrations };
