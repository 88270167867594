import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { graphql } from '@/gql';
import { cn } from '@/lib/utils';
import { ArrowUpDown, Check } from 'lucide-react';
import { useMutation } from 'urql';

const EnableIncidentTypeWithGroupGql = graphql(`
  mutation EnableIncidentTypeWithGroupGql(
    $organizationId: String!
    $incidentTypeId: String!
    $groupId: String!
  ) {
    enableIncidentForOrganization(
      input: {
        organizationId: $organizationId
        incidentTypeId: $incidentTypeId
        defaultAssignedGroupId: $groupId
      }
    ) {
      organization {
        id
        enabledIncidentTypes {
          totalCount
          nodes {
            id
            organizationId
            incidentTypeId
            defaultAssignedGroup {
              id
              name
            }
          }
        }
      }
    }
  }
`);

const UpdateIncidentTypeWithGroupGql = graphql(`
  mutation UpdateIncidentTypeWithGroupGql($id: String!, $groupId: String!) {
    updateEnabledIncidentTypeAssignedGroup(
      input: { id: $id, groupId: $groupId }
    ) {
      enabledIncidentType {
        id
        defaultAssignedGroup {
          id
          name
        }
      }
    }
  }
`);

type AssignDefaultGroupToIncidentTypeModalProps = {
  readonly assignedGroupId?: string;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly enabledIncidentTypeId?: string;
  readonly groups: Array<{ id: string; name: string }>;
  readonly incidentTypeId: string;
  readonly organizationId: string;
};

const AssignDefaultGroupToIncidentTypeModal = ({
  assignedGroupId,
  className,
  disabled,
  enabledIncidentTypeId,
  groups,
  incidentTypeId,
  organizationId,
}: AssignDefaultGroupToIncidentTypeModalProps) => {
  const [, updateIncidentType] = useMutation(UpdateIncidentTypeWithGroupGql);
  const [, enableIncidentType] = useMutation(EnableIncidentTypeWithGroupGql);

  if (disabled) {
    return null;
  }

  const handleOnChange = async (newValue: string) => {
    if (enabledIncidentTypeId) {
      await updateIncidentType({
        groupId: newValue === assignedGroupId ? '' : newValue,
        id: enabledIncidentTypeId,
      });
    } else {
      await enableIncidentType({
        groupId: newValue,
        incidentTypeId,
        organizationId,
      });
    }
  };

  const group = groups?.find((item) => item.id === assignedGroupId);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            'justify-between group text-sm p-0',
            group && 'hover:no-underline',
            className,
          )}
          disabled={disabled}
          size="sm"
          variant="link"
        >
          {group ? (
            <span className="flex items-center gap-2 pr-3 border rounded-full bg-muted text-foreground">
              <Avatar className="size-6">
                <AvatarFallback>{group.name.slice(0, 1)}</AvatarFallback>
              </Avatar>
              {group.name}
              <ArrowUpDown className="hidden group-hover:flex" />
            </span>
          ) : (
            <span>Select group...</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="p-0 PopoverContent min-w-64"
        side="bottom"
      >
        <Command>
          <CommandInput placeholder="Filter groups..." />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {groups?.map((item) => (
                <CommandItem
                  className={
                    item.id === group?.id ? 'text-sm bg-accent/50' : ''
                  }
                  key={item.id}
                  onSelect={() => {
                    handleOnChange(item.id === group?.id ? '' : item.id);
                  }}
                  value={`${item.id}`}
                >
                  <span>{item.name}</span>
                  {item.id === group?.id && (
                    <Check className="ml-auto size-6 shrink-0 text-primary" />
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { AssignDefaultGroupToIncidentTypeModal };
