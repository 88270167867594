import { CreateIncidentNoteForm } from '../components/CreateIncidentNoteForm';
import { EditIncidentContactDropdown } from '../components/EditIncidentContactDropdown';
import { EditIncidentGroupDropdown } from '../components/EditIncidentGroupDropdown';
import { EditIncidentOwnerDropdown } from '../components/EditIncidentOwnerDropdown';
import { EditIncidentPriorityDropdown } from '../components/EditIncidentPriorityDropdown';
import { EditIncidentStatusDropdown } from '../components/EditIncidentStatusDropdown';
import { IncidentDetail } from '../components/IncidentDetail';
import { IncidentLivestream } from '../components/IncidentLivestream';
import { IncidentLivestreamDrone } from '../components/IncidentLivestreamDrone';
import { IncidentLocation } from '../components/IncidentLocation';
import { IncidentReportPdf } from '../components/IncidentReportPdf';
import { IncidentUpdate } from '../components/IncidentUpdate';
import {
  IncidentUpdateContainer,
  IncidentUpdateSubtitle,
  IncidentUpdateTitle,
} from '../components/IncidentUpdateCompontent';
import { IncidentUpdates } from '../components/IncidentUpdates';
import { ClientError, Loader } from '@/components';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { Datetime } from '@/components/Datetime';
import { Header, HeaderSubtitle, HeaderTitle } from '@/components/header';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ZoomableImage } from '@/components/ZoomableImage';
import { graphql } from '@/gql';
import { IncidentUpdateTypeType, LivestreamStatus } from '@/gql/graphql';
import { useAppStore } from '@/stores';
import { fmtPhoneNumber } from '@/utils';
import { Dot, FileText, Mail, Map, Phone, Plus } from 'lucide-react';
import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentGql = graphql(`
  query IncidentGql($id: String!) {
    incident(id: $id) {
      id
      shortId
      organizationId
      updatedAt
      createdAt
      priority
      status
      subject
      description
      additionalData
      incidentCoordinates {
        x
        y
      }
      incidentCoordinatesGeocode {
        id
        address
        plusCode
      }
      submittedByMemberCoordinates {
        x
        y
      }
      submittedByMember {
        id
        fullName
        displayName
        avatarUrl
      }
      contactMemberId
      contactMember {
        id
        fullName
        displayName
        avatarUrl
        emailAddress
        phoneNumber
      }
      ownedByMemberId
      ownedByMember {
        id
        fullName
        displayName
        avatarUrl
      }
      ownedByGroupId
      ownedByGroup {
        id
        name
      }
      incidentUpdates {
        nodes {
          id
          type
          message
          createdAt
          previousValue
          updatedValue
          isSystemMessage
          performedByMember {
            id
            fullName
            displayName
            avatarUrl
          }
          attachments {
            nodes {
              id
              mimetype
              url
              filename
            }
          }
        }
      }
      livestreams {
        nodes {
          id
          status
          memberId
        }
      }
      incidentType {
        id
        name
        dataSchema
        uiSchema
        iconSvg
        allowDroneEndpoint
      }
      places {
        nodes {
          id
          name
        }
      }
    }
  }
`);

// eslint-disable-next-line complexity
const Incident = () => {
  const { id } = useParams() as { id: string };

  const [currentUser, isIncidentSupervisor, memberId, groups] = useAppStore(
    (state) => [
      state.currentUser,
      state.activeMembership?.isIncidentSupervisor,
      state.activeMembership?.memberId,
      state.activeMembership?.groups,
    ],
  );

  const [{ data, error, fetching }, refetch] = useQuery({
    query: IncidentGql,
    variables: {
      id,
    },
  });

  const canEditIncident =
    isIncidentSupervisor ||
    data?.incident?.ownedByMemberId === memberId ||
    groups?.includes(data?.incident?.ownedByGroupId ?? '---') ||
    false;

  const attachments: Array<{
    filename: string;
    id: string;
    type: 'livestream' | 'image' | 'file';
    url: string;
  }> = [];

  for (const node of data?.incident?.incidentUpdates.nodes || []) {
    if (
      node.type === IncidentUpdateTypeType.PublicNote ||
      node.type === IncidentUpdateTypeType.PrivateNote ||
      node.type === IncidentUpdateTypeType.AttachmentAdded
    ) {
      for (const attachment of node.attachments.nodes) {
        if (attachment.url && attachment.mimetype.startsWith('image/')) {
          attachments.push({
            filename: attachment.filename,
            id: attachment.id,
            type: 'image',
            url: attachment.url,
          });
        } else if (
          attachment.url &&
          attachment.mimetype.startsWith('application/pdf')
        ) {
          attachments.push({
            filename: attachment.filename,
            id: attachment.id,
            type: 'file',
            url: attachment.url,
          });
        }
      }
    } else if (node.type === IncidentUpdateTypeType.LivestreamStarted) {
      attachments.push({
        filename: '',
        id: node.updatedValue,
        type: 'livestream',
        url: '',
      });
    }
  }

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to="/incidents">Incidents</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Incident Details</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>
          {fetching ? 'Loading...' : data?.incident?.subject}
        </HeaderTitle>
        <HeaderSubtitle className="flex items-center">
          Submitted
          <Datetime
            className="ml-1"
            datetime={data?.incident?.createdAt}
            format="MMMM dd, yyyy 'at' hh:mm aaa"
          />
          {data?.incident?.createdAt !== data?.incident?.updatedAt && (
            <>
              <Dot className="w-4 h-4 mx-1" />
              <span className="italic">
                Updated
                <Datetime
                  className="ml-1 italic"
                  datetime={data?.incident?.updatedAt}
                  side="bottom"
                  variant="distance"
                />
              </span>
            </>
          )}
        </HeaderSubtitle>
      </Header>
      <div className="grid flex-1 items-start gap-4">
        <div className="grid flex-1 auto-rows-max gap-4">
          <Loader
            isLoading={fetching}
            loadingError={error}
          >
            <div className="">
              <div className="mt-4 ml-1 flex items-center">
                <div className="ml-0">
                  <div className="text-muted-foreground text-sm">ID</div>
                  <div>
                    {data?.incident?.shortId ? (
                      data?.incident?.shortId
                    ) : (
                      <Skeleton className="h-6 w-12" />
                    )}
                  </div>
                </div>

                <div className="ml-8 md:ml-16">
                  <div className="text-muted-foreground text-sm">Status</div>
                  <div>
                    {data?.incident?.status ? (
                      <EditIncidentStatusDropdown
                        incidentId={id}
                        onComplete={() =>
                          refetch({ requestPolicy: 'cache-and-network' })
                        }
                        readonly={!canEditIncident}
                        status={data.incident.status}
                      />
                    ) : (
                      <Skeleton className="h-6 w-12" />
                    )}
                  </div>
                </div>

                <div className="ml-6 md:ml-16">
                  <div className="text-muted-foreground text-sm">Priority</div>
                  <div>
                    {data?.incident?.priority ? (
                      <EditIncidentPriorityDropdown
                        incidentId={id}
                        onComplete={() =>
                          refetch({ requestPolicy: 'cache-and-network' })
                        }
                        priority={data.incident.priority}
                        readonly={!canEditIncident}
                      />
                    ) : (
                      <Skeleton className="h-6 w-12" />
                    )}
                  </div>
                </div>

                <div className="ml-auto flex items-center justify-end gap-2">
                  {data?.incident?.incidentType?.allowDroneEndpoint && (
                    <IncidentLivestreamDrone
                      contactMemberId={data?.incident?.contactMemberId}
                      incidentId={data.incident.id}
                      livestreams={
                        data.incident.livestreams.nodes
                          .filter((x) => x.status !== LivestreamStatus.Ended)
                          .map((x) => ({
                            id: x.id,
                            memberId: x.memberId,
                          })) ?? []
                      }
                      ownedByMemberId={data?.incident?.ownedByMemberId}
                    />
                  )}

                  {data?.incident && (
                    <IncidentReportPdf incidentId={data.incident.id} />
                  )}
                </div>
              </div>
            </div>

            {data?.incident ? (
              <div className="grid grid-cols-1 gap-4 md:grid-cols-8">
                <IncidentUpdates incidentId={data.incident.id} />

                <div className="col-span-1 lg:col-span-5">
                  <Tabs
                    className="w-full"
                    defaultValue="details"
                  >
                    <TabsList className="grid w-full grid-cols-3">
                      <TabsTrigger value="details">Details</TabsTrigger>
                      <TabsTrigger value="attachments">Attachments</TabsTrigger>
                      <TabsTrigger value="map">Map Location</TabsTrigger>
                    </TabsList>

                    <TabsContent value="details">
                      <IncidentDetail
                        additionalData={data.incident.additionalData}
                        canEditIncident={canEditIncident}
                        dataSchema={data.incident.incidentType?.dataSchema}
                        description={data.incident.description}
                        incidentId={data.incident.id}
                        incidentType={data.incident.incidentType}
                        subject={data.incident.subject}
                        uiSchema={data.incident.incidentType?.uiSchema}
                      />

                      <div>
                        <div className="grid auto-rows-max items-start gap-4 lg:col-span-2">
                          <div className="order-2 md:order-1 md:col-span-8 md:pr-2">
                            <div className="flow-root mt-6">
                              <ul className="-mb-8">
                                <li>
                                  <div className="relative pb-8">
                                    <span
                                      aria-hidden="true"
                                      className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                    />
                                    <div className="relative flex items-start space-x-3">
                                      <IncidentUpdateContainer icon={Plus}>
                                        <IncidentUpdateTitle>
                                          <strong>
                                            {data.incident.incidentType?.name}
                                          </strong>{' '}
                                          incident created
                                        </IncidentUpdateTitle>
                                        <IncidentUpdateSubtitle
                                          createdAt={data.incident.createdAt}
                                          isSystemMessage={false}
                                          performedByMember={
                                            data.incident.submittedByMember
                                          }
                                        />
                                      </IncidentUpdateContainer>
                                    </div>
                                  </div>
                                </li>
                                {data.incident.incidentUpdates.nodes.map(
                                  (item) => (
                                    <li key={item.id}>
                                      <div className="relative pb-8">
                                        <span
                                          aria-hidden="true"
                                          className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                        />
                                        <div className="relative flex items-start space-x-3">
                                          <IncidentUpdate
                                            dataSchema={
                                              data.incident?.incidentType
                                                ?.dataSchema
                                            }
                                            uiSchema={
                                              data.incident?.incidentType
                                                ?.uiSchema
                                            }
                                            update={item}
                                          />
                                        </div>
                                      </div>
                                    </li>
                                  ),
                                )}
                                {(canEditIncident ||
                                  data.incident.contactMemberId ===
                                    memberId) && (
                                  <li>
                                    <div className="relative pb-8">
                                      <div className="relative flex items-start space-x-3">
                                        <div className="relative">
                                          <Avatar className="h-10 w-10">
                                            <AvatarImage
                                              src={currentUser.avatarUrl}
                                            />
                                            <AvatarFallback>
                                              <Map className="h-6 w-6" />
                                            </AvatarFallback>
                                          </Avatar>
                                        </div>
                                        <div className="min-w-0 flex-1">
                                          <CreateIncidentNoteForm
                                            incidentId={data.incident.id}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabsContent>

                    <TabsContent value="map">
                      <IncidentLocation
                        coordinates={data.incident.incidentCoordinates}
                        fetching={fetching}
                        organizationId={data.incident.organizationId}
                        relatedPlaces={data.incident.places.nodes}
                      />
                    </TabsContent>

                    <TabsContent value="attachments">
                      <div className="grid grid-cols-2 gap-4">
                        {attachments.map((item) => (
                          <Fragment key={item.id}>
                            {item.type === 'livestream' && (
                              <IncidentLivestream
                                hideEmpty
                                id={item.id}
                              />
                            )}
                            {item.type === 'image' && (
                              <ZoomableImage src={item.url || undefined} />
                            )}
                            {item.type === 'file' && (
                              <a
                                className="col-span-full"
                                href={item.url}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Button
                                  className="w-full"
                                  variant="outline"
                                >
                                  <FileText className="w-4 h-4" />
                                  <span className="whitespace-nowrap">
                                    {item.filename ?? item.id}
                                  </span>
                                </Button>
                              </a>
                            )}
                          </Fragment>
                        ))}
                      </div>
                      {attachments.length === 0 && (
                        <Card className="p-0 font-semibold flex justify-center items-center h-64">
                          No Attachments
                        </Card>
                      )}
                    </TabsContent>
                  </Tabs>
                </div>

                <div className="col-span-1 md:col-span-3">
                  <div className="grid auto-rows-max items-start gap-4">
                    <Card className="p-0">
                      <CardHeader className="p-4 space-y-3">
                        <div className="w-full space-y-1">
                          <CardDescription className="flex items-center justify-between font-semibold">
                            Assigned Agent
                          </CardDescription>
                          <EditIncidentOwnerDropdown
                            className="w-full"
                            incidentId={id}
                            member={data.incident.ownedByMember}
                            onComplete={() =>
                              refetch({ requestPolicy: 'cache-and-network' })
                            }
                            readonly={!canEditIncident}
                          />
                        </div>

                        <div className="w-full space-y-1">
                          <CardDescription className="flex items-center justify-between font-semibold">
                            Group
                          </CardDescription>
                          <EditIncidentGroupDropdown
                            className="w-full"
                            group={data.incident.ownedByGroup}
                            incidentId={id}
                            onComplete={() =>
                              refetch({ requestPolicy: 'cache-and-network' })
                            }
                            readonly={!canEditIncident}
                          />
                        </div>
                      </CardHeader>
                    </Card>

                    <Card className="p-0">
                      <CardHeader className="p-4 pb-1">
                        <CardDescription className="flex items-center justify-between font-semibold">
                          Contact Information
                          {canEditIncident && (
                            <EditIncidentContactDropdown
                              incidentId={data.incident.id}
                              memberId={data.incident.contactMemberId}
                              onComplete={() =>
                                refetch({ requestPolicy: 'cache-and-network' })
                              }
                              organizationId={data.incident.organizationId}
                            />
                          )}
                        </CardDescription>
                        <CardTitle className="flex items-center gap-2 text-xl">
                          <Avatar className="h-6 w-6">
                            <AvatarImage
                              src={
                                data.incident.contactMember?.avatarUrl ??
                                undefined
                              }
                            />
                            <AvatarFallback>
                              {data.incident.contactMember?.displayName?.slice(
                                0,
                                2,
                              ) ??
                                data.incident.contactMember?.fullName?.slice(
                                  0,
                                  2,
                                ) ??
                                'M'}
                            </AvatarFallback>
                          </Avatar>
                          <Link
                            className="font-semibold hover:underline"
                            to={`/members/${data.incident.contactMember?.id}`}
                          >
                            {data.incident.contactMember?.displayName ??
                              data.incident.contactMember?.fullName}
                          </Link>
                        </CardTitle>
                      </CardHeader>
                      <CardContent className="p-4 pt-1">
                        <div className="flex items-center gap-2">
                          {data.incident.contactMember?.emailAddress ? (
                            <>
                              <Mail className="w-4 h-4 text-muted-foreground" />
                              <p className="mt-0.5 text-sm">
                                {data.incident.contactMember?.emailAddress}
                              </p>
                            </>
                          ) : (
                            'No email address'
                          )}
                        </div>
                        <div className="flex items-center gap-2">
                          {data.incident.contactMember?.phoneNumber ? (
                            <>
                              <Phone className="w-4 h-4 text-muted-foreground" />
                              <p className="mt-0.5 text-sm">
                                {fmtPhoneNumber(
                                  data.incident.contactMember?.phoneNumber,
                                )}
                              </p>
                            </>
                          ) : (
                            'No phone number'
                          )}
                        </div>
                      </CardContent>
                    </Card>

                    <Card className="p-0">
                      <CardHeader className="p-4 space-y-3">
                        <div className="w-full space-y-1">
                          <CardDescription className="flex items-center justify-between font-semibold">
                            Reported Location
                          </CardDescription>
                          <div>
                            <ul className="mt-3 grid gap-2">
                              <li className="flex items-start justify-between">
                                <span className="text-muted-foreground text-sm">
                                  Address
                                </span>
                                <address className="grid gap-0.5 not-italic text-sm text-right">
                                  {data.incident.incidentCoordinatesGeocode?.address
                                    .split(',')
                                    .map((item) => (
                                      <span key={item}>{item}</span>
                                    )) ?? <span>---</span>}
                                </address>
                              </li>
                              <li className="flex items-start justify-between">
                                <span className="text-muted-foreground text-sm">
                                  Latitude
                                </span>
                                <span className="text-sm">
                                  {data.incident.incidentCoordinates.y}
                                </span>
                              </li>
                              <li className="flex items-start justify-between">
                                <span className="text-muted-foreground text-sm">
                                  Longitude
                                </span>
                                <span className="text-sm">
                                  {data.incident.incidentCoordinates.x}
                                </span>
                              </li>
                              <li className="flex items-start justify-between">
                                <span className="text-muted-foreground text-sm">
                                  Google Plus Code
                                </span>
                                <span className="text-sm">
                                  {data.incident.incidentCoordinatesGeocode
                                    ?.plusCode ?? '---'}
                                </span>
                              </li>
                              {(data.incident.places.nodes.length ?? 0) > 0 && (
                                <li className="flex items-start justify-between">
                                  <span className="text-muted-foreground text-sm">
                                    Related Places
                                  </span>
                                  <span className="text-sm text-right">
                                    {data.incident.places.nodes.map((place) => (
                                      <Badge
                                        key={place.id}
                                        variant="secondary"
                                      >
                                        {place.name}
                                      </Badge>
                                    ))}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </CardHeader>
                    </Card>
                  </div>
                </div>
              </div>
            ) : (
              <ClientError
                code={404}
                message="Incident does not exist"
              />
            )}
          </Loader>
        </div>
      </div>
    </>
  );
};

export { Incident };
