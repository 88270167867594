import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import { LifeBuoy, Radio, Settings } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const navItems = [
  {
    icon: Settings,
    title: 'Overview',
    url: '/administration/overview',
  },
  {
    icon: Settings,
    title: 'Plans',
    url: '/administration/plans',
  },
  {
    icon: Settings,
    title: 'Organizations',
    url: '/administration/organizations',
  },
  {
    icon: Settings,
    title: 'Users',
    url: '/administration/users',
  },
  {
    icon: Settings,
    title: 'Incident Types',
    url: '/administration/incident-types',
  },
  {
    icon: Settings,
    title: 'Bivy Sticks',
    url: '/administration/bivy-sticks',
  },
  {
    icon: Settings,
    title: 'Color List',
    url: '/administration/colors',
  },
];

const navClientTools = [
  {
    icon: LifeBuoy,
    title: 'Check In',
    url: '/administration/client-tools/check-in',
  },
  {
    icon: Radio,
    title: 'GuardMe',
    url: '/administration/client-tools/guardme',
  },
];

const NavAdministration = () => {
  const { pathname } = useLocation();

  return (
    <>
      <SidebarGroup>
        <SidebarGroupLabel>Application</SidebarGroupLabel>
        <SidebarMenu>
          {navItems.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton
                asChild
                isActive={pathname === item.url}
                tooltip={item.title}
              >
                <Link to={item.url}>
                  <item.icon />
                  <span>{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup>
        <SidebarGroupLabel>Client Tools</SidebarGroupLabel>
        <SidebarMenu>
          {navClientTools.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton
                asChild
                isActive={pathname === item.url}
                tooltip={item.title}
              >
                <Link to={item.url}>
                  <item.icon />
                  <span>{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroup>
    </>
  );
};

export { NavAdministration };
