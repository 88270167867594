import { DeleteCustomMetadataFieldDialog } from './DeleteCustomMetadataFieldDialog';
import { Empty } from '@/components/Empty';
import { EmptyState } from '@/components/empty-state';
import { Alert } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { type Organization } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { Eye, Star } from 'lucide-react';

type MetadataField = {
  defaultValue: boolean | number | string;
  isPrivate: boolean;
  isRequired: boolean;
  name: string;
  type: string;
  value: string;
};

const metadataToArray = (records?: Record<string, MetadataField> | null) => {
  if (!records) {
    return [];
  }

  const nodes: MetadataField[] = [];
  for (const value of Object.values(records)) {
    nodes.push(value);
  }

  return nodes;
};

type Props = {
  readonly className?: string;
  readonly fetching?: boolean;
  readonly memberMetadataFields?: Organization['memberMetadataFields'];
  readonly organizationId: string;
};

const CustomMetadata = ({
  className,
  fetching = false,
  memberMetadataFields,
  organizationId,
}: Props) => {
  const metadataRows = metadataToArray(memberMetadataFields);

  if (fetching) {
    return <Skeleton className="w-full h-64" />;
  }

  if (metadataRows.length === 0) {
    return (
      <EmptyState
        description="Start customizing your member's information by adding a new field."
        title="No Metadata Fields"
      />
    );
  }

  return (
    <div className={cn('space-y-4', className)}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Default</TableHead>
            <TableHead>
              <span className="sr-only">Actions</span>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {metadataRows.length > 0 ? (
            metadataRows.map((item) => (
              <TableRow key={item.name}>
                <TableCell className="font-semibold">
                  <div className="flex items-center gap-2">
                    {item.name}
                    {item.isRequired && (
                      <Star className="ml-auto size-4 fill-red-500 text-red-500" />
                    )}
                    {item.isPrivate && (
                      <Eye className="ml-auto size-4 text-primary" />
                    )}
                  </div>
                </TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item.defaultValue}</TableCell>
                <TableCell className="text-right">
                  <DeleteCustomMetadataFieldDialog
                    fieldName={item.name}
                    memberMetadataFields={memberMetadataFields}
                    organizationId={organizationId}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <Empty message="You can add additional member information by creating a custom member field." />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Alert>
        <ul className="space-y-1">
          <li className="flex items-center">
            <Eye className="size-4 mr-2 text-primary" />
            <p className="text-sm whitespace-nowrap truncate text-muted-foreground">
              Private fields will remain hidden from members.
            </p>
          </li>
          <li className="flex items-center">
            <Star className="size-4 mr-2 fill-red-500 text-red-500" />
            <p className="text-sm whitespace-nowrap truncate text-muted-foreground">
              Required Fields must contain a value.
            </p>
          </li>
        </ul>
      </Alert>
    </div>
  );
};

export { CustomMetadata };
