import {
  display12HourValue,
  type Period,
  setDateByType,
} from './time-picker-utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import * as React from 'react';

export type TimePickerPeriodSelectProps = {
  readonly date: Date | undefined;
  readonly onLeftFocus?: () => void;
  readonly onRightFocus?: () => void;
  readonly period: Period;
  readonly setDate: (date: Date | undefined) => void;
  readonly setPeriod: (m: Period) => void;
};

const TimePickerPeriodSelect = React.forwardRef<
  HTMLButtonElement,
  TimePickerPeriodSelectProps
>(({ date, onLeftFocus, onRightFocus, period, setDate, setPeriod }, ref) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'ArrowRight') onRightFocus?.();
    if (event.key === 'ArrowLeft') onLeftFocus?.();
  };

  const handleValueChange = (value: Period) => {
    setPeriod(value);

    /**
     * trigger an update whenever the user switches between AM and PM;
     * otherwise user must manually change the hour each time
     */
    if (date) {
      const temporaryDate = new Date(date);
      const hours = display12HourValue(date.getHours());
      setDate(
        setDateByType(
          temporaryDate,
          hours.toString(),
          '12hours',
          period === 'AM' ? 'PM' : 'AM',
        ),
      );
    }
  };

  return (
    <div className="flex h-10 items-center">
      <Select
        onValueChange={(value: Period) => handleValueChange(value)}
        value={period}
      >
        <SelectTrigger
          className="w-[65px] focus:bg-accent focus:text-accent-foreground"
          onKeyDown={handleKeyDown}
          ref={ref}
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="AM">AM</SelectItem>
          <SelectItem value="PM">PM</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
});

TimePickerPeriodSelect.displayName = 'TimePickerPeriodSelect';

export { TimePickerPeriodSelect };
