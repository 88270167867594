import { Datetime } from '@/components/Datetime';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DataList,
  DataListItem,
  DataListLabel,
  DataListValue,
} from '@/components/ui/data-list';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
  dataFromValues,
  type DataSchema,
  fieldFromScope,
  isUISchemaControl,
  isUISchemaGroup,
  isUISchemaLayout,
  type UISchema,
} from '@/lib/incidentType';

const LABEL_CLASS = 'w-3/5 self-start';

type IncidentDataBuilderProps = {
  readonly data: Record<string, unknown>;
  readonly dataSchema: DataSchema;
  readonly path?: string;
  readonly uiSchema: UISchema;
};

// eslint-disable-next-line complexity
const IncidentDataBuilder = ({
  data,
  dataSchema,
  path = '#',
  uiSchema,
}: IncidentDataBuilderProps) => {
  if (isUISchemaLayout(uiSchema)) {
    return uiSchema.elements.map((item, index) => (
      <IncidentDataBuilder
        data={data}
        dataSchema={dataSchema}
        key={path + '-' + index.toString()}
        path={path + '-' + index.toString()}
        uiSchema={item}
      />
    ));
  } else if (isUISchemaGroup(uiSchema)) {
    return (
      <DataListItem>
        <DataListLabel className={LABEL_CLASS}>{uiSchema.label}</DataListLabel>
        <DataListValue className="w-full">
          <DataList
            className="gap-4"
            orientation="horizontal"
          >
            {uiSchema.elements.map((item, index) => (
              <IncidentDataBuilder
                data={data}
                dataSchema={dataSchema}
                key={path + '-' + index.toString()}
                path={path + '-' + index.toString()}
                uiSchema={item}
              />
            ))}
          </DataList>
        </DataListValue>
      </DataListItem>
    );
  } else if (isUISchemaControl(uiSchema)) {
    const fieldType = fieldFromScope(uiSchema.scope, dataSchema);

    let fieldValue = dataFromValues(
      uiSchema.scope.replaceAll('properties/', ''),
      data,
    );

    if (uiSchema.label === 'Subject') {
      return null;
    }

    if (fieldType.default !== undefined && fieldValue === undefined) {
      fieldValue = fieldType.default;
    }

    if (fieldValue === undefined && fieldType.type === 'boolean') {
      fieldValue = false;
    } else if (!fieldValue) {
      fieldValue = '-- No Data --';
    }

    if (fieldType.type === 'string') {
      if (uiSchema.options?.format === 'radio') {
        return (
          <DataListItem>
            <DataListLabel className={LABEL_CLASS}>
              {uiSchema.label}
            </DataListLabel>
            <DataListValue className="w-full">
              <RadioGroup
                className="grid grid-cols-3 gap-2"
                defaultValue={fieldValue?.toString()}
                id={uiSchema.scope}
              >
                {fieldType.oneOf?.map((item) => (
                  <div
                    className="flex items-center"
                    key={item.const}
                  >
                    <RadioGroupItem
                      disabled
                      value={item.const}
                    />
                    <Label className="ml-3 font-normal">{item.title}</Label>
                  </div>
                ))}
              </RadioGroup>
            </DataListValue>
          </DataListItem>
        );
      } else if (fieldType.format === 'date') {
        return (
          <DataListItem>
            <DataListLabel className={LABEL_CLASS}>
              {uiSchema.label}
            </DataListLabel>
            <DataListValue className="w-full">
              <time
                className="text-inherit lining-nums tabular-nums whitespace-nowrap"
                dateTime={fieldValue?.toString().slice(0, 10)}
              >
                {fieldValue?.toString().slice(0, 10)}
              </time>
            </DataListValue>
          </DataListItem>
        );
      } else if (fieldType.format === 'date-time') {
        return (
          <DataListItem>
            <DataListLabel className={LABEL_CLASS}>
              {uiSchema.label}
            </DataListLabel>
            <DataListValue className="w-full">
              <Datetime
                datetime={fieldValue?.toString()}
                passthrough
              />
            </DataListValue>
          </DataListItem>
        );
      } else {
        return (
          <DataListItem>
            <DataListLabel className={LABEL_CLASS}>
              {uiSchema.label}
            </DataListLabel>
            <DataListValue className="w-full">
              {fieldValue?.toString()}
            </DataListValue>
          </DataListItem>
        );
      }
    } else if (fieldType.type === 'number') {
      return (
        <DataListItem>
          <DataListLabel className={LABEL_CLASS}>
            {uiSchema.label}
          </DataListLabel>
          <DataListValue className="w-full">
            {fieldValue?.toString()}
          </DataListValue>
        </DataListItem>
      );
    } else if (fieldType.type === 'boolean') {
      return (
        <DataListItem>
          <DataListLabel className="w-1/2">{uiSchema.label}</DataListLabel>
          <DataListValue className="w-1/2">
            <Checkbox
              checked={typeof fieldValue === 'boolean' ? fieldValue : false}
              id={uiSchema.scope}
            />
          </DataListValue>
        </DataListItem>
      );
    }

    return null;
  }

  return null;
};

export { IncidentDataBuilder };
