import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Checkbox } from './ui/checkbox';
import { type Member } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { forwardRef, useMemo } from 'react';

type MembersCheckboxProps = {
  readonly className?: string;
  readonly includeDisabled?: boolean;
  readonly members?: Array<
    Pick<Member, 'fullName' | 'displayName' | 'avatarUrl' | 'id'>
  > | null;
  readonly onChange: (newValues: string[]) => void;
  readonly value: string[];
};

const MembersCheckbox = forwardRef<HTMLDivElement, MembersCheckboxProps>(
  (props, ref) => {
    const { className, members = [], onChange, value, ...rest } = props;

    const options = useMemo(() => members ?? [], [members]);

    return (
      <div
        className={cn('p-2 space-y-4', className)}
        ref={ref}
        {...rest}
      >
        {options.map((item) => (
          <div
            className="flex items-center space-x-2"
            key={item.id}
          >
            <Checkbox
              checked={value.includes(item.id)}
              id={item.id}
              onCheckedChange={(checked) => {
                return checked
                  ? onChange([...value, item.id])
                  : onChange(
                      value.filter((filterItem) => filterItem !== item.id),
                    );
              }}
            />
            <label
              className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex items-center gap-2"
              htmlFor={item.id}
            >
              <Avatar className="h-8 w-8">
                <AvatarImage
                  alt={item.displayName ?? item.fullName}
                  src={item.avatarUrl ?? undefined}
                />
                <AvatarFallback>
                  {(
                    item.displayName ??
                    item.fullName ??
                    'Deleted Member'
                  )?.slice(0, 2)}
                </AvatarFallback>
              </Avatar>
              {item.displayName ?? item.fullName ?? 'Deleted User'}
            </label>
          </div>
        ))}
      </div>
    );
  },
);

export { MembersCheckbox, type MembersCheckboxProps };
