import { MutationError } from '../../Core';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { graphql } from '@/gql';
import { type Group } from '@/gql/graphql';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Loader } from 'lucide-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { type Infer, nonempty, object, string } from 'superstruct';
import { useMutation } from 'urql';

const GroupUpdateGql = graphql(`
  mutation GroupUpdateGql($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      group {
        id
        name
        description
        updatedAt
      }
    }
  }
`);

const schema = object({
  description: string(),
  name: nonempty(string()),
});

type Props = {
  readonly group?: Pick<Group, 'id' | 'name' | 'description'> | null;
};

const GroupUpdate = ({ group }: Props) => {
  const [{ error, fetching }, updateGroup] = useMutation(GroupUpdateGql);

  const form = useForm<Infer<typeof schema>>({
    defaultValues: {
      description: group?.description ?? '',
      name: group?.name ?? '',
    },
    resolver: superstructResolver(schema),
  });

  useEffect(() => {
    if (group) {
      form.reset({
        description: group.description,
        name: group.name,
      });
    }
  }, [form, group]);

  const onSubmit = async (values: Infer<typeof schema>) => {
    if (group) {
      const response = await updateGroup({
        input: {
          description: values.description,
          id: group.id,
          name: values.name,
        },
      });

      if (!response.error) {
        form.reset();
      }
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Group Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="About this group..."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <MutationError error={error} />

          <div className="flex justify-end gap-2">
            {form.formState.isDirty && (
              <Button
                disabled={fetching}
                onClick={() => {
                  form.reset();
                }}
                type="reset"
                variant="ghost"
              >
                Reset
              </Button>
            )}
            <Button
              disabled={fetching || !form.formState.isDirty}
              type="submit"
            >
              {fetching && <Loader className="size-6 animate-spin" />}
              Save
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export { GroupUpdate };
