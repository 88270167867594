import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { Users } from 'lucide-react';
import { useQuery } from 'urql';

const MemberCountGql = graphql(`
  query MemberCountGql($organizationId: String!) {
    organizationMetric(organizationId: $organizationId) {
      nodeId
      invitations
      members
      membershipRequests
    }
  }
`);

type Props = {
  readonly organizationId: string;
};

const MemberCount = ({ organizationId }: Props) => {
  const [{ data }] = useQuery({
    query: MemberCountGql,
    variables: {
      organizationId,
    },
  });

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle>Organization Members</CardTitle>
        <Users className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent className="grid md:grid-cols-3">
        <div className="pt-2">
          <div className="text-sm text-muted-foreground">Active</div>
          {data?.organizationMetric ? (
            <div className="text-2xl font-bold">
              {data?.organizationMetric?.members.toLocaleString()}
            </div>
          ) : (
            <Skeleton className="h-8 w-1/4" />
          )}
        </div>
        <div className="pt-2">
          <div className="text-sm text-muted-foreground">Invitations</div>
          {data?.organizationMetric ? (
            <div className="text-2xl font-bold">
              {data?.organizationMetric?.invitations.toLocaleString()}
            </div>
          ) : (
            <Skeleton className="h-8 w-1/4" />
          )}
        </div>
        <div className="pt-2">
          <div className="text-sm text-muted-foreground">
            Membership Requests
          </div>
          {data?.organizationMetric ? (
            <div className="text-2xl font-bold">
              {data?.organizationMetric?.membershipRequests.toLocaleString()}
            </div>
          ) : (
            <Skeleton className="h-8 w-1/4" />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export { MemberCount };
