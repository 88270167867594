import { Button } from './ui/button';
import { Calendar } from './ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { type Table } from '@tanstack/react-table';
import { endOfDay, format, startOfDay } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { type DateRange } from 'react-day-picker';

type DataTableTimerangeFilterProps<T> = {
  readonly className?: string;
  readonly columnId: string;
  readonly table: Table<T>;
};

const DataTableTimerangeFilter = <TData,>({
  className,
  columnId,
  table,
}: DataTableTimerangeFilterProps<TData>) => {
  const value = columnId as string;
  const column = table.getColumn(value);

  if (!column) {
    return null;
  }

  const filterValue = table
    .getState()
    .columnFilters.find((index: { id: string }) => index.id === value)
    ?.value as number[];

  const [from, to] = filterValue ?? [];

  const date = from
    ? to
      ? {
          from: new Date(from),
          to: new Date(to),
        }
      : { from: new Date(from) }
    : undefined;

  const setDate = (newValue: DateRange | undefined) => {
    if (!newValue?.from && !newValue?.to) {
      column.setFilterValue([]);
    } else if (newValue.from && !newValue.to) {
      column.setFilterValue([startOfDay(newValue.from)]);
    } else if (newValue.to && newValue.from) {
      column.setFilterValue([startOfDay(newValue.from), endOfDay(newValue.to)]);
    }
  };

  const content = date?.from
    ? date.to
      ? `${format(date.from, 'MM/dd/y')} - ${format(date.to, 'MM/dd/y')}`
      : `${format(date.from, 'MM/dd/y')} - now`
    : 'Date';

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            `w-[${content.length}cd] h-8 text-primary`,
            !date && 'text-foreground border-dashed',
            className,
          )}
          size="sm"
          variant="outline"
        >
          <CalendarIcon />
          {content}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="w-auto p-0"
      >
        <Calendar
          defaultMonth={date?.from}
          initialFocus
          mode="range"
          numberOfMonths={1}
          onSelect={setDate}
          selected={date}
        />
        {date && (
          <div className="border-t p-1">
            <Button
              className="w-full"
              onClick={() => {
                column.setFilterValue([]);
              }}
              variant="ghost"
            >
              Clear filters
            </Button>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export { DataTableTimerangeFilter, type DataTableTimerangeFilterProps };
