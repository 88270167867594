import { Datetime } from '@/components/Datetime';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { datetime } from '@/utils';
import { CircleAlert, CircleCheck, CircleHelp } from 'lucide-react';

type CheckInResponseStatusProps = {
  readonly createdAt?: string;
  readonly cutoff?: string;
};

const CheckInResponseStatus = ({
  createdAt,
  cutoff,
}: CheckInResponseStatusProps) => {
  const now = new Date();

  if (cutoff) {
    if (createdAt) {
      if (datetime(createdAt) < datetime(cutoff)) {
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <CircleCheck className="text-green-600 h-6 w-6" />
            </TooltipTrigger>
            <TooltipContent side="right">
              <p className="text-center">
                Checked In On-Time
                <br />
                <Datetime datetime={createdAt} />
              </p>
            </TooltipContent>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <CircleCheck className="text-yellow-400 h-6 w-6" />
            </TooltipTrigger>
            <TooltipContent side="right">
              <p className="text-center">
                Checked In Late
                <br />
                <Datetime datetime={createdAt} />
              </p>
            </TooltipContent>
          </Tooltip>
        );
      }
    } else if (now < datetime(cutoff)) {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleHelp className="text-gray-400 h-6 w-6" />
          </TooltipTrigger>
          <TooltipContent side="right">
            <p>Waiting...</p>
          </TooltipContent>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <CircleAlert className="text-red-400 h-6 w-6" />
          </TooltipTrigger>
          <TooltipContent side="right">
            <p>Missed Cutoff</p>
          </TooltipContent>
        </Tooltip>
      );
    }
  } else if (createdAt) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <CircleCheck className="text-green-600 h-6 w-6" />
        </TooltipTrigger>
        <TooltipContent side="right">
          <p>
            Checked In <Datetime datetime={createdAt} />
          </p>
        </TooltipContent>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <CircleHelp className="text-gray-400 h-6 w-6" />
        </TooltipTrigger>
        <TooltipContent side="right">
          <p>Waiting...</p>
        </TooltipContent>
      </Tooltip>
    );
  }
};

export { CheckInResponseStatus };

// <Link to={`/check-in/${item.id}`}></Link>
// <CheckCircleIcon className="h-5 w-5 text-green-500" />
