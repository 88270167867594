import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { type Member } from '@/gql/graphql';
import { Link } from 'react-router-dom';

type Props = {
  readonly members: Array<
    Pick<Member, 'id' | 'fullName' | 'displayName' | 'avatarUrl'>
  >;
  readonly to?: string;
};

// className="size-10 rounded-full border-2 border-white dark:border-gray-800"
// height={40}
// width={40}

const GroupMemberAvatarGroup = ({ members = [], to }: Props) => {
  return (
    <div className="z-10 flex -space-x-4 hover:-space-x-1">
      {members.slice(0, 4).map((item) => (
        <Tooltip key={item.id}>
          <TooltipTrigger asChild>
            <Avatar className="size-8">
              <AvatarImage
                alt={item.displayName ?? item.fullName}
                src={item.avatarUrl ?? undefined}
              />
              <AvatarFallback className="size-8">
                {(item.displayName ?? item.fullName).slice(0, 2)}
              </AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent side="top">
            <p className="text-sm">{item.displayName ?? item.fullName}</p>
          </TooltipContent>
        </Tooltip>
      ))}
      {members.length > 5 && to && (
        <Link to={to}>
          <Avatar className="size-8">
            <AvatarFallback>+{members.length - 5}</AvatarFallback>
          </Avatar>
        </Link>
      )}
      {members.length > 5 && !to && (
        <Avatar className="size-8">
          <AvatarFallback>+{members.length - 5}</AvatarFallback>
        </Avatar>
      )}
    </div>
  );
};

export { GroupMemberAvatarGroup };
