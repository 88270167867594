import { ErrorBoundaryFallback } from '../../Core';
import { Header, HeaderTitle } from '@/components/header';
import { TabsListAlt, TabsTriggerAlt } from '@/components/tabs-alt';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Tabs } from '@/components/ui/tabs';
import { config } from '@/config';
import { ErrorBoundary } from '@sentry/react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const navItems = [
  {
    title: 'Profile',
    to: '/account/profile',
    value: 'profile',
  },
  {
    title: 'Memberships',
    to: '/account/memberships',
    value: 'memberships',
  },
  {
    title: 'Notifications',
    to: '/account/notifications',
    value: 'notifications',
  },
];

if (['development', 'local'].includes(config.STAGE)) {
  navItems.push({
    title: 'Debug',
    to: '/account/debug',
    value: 'debug',
  });
}

const AccountLayout = () => {
  const pathname = useLocation().pathname;
  const currentPage = navItems.find((x) => x.to === pathname) ?? navItems[0];

  return (
    <>
      <Header
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>Account</BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{currentPage.title}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>Account</HeaderTitle>
      </Header>

      <Tabs
        onValueChange={() => {}}
        value={currentPage.value}
      >
        <TabsListAlt>
          {navItems.map((item) => (
            <TabsTriggerAlt
              asChild
              key={item.value}
              value={item.value}
            >
              <Link to={item.to}>
                <span>{item.title}</span>
              </Link>
            </TabsTriggerAlt>
          ))}
        </TabsListAlt>
      </Tabs>

      <div className="pt-6">
        <ErrorBoundary
          fallback={ErrorBoundaryFallback}
          key={pathname}
        >
          <Outlet />
        </ErrorBoundary>
      </div>
    </>
  );
};

export { AccountLayout };
