import { NewCheckInReportSheet } from '../components/NewCheckInReportSheet';
import { useCheckInReportsSearchParameters } from '../hooks/useCheckInReportsSearchParameters';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { Datetime } from '@/components/Datetime';
import { Header, HeaderTitle } from '@/components/header';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { type DocumentType, graphql } from '@/gql';
import { CheckInReportsOrderBy } from '@/gql/graphql';
import { sorToOrderByParser } from '@/lib/data-table';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError, PermissionDeniedError } from '@/utils';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Plus } from 'lucide-react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

const CheckInReporsGql = graphql(`
  query CheckInReporsGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $shortId: String
    $createdAt: CheckInReportsCreatedAtConditionInput
    $requestorMemberId: String
    $orderBy: [CheckInReportsOrderBy!] = ID_DESC
  ) {
    checkInReports(
      first: $first
      offset: $offset
      condition: {
        createdAt: $createdAt
        organizationId: $organizationId
        requestorMemberId: $requestorMemberId
        shortId: $shortId
      }
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        shortId
        createdAt
        cutoffAt
        requestorMember {
          id
          fullName
          displayName
          avatarUrl
        }
        checkInRequests {
          totalCount
        }
        place {
          id
          name
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`);

type Column = NonNullable<
  DocumentType<typeof CheckInReporsGql>['checkInReports']
>['nodes'][0];

const columnHelper = createColumnHelper<Column>();

const COLUMNS = [
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <Link
        className="capitalize hover:text-primary"
        to={`/check-in-reports/${getValue()}`}
      >
        {row.original.shortId}
      </Link>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="ID"
      />
    ),
  }),

  columnHelper.accessor('requestorMember', {
    cell: ({ getValue }) => (
      <div className="flex items-center gap-2">
        <Avatar className="h-6 w-6">
          <AvatarImage src={getValue()?.avatarUrl ?? undefined} />
          <AvatarFallback>{getValue()?.fullName.slice(0, 2)}</AvatarFallback>
        </Avatar>
        <div className="text-sm">
          {getValue()?.displayName ?? getValue()?.fullName}
        </div>
      </div>
    ),
    header: () => 'Requested By',
  }),
  columnHelper.accessor('checkInRequests.totalCount', {
    cell: ({ getValue }) => getValue().toLocaleString(),
    header: () => 'Members',
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Created',
  }),
  columnHelper.accessor('cutoffAt', {
    cell: ({ getValue }) => (
      <Datetime
        datetime={getValue()}
        passthrough
      />
    ),
    header: () => 'Cutoff',
  }),
  columnHelper.accessor('place.name', {
    cell: ({ getValue }) =>
      getValue() ? <Badge variant="secondary">{getValue()}</Badge> : '',
    header: () => 'Places',
  }),
];

const filterFields: Array<DataTableFacetedFilterField<Column>> = [
  {
    label: 'Date',
    type: 'timerange',
    value: 'createdAt',
  },
];

const sorToOrderBy = sorToOrderByParser({
  id_asc: CheckInReportsOrderBy.IdAsc,
  id_desc: CheckInReportsOrderBy.IdDesc,
});

const CheckInReports = () => {
  const [searchParameters] = useSearchParams();
  const [organizationId, memberId, isSupervisor] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
    state.activeMembership?.isCheckInSupervisor,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  if (!isSupervisor) {
    throw new PermissionDeniedError();
  }

  const [queryParameters, setQueryParameters] =
    useCheckInReportsSearchParameters();

  const [{ data, fetching }, refresh] = useQuery({
    query: CheckInReporsGql,
    variables: {
      createdAt: {
        gte: queryParameters.createdAt?.at(0)?.toISOString() || null,
        lte: queryParameters.createdAt?.at(1)?.toISOString() || null,
      },
      first: queryParameters.pageSize,
      offset: queryParameters.pageIndex * queryParameters.pageSize,
      orderBy: sorToOrderBy(queryParameters.sort),
      organizationId,
      shortId: queryParameters.search || null,
    },
  });

  return (
    <>
      <Header
        actions={
          isSupervisor ? (
            <NewCheckInReportSheet
              defaultOpen={searchParameters.get('new') !== null}
              organizationId={organizationId}
            >
              <Button size="sm">
                <Plus />
                New Check In Report
              </Button>
            </NewCheckInReportSheet>
          ) : undefined
        }
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Check In Reports</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>Check In Reports</HeaderTitle>
      </Header>

      <DataTable
        columns={COLUMNS as Array<ColumnDef<Column>>}
        data={data?.checkInReports?.nodes}
        fetching={fetching}
        filterFields={filterFields}
        queryParameters={queryParameters}
        refresh={refresh}
        rowCount={data?.checkInReports?.totalCount}
        searchPlaceholder="Search by ID..."
        setQueryParameters={setQueryParameters}
      />
    </>
  );
};

export { CheckInReports };
