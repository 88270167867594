import { useCheckInsSearchParameters } from '../hooks/useCheckInsSearchParameters';
import { DashboardBreadcrumbLink } from '@/components/breadcrumbs';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { type DataTableFacetedFilterField } from '@/components/data-table-faceted-filter';
import { Datetime } from '@/components/Datetime';
import { Header, HeaderTitle } from '@/components/header';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { type DocumentType, graphql } from '@/gql';
import { CheckInsOrderBy } from '@/gql/graphql';
import { sorToOrderByParser } from '@/lib/data-table';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { FileCheck } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const CheckInsGql = graphql(`
  query CheckInsGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $shortId: String
    $createdAt: CheckInsCreatedAtConditionInput
    $memberId: String
    $orderBy: [CheckInsOrderBy!] = ID_DESC
  ) {
    checkIns(
      first: $first
      offset: $offset
      condition: {
        createdAt: $createdAt
        organizationId: $organizationId
        shortId: $shortId
        memberId: $memberId
      }
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        shortId
        createdAt
        memberId
        member {
          id
          fullName
          displayName
          avatarUrl
        }
        places {
          nodes {
            id
            name
            spatialData {
              geojson
            }
            radius
            center {
              longitude
              latitude
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

type Column = NonNullable<
  DocumentType<typeof CheckInsGql>['checkIns']
>['nodes'][0];

const columnHelper = createColumnHelper<Column>();

const COLUMNS = [
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <Link
        className="capitalize hover:text-primary"
        to={`/check-in/${getValue()}`}
      >
        {row.original.shortId}
      </Link>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="ID"
      />
    ),
  }),
  columnHelper.accessor('member', {
    cell: ({ getValue }) => (
      <div className="flex items-center gap-2">
        <Avatar className="h-6 w-6">
          <AvatarImage src={getValue()?.avatarUrl ?? undefined} />
          <AvatarFallback>{getValue()?.fullName.slice(0, 2)}</AvatarFallback>
        </Avatar>
        <div className="text-sm">
          {getValue()?.displayName ?? getValue()?.fullName}
        </div>
      </div>
    ),
    header: () => 'Member',
  }),
  columnHelper.accessor('createdAt', {
    cell: ({ getValue }) => <Datetime datetime={getValue()} />,
    header: () => 'Created',
  }),
  columnHelper.accessor('places', {
    cell: ({ getValue }) => (
      <ul className="flex flex-wrap gap-2">
        {getValue().nodes.map((item) => (
          <li key={item.id}>
            <Badge variant="secondary">{item.name}</Badge>
          </li>
        ))}
      </ul>
    ),
    header: () => 'Places',
  }),
];

const filterFields: Array<DataTableFacetedFilterField<Column>> = [
  {
    label: 'Date',
    type: 'timerange',
    value: 'createdAt',
  },
];

const sorToOrderBy = sorToOrderByParser({
  id_asc: CheckInsOrderBy.IdAsc,
  id_desc: CheckInsOrderBy.IdDesc,
});

const CheckIns = () => {
  const [organizationId, memberId, isSupervisor] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
    state.activeMembership?.isCheckInSupervisor,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const [queryParameters, setQueryParameters] = useCheckInsSearchParameters();

  const [{ data, fetching }, refresh] = useQuery({
    query: CheckInsGql,
    requestPolicy: 'cache-and-network',
    variables: {
      createdAt: {
        gte: queryParameters.createdAt?.at(0)?.toISOString() || null,
        lte: queryParameters.createdAt?.at(1)?.toISOString() || null,
      },
      first: queryParameters.pageSize,
      offset: queryParameters.pageIndex * queryParameters.pageSize,
      orderBy: sorToOrderBy(queryParameters.sort),
      organizationId,
      shortId: queryParameters.search || null,
    },
  });

  return (
    <>
      <Header
        actions={
          isSupervisor ? (
            <div className="ml-auto">
              <Link to="/check-in-reports">
                <Button size="sm">
                  <FileCheck />
                  Check In Reports
                </Button>
              </Link>
            </div>
          ) : undefined
        }
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <DashboardBreadcrumbLink />
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Check In</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <HeaderTitle>Check In</HeaderTitle>
      </Header>

      <DataTable
        columns={COLUMNS as Array<ColumnDef<Column>>}
        data={data?.checkIns?.nodes}
        fetching={fetching}
        filterFields={filterFields}
        queryParameters={queryParameters}
        refresh={refresh}
        rowCount={data?.checkIns?.totalCount}
        searchPlaceholder="Search by ID..."
        setQueryParameters={setQueryParameters}
      />
    </>
  );
};

export { CheckIns };
