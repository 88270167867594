import { JoinOrganization } from '../components/JoinOrganization';
import { MembershipList } from '../components/MembershipList';
import {
  AnnotatedContent,
  AnnotatedHeader,
  AnnotatedLayout,
  AnnotatedSection,
  AnnotatedSeparator,
} from '@/components/annotated';
import { graphql } from '@/gql';
import { useQuery } from 'urql';

const MembershipsGql = graphql(`
  query MembershipsGql {
    currentUser {
      id
      memberships {
        totalCount
        nodes {
          createdAt
          id
          organization {
            id
            logoUrl
            name
          }
          organizationRole
        }
      }
      invitations {
        totalCount
        nodes {
          id
          key
          organizationRole
          organizationName
          organization {
            id
            logoUrl
          }
        }
      }
      membershipRequests {
        totalCount
        nodes {
          id
          organizationName
          organizationId
          createdAt
        }
      }
    }
  }
`);

const Memberships = () => {
  const [{ data, fetching }] = useQuery({
    query: MembershipsGql,
  });

  return (
    <AnnotatedLayout>
      <AnnotatedSection aria-labelledby="join-organization">
        <AnnotatedHeader
          description="Use a Membership Code to request access to an organization."
          title="Join an organization"
          titleId="join-organization"
        />

        <AnnotatedContent>
          <JoinOrganization />
        </AnnotatedContent>
      </AnnotatedSection>

      <AnnotatedSeparator />

      <AnnotatedSection aria-labelledby="membership-list">
        <AnnotatedHeader
          description="Manage the organizations you belong to."
          title="Organization memberships"
          titleId="membership-list"
        />

        <AnnotatedContent>
          <MembershipList
            fetching={fetching}
            invitations={data?.currentUser?.invitations.nodes}
            membershipRequests={data?.currentUser?.membershipRequests.nodes}
            memberships={data?.currentUser?.memberships.nodes}
          />
        </AnnotatedContent>
      </AnnotatedSection>
    </AnnotatedLayout>
  );
};

export { Memberships };
