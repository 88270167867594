import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { graphql } from '@/gql';
import { toast } from '@/lib/toast';
import { EllipsisVertical } from 'lucide-react';
import { useMutation } from 'urql';

const IncidentReportPdfGql = graphql(`
  mutation IncidentReportPdfGql($input: GenerateIncidentPdfInput!) {
    generateIncidentPdf(input: $input) {
      incidentId
      downloadUrl
    }
  }
`);

type Props = {
  readonly incidentId: string;
};

const IncidentReportPdf = ({ incidentId }: Props) => {
  const [, executeMutation] = useMutation(IncidentReportPdfGql);

  const handleOnClick = async () => {
    const response = await executeMutation({ input: { incidentId } });

    if (response.error) {
      toast.error('Problem generating pdf!', {
        description: response.error.message
          ? response.error.message
          : undefined,
      });
    } else if (response.data?.generateIncidentPdf?.downloadUrl) {
      const a = document.createElement('a');
      a.href = response.data.generateIncidentPdf.downloadUrl;
      a.target = '_blank';
      a.click();
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          size="sm"
          variant="outline"
        >
          <EllipsisVertical className="w-4 h-4" />
          <span className="sr-only">More Actions</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        side="bottom"
      >
        <DropdownMenuItem onClick={handleOnClick}>
          <span>Download Report</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { IncidentReportPdf };
